
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import TitlePopup from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ColumnsListMail } from '../shared/contants'
import { ColumnsListMailAdditional } from '../shared/contants'
import { isObject } from '@/shared/function'
import { filterStatusTable } from '@/shared/function'
import { listTypeMail } from '@/shared/constants'
import { DeleteFlg, EditPermission, RecieverFlg } from '@/shared/enums'
import { ListNamePopup } from '@/shared/enums'
import TooltipCustom from '@/components/common_new/tooltip/index.vue'

export default Vue.extend({
    name: 'MailListAdvisor',
    components: { TooltipCustom, ButtonCustom, TitlePopup },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataListMail',
            'getRoleEditAdvisor'
        ])
    },
    data(): any {
        return {
            ColumnsListMail,
            ColumnsListMailAdditional,
            listMail: [],
            listMailAdditional: [],
            pageIndex: 1,
            listTypeMail,
            RecieverFlg,
            EditPermission
        }
    },
    mounted() {
        this.$store.dispatch('signupAdvisor/getRoleEditAdvisor')
        this.getListDataMail()
    },
    watch: {
        getDataListMail(newVal, oldVal) {
            if (newVal !== oldVal && isObject(newVal)) {
                if (newVal?.email_type12) {
                    this.listMail = newVal?.email_type12.map(
                        (item: any, index: number) => {
                            return {
                                id: item.id,
                                key: index,
                                type: item.type,
                                email: item.email,
                                reciever_flg:
                                    item.reciever_flg === RecieverFlg.TRUE,
                                delete_flg: false
                            }
                        }
                    )
                }
                if (newVal?.email_type3) {
                    this.listMailAdditional = newVal?.email_type3.map(
                        (item: any, index: number) => {
                            return {
                                id: item.id,
                                key: index,
                                name: item.name,
                                email: item.email,
                                reciever_flg:
                                    item.reciever_flg === RecieverFlg.TRUE,
                                delete_flg: false
                            }
                        }
                    )
                }
            }
        }
    },
    methods: {
        filterStatusTable,
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        getListDataMail() {
            this.$store.dispatch('signupAdvisor/getListMail', 0)
        },
        onChange(index: any, type: string) {
            switch (type) {
                case 'receiver':
                    this.listMail[index].reciever_flg =
                        !this.listMail[index].reciever_flg
                    break
                case 'receiver_add':
                    this.listMailAdditional[index].reciever_flg =
                        !this.listMailAdditional[index].reciever_flg
                    break
                case 'delete':
                    this.listMailAdditional[index].delete_flg =
                        !this.listMailAdditional[index].delete_flg
                    break
            }
        },
        async updateMailList() {
            let allData = [...this.listMail, ...this.listMailAdditional]
            let payload: {
                id: any
                reciever_flg: number
                delete_flg: number
            }[] = []
            allData.forEach((element, index) => {
                let dataPost = {
                    id: element.id,
                    reciever_flg: element.reciever_flg
                        ? RecieverFlg.TRUE
                        : RecieverFlg.FALSE,
                    delete_flg: element.delete_flg
                        ? DeleteFlg.TRUE
                        : DeleteFlg.FALSE
                }
                payload.push(dataPost)
            })
            await this.$store.dispatch('signupAdvisor/updateListEmail', payload)
        },
        showPopupAddMail(payload: any) {
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.AddMailAdvisor,
                payload: payload
            })
        }
    }
})
