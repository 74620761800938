import {
    ITypeCheckboxServiceContent,
    ITypeSelectCustom
} from '@/shared/constants/IContants'
import { ProductGenre } from '../enums'

const CheckboxServiceDomain = {
    1: '会計ソフト・システム',
    2: 'コンサルタント',
    3: '士業',
    4: '不動産',
    5: '許認可',
    6: '事業承継'
}

const CheckboxServiceDomainAll = [
    '会計ソフト・システム',
    'コンサルタント',
    '士業',
    '不動産',
    '許認可',
    '事業承継'
]

const CheckboxServiceContent: ITypeCheckboxServiceContent = {
    1: '会計ソフト',
    2: '経営管理システム',
    3: '融資・資金繰り',
    4: '補助金・助成金',
    5: 'コスト改善',
    6: '弁護士',
    7: '司法書士',
    8: '社会保険労務士',
    9: '中小企業診断士',
    10: '行政書士',
    11: '弁理士',
    12: '不動産売買',
    13: '不動産賃貸管理',
    14: '有効活用',
    15: '特許・商標登録',
    16: '職業紹介・労務',
    17: '建設・不動産',
    18: '環境許可（産業廃棄物等）',
    19: 'ビザ申請',
    20: '営業許可',
    21: 'M&A',
    22: '事業承継 '
}

const CheckboxProductGenre = [
    {
        value: ProductGenre.SOFTWARE_SYSTEM,
        label: '会計ソフト・システム',
        feature: '1|2'
    },
    {
        value: ProductGenre.CONSULTANT,
        label: 'コンサルタント',
        feature: '3|4|5'
    },
    {
        value: ProductGenre.PROFESSIONAL,
        label: '士業',
        feature: '6|7|8|9|10|11'
    },
    {
        value: ProductGenre.REAL_ESTATE,
        label: '不動産',
        feature: '12|13|14'
    },
    {
        value: ProductGenre.APPROVAL,
        label: '許認可',
        feature: '15|16|17|18|19|20'
    },
    {
        value: ProductGenre.BUSINESS_SUCCESSION,
        label: '事業承継',
        feature: '21|22'
    }
]

const CheckboxProductFeature: ITypeSelectCustom[] = [
    { label: '会計ソフト', value: 1 },
    { label: '経営管理システム', value: 2 },
    { label: '融資・資金繰り', value: 3 },
    { label: '補助金・助成金', value: 4 },
    { label: 'コスト改善', value: 5 },
    { label: '弁護士', value: 6 },
    { label: '司法書士', value: 7 },
    { label: '社会保険労務士', value: 8 },
    { label: '中小企業診断士', value: 9 },
    { label: '行政書士', value: 10 },
    { label: '弁理士', value: 11 },
    { label: '不動産売買', value: 12 },
    { label: '不動産賃貸管理', value: 13 },
    { label: '有効活用', value: 14 },
    { label: '特許・商標登録', value: 15 },
    { label: '職業紹介・労務', value: 16 },
    { label: '建設・不動産', value: 17 },
    { label: '環境許可（産業廃棄物等）', value: 18 },
    { label: 'ビザ申請', value: 19 },
    { label: '営業許可', value: 20 },
    { label: 'M&A', value: 21 },
    { label: '事業承継 ', value: 22 }
]

const FeeType = [
    { label: '有り', value: 0 },
    { label: '無し', value: 1 },
    { label: '相談', value: 2 }
]

const StatusProgress = [
    { label: '開始', value: 1 },
    { label: '進行中', value: 2 },
    { label: 'トラブル中', value: 3 },
    { label: '終了', value: 4 }
]

export {
    CheckboxServiceDomain,
    CheckboxServiceDomainAll,
    CheckboxServiceContent,
    CheckboxProductGenre,
    CheckboxProductFeature,
    StatusProgress,
    FeeType
}
