
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SampleFeesPreviewForm from '@/modules/advisor/modules/signup/components/sample-fees/SampleFeesPreviewForm.vue'
import { StatusPublicSampleFees } from '@/shared/enums'

export default Vue.extend({
    name: 'SampleFeesPreview',
    data() {
        return {
            listSampleFees: [] as any
        }
    },
    components: { SampleFeesPreviewForm },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataSampleFeesAdvisor',
            'getBaseDomainSampleFee',
            'getDataPreviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', ['getDataSamplePeeAdvisorAdmin'])
    },
    mounted() {
        if (this.getDataPreviewAdvisor.isAdmin) {
            this.listSampleFees = this.getDataSampleFees(
                this.getDataSamplePeeAdvisorAdmin.tax_advisor_sample_fees
            )
            return
        }
        this.listSampleFees = this.getDataSampleFees(
            this.getDataPreviewAdvisor.listSampleFees ??
                this.getDataSampleFeesAdvisor.tax_advisor_sample_fees
        )
    },
    methods: {
        getDataSampleFees(dataSampleFees: any) {
            let listSampleFees: any[] = []
            dataSampleFees.forEach((item: any) => {
                const special_domain_name = this.getBaseDomainSampleFee.find(
                    (domain: any) => {
                        return domain.value === item.domain
                    }
                )
                listSampleFees.push({
                    ...item,
                    special_domain_name: special_domain_name?.label
                })
            })
            listSampleFees = listSampleFees.filter(
                (item: any) => item.public_flg === StatusPublicSampleFees.public
            )

            return listSampleFees
        }
    }
})
