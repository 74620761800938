
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import PreviewInfoAdvisorComponent from '@/modules/advisor/modules/signup/components/preview/index.vue'
import Loading from '@/components/common_new/loading/index.vue'
import { mapGetters } from 'vuex'
import { isArray } from '@/shared/function'

export default Vue.extend({
    name: 'PreviewInfoAdvisor',
    components: { PreviewInfoAdvisorComponent },
    computed: {
        ...mapGetters('popup', ['getIsLoading']),
        ...mapGetters('signupAdvisor', ['getDataPreviewAdvisor'])
    },
    data() {
        return {
            keyComponent: 0
        }
    },
    async created() {
        if (this.getDataPreviewAdvisor.isRegistration) {
            return
        }
        if (this.getDataPreviewAdvisor.isAdmin) {
            const query = this.getDataPreviewAdvisor?.advisorId
            const res = await Promise.all([
                this.getDataPreviewAdvisor.basicInfo
                    ? ''
                    : this.$store.dispatch(
                          'advisorAdmin/getDataAdvisorBasicInfo',
                          query
                      ),
                this.getDataPreviewAdvisor.officeInfo
                    ? ''
                    : this.$store.dispatch(
                          'advisorAdmin/getDataOfficeInfo',
                          query
                      ),
                this.$store.dispatch(
                    'advisorAdmin/getDataPromotionVideo',
                    query
                ),
                this.$store.dispatch(
                    'advisorAdmin/getDataAdvisorAffiliate',
                    query
                ),
                this.$store.dispatch(
                    'advisorAdmin/getDataAdvisorSampleFees',
                    query
                ),
                this.$store.dispatch(
                    'advisorAdmin/getDataInterviewAdvisor',
                    query.id
                ),
                this.$store.dispatch('signupAdvisor/getDomainSampleFee'),
                this.$store.dispatch('advisorAdmin/getListTaxGroup'),

                this.$store.dispatch('popup/setLoading', true, {
                    root: true
                })
            ])
            this.keyComponent += 1
        }
        if (
            !this.getDataPreviewAdvisor.isRegistration &&
            !this.getDataPreviewAdvisor.isAdmin
        ) {
            const res = await Promise.all([
                this.getDataPreviewAdvisor.basicInfo
                    ? ''
                    : this.$store.dispatch('signupAdvisor/getBasicInfo'),
                this.getDataPreviewAdvisor.officeInfo
                    ? ''
                    : this.$store.dispatch('signupAdvisor/getOfficeInfo'),
                this.getDataPreviewAdvisor.listAffiliate
                    ? ''
                    : [
                          this.$store.dispatch('signupAdvisor/getTaxAccInfo'),
                          this.$store.dispatch('signupAdvisor/getListTaxGroup')
                      ],
                this.getDataPreviewAdvisor.listSampleFees
                    ? ''
                    : [
                          this.$store.dispatch(
                              'signupAdvisor/getDataSampleFees'
                          ),
                          this.$store.dispatch(
                              'signupAdvisor/getDomainSampleFee'
                          )
                      ],
                this.getDataPreviewAdvisor.listInterview
                    ? ''
                    : this.$store.dispatch('signupAdvisor/getInterview'),
                this.getDataPreviewAdvisor.promotionVideo
                    ? ''
                    : this.$store.dispatch(
                          'signupAdvisor/getPromotionVideoInfo'
                      )
            ])
            this.keyComponent += 1
        }
    }
})
