import Vue from 'vue'
import VueRouter, { RawLocation, Route } from 'vue-router'
import { keyTypeScreenLogin, loginPaths } from '@/shared/constants'
import { routesAdmin } from '@/router/routerAdmin'
import { routesAdvisor } from '@/router/routerAdvisor'
import { routesProvider } from '@/router/routerProvider'
import { filterDomain } from '@/shared/function'
import { routesGuest } from './routerGuest'
import { getDataStorage } from '@/shared'
import { KeyStorage, TypeAccount } from '@/shared/enums'
import { routesAdminFqa } from '@/router/routerAdminFqa'

Vue.use(VueRouter)
const superPush = VueRouter.prototype.push

VueRouter.prototype.push = async function push(
    loc: RawLocation
): Promise<Route> {
    try {
        return await superPush.bind(this)(loc)
    } catch (e: any) {
        if (e?.name === 'NavigationDuplicated') {
            return e
        } else {
            throw e
        }
    }
}
function routes() {
    let router = routesGuest
    const typeRouter = filterDomain()
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.provider) {
        router = routesProvider
    }
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.advisor) {
        router = routesAdvisor
    }
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.admin) {
        const profile = getDataStorage(KeyStorage.profile)
        router =
            profile?.type === TypeAccount.adminFQA
                ? routesAdminFqa
                : routesAdmin
    }

    return router
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(),
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

function nextFactory(context: any, middleware: any, index: any) {
    const subsequentMiddleware = middleware[index]
    if (!subsequentMiddleware) return context.next
    return (...parameters: any) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }
}
router.beforeEach((to: any, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context: any = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()
})
export default router
