
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { ListNamePopup } from '@/shared/enums'
import { getDomainMitsukaru, getDomainProvider } from '@/shared/function'
import { pathTopScreen } from '@/shared/constants'

export default Vue.extend({
    name: 'LoginSupport',
    data() {
        return {
            ListNamePopup
        }
    },
    methods: {
        openPopup(namePopup: string) {
            if (!namePopup) {
                return
            }
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: namePopup,
                payload: {
                    openFromLoginSupport: true
                }
            })
        },
        redirectTop() {
            if (window.location.origin.includes('provider')) {
                window
                    .open(getDomainProvider() + pathTopScreen.inquiry, '_blank')
                    ?.focus()

                return
            }
            window
                .open(getDomainMitsukaru() + pathTopScreen.inquiry, '_blank')
                ?.focus()
        }
    }
})
