var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
        'gHeaderType1',
        _vm.logoHeader === _vm.logo.advisor
            ? 'mitsukaru'
            : _vm.logoHeader === _vm.logo.mitsukaru || _vm.isMitsukaru
            ? 'header-cus mitsukaru'
            : 'portal',
        _vm.isMitsukaru && 'tsunagaru'
    ]},[_c('div',{staticClass:"hdrMyInr flexCols flexAc"},[_c('h1',{staticClass:"hdrMy_site-name"},[_c('a',{on:{"click":_vm.redirectTop}},[(!_vm.isProvider || _vm.isMitsukaru)?_c('img',{attrs:{"src":require(`@/assets/lib/images/common/logo/${_vm.logoHeader}`),"alt":""}}):_c('img',{attrs:{"src":require(`@/assets/lib/images/common/logo/${_vm.logo.provider}`),"alt":""}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }