enum StatusProductProvider {
    PRIVATE = 0,
    PUBLIC = 1,
    TEMPORARY_STORAGE = 2
}

enum IntroductionFee {
    NO = 0,
    YES = 1,
    CONSULTATION = 2
}

enum DraftFlag {
    SEND = 0,
    DRAFT = 1,
    CHECK_DRAFT = 2
}

enum ProductGenre {
    INSURANCE_AND_FINANCE = 1,
    REAL_ESTATE = 2,
    PROFESSIONAL = 3,
    SERVICES = 4,
    HUMAN_RESOURCES = 5
}

enum StatusProductProviderNew {
    PUBLIC = 1,
    PRIVATE = 2,
    DRAFF = 3
}

export {
    StatusProductProvider,
    IntroductionFee,
    ProductGenre,
    DraftFlag,
    StatusProductProviderNew
}
