import { IStateStoreProviderAdmin } from '@/modules/admin/modules/providers/shared/types'
import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import ProviderAdminService from '@/modules/admin/modules/providers/services'
import { statusAdminSearchAllProvider } from '@/modules/admin/modules/providers/shared/constants'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import router from '@/router'

export const defaultStateProviderAdmin: IStateStoreProviderAdmin = {
    data: {},
    dataBasicInfoProviderAdmin: {},
    dataOfficeInfoProviderAdmin: {},
    dataContractInfoProviderAdmin: {},
    dataPromotionInfoProviderAdmin: {},
    dataProductProviderAdmin: {},
    isSuspendProvider: true,
    dataProductFullProviderAdmin: {},
    promotionImageAdmin: {}
}

const state = () => copyDeep(defaultStateProviderAdmin)

const getters = {
    getDataProvider(state: IStateStoreProviderAdmin) {
        return state.data
    },
    getDataBasicInfoProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataBasicInfoProviderAdmin
    },
    getDataOfficeInfoProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataOfficeInfoProviderAdmin
    },
    getDataContractInfoProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataContractInfoProviderAdmin
    },
    getDataPromotionInfoProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataPromotionInfoProviderAdmin
    },
    getDataProductProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataProductProviderAdmin
    },
    getDataProductFullProviderAdmin(state: IStateStoreProviderAdmin) {
        return state.dataProductFullProviderAdmin
    },
    getPromotionImageAdmin(state: IStateStoreProviderAdmin) {
        return state.promotionImageAdmin
    }
}

const mutations = {
    setDataProviderAdmin(state: IStateStoreProviderAdmin, payload: any) {
        state.data = payload
    },
    setDataBasicInfoProviderAdmin(
        state: IStateStoreProviderAdmin,
        payload: any
    ) {
        state.dataBasicInfoProviderAdmin = payload
    },
    setDataOfficeInfoProvider(state: IStateStoreProviderAdmin, payload: any) {
        state.dataOfficeInfoProviderAdmin = payload
    },
    setDataContractInfoProvider(state: IStateStoreProviderAdmin, payload: any) {
        state.dataContractInfoProviderAdmin = payload
    },
    setDataPromotionInfoProvider(
        state: IStateStoreProviderAdmin,
        payload: any
    ) {
        state.dataPromotionInfoProviderAdmin = payload
    },
    setDataProductProviderAdmin(state: IStateStoreProviderAdmin, payload: any) {
        state.dataProductProviderAdmin = payload
    },
    setDataProductFullProviderAdmin(
        state: IStateStoreProviderAdmin,
        payload: any
    ) {
        state.dataProductFullProviderAdmin = payload
    },
    setPromotionImageAdmin(state: IStateStoreProviderAdmin, payload: any) {
        state.promotionImageAdmin = payload
    }
}

const actions = {
    async getDataProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetProviders(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataProviderBasicInfo({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetBasicInfoProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataBasicInfoProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            payload.type === 'official'
                ? await router.push(RouterAdminUrl.providersOfficial)
                : await router.push(RouterAdminUrl.providersTemp)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async sendBackProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiPostSendBackProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('popup/hidePopup', null, { root: true })
                dispatch('getDataProviderBasicInfo', {
                    user_id: payload.provider_id
                })
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async suspendProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiPostSuspendProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('popup/hidePopup', null, { root: true })
                if (payload?.flag) {
                    dispatch('getDataProviderAdmin', {
                        status: statusAdminSearchAllProvider.official
                    })
                }
                dispatch('getDataProviderBasicInfo', {
                    user_id: payload.user_id
                })
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            commit('popup/hidePopup', null, { root: true })
            await router.push(RouterAdminUrl.providersOfficial)
            return false
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async unSuspendProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiPostUnSuspendProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getDataProviderBasicInfo', {
                    user_id: payload.user_id
                })
                commit('popup/hidePopup', null, { root: true })
                if (payload?.flag) {
                    dispatch('getDataProviderAdmin', {
                        status: statusAdminSearchAllProvider.official
                    })
                }
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            commit('popup/hidePopup', null, { root: true })
            await router.push(RouterAdminUrl.providersOfficial)
            return false
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async approveProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiPostApproveProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getDataProviderBasicInfo', payload)
                commit('popup/hidePopup', null, { root: true })
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return { id: data.data.id, status: true }
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getDetailDraftSendBackProvider(
        { commit, dispatch }: any,
        payload: any
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ProviderAdminService.apiGetDetailDraftSendBackProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataOfficeInfoProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetOfficeInfoProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataOfficeInfoProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataContractInfoProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetContractInfoProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataContractInfoProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataPromotionInfoProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetPromotionInfoProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataPromotionInfoProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiDeleteProvider(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataProviderAdmin', {
                    refreshData: true
                })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getDataProductProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await ProviderAdminService.apiGetProductProvider(
                payload,
                'list'
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getDataProductDetailProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiGetDetailProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductProviderAdmin', data.data)
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return 'back'
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataProductFullProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderAdminService.apiGetProductProvider(
                payload,
                'full'
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductFullProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getPromotionImageInfoProviderAdmin({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await ProviderAdminService.apiGetPromotionImage(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionImageAdmin', data.data)

                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
