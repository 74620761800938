
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'

export default Vue.extend({
    name: 'SampleFeesPreviewForm',
    props: {
        dataList: {
            type: Object
        }
    }
})
