// register with `formatter` option
import VueI18n from 'vue-i18n'
import jap from '@/locales/ja.json'
import validationAdvisor from '@/locales/validationAdvisor.json'
import validationAdmin from '@/locales/validationAdmin.json'
import validationProvider from '@/locales/validationProvider.json'
import Vue from 'vue'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'jp',
    fallbackLocale: 'jp',
    messages: {
        jp: {
            ...jap,
            ...validationAdvisor,
            ...validationAdmin,
            ...validationProvider
        }
    }
})
