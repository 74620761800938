import apiServices from '@/shared/services/api/axios'
const ProviderService = {
    apiGetInfoProvider(payload: any) {
        const url = 'admin/get-info-provider/' + payload.id

        return apiServices.get(url)
    },
    apiGetInfoProviderByProductId(payload: any) {
        let url = 'admin/get-provider-info-by-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetProductsProvider(payload: any) {
        let url = '/provider-product-is-after-preview'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetProviderRate(payload: any) {
        const url = '/provider-rating?id=' + payload

        return apiServices.get(url)
    },
    apiGetDetailProvider(payload: any) {
        return apiServices.get(`/get-info-provider/${payload.user_provider_id}`)
    }
}
export default ProviderService
