// initial state
// shape: [{ id, quantity }]
import { copyDeep } from '@/shared'
import { IPayloadPopup, IStatePopup } from '@/shared/@types/popup-types'
import { ListNamePopup } from '@/shared/enums'

export const defaultStatePopup: IStatePopup = {
    isOpen: false,
    namePopup: '',
    width: 1000,
    payload: {},
    isLoading: false,
    isNotPageLoading: false,
    checkUpdateEmail: false,
    conditionFilter: {}
}

const state = () => copyDeep(defaultStatePopup)
const getters = {
    getIsOpenPopup(state: IStatePopup) {
        return state.isOpen
    },
    getNamePopup(state: IStatePopup) {
        return state.namePopup
    },
    getParamsPopup(state: IStatePopup) {
        return state.payload
    },
    getWidthPopup(state: IStatePopup) {
        return state.width
    },
    getIsLoading(state: IStatePopup) {
        return state.isLoading
    },
    getUpdateEmail(state: IStatePopup) {
        return state.checkUpdateEmail
    },
    getIsNotPageLoading(state: IStatePopup) {
        return state.isNotPageLoading
    },
    getConditionFilter(state: IStatePopup) {
        return state.conditionFilter
    }
}
const actions = {
    openPopup({ commit }: any, payload: IPayloadPopup) {
        commit('openPopup', payload)
    },
    hidePopup({ commit }: any) {
        commit('hidePopup')
    },
    setLoading({ commit }: any, payload: boolean) {
        commit('setLoading', payload)
    },
    setUpdateEmail(state: IStatePopup) {
        state.checkUpdateEmail = !state.checkUpdateEmail
    },
    setIsNotPageLoading({ commit }: any, payload: boolean) {
        commit('setIsNotPageLoading', payload)
    },
    setConditionFilter({ commit }: any, payload: IPayloadPopup) {
        commit('setConditionFilter', payload)
    }
}
const mutations = {
    openPopup(state: IStatePopup, payload: IPayloadPopup) {
        Object.assign(state, payload)
    },
    hidePopup(state: IStatePopup) {
        state.isOpen = false
        state.payload = {}
        setTimeout(() => {
            state.namePopup = ''
        }, 400)
    },
    setLoading(state: IStatePopup, payload: boolean) {
        state.isLoading = payload
    },
    setIsNotPageLoading(state: IStatePopup, payload: boolean) {
        state.isNotPageLoading = payload
    },
    setConditionFilter(state: IStatePopup, payload: boolean) {
        Object.assign(state, payload)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
