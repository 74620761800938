
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import OfficeInformationPreview from '@/modules/advisor/modules/signup/components/office-infomation/OfficeInformationPreview.vue'
import AffiliateAdvisorPreview from '@/modules/advisor/modules/signup/components/affiliate/AffiliateAdvisorPreview.vue'
import SampleFeesPreview from '@/modules/advisor/modules/signup/components/sample-fees/SampleFeesPreview.vue'
import InterviewInformationPreview from '@/modules/advisor/modules/signup/components/interview/InterviewInformationPreview.vue'
import MapInformationPreview from '@/modules/advisor/modules/signup/components/map-information/MapInformationPreview.vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'MobileLayoutTabContent',
    props: {
        tabs: {
            type: Array,
            required: true
        },
        tabActive: {
            type: String,
            required: true
        }
    },
    components: {
        OfficeInformationPreview,
        AffiliateAdvisorPreview,
        SampleFeesPreview,
        InterviewInformationPreview,
        MapInformationPreview
    },
    computed: {
        ...mapGetters('signupAdvisor', ['getDataPreviewAdvisor'])
    },
    data() {
        return {
            dataTabActive: ''
        }
    },
    mounted() {
        this.dataTabActive = this.tabActive
    },
    methods: {
        changeTabActive(key: string) {
            if (key) {
                this.$emit('changeTabActive', key)
                const tabActive = document.querySelector(
                    `.mp_basicInfo_tabContents`
                ) as HTMLElement
                const y =
                    tabActive.getBoundingClientRect().top + window.scrollY - 70
                setTimeout(() => {
                    window.scroll({
                        behavior: 'smooth',
                        top: y
                    })
                }, 300)
            }
        }
    }
})
