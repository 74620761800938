import { clearStorage, copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom, updateDataProfile } from '@/shared/function'
import AuthService from '../services'
import { IStateStoreAuth } from '../shared/@types'
import router from '@/router'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import store from '@/store'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'

export const defaultStateAuth: IStateStoreAuth = {
    data: {}
}

const state = () => copyDeep(defaultStateAuth)

const getters = {
    getDataCount(state: IStateStoreAuth) {
        return state.data
    }
}

const mutations = {
    setDataCount(state: IStateStoreAuth, payload: any) {
        state.data = payload
    }
}

const actions = {
    async refreshToken({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await AuthService.apiRefreshToken(payload)
            return res
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateChangeEmail({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await AuthService.apiChangeEmail(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async updateEmail({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await AuthService.apiUpdateEmail(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                updateDataProfile({
                    key: 'email',
                    value: data.data
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async handleDeactive({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await AuthService.apiDeactiveAcc()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                clearStorage()
                router.push(RouterAdvisorUrl.signin)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async countNotifyAdvisor({ commit }: any) {
        try {
            const res: any = await AuthService.apiCountAdvisor()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataCount', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {}
    },
    async countNotifyAdmin({ commit }: any) {
        try {
            const res: any = await AuthService.apiCountAdmin()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataCount', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {}
    },
    async handleProviderDeactive({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await AuthService.apiProviderDeactiveAcc(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                clearStorage()
                router.push(RouterProviderUrl.signin)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
