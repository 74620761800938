import Vue from 'vue'
import Vuex from 'vuex'
import { filterDomain } from '@/shared/function'
import { keyTypeScreenLogin } from '@/shared/constants'
import { storeAdmin } from '@/store/list-store/storeAdmin'
import { storeAdvisor } from '@/store/list-store/storeAdvisor'
import { storeProvider } from '@/store/list-store/storeProvider'
import { storeGuest } from './list-store/storeGuest'

Vue.use(Vuex)

function stores() {
    const typeRouter = filterDomain()
    let storeConfig: any = storeGuest
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.advisor) {
        storeConfig = storeAdvisor
    }
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.admin) {
        storeConfig = storeAdmin
    }
    if (typeRouter && typeRouter.type === keyTypeScreenLogin.provider) {
        storeConfig = storeProvider
    }
    return storeConfig
}

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: stores()
})
