
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import { formatNumber, objectHasKey } from '@/shared/function'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
export default Vue.extend({
    name: 'confirmFeeManagement',
    data() {
        return {
            formatNumber
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    components: { ButtonCustom, Title },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        onConfirmMembershipFee() {
            if (objectHasKey(this.getParamsPopup, 'id')) {
                this.$store.dispatch(
                    'feeManagement/confirmMembershipFee',
                    {
                        'id': this.getParamsPopup.id,
                        'billStatus': this.getParamsPopup.billStatus,
                    }
                )
            }
        }
    }
})
