import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreProductProvider } from '../shared/@types'
import ProductProviderService from '../services'
import router from '@/router'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { ListNamePopup } from '@/shared/enums'

export const defaultStateAnnouncementAdvisor: IStateStoreProductProvider = {
    dataProduct: {},
    dataDetailProduct: {},
    dataDetailProductPreview: {},
    dataProductFull: {},
    detailInquiry: {},
    dataProductProgress: {},
    dataProductManagement: {}
}

const state = () => copyDeep(defaultStateAnnouncementAdvisor)

const getters = {
    getDataProduct(state: IStateStoreProductProvider) {
        return state.dataProduct
    },
    getDataDetailProduct(state: IStateStoreProductProvider) {
        return state.dataDetailProduct
    },
    getDataProductFull(state: IStateStoreProductProvider) {
        return state.dataProductFull
    },
    getDataDetailProductPreview(state: IStateStoreProductProvider) {
        return state.dataDetailProductPreview
    },
    getDataDetailInquiry(state: IStateStoreProductProvider) {
        return state.detailInquiry
    },
    getDataProductProgress(state: IStateStoreProductProvider) {
        return state.dataProductProgress
    },
    getDataProductManagement(state: IStateStoreProductProvider) {
        return state.dataProductManagement
    }
}

const mutations = {
    setDataProduct(state: IStateStoreProductProvider, payload: any) {
        state.dataProduct = payload
    },
    setDataDetailProduct(state: IStateStoreProductProvider, payload: any) {
        state.dataDetailProduct = payload
    },
    setDataProductFull(state: IStateStoreProductProvider, payload: any) {
        state.dataProductFull = payload
    },
    setDataDetailProductPreview(
        state: IStateStoreProductProvider,
        payload: any
    ) {
        state.dataDetailProductPreview = payload
    },
    setDataDetailInquiry(state: IStateStoreProductProvider, payload: any) {
        state.detailInquiry = payload
    },
    setDataProductProgress(state: IStateStoreProductProvider, payload: any) {
        state.dataProductProgress = payload
    },
    setDataProductManagement(state: IStateStoreProductProvider, payload: any) {
        state.dataProductManagement = payload
    }
}

const actions = {
    async dataProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiGetProduct(
                payload,
                'list'
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProduct', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async createDataProduct({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiCreateProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                return true
            }
            if (
                data.errors.error_message == '商材公開数は３つまでです。' ||
                data.errors.error_message == '商材公開数は１つまでです。'
            ) {
                this.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.PopupNotiErrorProduct,
                    width: 1000,
                    payload: { message: data.errors.error_message }
                })
            } else {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async updateDataProduct({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiUpdateProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }

            if (
                data.errors.error_message == '商材公開数は３つまでです。' ||
                data.errors.error_message == '商材公開数は１つまでです。'
            ) {
                this.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.PopupNotiErrorProduct,
                    width: 1000,
                    payload: { message: data.errors.error_message }
                })
            } else {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async dataProductDetail({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiDetailProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataDetailProduct', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async deleteProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiDeleteProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('popup/hidePopup', null, { root: true })
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                router.push(RouterProviderUrl.products)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async dataProductFull({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiGetProduct(
                payload,
                'full'
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductFull', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DataDetailInquiry({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiDetailInquiryAdvisor(
                payload
            )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataDetailInquiry', data.data)
                dispatch('signupProvider/countNotifyProvider', true, {
                    root: true
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async AnswerInquiryAdvisor({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiAnswerInquiryAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })

                dispatch('DataDetailInquiry', payload?.inquiry_id)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(
                RouterProviderUrl.productManagement + payload?.inquiry_id
            )
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DataProductProgress({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiProductProgress(payload)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataProductProgress', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async UpdateDataProductProgress({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiUpdateProductProgress(
                payload
            )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                dispatch(
                    'DataProductProgress',
                    payload?.tax_advisor_product_inquiry_id
                )
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })

                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DataProductManagement({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductProviderService.apiProductManagement(
                payload
            )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataProductManagement', data.data)

                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
