
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import { keyTypeScreenLogin } from '@/shared/constants'
import { filterDomain } from '@/shared/function'
import { UserTypeRole } from '@/shared/enums'

export default {
    name: 'FooterLogin',
    computed: {
        isProvider(): boolean {
            return (
                this.typeRouter &&
                this.typeRouter.type === keyTypeScreenLogin.provider
            )
        },
        isAdmin() {
            return filterDomain().role === UserTypeRole.admin
        },
        isAdvisor() {
            return filterDomain().role === UserTypeRole.advisor
        }
    },
    created() {
        this.typeRouter = filterDomain()
    }
}
