
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import Title from '@/components/common_new/popup/title.vue'
import { ValueStatusProvider } from '@/shared/enums'
import { removeKeyInObject } from '@/shared/function'
import { statusAdminSearchAllProvider } from '../../shared/constants'

export default Vue.extend({
    name: 'ConfirmSubmitRejectProvider',
    components: { ButtonCustom, Title },
    data() {
        return {
            titlePopup: '',
            descriptionPopup: 'この会員のサービスを停止しますか？'
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            let res = false
            const { status, id } = this.getParamsPopup
            const getParamsPopup = { ...this.getParamsPopup }
            const params = {
                user_id: id,
                flag: false
            }
            if (Number(status) === ValueStatusProvider.IN_USE) {
                res = await this.$store.dispatch(
                    'providerAdmin/suspendProvider',
                    params
                )
            }
            if (Number(status) === ValueStatusProvider.SUSPEND) {
                res = await this.$store.dispatch(
                    'providerAdmin/unSuspendProvider',
                    params
                )
            }

            if (res) {
                const payload = {
                    status: getParamsPopup.Suspend
                        ? getParamsPopup.Suspend
                        : statusAdminSearchAllProvider.official,
                    provider_name: getParamsPopup.InputName,
                    type:
                        getParamsPopup.Type !== 0
                            ? getParamsPopup.Type
                            : undefined,
                    orderby: getParamsPopup.OrderBy
                }
                await this.$store.dispatch('popup/hidePopup')
                this.$store.dispatch(
                    'providerAdmin/getDataProviderAdmin',
                    removeKeyInObject(payload)
                )
            }
        }
    },
    mounted() {
        if (
            Number(this.getParamsPopup.status) === ValueStatusProvider.SUSPEND
        ) {
            this.titlePopup = ''
            this.descriptionPopup = 'この会員のサービス停止を解除しますか？'
        }
    }
})
