import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { IStateStoreMyPage } from '../shared/contants/IConstants'
import MyPageAdvisorServices from '../services'
import { NotificationCustom } from '@/shared/function'

export const defaultStateMyPageAdvisor: IStateStoreMyPage = {
    data: {},
    dataFee: {}
}

const state = () => copyDeep(defaultStateMyPageAdvisor)

const getters = {
    getDataMyPageProvider(state: IStateStoreMyPage) {
        return state.data
    },

    getProviderFee(state: IStateStoreMyPage) {
        return state.dataFee
    }
}

const mutations = {
    setDataMyPageProvider(state: IStateStoreMyPage, payload: any) {
        state.data = payload
    },

    setDataProviderFee(state: IStateStoreMyPage, payload: any) {
        state.dataFee = payload
    }
}

const actions = {
    async getListDataMyPageProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await MyPageAdvisorServices.apiGetProviderMyPage()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataMyPageProvider', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getDataProviderFee({ commit }: any, payload: any) {
        // commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await MyPageAdvisorServices.apiGetProviderFee()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProviderFee', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async approveProviderFee({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await MyPageAdvisorServices.apiApproveProviderFee(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('signupProvider/getDataProvider', true, { root: true })
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
