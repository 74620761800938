import { IStateStoreAdvisorAdmin } from '@/modules/admin/modules/advisors/shared/@types'
import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom, routeGoBackToListAdvisor } from '@/shared/function'
import AdvisorAdminService from '../services'
import router from '@/router'

export const defaultStateAdvisorAdmin: IStateStoreAdvisorAdmin = {
    data: {},
    dataBasicInfoAdvisorAdmin: {},
    dataOfficeInfoAdvisorAdmin: {},
    dataAffiliateAdvisorAdmin: {},
    dataSamplePeeAdvisorAdmin: {},
    dataInterviewAdvisorAdmin: {},
    dataVideoAdvisorAdmin: {},
    listDataTaxGroupAdvisorAdmin: [],
    dataPreviewAdvisor: {}
}

const state = () => copyDeep(defaultStateAdvisorAdmin)

const getters = {
    getDataAdvisor(state: IStateStoreAdvisorAdmin) {
        return state.data
    },
    getDataListTaxGroupAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.listDataTaxGroupAdvisorAdmin
    },
    getDataBasicInfoAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataBasicInfoAdvisorAdmin
    },
    getDataOfficeInfoAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataOfficeInfoAdvisorAdmin
    },
    getDataAffiliateAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataAffiliateAdvisorAdmin
    },
    getDataSamplePeeAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataSamplePeeAdvisorAdmin
    },
    getDataInterviewAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataInterviewAdvisorAdmin
    },
    getDataVideoAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataVideoAdvisorAdmin
    },
    getDataPreviewAdvisorAdmin(state: IStateStoreAdvisorAdmin) {
        return state.dataPreviewAdvisor
    }
}

const mutations = {
    setDataAdvisorAdmin(state: IStateStoreAdvisorAdmin, payload: any) {
        state.data = payload
    },
    setDataListTaxGroupAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.listDataTaxGroupAdvisorAdmin = payload
    },
    setDataOfficeInfoAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataOfficeInfoAdvisorAdmin = payload
    },
    setDataBasicInfoAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataBasicInfoAdvisorAdmin = payload
    },
    setDataVideoAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataVideoAdvisorAdmin = payload
    },
    setDataAffiliateAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataAffiliateAdvisorAdmin = payload
    },
    setDataSamplePeeAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataSamplePeeAdvisorAdmin = payload
    },
    setDataInterviewAdvisor(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataInterviewAdvisorAdmin = payload
    },
    setDataPreviewAdvisorAdmin(state: IStateStoreAdvisorAdmin, payload: any) {
        state.dataPreviewAdvisor = payload
    }
}

const actions = {
    async getDataAdvisorAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetAdvisors(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAdvisorAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListTaxGroup({ commit }: any) {
        try {
            const resList: any = await AdvisorAdminService.apiListTaxAdvisor()
            const { status, data } = resList
            if (status === HttpStatusCode.ok) {
                commit('setDataListTaxGroupAdvisor', data.data)
                return
            }
        } catch (error) {}
    },

    async getDataAdvisorBasicInfo({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetBasicInfoAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataBasicInfoAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataOfficeInfo({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetOfficeInfoAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataOfficeInfoAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAdvisorAffiliate({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetInfoAffiliateAdmin(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAffiliateAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getDataAdvisorSampleFees({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetSampleFeesAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataSamplePeeAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataPromotionVideo({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiInfoPromotionVideo(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataVideoAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async stopAdvisor({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, {
            root: true
        })
        try {
            const res = await AdvisorAdminService.apiStopAdvisor(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },
    async unStopAdvisor({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, {
            root: true
        })
        try {
            const res = await AdvisorAdminService.apiUnStopAdvisor(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },

    async deleteAdvisor({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await AdvisorAdminService.apiDeleteAdvisor(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataAdvisorAdmin', {
                    refreshData: true
                })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getDataInterviewAdvisor({ commit }: any, id: number) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdvisorAdminService.apiGetInterviewAdvisor(id)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInterviewAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateAffiliateAdmin({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await AdvisorAdminService.apiUpdateStatusAdvisor(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('setDataAdvisorAdmin', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
