
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import Title from '@/components/common/popup/title.vue'
import InputCustom from '@/components/common/input/index.vue'
import TextAreaCustom from '@/components/common/textarea/index.vue'
import ButtonCustom from '@/components/common/button/index.vue'
import { regex } from '@/shared/regex'
import { ruleValidEmail, scrollToFirstErrorLineForm } from '@/shared/function'
import { mapGetters } from 'vuex'
import { RequestType } from '@/shared/enums'

export default Vue.extend({
    name: 'PopupFormGuest',
    components: { Title, InputCustom, TextAreaCustom, ButtonCustom },
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'form'
            }),
            ruleValidEmail: ruleValidEmail(),
            titlePopup: 'メール相談',
            titleConsultationSubject:
                '（50文字以内）※相談依頼内容を要約した文章をご記載ください。',
            titleConsultationContent:
                '（3000文字以内）※できる限り詳しく現在の状況と相談依頼内容をご記載ください。'
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        if (this.getParamsPopup.request_type === RequestType.INTERVIEW) {
            this.titlePopup = '面談依頼'
            this.titleConsultationSubject =
                '（50文字以内）※面談依頼内容を要約した文章をご記載ください。'
            this.titleConsultationContent =
                '（3000文字以内）※できる限り詳しく現在の状況と面談依頼内容をご記載ください。'
        }
        if (this.getParamsPopup.request_type === RequestType.REQUEST) {
            this.titlePopup = '見積依頼'
            this.titleConsultationSubject =
                '（50文字以内）※見積依頼内容を要約した文章をご記載ください。'
            this.titleConsultationContent =
                '（3000文字以内）※できる限り詳しく現在の状況と見積依頼内容をご記載ください。'
        }
    },
    methods: {
        rulesCompanyName() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length100.test(value)) {
                                callback(
                                    '企業・団体名は100文字以内で入力してください'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesSuperior() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length100.test(value)) {
                                callback('所属は100文字以内で入力してください')
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesName() {
            return [
                {
                    required: true,
                    message: 'お名前を入力して下さい'
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length50.test(value)) {
                                callback('お名前は50文字以内で入力してください')
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesTitle() {
            return [
                {
                    required: true,
                    message: '相談件名を入力して下さい'
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length50.test(value)) {
                                callback(
                                    '相談件名は50文字以内で入力してください'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesContent() {
            return [
                {
                    required: true,
                    message: '相談内容を入力して下さい'
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length3000.test(value)) {
                                callback(
                                    '相談内容は3000文字以内で入力してください'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleClickBtn() {
            this.form.validateFields((errors: Error[], values: any) => {
                if (errors) {
                    scrollToFirstErrorLineForm()
                    return
                }
                this.handleCreateRequest(values)
            })
        },
        handleCreateRequest(payload: any) {
            const body = {
                ...payload,
                supervisor_id: Number(Object.values(this.$route.query)[0]),
                request_type: this.getParamsPopup.request_type
            }
            this.$store.dispatch('Guest/postCreateIncomingRequest', body)
        }
    }
})
