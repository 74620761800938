
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { ListNamePopup } from '@/shared/enums'
import { ITypeListNavigateMenu } from '@/shared/constants/IContants'
import {
    checkIsTempAdvisor,
    checkIsTempProvider,
    openNewTab,
    NotificationCustom,
    filterDomain
} from '@/shared/function'
import { getDataStorage } from '@/shared'
import { KeyStorage, TypeNotify, ValueTypeProvider } from '@/shared/enums'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { keyTypeScreenLogin } from '@/shared/constants'

export default Vue.extend({
    name: 'LayoutSideMenu',
    props: {
        listMenu: Array,
        isAdvisor: Boolean
    },
    computed: {
        isProvider(): boolean {
            return (
                this.typeRouter &&
                this.typeRouter.type === keyTypeScreenLogin.provider
            )
        }
    },
    created() {
        this.typeRouter = filterDomain()
    },
    data() {
        return {
            openSide: false
        }
    },
    methods: {
        filterDomain,
        beforeEnter(el: any) {
            el.style.height = '0'
        },
        enter(el: any) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el: any) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el: any) {
            el.style.height = '0'
        },
        changeTab(item: any) {
            if (item.subMenu) this.openSideMenu(item)
            if (item.namePopup === ListNamePopup.ChangePassword) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.ChangePassword,
                    width: 1000
                })
            }
            if (item.namePopup === ListNamePopup.CreateSignature) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.CreateSignature,
                    width: 1000
                })
            }
            // if (
            //     item.to === RouterProviderUrl.countAccess &&
            //     Number(getDataStorage(KeyStorage.profile).type) ===
            //         ValueTypeProvider.FREE
            // ) {
            //     this.$store.dispatch('popup/openPopup', {
            //         isOpen: true,
            //         namePopup: ListNamePopup.popUpConfirmCountAccess,
            //         width: 1000
            //     })
            //     return
            // }
            if (item.to) {
                this.$router.push(item.to)
            }
            if (item.newWindowLink) {
                openNewTab(item.newWindowLink, false)
            }
        },

        titleClick(sub: any) {
            if (sub.namePopup) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: sub.namePopup,
                    width: 1000
                })
                return
            }
            if (sub.to) {
                if (sub.to === '/promotions') {
                    if (
                        this.isProvider &&
                        Number(getDataStorage(KeyStorage.profile).type) ===
                            ValueTypeProvider.FREE
                    ) {
                        NotificationCustom({
                            message: '有料会員のみ動画の登録が可能です。',
                            type: TypeNotify.error
                        })
                        return
                    }

                    this.$router.push(sub.to).catch(() => {
                        //
                    })
                }
                this.$router.push(sub.to).catch(() => {
                    //
                })
            }
        },
        openSideMenu(item: ITypeListNavigateMenu) {
            if (checkIsTempAdvisor() || checkIsTempProvider()) {
                return
            }
            const itemMenu = this.$refs[`item${item.title}`] as any
            item.isOpen = !item.isOpen
            if (item.isOpen) {
                itemMenu[0].classList.add('active')
                return
            }
            itemMenu[0].classList.remove('active')
        }
    }
})
