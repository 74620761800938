
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
    apiEmbedMap,
    defaultAddressMap
} from '@/modules/advisor/modules/signup/shared/contants'
import { regex } from '@/shared/regex'
import ButtonCustom from '@/components/common/button/index.vue'

export default Vue.extend({
    name: 'MapInformationPreview',
    components: { ButtonCustom },
    data() {
        return {
            basicInfo: {} as any,
            officeInfo: {} as any,
            isRegistration: true,
            apiEmbedMap
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataAdvisor',
            'getBaseOffice',
            'getDataPreviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', [
            'getDataBasicInfoAdvisorAdmin',
            'getDataOfficeInfoAdvisorAdmin'
        ]),
        fullAddress(): string {
            if (Object.keys(this.basicInfo).length !== 0) {
                return (
                    (this.basicInfo?.address ?? '') +
                    (this.basicInfo?.house_number ?? '') +
                    (this.basicInfo?.building_name ?? '')
                )
            }
            return ''
        },
        apiAddress(): any {
            return (
                encodeURI(
                    this.fullAddress.toString().replace(regex.andCharacter, '')
                ) || defaultAddressMap
            )
        }
    },
    mounted() {
        this.isRegistration = this.getDataPreviewAdvisor.isRegistration ?? false
        this.officeInfo =
            this.getDataPreviewAdvisor.officeInfo ?? this.getBaseOffice
        this.basicInfo = this.getDataPreviewAdvisor.basicInfo ?? {
            ...this.getDataAdvisor.tax_advisor_info,
            tax_advisor_name: this.getDataAdvisor.tax_advisor_name
        }
        if (this.getDataPreviewAdvisor.isAdmin) {
            this.officeInfo = this.getDataOfficeInfoAdvisorAdmin
            this.basicInfo = {
                ...this.getDataBasicInfoAdvisorAdmin.tax_advisor_info,
                tax_advisor_name:
                    this.getDataBasicInfoAdvisorAdmin.tax_advisor_name
            }
        }
    },
    methods: {
        async openMap() {
            // TODO: Open map will be implement later
            // const data = {
            //     office_id: this.getDataGuest.tax_advisor_basic_infos?.id
            // }
            // const res = await this.$store.dispatch(
            //     'searchAdvisor/getLatLongOpenMap',
            //     data
            // )
            // if (res) {
            //     window.open(`https://www.google.com/maps/place/${res}`)
            // }
        }
    }
})
