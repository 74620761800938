var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"gHeader_myPage"},[_c('div',{staticClass:"hdrMyInr flexCols flexAc"},[_c('form',{attrs:{"method":"post","action":_vm.redirectTop,"target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"token"},domProps:{"value":_vm.getAssetToken}}),_c('button',{staticClass:"hdrMy_site-name",attrs:{"type":"submit"}},[(_vm.userTypeAccount === _vm.UserTypeRole.provider)?_c('a',{staticClass:"title-logo",on:{"click":function($event){return _vm.redirectGuest()}}},[_c('span',[_vm._v("税理士事務所の課題解決にはつながる税理士")]),_c('img',{staticClass:"img-provider",attrs:{"src":require(`@/assets/lib/images/common/logo/${_vm.logo.provider}`),"alt":""}})]):_c('a',[_c('img',{attrs:{"src":require(`@/assets/lib/images/common/logo/${_vm.logoHeader}`),"alt":""}})])])]),_c('div',{staticClass:"hdrMy_Link_box flexCols flexAc"},[(
                    _vm.userTypeAccount === _vm.UserTypeRole.provider &&
                    !_vm.checkProvider
                )?_c('a',{staticClass:"typeFee",on:{"click":function($event){return _vm.openMembershipRegist(_vm.typeAccount)}}},[_c('ButtonCustom',{attrs:{"label":_vm.typeAccount === _vm.TypeRenewalFlg.FREE
                            ? '有料会員登録'
                            : '無料会員変更',"classes":"btnTypeFee"}})],1):_vm._e(),(
                    _vm.userTypeAccount === _vm.UserTypeRole.advisor ||
                    _vm.userTypeAccount === _vm.UserTypeRole.provider
                )?_c('a',{on:{"click":_vm.openPortalInquiry}},[_c('img',{staticClass:"hdrMy_icon_mail",attrs:{"src":require("@/assets/lib/images/common/icon/mail-wh.svg"),"alt":"メール"}})]):_vm._e(),(
                    _vm.userTypeAccount === _vm.UserTypeRole.advisor ||
                    _vm.userTypeAccount === _vm.UserTypeRole.admin ||
                    _vm.userTypeAccount === _vm.UserTypeRole.provider
                )?_c('a',{on:{"click":_vm.openTayoriLink}},[_c('img',{staticClass:"hdrMy_icon_faq",attrs:{"src":require("@/assets/lib/images/common/icon/faq-wh.svg"),"alt":"FAQ"}})]):_vm._e(),_c('a',{on:{"click":_vm.handleLogout}},[_c('img',{staticClass:"hdrMy_icon_logout",attrs:{"src":require("@/assets/lib/images/common/icon/logout-wh.svg"),"alt":"ログアウト"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }