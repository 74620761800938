
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { customUrlImage, isArray } from '@/shared/function'
import { mapGetters } from 'vuex'
import { i18n } from '@/shared/i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper/core'
import 'swiper/swiper.scss'

SwiperCore.use([Navigation])

export default Vue.extend({
    name: 'OfficeInformationPreview',
    components: { SwiperSlide, Swiper },
    data(): any {
        return {
            customUrlImage,
            officeInfo: {} as any,
            isMobile: false
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getBaseOffice',
            'getDataPreviewAdvisor',
            'getBaseDataOffice'
        ]),
        ...mapGetters('advisorAdmin', ['getDataOfficeInfoAdvisorAdmin'])
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize)
        if (this.getDataPreviewAdvisor.isAdmin) {
            this.officeInfo =
                this.getDataPreviewAdvisor.officeInfo ??
                this.getDataOfficeInfoAdvisorAdmin
        } else {
            this.officeInfo =
                this.getDataPreviewAdvisor.officeInfo ?? this.getBaseOffice
        }
        if (
            this.officeInfo?.service_domain ===
            i18n.t('i18n_select_all_checkbox_service_office_advisor')
        ) {
            this.officeInfo.service_domain = Object.values(
                this.getBaseDataOffice.service_domain
            ).join('　')
        }
        if (
            this.officeInfo?.service_job ===
            i18n.t('i18n_select_all_checkbox_service_office_advisor')
        ) {
            this.officeInfo.service_job = Object.values(
                this.getBaseDataOffice.service_job
            ).join('　')
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize)
        }
    },
    watch: {
        getBaseOffice(newVal) {
            if (this.getDataPreviewAdvisor.isAdmin) {
                return
            }
            this.officeInfo = this.getDataPreviewAdvisor.officeInfo ?? newVal
        },
        getDataOfficeInfoAdvisorAdmin(newVal) {
            if (this.getDataPreviewAdvisor.isAdmin) {
                this.officeInfo =
                    this.getDataPreviewAdvisor.officeInfo ?? newVal
            }
        }
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 769
        },
        formatArray(data: string[] | string) {
            if (Array.isArray(data)) {
                return data.join('　')
            }
            return data
        }
    }
})
