import apiServices from '@/shared/services/api/axios'
import {
    IPayloadReplyConsultAdvisor,
    IPayloadUpdateStatusConsultAdvisor
} from '@/modules/advisor/modules/consult/shared/@types'

const ConsultAdvisorService = {
    apiGetConsultAdvisor(payload: any) {
        let url = '/tax-advisor/get-incoming-requests'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetConsultDetailAdvisor(id: number) {
        return apiServices.get(`/tax-advisor/incoming-request/${id}`)
    },
    apiUpdateStatusConsultAdvisor(payload: IPayloadUpdateStatusConsultAdvisor) {
        return apiServices.post(
            `/tax-advisor/update-status-incoming-request`,
            payload
        )
    },
    apiReplyConsultAdvisor(payload: IPayloadReplyConsultAdvisor) {
        return apiServices.post(`/tax-advisor/create-consultation`, payload)
    },
    apiPreviewConsultAdvisor(payload: IPayloadReplyConsultAdvisor) {
        return apiServices.post(
            `/tax-advisor/preview-create-consultation`,
            payload
        )
    }
}
export default ConsultAdvisorService
