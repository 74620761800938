
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import Title from '@/components/common_new/popup/title.vue'
import { AdvisorConsultStatus } from '@/modules/advisor/modules/consult/shared/enums'
import { StatusReadConciergeFqa } from '@/modules/adminFQA/modules/concierge/shared/enums'
export default Vue.extend({
    name: 'ConfirmUpdateStatusConcierge',
    components: { ButtonCustom, Title },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            switch (this.getParamsPopup.source) {
                case 'concierge':
                    await this.$store.dispatch(
                        'conciergeAdminFqa/updateStatusConciergeById',
                        {
                            id: this.getParamsPopup.id,
                            status: StatusReadConciergeFqa.CONTACTED
                        }
                    )
                    break
                case 'inquiry':
                    await this.$store.dispatch(
                        'inquiryAdminFqa/updateStatusAdminFqa',
                        {
                            id: this.getParamsPopup.id,
                            status: AdvisorConsultStatus.CONTACTED
                        }
                    )
                    break
                case 'consultation':
                    await this.$store.dispatch(
                        'consultAdvisor/updateStatusConsultAdvisor',
                        {
                            id: this.getParamsPopup.id,
                            status: AdvisorConsultStatus.CONTACTED
                        }
                    )
                    break
                case 'incoming':
                    await this.$store.dispatch(
                        'incomingProvider/updateStatusIncomingProvider',
                        this.getParamsPopup.data
                    )
                    break
            }
            await this.$store.dispatch('popup/hidePopup')
        }
    }
})
