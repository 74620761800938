
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import PopupCommon from '@/components/common_new/popup/index.vue'
import HeaderLogin from '@/components/layouts/header-login/index.vue'
import FooterLogin from '@/components/layouts/footer-login/index.vue'
import Loading from '@/components/common_new/loading/index.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutDefault',
    components: { FooterLogin, HeaderLogin, PopupCommon },
    computed: {
        ...mapGetters('popup', ['getIsLoading'])
    }
})
