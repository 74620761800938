import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import InquiryAdminFQAService from '../services'
import {
    IPayloadSearchInquiryFqa,
    IStateStoreInquiryAdminFQA,
    ITypePayloadAnswerInquiryAdminFQA,
    IUpdateStatusInquiryAdminFQA
} from '@/modules/adminFQA/modules/inquiry/shared/@types'
import { StatusReadInquiryFqa } from '../shared/enums'
import router from '@/router'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'

export const defaultStateInquiryAdmin: IStateStoreInquiryAdminFQA = {
    data: {},
    previewInquiry: '',
    payloadInquiry: {}
}

const state = () => copyDeep(defaultStateInquiryAdmin)

const getters = {
    getDataInquiryAdminFqa(state: IStateStoreInquiryAdminFQA) {
        return state.data
    },
    getPreviewInquiry(state: IStateStoreInquiryAdminFQA) {
        return state.previewInquiry
    },
    getPayloadInquiry(state: IStateStoreInquiryAdminFQA) {
        return state.payloadInquiry
    }
}

const mutations = {
    setDataInquiryAdminFqa(state: IStateStoreInquiryAdminFQA, payload: any) {
        state.data = payload
    },
    setPreviewInquiry(state: IStateStoreInquiryAdminFQA, payload: any) {
        state.previewInquiry = payload
    },
    setPayloadInquiry(state: IStateStoreInquiryAdminFQA, payload: any) {
        state.payloadInquiry = payload
    }
}

const actions = {
    async getDataInquiryAdminFqa(
        { commit, dispatch }: any,
        payload: IPayloadSearchInquiryFqa
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InquiryAdminFQAService.apiGetInquiry(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryAdminFqa', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createInquiryAnswerAdminFqa(
        { commit, dispatch }: any,
        payload: ITypePayloadAnswerInquiryAdminFQA
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InquiryAdminFQAService.apiCreateInquiryAnswerAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getInquiryDetailAdminFqa', {
                    id: payload?.inquiry_id
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterAdminFQA.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusAdminFqa(
        { commit, dispatch }: any,
        payload: IUpdateStatusInquiryAdminFQA
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res =
                await InquiryAdminFQAService.apiUpdateStatusInquiryAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getInquiryDetailAdminFqa({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await InquiryAdminFQAService.apiGetInquiryDetail(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryAdminFqa', {
                    ...data.data,
                    reply_content: ''
                })
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterAdminFQA.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getPreviewInquiryAdminFqa({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InquiryAdminFQAService.apiPreviewInquiryAdminFqa(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setPreviewInquiry', data.data)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterAdminFQA.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
