
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { HttpStatusCode, TypeInputCustom, TypeNotify } from '@/shared/enums'
import { regex } from '@/shared/regex'
import { IDataFormChangePassword } from '@/modules/auth/shared/@types'
import AuthService from '@/modules/auth/services'
import {
    NotificationCustom,
    ruleValidPassword,
    scrollToFirstErrorLineForm,
    ValidatePasswordRule
} from '@/shared/function'
import { WrappedFormUtils } from 'ant-design-vue/types/form/form'
import TitlePopup from '@/components/common_new/popup/title.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import { clearStorage } from '@/shared'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { i18n } from '@/shared/i18n'

type IDataChangePassword = {
    formChangePassword: WrappedFormUtils
    ListTypeInput: object
    ruleValidPass: any[]
    isShowPassword: boolean
}

export default Vue.extend({
    name: 'ChangePassword',
    components: { InputCustom, TitlePopup, ButtonCustom },
    data(): IDataChangePassword {
        return {
            ListTypeInput: TypeInputCustom,
            ruleValidPass: ruleValidPassword(),
            formChangePassword: this.$form.createForm(this, {
                name: 'form_change_password9999'
            }),
            isShowPassword: false
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleShowPassword() {
            this.isShowPassword = !this.isShowPassword
        },
        onSubmitForm() {
            this.formChangePassword.validateFields(
                (errors: Error[], values: IDataFormChangePassword) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    this.onPostChangePassword(values)
                }
            )
        },
        async onPostChangePassword(payload: IDataFormChangePassword) {
            await this.$store.dispatch('popup/setLoading', true, { root: true })
            await this.$store.dispatch('popup/setIsNotPageLoading', true, {
                root: true
            })
            try {
                const res: any = await AuthService.apiChangePassword(payload)
                const { status, data }: any = res
                if (status === HttpStatusCode.ok) {
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: data.message
                    })
                    clearStorage()
                    this.formChangePassword.resetFields()
                    await this.$store.dispatch('popup/hidePopup')
                    await this.$router.push(RouterAdvisorUrl.signin)
                    // this.$cookies.remove
                }
                if (status === HttpStatusCode.bad_request) {
                    NotificationCustom({
                        type: TypeNotify.error,
                        message: data.errors.error_message.old_password
                    })
                }
            } catch (e) {
            } finally {
                await this.$store.dispatch('popup/setLoading', false, {
                    root: true
                })
                await this.$store.dispatch('popup/setIsNotPageLoading', false, {
                    root: true
                })
            }
        },
        validPassCurrent() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_changePassword_passwordCurrent_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (
                                !regex.lengthPassword.test(value) ||
                                !ValidatePasswordRule(value)
                            ) {
                                callback(this.$tc('i18n_invalid_password'))
                                return
                            }

                            const passwordNew =
                                this.formChangePassword.getFieldValue(
                                    'new_password'
                                )

                            if (passwordNew) {
                                this.formChangePassword.validateFields([
                                    'new_password'
                                ])
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        validPassNew() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_changePassword_passwordNew_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (
                                !regex.lengthPassword.test(value) ||
                                !ValidatePasswordRule(value)
                            ) {
                                callback(this.$tc('i18n_invalid_password'))
                                return
                            }
                            const passwordNewConfirm =
                                this.formChangePassword.getFieldValue(
                                    'passwordNewConfirm'
                                )

                            if (passwordNewConfirm) {
                                if (passwordNewConfirm !== value) {
                                    this.formChangePassword.validateFields([
                                        'passwordNewConfirm'
                                    ])
                                }
                                if (passwordNewConfirm === value) {
                                    this.formChangePassword.validateFields([
                                        'passwordNewConfirm'
                                    ])
                                }
                            }
                            const passwordOld =
                                this.formChangePassword.getFieldValue(
                                    'old_password'
                                )
                            if (passwordOld && passwordOld === value) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changePassword_passwordNew_matchPasswordCurrent'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },

        validPassNewConfirm() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_changePassword_passNewConfirm_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (
                                !regex.lengthPassword.test(value) ||
                                !ValidatePasswordRule(value)
                            ) {
                                callback(this.$tc('i18n_invalid_password'))
                                return
                            }
                            const passwordNew =
                                this.formChangePassword.getFieldValue(
                                    'new_password'
                                )
                            if (passwordNew && passwordNew !== value) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changePassword_passNewConfirm_notMatchPasswordNew'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
