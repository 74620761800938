<template>
    <span :class="classTooltip">
        <img
            :src="require('@/assets/lib/images/common/icon/q-mark-org.svg')"
            alt=""
            :width="width"
        />
        <span :class="classContent" v-html="content" />
    </span>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'TooltipCustom',
    props: {
        classTooltip: {
            type: String,
            default: 'tooltipType1'
        },
        content: {
            type: String,
            require
        },
        classContent: String,
        width: String
    }
})
</script>
