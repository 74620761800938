
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import InputCustom from '@/components/common/input/index.vue'
import { regex } from '@/shared/regex'
import { getDataStorage } from '@/shared'
import {
    KeyStorage,
    ListNamePopup,
    TypeInputCustom,
    UserTypeRole
} from '@/shared/enums'
import { mapGetters } from 'vuex'
import { configEditorWithSelectFont } from '@/shared/constants'
import {
    getRawHTML,
    getTextEditor,
    getRoleTypeDomain,
    scrollToFirstErrorLineForm
} from '@/shared/function'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import TooltipCustom from '@/components/common_new/tooltip/index.vue'

export default Vue.extend({
    name: 'CreateSignature',
    components: { TooltipCustom, Title, InputCustom, ButtonCustom },
    data() {
        return {
            formCreateSignature: this.$form.createForm(this, {
                name: 'form_Create_Signature'
            }),
            configEditorWithSelectFont,
            defaultSignature: '',
            TypeInputCustom,
            showRichText: false,
            UserTypeRole,
            typeRouter: getRoleTypeDomain()
        }
    },
    mounted() {
        if (this.getParamsPopup?.dataPreview) {
            this.formCreateSignature.setFieldsValue({
                signature_content: this.getParamsPopup?.signature
            })
            this.defaultSignature = this.getParamsPopup?.signature
            return
        }
        const getSignature = getDataStorage(
            KeyStorage.profile
        ).signature_content
        if (getSignature) {
            this.formCreateSignature.setFieldsValue({
                signature_content: getSignature
            })
            this.defaultSignature = getSignature
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        handlePreview() {
            this.formCreateSignature.validateFields(
                (error: Error[], values: any) => {
                    if (error) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const params = {
                        signature: values
                    }
                    this.$store.dispatch('popup/openPopup', {
                        isOpen: true,
                        namePopup: ListNamePopup.PreviewCreateSignature,
                        width: 1000,
                        payload: params
                    })
                }
            )
        },
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleCreate() {
            this.formCreateSignature.validateFields(
                (error: Error[], values: any) => {
                    if (error) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    this.handleSignature(values)
                }
            )
        },
        handleSignature(values: any) {
            const body = {
                ...values
            }
            body.signature_content = getRawHTML(body.signature_content)
            this.$store.dispatch('signupAdvisor/apiUpdateSignature', body)
        },
        rulesWriteSignature(isTaxAdvisor = false) {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (!isTaxAdvisor) {
                            value = getTextEditor(value)
                        }
                        if (value) {
                            if (!regex.length500.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_signature_writesSignature_length500'
                                    )
                                )
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
