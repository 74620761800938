import { RouteConfig } from 'vue-router'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import HomeView from '@/views/HomeView.vue'
import auth from '@/middleware/auth'
import { favicon, nameRouterProvider, titlePage } from '@/shared/constants'

export const routesProvider: Array<RouteConfig> = [
    {
        path: '/',
        name: nameRouterProvider.home,
        redirect: '/my-page',
        component: HomeView
    },
    {
        path: RouterProviderUrl.signin,
        name: nameRouterProvider.signin,
        component: () => import('../views/provider/signin/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.signup,
        name: nameRouterProvider.signup,
        component: () => import('../views/provider/signup/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.passwordReset,
        name: nameRouterProvider.passwordReset,
        component: () => import('../views/advisor/password-reset/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.resetPassword,
        name: nameRouterProvider.resetPassword,
        component: () => import('../views/reset-password/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.basicInformations,
        name: nameRouterProvider.basicInfo,
        component: () =>
            import('../views/provider/signup/basic-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.officeInformations,
        name: nameRouterProvider.officeInfo,
        component: () =>
            import('../views/provider/signup/office-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.contractInformations,
        name: nameRouterProvider.contractInfo,
        component: () =>
            import('../views/provider/signup/contract-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.promotions,
        name: nameRouterProvider.promotions,
        component: () =>
            import('../views/provider/signup/promotions/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.announcement,
        name: nameRouterProvider.announcement,
        component: () => import('../views/provider/announcement/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.announcementDetail + ':id',
        name: nameRouterProvider.announcementDetail,
        component: () =>
            import('../views/provider/announcement/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.myPage,
        name: nameRouterProvider.myPage,
        component: () => import('../views/provider/my-page/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.countAccess,
        name: nameRouterProvider.countAccess,
        component: () => import('../views/provider/count-access/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.inquiry,
        name: nameRouterProvider.inquiry,
        component: () => import('../views/provider/inquiry/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.inquiryCreate,
        name: nameRouterProvider.inquiryCreate,
        component: () => import('../views/provider/inquiry/create/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.inquiryDetail + ':id',
        name: nameRouterProvider.inquiryDetail,
        component: () => import('../views/provider/inquiry/detail/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.products,
        name: nameRouterProvider.products,
        component: () => import('../views/provider/product/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productManagement + ':id',
        name: nameRouterProvider.productManagement,
        component: () =>
            import('../views/provider/product/management/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productInquiry + ':id',
        name: nameRouterProvider.productInquiry,
        component: () =>
            import('../views/provider/product/management/inquiry.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productProgress + ':id',
        name: nameRouterProvider.productProgress,
        component: () =>
            import('../views/provider/product/management/progress.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productsPreview,
        name: nameRouterProvider.productsPreview,
        component: () => import('../views/provider/product/preview/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productCreate,
        name: nameRouterProvider.productCreate,
        component: () => import('../views/provider/product/create/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.incoming,
        name: nameRouterProvider.incoming,
        component: () => import('../views/provider/incoming/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.incomingDetail + ':id',
        name: nameRouterProvider.incomingDetail,
        component: () => import('../views/provider/incoming/detail/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.incomingPreview + ':id',
        name: nameRouterProvider.incomingPreview,
        component: () => import('../views/provider/incoming/preview/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.productDetail + ':id',
        name: nameRouterProvider.productDetail,
        component: () => import('../views/provider/product/detail/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.membershipRegist,
        name: nameRouterProvider.membershipRegist,
        component: () =>
            import('../views/provider/my-page/membership-regist/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.membershipRegistFree,
        name: nameRouterProvider.membershipRegistFree,
        component: () =>
            import('../views/provider/my-page/membership-regist/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.providerSearch,
        name: nameRouterProvider.providerSearch,
        component: () => import('../views/provider/search-provider/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.searchResult,
        name: nameRouterProvider.searchResult,
        component: () =>
            import('../views/provider/search-provider/result-list/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdminNew',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.providerSearchDetail + ':id',
        name: nameRouterProvider.providerSearchDetail,
        component: () =>
            import('../views/provider/search-provider/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdminNew',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.providerFavorite,
        name: nameRouterProvider.providerFavorite,
        component: () =>
            import('../views/provider/search-provider/favorite/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.paymentHistory,
        name: nameRouterProvider.paymentHistory,
        component: () => import('../views/provider/payment/history.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterProviderUrl.notFound,
        name: nameRouterProvider.notFound,
        component: () => import('../views/404.vue')
    }
]
