
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { checkTypeUrl, customUrlImage } from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { StatusPublicImage } from '@/modules/advisor/shared/enums'
import IframeVideoForm from '@/components/common/videoIframe/IframeVideoForm.vue'
import { loginPaths, typeVideo } from '@/shared/constants'

export default Vue.extend({
    name: 'ShowVideoPreview',
    components: {
        IframeVideoForm
    },
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'form_video'
            }),
            dataVideo: {} as any,
            typeVideo,
            typeVideoOfficeOnl: '',
            idOfficeUrl: '',
            typeVideoAdvisorOnl: '',
            idAdvisorUrl: ''
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getPromotionVideos',
            'getDataVideo',
            'getDataPreviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', ['getDataVideoAdvisorAdmin']),
        formatURLOfficeVideo(): string {
            if (
                this.getDataPreviewAdvisor.officeVideo &&
                this.getDataPreviewAdvisor.officeVideo[0].value &&
                this.dataVideo?.office_video_link === '' &&
                this.dataVideo?.office_video_link_online === ''
            ) {
                return this.getDataPreviewAdvisor.officeVideo[0].value
            }

            if (
                this.getDataPreviewAdvisor.officeVideo &&
                this.getDataPreviewAdvisor.officeVideo[0].value
            ) {
                return this.getDataPreviewAdvisor.officeVideo[0].value
            }
            return (
                this.dataVideo?.office_video_link_online ||
                this.dataVideo?.office_video_link
            )
        },
        formatURLAdvisorVideo(): string {
            if (
                this.getDataPreviewAdvisor.advisorVideo &&
                this.getDataPreviewAdvisor.advisorVideo[0].value &&
                this.dataVideo?.advisor_video_link === '' &&
                this.dataVideo?.advisor_video_link_online === ''
            ) {
                return this.getDataPreviewAdvisor.advisorVideo[0].value
            }

            if (
                this.getDataPreviewAdvisor.advisorVideo &&
                this.getDataPreviewAdvisor.advisorVideo[0].value
            ) {
                return this.getDataPreviewAdvisor.advisorVideo[0].value
            }
            return (
                this.dataVideo?.advisor_video_link_online ||
                this.dataVideo?.advisor_video_link
            )
        }
    },
    methods: {
        customUrlImage,
        isPublicVideo(dataVideo: any, status = StatusPublicImage.PRIVATE) {
            return dataVideo && status === StatusPublicImage.PUBLIC
        },
        getUrlAndTypeVideo() {
            let { office_video_link_online, advisor_video_link_online } =
                this.dataVideo
            if (office_video_link_online) {
                let { typeVideoOnl, idUrl } = checkTypeUrl(
                    office_video_link_online
                )
                this.typeVideoOfficeOnl = typeVideoOnl
                this.idOfficeUrl = idUrl
            }
            if (advisor_video_link_online) {
                let { typeVideoOnl, idUrl } = checkTypeUrl(
                    advisor_video_link_online
                )
                this.typeVideoAdvisorOnl = typeVideoOnl
                this.idAdvisorUrl = idUrl
            }
        }
    },
    mounted() {
        if (this.getDataPreviewAdvisor.isAdmin) {
            this.dataVideo = this.getDataVideoAdvisorAdmin
            return
        }
        this.dataVideo =
            this.getDataPreviewAdvisor.promotionVideo ?? this.getPromotionVideos
        this.getUrlAndTypeVideo()
    },
    watch: {
        getDataVideoAdvisorAdmin(newVal) {
            if (this.getDataPreviewAdvisor.isAdmin) {
                this.dataVideo = newVal
            }
        },
        getPromotionVideos(newVal) {
            if (this.getDataPreviewAdvisor.isAdmin) {
                return
            }
            this.dataVideo = this.getDataPreviewAdvisor.promotionVideo ?? newVal
            this.getUrlAndTypeVideo()
        }
    }
})
