import apiServices from '@/shared/services/api/axios'

const AdvisorProviderServices = {
    apiGetProductSave(payload: any) {
        let url = '/tax-advisor/saving-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiCreateMultipleInquiryProvider(payload: any) {
        return apiServices.post(
            'tax-advisor/create-multiple-product-inquiry',
            payload
        )
    },
    apiDeleteProductSave(payload: any) {
        return apiServices.post('tax-advisor/delete-saving-products', payload)
    }
}
export default AdvisorProviderServices
