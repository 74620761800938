import { copyDeep } from '@/shared'

export const defaultState: object = {
    isLoading: false,
    typeLoading: 1
}

const state = () => copyDeep(defaultState)

const getters = {
    getIsLoading(state: any) {
        return state.isLoading
    },
    getTypeLoading(state: any) {
        return state.typeLoading
    }
}

const actions = {
    setIsLoading({ commit }: any, payload: boolean) {
        commit('setLoadingState', payload)
    },
    setTypeLoading({ commit }: any, payload: number) {
        commit('setTypeLoading', payload)
    }
}

const mutations = {
    setLoadingState(state: any, payload: boolean) {
        state.isLoading = payload
    },
    setTypeLoading(state: any, payload: number) {
        state.typeLoading = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
