
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { ListNamePopup } from '@/shared/enums'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ExplainReferCode',
    components: { TitlePopup },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        handleClose() {
            if (this.getParamsPopup.openFromLoginSupport) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.LoginSupport
                })
                return
            }
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
