import apiServices from '@/shared/services/api/axios'
import { IPayloadCreateInquiryAdvisorProvider } from '@/modules/advisor/modules/product-inquiry/shared/@type'

const AdvisorProviderInquiryServices = {
    apiSendInquiryProvider(params: IPayloadCreateInquiryAdvisorProvider) {
        return apiServices.post('/tax-advisor/create-product-inquiry', params)
    },
    apiDetailInquiryProvider(params: any) {
        return apiServices.get(
            '/tax-advisor/product-inquiry-history?id=' + params
        )
    },
    apiDetailInquiryProviderDraft(params: any) {
        return apiServices.get(
            '/tax-advisor/product-inquiry-draft?product_id=' + params.product_id
        )
    },
    apiAnswerInquiryProvider(params: any) {
        return apiServices.post(
            '/tax-advisor/create-anwser-product-inquiry',
            params
        )
    },
    apiProgressProvider(params: any) {
        return apiServices.get(
            '/tax-advisor/provider-product-progress?inquiry_id=' + params
        )
    },
    apiGetAdvisorRatingProvider(params: any) {
        return apiServices.get(
            '/tax-advisor/product-rating?inquiry_id=' + params
        )
    },
    apiRatingProvider(params: any) {
        return apiServices.post('/tax-advisor/rate-product', params)
    },
    apiDetailRatingProvider(params: any) {
        return apiServices.get('/tax-advisor/provider-rating?id=' + params)
    },
    apiListInquiryProduct(payload: any) {
        let url = '/tax-advisor/list-product-inquiries'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    }
}
export default AdvisorProviderInquiryServices
