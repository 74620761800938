
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import AuthService from '@/modules/auth/services'
import {
    HttpStatusCode,
    KeyStorage,
    TypeAccount,
    TypeNotify,
    UserTypeRole,
    ValueStatusAdvisor
} from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { removeDataStorage, setDataStorage } from '@/shared'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import ButtonCustomNew from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'LoginConflictConfirm',
    components: { ButtonCustomNew, TitlePopup },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        async handleLoginConflict() {
            if (!this.getParamsPopup) return
            const payload = this.getParamsPopup
            this.$store.commit('popup/setLoading', true, { root: true })
            this.$store.commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            try {
                const res: any = await AuthService.apiLogin(payload)
                const { status, data }: any = res
                if (status === HttpStatusCode.ok) {
                    await this.$store.dispatch('popup/hidePopup')
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: data.message
                    })
                    const { type, user_type } = data.data.user
                    payload.remember
                        ? setDataStorage(
                              KeyStorage.rememberEmail,
                              payload.email
                          )
                        : removeDataStorage(KeyStorage.rememberEmail)
                    setDataStorage(
                        KeyStorage.assetToken,
                        data.data.access_token
                    )
                    setDataStorage(
                        KeyStorage.profile,
                        JSON.stringify(data.data.user)
                    )
                    setDataStorage(
                        KeyStorage.refreshToken,
                        data.data.refresh_token
                    )
                    // account TEMPORARY REGISTRATION
                    if (
                        user_type === UserTypeRole.advisor &&
                        Number(data.data.user.status) ===
                            ValueStatusAdvisor.TEMPORARY_REGISTRATION
                    ) {
                        window.location.href =
                            RouterAdvisorUrl.basicInformations
                        return
                    }
                    // account admin fqa
                    if (user_type === UserTypeRole.admin) {
                        if (type === TypeAccount.adminFQA) {
                            window.location.href = RouterAdminFQA.inquiry
                            return
                        }
                    }
                    window.location.href = '/my-page'
                    return
                }
                this.$store.commit('popup/setLoading', false, { root: true })
                this.$store.commit('popup/setIsNotPageLoading', false, {
                    root: true
                })
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            } catch (e) {}
        },
        handleClose() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
