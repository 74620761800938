import apiServices from '@/shared/services/api/axios'
import {
    IDataAddMail,
    IFormSampleFees,
    IPayloadChangeIntroduction,
    IPayloadDeleteSampleFees,
    ITypeGetListMail,
    ITypeTaxAdvisorRegistration,
    ITypeTaxAdvisorUpdate,
    ITypeUpdateSignature
} from '@/modules/advisor/shared/@types'
import { IPayloadInterview } from '@/modules/advisor/modules/signup/shared/@types'

const SignupAdvisorService = {
    apiGetBasicInfo() {
        return apiServices.get('/tax-advisor/basic-info')
    },
    apiRegisterBasicInfo(payload: any) {
        return apiServices.post('/tax-advisor/basic-info', payload)
    },
    apiRegisterSampleFees(payload: any) {
        return apiServices.post('/tax-advisor/basic-info', payload)
    },
    apiSampleFees() {
        return apiServices.get('/tax-advisor/fee')
    },
    apiCreateSampleFees(payload: IFormSampleFees[]) {
        return apiServices.post('/tax-advisor/save-fee', payload)
    },
    apiDeleteSampleFees(payload: IPayloadDeleteSampleFees) {
        return apiServices.post('/tax-advisor/delete-fee', payload)
    },
    apiSearchZipcode(zipCode: string) {
        return fetch(
            'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipCode
        )
            .then(res => res.json())
            .catch(error => error.response)
    },
    apiGetServiceContent() {
        return apiServices.get('/service-content')
    },
    apiGetAreaProvince() {
        return apiServices.get('/area-province')
    },
    apiGetAreaCity(params: any) {
        return apiServices.get('/area-city', { params })
    },
    apiGetOfficeInfo() {
        return apiServices.get('/tax-advisor/get-office-info')
    },
    apiRegisterOfficeInfo(payload: any) {
        return apiServices.post('/tax-advisor/register-office-info', payload)
    },
    apiUpdateOfficeInfo(payload: any) {
        return apiServices.post('/tax-advisor/update-office-info', payload)
    },
    apiGetTaxAccountInfo() {
        return apiServices.get('/tax-advisor/info')
    },
    apiListTaxGroup() {
        return apiServices.get('/tax-advisor/tax_group')
    },
    apiTaxAdvisorRegistration(payload: ITypeTaxAdvisorRegistration) {
        return apiServices.post('/tax-advisor/registration', payload)
    },
    apiTaxAdvisorUpdate(payload: ITypeTaxAdvisorUpdate) {
        return apiServices.post('/tax-advisor/update', payload)
    },
    apiGetPromotionVideo() {
        return apiServices.get('/tax-advisor/tax-promotion-video')
    },
    apiUploadPromotionVideo(payload: any) {
        return apiServices.post(
            '/tax-advisor/upload/tax-promotion-video',
            payload
        )
    },
    apiUpdateSignature(payload: ITypeUpdateSignature) {
        return apiServices.post('/update-signature', payload)
    },
    apiInterview(payload: IPayloadInterview) {
        return apiServices.post('/tax-advisor/save-interview', payload)
    },
    apiGetInterview() {
        return apiServices.get('/tax-advisor/get-interview')
    },
    apiGetListEmail(payload: ITypeGetListMail) {
        const url = '/tax-advisor/list-recievers'
        return apiServices.get(url, payload)
    },
    apiGetQualification() {
        return apiServices.get('/get-qualification-selection')
    },
    apiGetDomainSampleFee() {
        return apiServices.get('/domain-sample-fee')
    },
    apiUpdateListMail(payload: any) {
        return apiServices.post('/tax-advisor/update-reciever-emails', payload)
    },
    apiRegisterEmail(payload: IDataAddMail) {
        return apiServices.post(
            '/tax-advisor/registration-email-reciever',
            payload
        )
    },
    apiChangeIntroduction(payload: IPayloadChangeIntroduction) {
        return apiServices.post('/admin/change-introduction', payload)
    },
    apiGetMailReceivers(payload: any) {
        return apiServices.get(
            `/tax-advisor/email-recievers?reciever_flg=${payload}`
        )
    },
    apiGetListMailAdminAdvisor(id: number) {
        return apiServices.get(`/admin/email-recievers/${id}`)
    },
    apiGetRoleEditFormAdvisor() {
        return apiServices.get(`/tax-advisor/user`)
    }
}
export default SignupAdvisorService
