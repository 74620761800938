var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mailListPage"},[_c('TitlePopup',{attrs:{"title":"メールアドレス登録・編集"}}),_vm._m(0),_c('div',{staticClass:"change-rcvg-emailForm"},[_c('div',{staticClass:"chg-rcvg-email_btnAddWrap"},[_c('ButtonCustom',{attrs:{"label":"追加登録","classes":"btnType3d","disabled":_vm.listMailAdditional.length === 2 ||
                    _vm.getRoleEditAdvisor === _vm.EditPermission.FALSE},on:{"onClick":function($event){return _vm.showPopupAddMail(null)}}})],1),_c('div',{staticClass:"chg-rcvg-email_delLayOut"},[_c('div',{staticClass:"chg-rcvg-email_tableWrap"},[_c('table',{staticClass:"tableType6"},[_c('thead',[_c('tr',[_c('th',{staticClass:"w210"},[_vm._v("メール種別・名前")]),_c('th',{staticClass:"w330"},[_vm._v("メールアドレス")]),_c('th',{staticClass:"w120"},[_vm._v(" 受信可否 "),_c('TooltipCustom',{attrs:{"class-tooltip":"tooltipType1","content":"受信ボックスをチェックすることで、外部との受信ができます。","class-content":"balloon_left"}})],1)])]),_c('tbody',_vm._l((_vm.listMail),function(mail,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(_vm.filterStatusTable( _vm.listTypeMail, mail?.type ))+" ")]),_c('td',[_vm._v(_vm._s(mail.email))]),_c('td',[(mail.type !== _vm.RecieverFlg.TRUE)?_c('label',{class:_vm.getRoleEditAdvisor ===
                                            _vm.EditPermission.FALSE &&
                                        'disabled'},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.getRoleEditAdvisor ===
                                            _vm.EditPermission.FALSE},domProps:{"checked":mail?.reciever_flg},on:{"click":function($event){return _vm.onChange(mail.key, 'receiver')}}}),_c('span',[_vm._v("受信")])]):_vm._e()])])}),0)])])]),_c('div',{staticClass:"chg-rcvg-email_delLayOut pc-mt-2"},[_c('div',{staticClass:"chg-rcvg-email_tableWrap flexCols flexAc"},[_c('table',{staticClass:"tableType6"},[_c('tbody',_vm._l((_vm.listMailAdditional),function(mail,key){return _c('tr',{key:key},[_c('td',{staticClass:"w210"},[_vm._v(_vm._s(mail.name))]),_c('td',{staticClass:"w240"},[_vm._v(_vm._s(mail.email))]),_c('td',{staticClass:"w90"},[_c('ButtonCustom',{attrs:{"label":"編集","classes":"btn-chg-rcvg-email_edit","disabled":_vm.getRoleEditAdvisor ===
                                        _vm.EditPermission.FALSE},on:{"onClick":function($event){return _vm.showPopupAddMail(mail)}}})],1),_c('td',{staticClass:"w120"},[_c('label',[_c('input',{attrs:{"type":"checkbox","disabled":_vm.getRoleEditAdvisor ===
                                            _vm.EditPermission.FALSE},domProps:{"checked":mail.reciever_flg},on:{"click":function($event){return _vm.onChange(
                                                mail.key,
                                                'receiver_add'
                                            )}}}),_c('span',[_vm._v("受信")])])]),_c('td',{staticClass:"w60 chg-rcvg-email_check"},[_c('label',{class:_vm.getRoleEditAdvisor ===
                                            _vm.EditPermission.FALSE &&
                                        'disabled'},[_c('input',{attrs:{"type":"checkbox","name":"","disabled":_vm.getRoleEditAdvisor ===
                                            _vm.EditPermission.FALSE},domProps:{"checked":mail.delete_flg},on:{"click":function($event){return _vm.onChange(mail.key, 'delete')}}}),_c('span',{staticClass:"fw700"},[_vm._v("削除")])])])])}),0)])])]),_c('p',{staticClass:"chg-rcvg-email_note1"},[_vm._v(" ※追加メールアドレスは2件までとなります。 ")]),_c('div',{staticClass:"change-email_btnWrap flexCols flexJc pc-mt-4 sp-mt-2"},[_c('ButtonCustom',{attrs:{"label":_vm.$tc('i18n_back'),"classes":"btnType2c js-modal_close"},on:{"onClick":_vm.onClosePopup}}),_c('ButtonCustom',{attrs:{"label":_vm.$tc('i18n_btn_update_mail_list'),"classes":"btnType2a withdrawal_type","disabled":_vm.getRoleEditAdvisor === _vm.EditPermission.FALSE,"btn-call-api":""},on:{"onClick":_vm.updateMailList}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"change-rcvg-email_intro"},[_c('h3',[_vm._v("受信用メールアドレス登録・編集")]),_c('p',[_vm._v(" 受信用メールアドレスは「最大5件」までご登録できます。"),_c('br'),_vm._v(" 所属税理士の受信用メールアドレスの登録は、所属税理士追加画面よりご登録ください。"),_c('br'),_vm._v(" 税理士以外の受信者は追加登録ボタンよりご登録ください。"),_c('br'),_vm._v(" 登録することで、個人・法人からの問合せおよび運営者等からのメールを受信する事ができます。 ")])])
}]

export { render, staticRenderFns }