import { ITypeSelectCustom } from '@/shared/constants/IContants'
import {
    ImagePositionInterview,
    OrderInterviewItem
} from '@/modules/advisor/modules/signup/shared/enums'
import {
    ITypeDataInitInterviewForm,
    ITypeFormInterviewItem
} from '@/modules/advisor/modules/signup/shared/@types'
import { copyDeep } from '@/shared'
import { ViewMode } from '@/shared/enums'
import { ITypeColumnsTableCustom } from '@/modules/admin/modules/admin-management/shared/constants/IConstants'

const PublicInfoInterviewSelect: ITypeSelectCustom[] = [
    { label: '公開', value: ViewMode.PUBLIC },
    { label: '非公開', value: ViewMode.PRIVATE }
]

const CategoryInterviewSelect: ITypeSelectCustom[] = [
    {
        value: 1,
        label: '税理士を目指すきっかけは何ですか？',
        order: OrderInterviewItem.FIRST,
        placeholder:
            '（例）父親の時代から税理士事務所を開業しており、自然と将来は税理士になって税務面から経営サポートを行いたいと考え税理士を目指しました。また、父親からは「税理士はお客様の会社の事はもちろん、個人の事、家庭の事等、人生のあらゆる場面の相談を受ける仕事だよ」と教えられました。責任は重いですが、その分やりがいのある、人の役に立てる仕事だと感じ、税理士を目指そうと思ったきっかけとなりました。'
    },
    {
        value: 2,
        label: '独立されたきっかけと、その後を教えていただけますか？',
        order: OrderInterviewItem.FIRST,
        placeholder:
            '（例）事業会社退職後、会計事務所勤務時に年齢的にも自身が目指す会計人として精力的に働くにはギリギリのタイミングだと思い独立しました。独立当初は、相続や事業承継を取扱い業務の中心にしたいと考えていましたが、知合いの銀行員から新規法人の税務顧問の紹介や顧問先からの紹介、インターネットからの問合せなど、ご縁が重なり現在は顧問企業数〇〇社以上の顧問契約をいただいております。'
    },
    {
        value: 3,
        label: '税理士事務所を開業されたきっかけは何ですか？',
        order: OrderInterviewItem.FIRST,
        placeholder:
            '（例）一人一人の経営者に向き合い、税務業務のみならず多方面で経営者を支える事ができる税理士になりたいと考え、自分の目指す税理士事務所を開業しました。'
    },
    {
        value: 1,
        label: 'どのような事務所ですか？',
        order: OrderInterviewItem.SECOND,
        placeholder:
            '（例）当事務所は東京3拠点、大阪1拠点、と法人の申告に特化した税理士事務所です。事務所内の雰囲気は柔らかく、事務所のスタッフが顧問先に対して少しでも役に立てるように研修体制を整えています。また、お客様に合った経理体制を柔軟に対応できるようにおこなっている事務所です。'
    },
    {
        value: 2,
        label: '事務所の特徴は何ですか？',
        order: OrderInterviewItem.SECOND,
        placeholder:
            '（例）顧問先は多岐にわたり芸能事務所などもあります。特に当事務所はお客様目線で経理スタッフの一員として意識を共有しサポートをしています。また「もし私が経理や税務申告を依頼するとしたら、どんな税理士事務所に頼みたいだろうか？」と日々考え、そうした税理士事務所であろうとしています。'
    },
    {
        value: 3,
        label: '事務所の強みは何ですか？',
        order: OrderInterviewItem.SECOND,
        placeholder:
            '（例）創業前の方、事業を拡大しようとしている経営者、逆に事業が不振な状況の経営者も、多くの経営者にとって資金繰りは常に重要な課題と考えています。そうした経営者ために融資のサポートは重視していますし、当事務所の強みでもあります。'
    },

    {
        value: 1,
        label: '業務としてはどのようなご相談が多いですか？',
        order: OrderInterviewItem.TUESDAY,
        placeholder:
            '（例）税理士業務として、クラウドの導入が多くなってきましたが日々の記帳業務、税務申告業務や確定申告、この他に事業計画書策定支援などを取扱うことが多いのですが、それ以外にも社会保険手続・許認可の取得や届出・補助金の申請支援など、本業以外にも相談が多いです。事務所の本業でないことも、当事務所ではお客様の利便性を考え、弁護士・社会保険労務士などの他の士業の方とも連携しながらご相談に対応しております。'
    },
    {
        value: 2,
        label: 'お客様と接する中で心掛けていることはありますか？',
        order: OrderInterviewItem.TUESDAY,
        placeholder:
            '（例）税理士は、人と人との関係で成り立っている職業だと考えています。もちろん税務業務は大事ですが、帳簿が相手ではなく、当事務所は、まずは人との関わりが大事になってきます。そこの信頼関係があってこその税務業務だと思っています。'
    },
    {
        value: 3,
        label: 'お客様にどのような事を伝えたいですか？',
        order: OrderInterviewItem.TUESDAY,
        placeholder:
            '（例）相続税の申告や企業における決算申告、個人の確定申告など税理士が皆様に関わることは非常に多いと思います。当事務所は「税務申告に関わる方に正確な申告と親身な相談相手」をモットーとしておりますので、是非、どんような些細なご相談も当事務所にご相談下さい。'
    },
    {
        value: 1,
        label: '御事務所にご依頼しようと思っている方に一言お願いします。',
        order: OrderInterviewItem.FOURTH,
        placeholder:
            '（例）ホームページに負の情報を書く人はいませんので、たくさんの情報から本当に信頼できる事務所を探すのは難しいことだと思います。当事務所ではホームページの中で業務に関する情報を少しでも感じ取って頂けるようにと思っております。どんな些細なご相談でも声を掛けて頂けること自体に喜びを感じながら楽しく仕事をしていますので、どうぞお気軽にお声をお掛け下さい。'
    },
    {
        value: 2,
        label: '今後どのような税理士事務所にしていきたいですか？',
        order: OrderInterviewItem.FOURTH,
        placeholder:
            '（例）これからは、AIやITを活用しながらお客様の経営に役立つサービスを提供していきたいと考えていますが、あくまでAIやITを使うのは人間であり、人と関わっていくという本質は変わらないと思います。顧問先とのコミュニケーションを重視し、顧問先に喜んで頂ける、「この事務所に頼んで良かった」と思われる事務所でありたいと考えています。'
    },
    {
        value: 3,
        label: '今後はどのような業務を展開される予定ですか？',
        order: OrderInterviewItem.FOURTH,
        placeholder:
            '（例）現在は法人や個人事業の方の申告が中心ですが今後は相続や事業承継などにも力を入れていきます。また、税務だけでなく弁護士や司法書士などワンストップで企業経営者の方にご満足いただけるサービスを展開していきたいと考えています。'
    }
]

const ImagePositionInterviewSelect: ITypeSelectCustom[] = [
    {
        label: '回答欄の左に表示',
        value: ImagePositionInterview.LEFT
    },
    {
        label: '回答欄の右に表示',
        value: ImagePositionInterview.RIGHT
    }
]

const DataInitInterviewForm: ITypeDataInitInterviewForm = {
    is_public_flg: ViewMode.PRIVATE,
    image_position: ImagePositionInterview.LEFT,
    interview_cate: null,
    interview_content: '',
    image: ''
}

const TitleFormInterviewItem: ITypeFormInterviewItem[] = [
    {
        label: '税理士としての「きっかけ」と「独立」',
        value: OrderInterviewItem.FIRST,
        data: copyDeep(DataInitInterviewForm),
        ref: 'formRef1'
    },
    {
        label: '事務所の「特徴」と「強み」',
        value: OrderInterviewItem.SECOND,
        data: copyDeep(DataInitInterviewForm),
        ref: 'formRef2'
    },
    {
        label: '顧問先・お客様に対して',
        value: OrderInterviewItem.TUESDAY,
        data: copyDeep(DataInitInterviewForm),
        ref: 'formRef3'
    },
    {
        label: '今後の展望',
        value: OrderInterviewItem.FOURTH,
        data: copyDeep(DataInitInterviewForm),
        ref: 'formRef4'
    }
]

const PublicSampleFeesSelect: ITypeSelectCustom[] = [
    { label: '公開', value: ViewMode.PUBLIC },
    { label: '非公開', value: ViewMode.PRIVATE }
]

const ColumnsListMail: ITypeColumnsTableCustom = [
    {
        title: 'メール種別・名前',
        dataIndex: 'type',
        key: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: '受信可否',
        dataIndex: 'reciever_flg',
        key: 'reciever_flg',
        scopedSlots: { customRender: 'reciever_flg' }
    }
]
const ColumnsListMailAdditional: ITypeColumnsTableCustom = [
    {
        title: '',
        dataIndex: 'type',
        key: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: '',
        dataIndex: 'reciever_flg',
        key: 'reciever_flg',
        scopedSlots: { customRender: 'reciever_flg' }
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        scopedSlots: { customRender: 'delete' }
    }
]

const apiEmbedMap = process.env.VUE_APP_API_EMBED_GOOGLE_MAP

const defaultAddressMap = '日本'

export {
    ImagePositionInterviewSelect,
    CategoryInterviewSelect,
    PublicInfoInterviewSelect,
    DataInitInterviewForm,
    TitleFormInterviewItem,
    PublicSampleFeesSelect,
    ColumnsListMail,
    ColumnsListMailAdditional,
    apiEmbedMap,
    defaultAddressMap
}
