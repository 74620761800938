import apiServices from '@/shared/services/api/axios'
import {
    ITypeParamAdmin,
    ITypeRegisterAdmin,
    ITypeUpdateAdmin
} from '../shared/constants/IConstants'

const AdminManagementService = {
    apiGetListAdmin(payload: any) {
        let url = '/admin/get-list-admin'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }
        return apiServices.get(url)
    },
    apiRegisterAdmin(payload: ITypeRegisterAdmin) {
        return apiServices.post('/admin/register-admin', payload)
    },
    apiUpdateAdmin(payload: ITypeUpdateAdmin) {
        return apiServices.post('/admin/update-admin', payload)
    },
    apiGetAdminUser(id: ITypeParamAdmin) {
        return apiServices.get(`/admin/get-user?id=${id}`)
    },
    apiDeleteAdminUser(payload: ITypeParamAdmin) {
        return apiServices.post('/admin/delete-admin', payload)
    }
}
export default AdminManagementService
