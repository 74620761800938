import apiServices from '@/shared/services/api/axios'
import { AxiosResponse } from 'axios'

const SignupProviderService = {
    apiGetBasicInfo() {
        return apiServices.get('/provider/basic-info')
    },
    apiCreateBasicInfo(payload: any) {
        return apiServices.post('/provider/basic-info', payload)
    },
    apiUpdateBasicInfo(payload: any) {
        return apiServices.post('/provider/user-basic-info', payload)
    },
    apiGetListEmail(payload: any) {
        return apiServices.get('/provider/list-reciever-emails', payload)
    },
    apiGetListMailProvider(payload: any) {
        return apiServices.get(
            `/provider/list-reciever-emails?reciever_flg=${payload}`
        )
    },
    apiUpdateListMail(payload: any) {
        return apiServices.post('/provider/update-reciever-emails', payload)
    },
    apiRegisterEmail(payload: any) {
        return apiServices.post(
            '/provider/registration-email-reciever',
            payload
        )
    },
    apiGetOfficeInfo() {
        return apiServices.get('/provider/office')
    },
    apiRegisterOfficeInfo(payload: any) {
        return apiServices.post('/provider/register-office', payload)
    },
    apiUpdateOfficeInfo(payload: any) {
        return apiServices.post('/provider/update-office', payload)
    },
    apiGetPromotionVideo(): Promise<AxiosResponse<any>> {
        return apiServices.get('/provider/promotion-video')
    },
    apiUploadPromotionVideo(payload: any): Promise<AxiosResponse<any>> {
        return apiServices.post('/provider/upload-promotion', payload)
    },
    apiGetSampleContract() {
        return apiServices.get('/provider/sample-contract')
    },
    apiGetSampleContractProduct() {
        return apiServices.get('/provider/products')
    },
    apiCreateContract(payload: any) {
        return apiServices.post('/provider/create-sample-contract', payload)
    },
    apiUpdateContract(payload: any) {
        return apiServices.post('/provider/update-sample-contract', payload)
    },
    apiDeleteContract(payload: any) {
        return apiServices.post('/provider/delete-sample-contract', payload)
    },
    apiSearchIntroCode(payload: any) {
        return apiServices.post('/check-intro-code', payload)
    },
    apiCountProvider() {
        return apiServices.get('/provider/count-notify')
    },
    apiGetListMailAdminProvider(id: number) {
        return apiServices.get(`/admin/provider-email-recievers/${id}`)
    },
    apiGetPromotionImage(): Promise<AxiosResponse<any>> {
        return apiServices.get('/provider/promotion-image')
    }
}
export default SignupProviderService
