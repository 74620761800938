
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ListNamePopup } from '@/shared/enums'
import { isObject } from '@/shared/function'
export default Vue.extend({
    name: 'GraphRateProvider',
    components: { Title, ButtonCustom },
    data(): any {
        return {
            count: 0,
            averageRating: 0,
            listDataRate: [],
            isBack: true
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        ...mapGetters('advisorInquiryProvider', ['getDataRating']),
        ...mapGetters('adminFQAProduct', ['getProviderRate'])
    },
    mounted() {
        if (this.getParamsPopup?.isAdminFQA) {
            this.$store.dispatch(
                'adminFQAProduct/getRateProvider',
                this.getParamsPopup?.provider_id
            )

            this.isBack = false
            return
        }
        if (this.getParamsPopup?.provider_id) {
            this.$store.dispatch(
                'advisorInquiryProvider/DetailRatingProvider',
                this.getParamsPopup?.provider_id
            )
        }

        if (this.getParamsPopup?.isBack === false) {
            this.isBack = false
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleBack() {
            if (this.getParamsPopup) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.AdvisorRateProvider,
                    payload: this.getParamsPopup
                })
            }
        }
    },
    watch: {
        getDataRating(newVal, oldVal) {
            if (newVal !== oldVal && isObject(newVal)) {
                this.count = newVal?.count
                this.averageRating = newVal?.averageRating
                this.listDataRate = Object.values(newVal?.ratings)
            }
        },
        getProviderRate(newVal, oldVal) {
            if (newVal !== oldVal && isObject(newVal)) {
                this.count = newVal?.count
                this.averageRating = newVal?.averageRating
                this.listDataRate = Object.values(newVal?.ratings)
            }
        }
    }
})
