
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { TypeFeeAccount } from '@/shared/enums'
import { getDataStorage } from '@/shared'
import { KeyStorage } from '@/shared/enums'

type dataProviderFeePreview = {
    form: any
}

export default Vue.extend({
    name: 'UnApproveProviderFree',
    components: {
        ButtonCustom,
        TitlePopup
    },
    data(): dataProviderFeePreview {
        return {
            form: this.$form.createForm(this, {
                name: 'provider_un_approve_fee'
            })
        }
    },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            const data: any = {
                id: getDataStorage(KeyStorage.profile).id,
                type: TypeFeeAccount.FREE
            }
            await this.$store.dispatch(
                'myPageProvider/approveProviderFee',
                data
            )

            this.closePopup()
        }
    }
})
