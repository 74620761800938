var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
        _vm.isPublicVideo(
            _vm.formatURLOfficeVideo,
            _vm.dataVideo?.office_video_status
        ) ||
        _vm.isPublicVideo(
            _vm.formatURLAdvisorVideo,
            _vm.dataVideo?.advisor_video_status
        )
    )?_c('div',{staticClass:"tcAdmin_movie_intro pc-mt-3 sp-mt-4"},[_c('h3',{staticClass:"tcAdmin_mi_ttl"},[_vm._v("動画紹介")]),_c('div',{staticClass:"tcAdmin_miInr flexCols flexJc"},[(
                _vm.isPublicVideo(
                    _vm.formatURLOfficeVideo,
                    _vm.dataVideo?.office_video_status
                )
            )?_c('div',{staticClass:"tcAdmin_mi_box"},[_c('h5',[_vm._v("事務所紹介")]),_c('div',{staticClass:"videoInfo"},[(!_vm.dataVideo.office_video_link_online)?_c('video',{attrs:{"width":"398","controls":"","controlsList":"nodownload"}},[_c('source',{attrs:{"id":"video-source","src":this.getDataVideo.office_video?.blod_link ??
                            _vm.customUrlImage(_vm.formatURLOfficeVideo)}})]):_c('IframeVideoForm',{attrs:{"type-video-onl":_vm.typeVideoOfficeOnl,"width":'398',"height":'224',"id-url":_vm.idOfficeUrl}})],1)]):_vm._e(),(
                _vm.isPublicVideo(
                    _vm.formatURLAdvisorVideo,
                    _vm.dataVideo?.advisor_video_status
                )
            )?_c('div',{staticClass:"tcAdmin_mi_box"},[_c('h5',[_vm._v("税理士紹介")]),_c('div',{staticClass:"videoInfo"},[(!_vm.dataVideo.advisor_video_link_online)?_c('video',{attrs:{"width":"398","controls":"","controlsList":"nodownload"}},[_c('source',{attrs:{"id":"video-source","src":this.getDataVideo.tax_advisor_video
                                ?.blod_link ??
                            _vm.customUrlImage(_vm.formatURLAdvisorVideo)}})]):_c('IframeVideoForm',{attrs:{"type-video-onl":_vm.typeVideoAdvisorOnl,"width":'398',"height":'224',"id-url":_vm.idAdvisorUrl}})],1)]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }