var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TitlePopup',{attrs:{"title":" "}}),(_vm.pageRegisterProvider)?_c('div',{staticClass:"notif-cus"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.checkPage && !_vm.pageRegisterProvider)?_c('div',[_vm._m(2),_c('div',{staticClass:"cmpl-notif_box"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"cmpl-notif_attention pc-mt-2"},[_c('p',[_vm._v(" ※ご利用のキャリアメールソフトによっては、みつかる税理士からのメール（info@mitsukaru-zeirishi.com)が、迷惑メールへ分類されている可能性や、受信拒否設定がされている可能性があります。 "),_c('br'),_vm._v("メールが届かない場合は、下記のページの手順にて迷惑メールフォルダおよび設定を "),_c('br',{staticClass:"spNone"}),_vm._v("ご確認ください。 "),_c('br'),_vm._v("メールが受信できない場合は"),_c('a',{staticClass:"cmpl-noti_link",on:{"click":_vm.openTayoriLink}},[_vm._v("こちら")])])])])]):_vm._e(),(!_vm.checkPage && !_vm.pageRegisterProvider)?_c('div',[_vm._m(5),_vm._m(6),_c('div',{staticClass:"btnWrap_alignC pc-mt-2-5"},[_c('ButtonCustom',{attrs:{"label":"入力画面へ","classes":"btnType2c"},on:{"onClick":_vm.closeBtn}})],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"cp_stepflow1"},[_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("規約確認")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("仮登録")]),_c('li',[_c('span',{staticClass:"bubble"}),_vm._v("お客様情報入力")]),_c('li',[_c('span',{staticClass:"bubble"}),_vm._v("ご登録完了")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmpl-notif_box"},[_c('h4',[_vm._v(" ありがとうございます。"),_c('br'),_vm._v(" 会員情報の仮登録を"),_c('br',{staticClass:"pcNone"}),_vm._v("受け付けました。"),_c('br'),_vm._v(" 本登録のご案内のメールを"),_c('br',{staticClass:"pcNone"}),_vm._v("お待ちください。 ")]),_c('div',{staticClass:"cmpl-notif_attention pc-mt-2"},[_c('p',{staticClass:"not"},[_vm._v(" つながる税理士を有効に活用していただくために、本登録のご案内のメールが届きましたら事業者情報や商材・サービス情報の入力をお願いいたします。"),_c('br'),_c('br'),_vm._v("税理士が商材やサービスの検索のさい、事業者情報の詳細が表示されていると、"),_c('span',{staticClass:"fw700 udrLine"},[_vm._v("税理士事務所からの問合せや商談の機会が多くなり事業者にとって多くのメリットがあります。")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"cp_stepflow1"},[_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("個人情報保護方針のご確認 ")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("お客様情報の入力 ")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("仮登録受付完了 ")]),_c('li',[_c('span',{staticClass:"bubble"}),_vm._v("完了")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h4',[_vm._v("仮登録が完了しました。"),_c('br'),_vm._v("メールをご確認ください。")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"cmpl-notif_msg"},[_vm._v(" ご登録のメールアドレスに本登録"),_c('br',{staticClass:"pcNone"}),_vm._v("メールを送信しました。"),_c('br'),_vm._v("メールに記載されているURLから"),_c('br',{staticClass:"pcNone"}),_vm._v("本登録を完了してください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"cp_stepflow1"},[_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("規約確認")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("仮登録完了 ")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("お客様情報入力 ")]),_c('li',{staticClass:"completed"},[_c('span',{staticClass:"bubble"}),_vm._v("ご登録完了 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmpl-notif_box"},[_c('h4',[_vm._v(" ご登録ありがとうございます。"),_c('br'),_vm._v("会員情報の登録が完了しました。"),_c('br'),_vm._v("引き続き事務所情報の入力にお進みください。 ")]),_c('div',{staticClass:"completedMark pc-mt-1-5"},[_c('span')]),_c('div',{staticClass:"cmpl-notif_attention pc-mt-2"},[_c('p',{staticClass:"not"},[_vm._v(" みつかる税理士を有効に活用していただくために、引き続き事務所情報や料金・事例等の情報の入力をお願いいたします。"),_c('br'),_c('br'),_vm._v("税理士事務所検索のさい、事務所情報の詳細が表示されていると、 "),_c('span',{staticClass:"fw700 udrLine"},[_vm._v(" 顧客からの問い合わせや新規顧客の獲得など事務所にとって多くのメリットがあります。 ")])])])])
}]

export { render, staticRenderFns }