
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { getDataStorage } from '@/shared'

export default Vue.extend({
    name: 'PreviewAnswerConsultAdvisor',
    data() {
        return {
            previewConsult: '',
            companyName: getDataStorage('profile').office_name
        }
    },
    components: { ButtonCustom },
    computed: {
        ...mapGetters('consultAdvisor', [
            'getPreviewConsult',
            'getPayloadConsult',
            'getDataConsult'
        ])
    },
    mounted() {
        if (this.getPreviewConsult) {
            this.previewConsult = this.getPreviewConsult
            return
        }
        this.$router.push({ path: RouterAdvisorUrl.consult })
    },
    methods: {
        backPreview() {
            if (this.$route.name === 'consultPreview') {
                this.$nextTick(() => {
                    this.$router.back()
                })
                return
            }
        },
        async submit() {
            await this.$store.dispatch(
                'consultAdvisor/replyConsultAdvisor',
                this.getPayloadConsult
            )
            this.$store.commit('consultAdvisor/setDataConsultAdvisor', {
                ...this.getDataConsult,
                dataPreview: false
            })
            await this.$router.push({
                path: RouterAdvisorUrl.consultDetail + this.getDataConsult.id
            })
        }
    }
})
