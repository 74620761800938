enum AdvisorConsultType {
    MAIL = 1,
    MEETING = 2,
    ESTIMATE = 3
}
enum AdvisorConsultStatus {
    NEW = 1,
    UNREAD = 2,
    READ = 3,
    ANSWERED = 4,
    CONTACTED = 5
}

export { AdvisorConsultType, AdvisorConsultStatus }
