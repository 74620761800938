import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import InvitedProviderAdminService from '../services'
import { IStateStoreInvitedProviderAdmin } from '@/modules/admin/modules/invitation/shared/@type/index'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import router from '@/router'

export const defaultStateInvitedProviderAdmin: IStateStoreInvitedProviderAdmin =
    {
        data: {},
        detailData: {}
    }

const state = () => copyDeep(defaultStateInvitedProviderAdmin)

const getters = {
    getDataInvitedProvider(state: IStateStoreInvitedProviderAdmin) {
        return state.data
    },
    getDetailDataInvitedProvider(state: IStateStoreInvitedProviderAdmin) {
        return state.detailData
    }
}

const mutations = {
    setDataInvitedProviderAdmin(
        state: IStateStoreInvitedProviderAdmin,
        payload: any
    ) {
        state.data = payload
    },
    setDetailDataInvitedProviderAdmin(
        state: IStateStoreInvitedProviderAdmin,
        payload: any
    ) {
        state.detailData = payload
    }
}

const actions = {
    async getDataInvitedProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await InvitedProviderAdminService.apiGetInvitedProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInvitedProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDetailDataInvitedProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InvitedProviderAdminService.apiGetDetailInvitedProvider(
                    payload
                )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDetailDataInvitedProviderAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            if (data.errors.error_message === '紹介が見つかりません。') {
                await router.push(RouterAdminUrl.invitationProviderAdmin)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async approveInvitedProviderAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InvitedProviderAdminService.apiApproveInvitedProvider(
                    payload
                )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDetailDataInvitedProviderAdmin', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data?.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/hidePopup', null, { root: true })
        }
    },

    async deleteInvitedProviderAdmin({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InvitedProviderAdminService.apiDeleteInvitedProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataInvitedProviderAdmin', {
                    refreshData: true
                })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/hidePopup', null, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
