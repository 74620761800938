import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify, ListNamePopup } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreInquiryAdvisorProvider } from '@/modules/advisor/modules/product-inquiry/shared/@type'
import AdvisorProviderInquiryServices from '@/modules/advisor/modules/product-inquiry/service'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import router from '@/router'

export const defaultStateMyPageAdvisor: IStateStoreInquiryAdvisorProvider = {
    data: {},
    detailInquiry: {},
    ratingProvider: {},
    dataInquiryProgress: {},
    advisorRateProvider: {},
    dataInquiryProduct: {},
    dataInquiryDraftProduct: {}
}

const state = () => copyDeep(defaultStateMyPageAdvisor)

const getters = {
    getDataDetailInquiry(state: IStateStoreInquiryAdvisorProvider) {
        return state.detailInquiry
    },
    getDataRating(state: IStateStoreInquiryAdvisorProvider) {
        return state.ratingProvider
    },
    getDataInquiryProgress(state: IStateStoreInquiryAdvisorProvider) {
        return state.dataInquiryProgress
    },
    getDataAdvisorRateProvider(state: IStateStoreInquiryAdvisorProvider) {
        return state.advisorRateProvider
    },
    getDataInquiryProduct(state: IStateStoreInquiryAdvisorProvider) {
        return state.dataInquiryProduct
    },
    getDataDetailInquiryDraft(state: IStateStoreInquiryAdvisorProvider) {
        return state.dataInquiryDraftProduct
    }
}

const mutations = {
    setDataDetailInquiry(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.detailInquiry = payload
    },
    setDataRating(state: IStateStoreInquiryAdvisorProvider, payload: any) {
        state.ratingProvider = payload
    },
    setDataInquiryProgress(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.dataInquiryProgress = payload
    },
    setDataAdvisorRateProvider(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.advisorRateProvider = payload
    },
    setDataInquiryProduct(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.dataInquiryProduct = payload
    },
    setDataDetailInquiryDraft(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.dataInquiryDraftProduct = payload
    }
}

const actions = {
    async DataDetailInquiry({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiDetailInquiryProvider(
                    payload
                )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataDetailInquiry', data.data)
                dispatch('signupAuth/countNotifyAdvisor', true, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async CreateInquiryProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiSendInquiryProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            if (
                data.errors.error_message ===
                '閲覧している商材が無効になりました。再度検索を行なってください。'
            ) {
                await router.push(RouterAdvisorUrl.myPageNoToken)
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async AnswerInquiryProvider({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiAnswerInquiryProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('DataDetailInquiry', payload?.inquiry_id)
                return true
            }
            if (
                data.errors.error_message ===
                'この問合せはすでに削除されています。'
            ) {
                this.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.ErrorProductIsDelete
                })

                return
            }

            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            await router.push(RouterAdvisorUrl.productInquiry)
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async RatingProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdvisorProviderInquiryServices.apiRatingProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })

                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return false
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DetailRatingProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiDetailRatingProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataRating', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DetailProgressProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiProgressProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryProgress', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async AdvisorRateProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiGetAdvisorRatingProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAdvisorRateProvider', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async AdvisorInquiryProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiListInquiryProduct(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryProduct', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async DataDetailInquiryDraft({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await AdvisorProviderInquiryServices.apiDetailInquiryProviderDraft(
                    payload
                )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataDetailInquiryDraft', data.data)
                dispatch('signupAuth/countNotifyAdvisor', true, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
