import { render, staticRenderFns } from "./adminAdvisor.vue?vue&type=template&id=29a112ab&scoped=true&"
import script from "./adminAdvisor.vue?vue&type=script&lang=ts&"
export * from "./adminAdvisor.vue?vue&type=script&lang=ts&"
import style0 from "./adminAdvisor.vue?vue&type=style&index=0&id=29a112ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a112ab",
  null
  
)

export default component.exports