import apiServices from '@/shared/services/api/axios'
const ConciergeService = {
    apiCreateConcierge(payload: any) {
        return apiServices.post('/create-concierge', payload)
    },
    apiGetArea() {
        return apiServices.get('/prefectures')
    }
}
export default ConciergeService
