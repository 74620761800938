
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import TitlePopup from '@/components/common_new/popup/title.vue'
import moment from 'moment/moment'
import ButtonCustomNew from '@/components/common_new/button-custom/index.vue'
import { DateFormatInput } from '@/shared/enums'
import DatePickerCustom from '@/components/common/datepicker/index.vue'

export default Vue.extend({
    name: 'ExportCSV',
    components: { ButtonCustomNew, TitlePopup, DatePickerCustom },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'dynamic_rule' }),
            minDate: '',
            maxDate: '',
            infoDataExport: {
                time_start: '',
                time_end: ''
            },
            isProvider: false
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        isDisabledButtonCSV(): boolean {
            return !(
                this.infoDataExport?.time_start && this.infoDataExport?.time_end
            )
        }
    },
    mounted() {
        this.isProvider = this.getParamsPopup?.isProvider
    },
    methods: {
        onDateStartChange(evt: any) {
            this.minDate = evt
            this.infoDataExport.time_start = evt
                ? moment(evt).format(DateFormatInput.search)
                : ''
        },
        onDateEndChange(evt: any) {
            this.maxDate = evt
            this.infoDataExport.time_end = evt
                ? moment(evt).format(DateFormatInput.search)
                : ''
        },
        minChooseDate(current: any) {
            if (!this.maxDate) {
                return false
            }
            if (current && moment(this.maxDate).isSame(current, 'days')) {
                return false
            }
            return current && current >= moment(this.maxDate)
        },
        maxChooseDate(current: any) {
            if (!this.minDate) {
                return false
            }
            return (
                current &&
                moment(current.format('YYYY/MM/DD')) < moment(this.minDate)
            )
        },
        async handleExportCSV() {
            let dispatch = 'myPageAdmin/apiGetAccessInformationCSV'
            if (this.isProvider) {
                dispatch = 'myPageAdmin/apiGetProviderAccessInformationCSV'
            }

            const res = await this.$store.dispatch(
                dispatch,
                this.infoDataExport
            )
            if (res) {
                await this.$store.dispatch('popup/hidePopup')
            }
        }
    }
})
