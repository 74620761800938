
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { customUrlImage, isArray } from '@/shared/function'
import { mapGetters } from 'vuex'
import { i18n } from '@/shared/i18n'
import { CheckboxServiceContent } from '@/modules/provider/modules/signup/shared/constants'
import {
    CheckboxProductGenre,
    CheckboxProductFeature
} from '@/modules/provider/modules/signup/shared/constants'
import { generateValueCheckbox } from '@/modules/provider/modules/signup/shared/functions'
import { renderGenreAndFeature } from '@/modules/provider/shared/function'

export default Vue.extend({
    name: 'OfficeInformationPreviewProvider',
    data(): any {
        return {
            customUrlImage,
            CheckboxProductFeature,
            CheckboxProductGenre,
            officeInfo: {} as any,
            CheckboxServiceContent
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getBaseOffice',
            'getDataPreviewProvider',
            'getBaseDataOffice'
        ]),
        ...mapGetters('providerAdmin', ['getDataOfficeInfoProviderAdmin'])
    },
    mounted() {
        if (this.getDataPreviewProvider.isAdmin) {
            this.officeInfo =
                this.getDataPreviewProvider?.officeInfo ??
                this.getDataOfficeInfoProviderAdmin
            if (this.officeInfo?.content_name?.length === 0) {
                for (const key in this.CheckboxServiceContent) {
                    this.listContent.push(this.CheckboxServiceContent[key])
                }
            }

            return
        }
        this.officeInfo =
            this.getDataPreviewProvider?.officeInfo ?? this.getBaseOffice

        if (
            this.officeInfo?.service_domain ===
            i18n.t('i18n_select_all_checkbox_service_office_advisor')
        ) {
            this.officeInfo.service_domain = Object.values(
                this.getBaseDataOffice?.service_domain
            ).join('　')
        }
        if (
            this.officeInfo?.service_job ===
            i18n.t('i18n_select_all_checkbox_service_office_advisor')
        ) {
            this.officeInfo.service_job = Object.values(
                this.getBaseDataOffice.service_job
            ).join('　')
        }
        if (
            this.officeInfo?.content_name ??
            this.officeInfo?.content ===
                i18n.t('i18n_select_all_checkbox_service_office_advisor')
        ) {
            for (const key in this.CheckboxServiceContent) {
                this.listContent.push(this.CheckboxServiceContent[key])
            }
        }
    },
    watch: {
        getBaseOffice(newVal) {
            if (this.getDataPreviewProvider.isAdmin) {
                return
            }
            this.officeInfo = this.getDataPreviewProvider.officeInfo ?? newVal
        },
        getDataOfficeInfoProviderAdmin(newVal) {
            if (this.getDataPreviewProvider.isAdmin) {
                this.officeInfo =
                    this.getDataPreviewProvider.officeInfo ?? newVal
            }
        }
    },
    methods: {
        isArray,
        generateValueCheckbox,
        renderGenreAndFeature,
        formatArray(data: any) {
            if (Array.isArray(data)) {
                return data.join('　')
            }
            return data
        },
        officeDomainContent(domain: any, content: any) {
            let nameContentAndDomain: string[] = []
            const dataContent = content?.split('|').map(Number)
            domain?.split('|').forEach((d: number) => {
                nameContentAndDomain = [
                    ...nameContentAndDomain,
                    ...this.renderGenreAndFeature(d, dataContent)
                ]
            })

            return nameContentAndDomain.join('　')
        }
    }
})
