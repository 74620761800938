
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import { objectHasKey } from '@/shared/function'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'DeleteAdmin',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    components: { ButtonCustom, Title },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        onDeleteAdmin() {
            if (objectHasKey(this.getParamsPopup, 'id')) {
                this.$store.dispatch(
                    'managementAdmin/handleDeleteAdmin',
                    this.getParamsPopup
                )
            }
        }
    }
})
