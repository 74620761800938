import { copyDeep } from '@/shared'
import { HttpStatusCode, AreaName, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreProviderSearch } from '@/modules/provider/modules/search-provider/shared/@types'
import ProviderSearchService from '../services'
import { objectHasKey } from '@/shared/function'
import router from '@/router'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'

export const defaultStateProductProvider: IStateStoreProviderSearch = {
    data: {},
    dataArea: [],
    dataAllArea: [],
    dataProduct: {},
    dataProductViewed: {},
    dataSavingProduct: {},
    dataInfoProvider: {},
    dataProductsProvider: [],
    dataListProductSelected: [],
    dataListProductFavorite: []
}

const state = () => copyDeep(defaultStateProductProvider)

const getters = {
    getDataProductProvider(state: IStateStoreProviderSearch) {
        return state.data
    },
    getDataArea(state: IStateStoreProviderSearch) {
        return state.dataArea
    },
    getDataAllArea(state: IStateStoreProviderSearch) {
        return state.dataAllArea
    },
    getDataProduct(state: IStateStoreProviderSearch) {
        return state.dataProduct
    },
    getDataProductViewed(state: IStateStoreProviderSearch) {
        return state.dataProductViewed
    },
    getDataSavingProduct(state: IStateStoreProviderSearch) {
        return state.dataSavingProduct
    },

    getDataInfoProvider(state: IStateStoreProviderSearch) {
        return state.dataInfoProvider
    },
    getDataProductsProvider(state: IStateStoreProviderSearch) {
        return state.dataProductsProvider
    },
    getDataProductSave(state: IStateStoreProviderSearch) {
        return state.dataListProductFavorite
    },
    getDataListProductSelected(state: IStateStoreProviderSearch) {
        return state.dataListProductSelected
    }
}

const mutations = {
    setDataProductProvider(state: IStateStoreProviderSearch, payload: any) {
        state.data = payload
    },
    setDataArea(state: IStateStoreProviderSearch, payload: any) {
        state.dataArea = payload
    },
    setDataAllArea(state: IStateStoreProviderSearch, payload: any) {
        state.dataAllArea = payload
    },
    setDataProduct(state: IStateStoreProviderSearch, payload: any) {
        state.dataProduct = payload
    },
    setDataProductViewed(state: IStateStoreProviderSearch, payload: any) {
        state.dataProductViewed = payload
    },
    setDataSavingProduct(state: IStateStoreProviderSearch, payload: any) {
        state.dataSavingProduct = payload
    },

    setDataInfoProvider(state: IStateStoreProviderSearch, payload: any) {
        state.dataInfoProvider = payload
    },
    setDataProductsProvider(state: IStateStoreProviderSearch, payload: any) {
        state.dataProductsProvider = payload
    },
    setDataProductSave(state: IStateStoreProviderSearch, payload: any) {
        state.dataListProductFavorite = payload
    },
    setDataListProductSelected(state: IStateStoreProviderSearch, payload: any) {
        state.dataListProductSelected = payload
    }
}

const actions = {
    async getListDataProductProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderSearchService.apiGetListProducts(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductProvider', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAllArea({ commit }: any) {
        try {
            const res = await ProviderSearchService.apiGetAllArea()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAllArea', data.data)
                return
            }
        } catch (e) {}
    },
    async getSearchProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderSearchService.apiSearchProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProduct', data.data)
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataProductViewed({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiGetListProductViewed(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductViewed', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataSavingProduct({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiSavingProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getSearchProduct', payload?.search)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async savingProduct({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiSavingProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteSavingProduct({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiDeleteSavingProduct(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getSearchProduct', payload?.search)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAreaProvince({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderSearchService.apiGetAreaProvince(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                const listArea2 = data.data.reduce(
                    (preVal: any[], nextVal: any) => {
                        if (
                            objectHasKey(nextVal, 'area_name') &&
                            nextVal.area_name === AreaName.Kanto
                        ) {
                            return nextVal.childrens.map((item: any) => {
                                item.label = item.area_name
                                item.value = item.id
                                return item
                            })
                        }
                        return preVal
                    },
                    []
                )
                commit('setDataArea', listArea2)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getInfoProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiGetInfoProvider(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInfoProvider', data.data)
            }
            if (status === HttpStatusCode.ok) {
                commit('setDataInfoProvider', data.data)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            router.push(RouterProviderUrl.myPageNoToken)

            return 'back'
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getProductsProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderSearchService.apiGetProductsProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductsProvider', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getProductSaveProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderSearchService.apiGetProductSave(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductSave', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteProductSave({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProviderSearchService.apiDeleteProductSave(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('popup/hidePopup', null, { root: true })
                dispatch('getProductSaveProvider')
                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
