
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import PopupCommon from '@/components/common_new/popup/index.vue'
import LayoutHeader from '@/components/layouts/header/index.vue'
import LayoutNavBar from '@/components/layouts/navbar/index.vue'
import Loading from '@/components/common_new/loading/index.vue'
import LayoutFooterMain from '@/components/layouts/footer-main/index.vue'
import PageTitle from '@/components/layouts/page-title/index.vue'
import { mapGetters } from 'vuex'
import { UserTypeRole } from '@/shared/enums'
import { filterDomain, isObject } from '@/shared/function'
import { BodyClassRole } from '@/modules/advisor/shared/contants'
import Vue from 'vue'
import AutoLogout from '@/modules/auth/components/autoLogout/autoLogout.vue'
import PreviewInfoAdvisor from '@/views/advisor/signup/preview-info/index.vue'
import PreviewInfoProvider from '@/views/provider/signup/preview-info/index.vue'

export default Vue.extend({
  name: 'LayoutProductDetail',
  data(): any {
      return {
          classParent: ''
      }
  },
  components: {
      PreviewInfoAdvisor,
      PreviewInfoProvider,
      AutoLogout,
      LayoutNavBar,
      LayoutHeader,
      PopupCommon,
      LayoutFooterMain,
      PageTitle
  },
  computed: {
      ...mapGetters('popup', [
          'getIsLoading',
          'getIsOpenPopup',
          'getIsNotPageLoading'
      ]),
      ...mapGetters('signupAdvisor', ['getDataPreviewAdvisor']),
      ...mapGetters('signupProvider', ['getDataPreviewProvider']),
      isShowMain(): boolean {
          return !this.isPreviewAdvisor && !this.isPreviewProvider
      },
      isPreviewAdvisor(): boolean {
          return isObject(this.getDataPreviewAdvisor)
      },
      isPreviewProvider(): boolean {
          return isObject(this.getDataPreviewProvider)
      }
  },
  mounted() {
      const typeRouter = filterDomain()
      if (!typeRouter) {
          return
      }
      if (typeRouter.role === UserTypeRole.provider) {
          document.documentElement.style.setProperty(
              '--main-color',
              '#186BB9'
          )
          document.documentElement.style.setProperty(
              '--color-hover',
              '#044887'
          )
      }
      if (
          typeRouter.role === UserTypeRole.advisor ||
          typeRouter.role === UserTypeRole.provider
      ) {
          this.classParent = BodyClassRole.tax_advisor
          return
      }
      this.classParent = BodyClassRole.admin
  },
  watch: {
      $route: {
          handler(newVal, oldVal) {
              if (newVal.name !== oldVal.name && this.isPreviewAdvisor) {
                  this.$store.commit(
                      'signupAdvisor/setDataPreviewAdvisor',
                      {}
                  )
              }
          }
      }
  }
})
