import apiServices from '@/shared/services/api/axios'
import {
    IPayloadId,
    ITypeCreateIncomingRequest,
    ITypeIdLoadView
} from '../../../shared/@types'

const GuestService = {
    apiGetInfoTax(id: IPayloadId) {
        return apiServices.get(`/get-info-tax/${id}`)
    },
    apiCreateIncomingRequest(payload: ITypeCreateIncomingRequest) {
        return apiServices.post('/create-incoming-request', payload)
    },
    apiLoadViewOffice(payload: ITypeIdLoadView) {
        return apiServices.post('/load-view-office', payload)
    },
    apiSearchInterviewAdvisor(id: number) {
        return apiServices.get(`/get-interview-tax/${id}`)
    }
}
export default GuestService
