import { IStateCountAccess } from '@/modules/admin/modules/countAccess/shared/@types'
import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import CountAccessService from '../services'

export const defaultStateCountAccess: IStateCountAccess = {
    data: {}
}

const state = () => copyDeep(defaultStateCountAccess)

const getters = {
    getDataCountAccess(state: IStateCountAccess) {
        return state.data
    }
}

const mutations = {
    setDataCountAccess(state: IStateCountAccess, payload: any) {
        state.data = payload
    },
    setClearDataStore(state: IStateCountAccess) {
        Object.assign(state, copyDeep(defaultStateCountAccess))
    }
}

const actions = {
    async getListCountAccess({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await CountAccessService.apiGetListCountAccess(payload)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataCountAccess', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    changeLoading({ commit }: any, payload: any) {
        commit('setLoading', payload)
    },
    resetParams({ commit }: any) {
        commit('setClearDataStore')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
