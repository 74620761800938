import { copyDeep } from '@/shared'
import { HttpStatusCode } from '@/shared/enums'
import { IStateStoreMyPage } from '../shared/contants/IConstants'
import MyPageAdvisorServices from '../services'

export const defaultStateMyPageAdvisor: IStateStoreMyPage = {
    data: {}
}

const state = () => copyDeep(defaultStateMyPageAdvisor)

const getters = {
    getDataMyPageAdvisor(state: IStateStoreMyPage) {
        return state.data
    }
}

const mutations = {
    setDataMyPageAdvisor(state: IStateStoreMyPage, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getListDataMyPageAdvisor({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await MyPageAdvisorServices.apiGetAdvisorMyPage()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataMyPageAdvisor', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
