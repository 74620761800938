
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent } from 'vue'
import { TypeInputCustom } from '@/shared/enums'
import { regex } from '@/shared/regex'
import Editor from '@tinymce/tinymce-vue'
import { getRawHTML } from '@/shared/function'

export default defineComponent({
    name: 'InputCustom',
    components: { Editor },
    props: {
        label: {
            type: String,
            required: true
        },
        classLabel: {
            type: String
        },
        descriptionLabel: {
            type: String
        },
        classInput: {
            type: String
        },
        classFormItem: {
            type: String
        },
        funcCustom: {
            type: Function
        },
        onKeyPressFunc: {
            type: Function
        },
        onKeyChangeFunc: {
            type: Function
        },
        nameInput: {
            type: String,
            required: true
        },
        required: Boolean,
        notRequired: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true
        },
        typeInputCustom: {
            type: String,
            default: TypeInputCustom.text
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        rows: {
            type: Number,
            default() {
                return 4
            }
        },
        inputProgClass: {
            type: String,
            default: 'inputProg1'
        },
        readOnly: Boolean,
        maxCount: Number,
        numberCharactersPr: Number,
        isShowCount: Boolean,
        hideShowCount: Boolean,
        description: {
            type: String
        },
        isShowRichText: {
            type: Boolean,
            default() {
                return false
            }
        },
        defaultRichText: {
            type: String,
            default() {
                return ''
            }
        },
        configEditor: {
            type: Object
        },
        classDescription: String,
        isShowDescription: Boolean,
        default() {
            return true
        }
    },
    data() {
        return {
            ListTypeInput: TypeInputCustom,
            numberCharacters: 0,
            resetPlaceHolderEditor: true
        }
    },
    computed: {
        defaultRichTextEditor(): any {
            return getRawHTML(this.defaultRichText).replaceAll('\n', '<br>')
        }
    },
    methods: {
        onKeyUpDebounce(evt: Event) {
            if (this.funcCustom) {
                this.funcCustom(evt, this.formInstance, this.nameInput)
                return
            }
            const eventTarget = evt.target as HTMLInputElement
            this.numberCharacters = this.removeBreakLine(
                eventTarget.value
            ).length
        },
        onKeyPress(event: HTMLElement) {
            if (this.onKeyPressFunc) {
                this.onKeyPressFunc(event, this.formInstance, this.nameInput)
            }
        },
        onKeyChange(event: Event) {
            if (this.onKeyChangeFunc) {
                this.onKeyChangeFunc(event)
            }
            const eventTarget = event.target as HTMLFormElement
            if (this.typeInputCustom === this.ListTypeInput.count) {
                this.numberCharacters = eventTarget.length
            }
        },
        keyupTinyMCE(e: any) {
            let value = e.currentTarget.innerText
            // remove \n value input
            if (value === '\n') {
                value = ''
            }
            const evt = {
                target: {
                    name: this.nameInput,
                    value: value
                }
            }

            if (this.funcCustom) {
                this.funcCustom(evt, this.formInstance, this.nameInput)
                return
            }
            let valueEditor = {
                text: this.removeBreakLine(value),
                rawHTML: e.currentTarget.innerHTML
            }
            if (!value.length) {
                valueEditor.rawHTML = ''
            }
            this.formInstance.setFieldsValue({
                [this.nameInput]: JSON.stringify(valueEditor)
            })
            this.numberCharacters = valueEditor.text.length
            this.formInstance.validateFields([this.nameInput])
        },
        changeTinyMCE(e: any) {
            let tmp = document.createElement('DIV')
            tmp.innerHTML = e.level.content.replaceAll('<br>', '\n')
            let value = tmp.innerText
            // remove \n value input
            if (value === '\n') {
                value = ''
            }

            const evt = {
                target: {
                    name: this.nameInput,
                    value: value
                }
            }

            if (this.funcCustom) {
                this.funcCustom(evt, this.formInstance, this.nameInput)
                return
            }
            let valueEditor = {
                text: this.removeBreakLine(value.trim()),
                rawHTML: e.level.content
            }
            if (!value.length) {
                valueEditor.rawHTML = ''
            }
            this.formInstance.setFieldsValue({
                [this.nameInput]: JSON.stringify(valueEditor)
            })
            this.numberCharacters = this.removeBreakLine(value).length
            this.formInstance.validateFields([this.nameInput])
        },
        getInnerText(stringReplace: string) {
            if (this.isShowRichText && this.defaultRichText) {
                let tmp = document.createElement('DIV')
                tmp.innerHTML = stringReplace.replaceAll('<br>', '\n')
                this.numberCharacters = this.removeBreakLine(
                    tmp.innerText
                ).length
                let valueEditor = {
                    text: tmp.innerText,
                    rawHTML: this.defaultRichText
                }
                this.formInstance.setFieldsValue({
                    [this.nameInput]: JSON.stringify(valueEditor)
                })
            }
        },
        removeBreakLine(stringInput: string) {
            return stringInput.replaceAll('\n', '')
        },
        reloadTextArea() {
            if (this.isShowRichText) {
                this.resetPlaceHolderEditor = false
                this.$nextTick(() => {
                    this.resetPlaceHolderEditor = true
                })
            }
        }
    },
    mounted() {
        if (this.numberCharactersPr) {
            this.numberCharacters = this.numberCharactersPr
        }
    },
    watch: {
        numberCharactersPr(newVal) {
            this.numberCharacters = newVal ? newVal : 0
            this.getInnerText(this.defaultRichText)
        },
        placeholder() {
            this.reloadTextArea()
        },
        disabled() {
            this.reloadTextArea()
        },
        defaultRichText() {
            this.reloadTextArea()
        }
    }
})
