// initial state
// shape: [{ id, quantity }]
import { copyDeep, getDataStorage, setDataStorage } from '@/shared'
import { IPayloadPopup } from '@/shared/@types/popup-types'
import {
    IFormSampleFees,
    IPayloadDeleteSampleFees,
    IStateStoreAdvisor,
    ITypeFormDataTaxAdvisorRegist,
    ITypeTaxAdvisorUpdate
} from '@/modules/advisor/shared/@types'
import SignupAdvisorService from '@/modules/advisor/modules/signup/services'
import {
    HttpStatusCode,
    RecieverFlg,
    KeyStorage,
    TypeNotify,
    UserTypeRole
} from '@/shared/enums'
import {
    NotificationCustom,
    routeGoBackToListAdvisor,
    updateDataProfile
} from '@/shared/function'
import { IPayloadInterview } from '@/modules/advisor/modules/signup/shared/@types'

export const defaultStateAdvisor: IStateStoreAdvisor = {
    data: {},
    dataListMail: {},
    dataListMailReciever: {},
    roleEditAdvisor: 0,
    updateBtnLayoutRegister: false,
    baseDataOffice: {},
    dataServiceContent: {
        characteristic: [],
        service_software: [],
        special_domain: [],
        special_job: [],
        characteristic_name: [],
        service_software_name: [],
        special_domain_name: [],
        special_job_name: []
    },
    dataAreaProvince: [],
    dataAreaCity: [],
    officeInfo: {},
    listDataAffiate: [],
    listDataTaxGroup: [],
    area: {
        id: [],
        name: []
    },
    promotionVideo: [],
    dataVideo: {},
    dataResultsArea: {
        type1: [],
        type2: [],
        type3: [],
        type4: []
    },
    dataResultsAreaChild: {
        parents: {},
        data: []
    },
    baseQualification: [],
    baseDomainSampleFee: [],
    dataQualification: {
        edu_qualification: [],
        edu_qualification_name: []
    },
    dataSampleFeesAdvisor: {},
    dataInterviewAdvisor: {},
    dataPreview: {}
}

const state = () => copyDeep(defaultStateAdvisor)

const getters = {
    getDataResultsArea(state: IStateStoreAdvisor) {
        return state.dataResultsArea
    },
    getDataResultsAreaChild(state: IStateStoreAdvisor) {
        return state.dataResultsAreaChild
    },
    getUpdateBtnLayoutRegister(state: IStateStoreAdvisor) {
        return state.updateBtnLayoutRegister
    },
    getDataAdvisor(state: IStateStoreAdvisor) {
        return state.data
    },
    getBaseDataOffice(state: IStateStoreAdvisor) {
        return state.baseDataOffice
    },
    getDataServicecontent(state: IStateStoreAdvisor) {
        return state.dataServiceContent
    },
    getDataAreaProvince(state: IStateStoreAdvisor) {
        return state.dataAreaProvince
    },
    getDataAreaCity(state: IStateStoreAdvisor) {
        return state.dataAreaCity
    },
    getBaseOffice(state: IStateStoreAdvisor) {
        return state.officeInfo
    },
    getDataAffiliateAdvisor(state: IStateStoreAdvisor) {
        return state.listDataAffiate
    },
    getDataListTaxGroup(state: IStateStoreAdvisor) {
        return state.listDataTaxGroup
    },
    getArea(state: IStateStoreAdvisor) {
        return state.area
    },
    getPromotionVideos(state: IStateStoreAdvisor) {
        return state.promotionVideo
    },
    getDataVideo(state: IStateStoreAdvisor) {
        return state.dataVideo
    },
    getDataListMail(state: IStateStoreAdvisor) {
        return state.dataListMail
    },
    getDataListMailReciever(state: IStateStoreAdvisor) {
        return state.dataListMailReciever
    },
    getRoleEditAdvisor(state: IStateStoreAdvisor) {
        return state.roleEditAdvisor
    },
    getDataQualification(state: IStateStoreAdvisor) {
        return state.dataQualification
    },
    getBaseQualification(state: IStateStoreAdvisor) {
        return state.baseQualification
    },
    getBaseDomainSampleFee(state: IStateStoreAdvisor) {
        return state.baseDomainSampleFee
    },
    getDataSampleFeesAdvisor(state: IStateStoreAdvisor) {
        return state.dataSampleFeesAdvisor
    },
    getDataInterviewAdvisor(state: IStateStoreAdvisor) {
        return state.dataInterviewAdvisor
    },
    getDataPreviewAdvisor(state: IStateStoreAdvisor) {
        return state.dataPreview
    }
}

const mutations = {
    setDataResultsArea(state: IStateStoreAdvisor, payload: any) {
        Object.assign(state.dataResultsArea, payload)
    },
    setDataResultsAreaType2(
        state: IStateStoreAdvisor,
        payload: { id: string | number; payload: boolean }
    ) {
        state.dataResultsArea = {
            ...state.dataResultsArea,
            type2: state.dataResultsArea.type2.map((e: any) => {
                if (String(e.id) === String(payload.id))
                    e.isAll = payload.payload
                return e
            })
        }
    },
    setDataResultsAreaChild(state: IStateStoreAdvisor, payload: any) {
        state.dataResultsAreaChild = payload
    },
    setUpdateBtnLayoutRegister(state: IStateStoreAdvisor, payload: boolean) {
        state.updateBtnLayoutRegister = payload
    },
    setDataAdvisor(state: IStateStoreAdvisor, payload: any) {
        state.data = payload
    },
    setDataListMail(state: IStateStoreAdvisor, payload: any) {
        state.dataListMail = payload
    },
    setDataListMailReciever(state: IStateStoreAdvisor, payload: any) {
        state.dataListMailReciever = payload
    },
    setRoleEditAdvisor(state: IStateStoreAdvisor, payload: number) {
        state.roleEditAdvisor = payload
    },
    baseDataOffice(state: IStateStoreAdvisor, payload: any) {
        state.baseDataOffice = payload
    },
    updateDataServiceContent(state: IStateStoreAdvisor, payload: any) {
        state.dataServiceContent = payload
    },
    updateDataAreaProvince(state: IStateStoreAdvisor, payload: any) {
        state.dataAreaProvince = payload
    },
    updateDataAreaCity(state: IStateStoreAdvisor, payload: any) {
        state.dataAreaCity = payload
    },
    officeInfo(state: IStateStoreAdvisor, payload: any) {
        state.officeInfo = payload
    },
    setDataAffiliateAdvisor(state: IStateStoreAdvisor, payload: any) {
        state.listDataAffiate = payload
    },
    setDataListTaxGroup(state: IStateStoreAdvisor, payload: any) {
        state.listDataTaxGroup = payload
    },
    updateArea(state: IStateStoreAdvisor, payload: any) {
        state.area = payload
    },
    setPromotionVideo(state: IStateStoreAdvisor, payload: any) {
        state.promotionVideo = payload
    },
    setDataVideo(state: IStateStoreAdvisor, payload: any) {
        state.dataVideo = { ...state.dataVideo, ...payload }
    },
    setDataQualification(state: IStateStoreAdvisor, payload: any) {
        state.dataQualification = payload
    },
    setBaseQualification(state: IStateStoreAdvisor, payload: any) {
        state.baseQualification = payload
    },
    setDomainSampleFee(state: IStateStoreAdvisor, payload: any) {
        state.baseDomainSampleFee = payload
    },
    setDataSampleFeesAdvisor(state: IStateStoreAdvisor, payload: any) {
        state.dataSampleFeesAdvisor = payload
    },
    setDataInterviewAdvisor(state: IStateStoreAdvisor, payload: any) {
        state.dataInterviewAdvisor = payload
    },
    setDataPreviewAdvisor(state: IStateStoreAdvisor, payload: any) {
        state.dataPreview = payload
    }
}

const actions = {
    updateDataResultsArea({ commit }: any, payload: IPayloadPopup) {
        commit('setDataResultsArea', payload)
    },
    updateType2(
        { commit }: any,
        payload: { id: string | number; payload: boolean }
    ) {
        commit('setDataResultsAreaType2', payload)
    },
    updateDataResultsAreaChild({ commit }: any, payload: IPayloadPopup) {
        commit('setDataResultsAreaChild', payload)
    },
    openPopup({ commit }: any, payload: IPayloadPopup) {
        commit('openPopup', payload)
    },
    hidePopup({ commit }: any) {
        commit('hidePopup')
    },
    async getDataSampleFees({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await SignupAdvisorService.apiSampleFees()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataSampleFeesAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createSampleFees(
        { commit, dispatch }: any,
        payload: IFormSampleFees[]
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await SignupAdvisorService.apiCreateSampleFees(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataSampleFees')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    async deleteSampleFees(
        { commit, dispatch }: any,
        payload: IPayloadDeleteSampleFees
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await SignupAdvisorService.apiDeleteSampleFees(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                dispatch('getDataSampleFees')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getServiceContent({ commit }: any) {
        const res: any = await SignupAdvisorService.apiGetServiceContent()
        commit('baseDataOffice', res.data.data)
    },
    changeDataServiceContent({ commit }: any, payload: any) {
        commit('updateDataServiceContent', payload)
    },
    async getDataAreaProvince({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiGetAreaProvince()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('updateDataAreaProvince', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAreaCity({ commit }: any, params: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiGetAreaCity(params)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('updateDataAreaCity', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getOfficeInfo({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiGetOfficeInfo()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                const dataObj = data.data

                commit('officeInfo', dataObj)

                commit('updateArea', {
                    id: dataObj?.area_id || [],
                    name: dataObj?.area_name || []
                })

                commit('updateDataServiceContent', {
                    characteristic: dataObj?.characteristic || [],
                    service_software: dataObj?.service_software || [],
                    special_domain: dataObj?.special_domain || [],
                    special_job: dataObj?.special_job || [],
                    service_job: dataObj?.service_job || [],
                    service_domain: dataObj?.service_domain || [],
                    characteristic_name: dataObj?.characteristic_name || [],
                    service_software_name: dataObj?.service_software_name || [],
                    special_domain_name: dataObj?.special_domain_name || [],
                    special_job_name: dataObj?.special_job_name || [],
                    service_job_name: dataObj?.service_job_name || [],
                    service_domain_name: dataObj?.service_domain_name || []
                })

                commit('setDataQualification', {
                    edu_qualification_name:
                        dataObj?.edu_qualification_name || [],
                    edu_qualification: dataObj?.edu_qualification || []
                })
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async registerOfficeInfo({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiRegisterOfficeInfo(
                payload
            )

            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getOfficeInfo')

                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },

    async updateOfficeInfo({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiUpdateOfficeInfo(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getOfficeInfo')
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    async getTaxAccInfo({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await SignupAdvisorService.apiGetTaxAccountInfo()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAffiliateAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListTaxGroup({ commit }: any) {
        try {
            const resList: any = await SignupAdvisorService.apiListTaxGroup()
            const { status, data } = resList
            if (status === HttpStatusCode.ok) {
                commit('setDataListTaxGroup', data.data)
                return
            }
        } catch (error) {}
    },
    async createTaxAdvisorRegistration(
        { commit, dispatch }: any,
        payload: ITypeFormDataTaxAdvisorRegist
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any =
                await SignupAdvisorService.apiTaxAdvisorRegistration(payload)
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                dispatch('getTaxAccInfo')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error: any) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async updateAffiliateAdvisor(
        { commit, dispatch }: any,
        payload: ITypeTaxAdvisorUpdate
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await SignupAdvisorService.apiTaxAdvisorUpdate(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getTaxAccInfo')
                dispatch('getRoleEditAdvisor')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    async getBasicInfo({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })

            const res: any = await SignupAdvisorService.apiGetBasicInfo()
            const { data, status }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAdvisor', data.data)
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async registerBasicInfo({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiRegisterBasicInfo(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    async getPromotionVideoInfo({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiGetPromotionVideo()
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionVideo', res.data.data)

                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updatePromotionVideoInfo({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiUploadPromotionVideo(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionVideo', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getPromotionVideoInfo')
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    changeArea({ commit }: any, payload: any) {
        commit('updateArea', payload)
    },
    changeDataVideo({ commit }: any, payload: any) {
        commit('setDataVideo', payload)
    },
    async apiUpdateSignature({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiUpdateSignature(
                payload
            )
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                updateDataProfile({
                    key: 'signature_content',
                    value: data.data.signature_content
                })
                commit('popup/hidePopup', null, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async createInterview({ commit }: any, payload: IPayloadInterview) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiInterview(payload)
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewAdvisor', {})
        }
    },
    async getInterview({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiGetInterview()
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                // commit('setDataAdvisor', data.data)
                commit('setDataInterviewAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListMail({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            const res: any = await SignupAdvisorService.apiGetListEmail(payload)
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                commit('setDataListMail', data.data)
                const newData: any = []
                Object.values(data.data).map((e: any) =>
                    e.map((e1: any) => newData.push(e1))
                )

                commit('setDataListMailReciever', newData)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },
    async getQualification({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiGetQualification()
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                commit('setBaseQualification', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDomainSampleFee({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiGetDomainSampleFee()
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                commit('setDomainSampleFee', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    updateDataQualification({ commit }: any, payload: any) {
        commit('setDataQualification', payload)
    },
    async updateListEmail({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            const res: any = await SignupAdvisorService.apiUpdateListMail(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getListMail')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },
    async editAddMailAdvisor({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            const res: any = await SignupAdvisorService.apiRegisterEmail(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                const dataPayload = {
                    isAdvisor: true,
                    recieverFlg: RecieverFlg.TRUE
                }
                dispatch('getMailReceivers', dataPayload)
                return 'success'
            }
            if (status === HttpStatusCode.bad_request) {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message.email[0]
                })
            }
            if (status === HttpStatusCode.error_server) {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },
    async changeIntroduction({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupAdvisorService.apiChangeIntroduction(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                dispatch(
                    'advisorAdmin/getDataAdvisorBasicInfo',
                    { id: payload.advisor_id },
                    { root: true }
                )
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            routeGoBackToListAdvisor()
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getMailReceivers({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res: any = payload.isAdvisor
                ? await SignupAdvisorService.apiGetMailReceivers(
                      payload.recieverFlg
                  )
                : await SignupAdvisorService.apiGetListMailAdminAdvisor(
                      payload.id
                  )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListMailReciever', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getRoleEditAdvisor({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            const res: any =
                await SignupAdvisorService.apiGetRoleEditFormAdvisor()
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setRoleEditAdvisor', data.data.edit_permission)
                data.data.user_type = UserTypeRole.advisor
                setDataStorage(KeyStorage.profile, JSON.stringify(data.data))
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
