enum HttpStatusCode {
    ok = 200,
    created = 201,
    bad_request = 400,
    expired_token = 401,
    forbidden = 403,
    not_found = 404,
    method_not_allowed = 405,
    not_accept = 406,
    conflict_session = 409,
    error_server = 500
}

enum KeyStorage {
    profile = 'profile',
    assetToken = 'assetToken',
    typeScreen = 'typeScreen',
    role = 'role',
    rememberEmail = 'rememberEmail',
    refreshToken = 'refreshToken',
    productAdvisorRecentlyViewed = 'productAdvisorRecentlyViewed',
    providerAdvisorRecentlyViewed = 'providerAdvisorRecentlyViewed',
    productRecentlyViewed = 'productRecentlyViewed',
    productSelect = 'productSelect',
    productFQARecentlyViewed = 'productFQARecentlyViewed'
}

enum TypeInputCustom {
    checkbox = 'checkbox',
    date = 'date',
    image = 'image',
    text = 'text',
    radio = 'radio',
    select = 'select',
    textarea = 'textarea',
    numberphone = 'numberphone',
    number = 'number',
    password = 'password',
    postcode = 'postcode',
    count = 'count'
}

enum TypeDatePicker {
    time = 'time',
    date = 'date',
    month = 'month',
    year = 'year'
}

enum ListNamePopup {
    UserForm = 'UserForm',
    UserDetails = 'UserDetails',
    ChangePassword = 'ChangePassword',
    ChangeEmail = 'ChangeEmail',
    Withdrawal = 'Withdrawal',
    TaxAccounting = 'TaxAccounting',
    ServicesSelectPopup = 'ServicesSelectPopup',
    AreaPopup = 'AreaPopup',
    AreaCityPopup = 'AreaCityPopup',
    StopAdvisorPopup = 'StopAdvisorPopup',
    ZoomImage = 'ZoomImage',
    DeleteAdmin = 'DeleteAdmin',
    AdminRegistration = 'AdminRegistration',
    AdminUpdate = 'AdminUpdate',
    ConfirmDeactive = 'ConfirmDeactive',
    CreateSignature = 'CreateSignature',
    ConfirmDeleteInquiryAdvisor = 'ConfirmDeleteInquiryAdvisor',
    PopupFormGuest = 'PopupFormGuest',
    ConfirmCreateInquiryGuest = 'ConfirmCreateInquiryGuest',
    Registration = 'Registration',
    MailListAdvisor = 'MailListAdvisor',
    AddMailAdvisor = 'AddMailAdvisor',
    QualificationSelectPopup = 'QualificationSelectPopup',
    LoginSupport = 'LoginSupport',
    ConfirmUpdateStatusConcierge = 'ConfirmUpdateStatusConcierge',
    ManagementCompanyPopup = 'ManagementCompanyPopup',
    Signature = 'Signature',
    DeactiveAccount = 'DeactiveAccount',
    ConfirmSubmitAnnouncement = 'ConfirmSubmitAnnouncement',
    PreviewAdvisorInfo = 'PreviewAdvisorInfo',
    PreviewAnswerConsultAdvisor = 'PreviewAnswerConsultAdvisor',
    PreviewInquiryAdminFqa = 'PreviewInquiryAdminFqa',
    PreviewCreateSignature = 'PreviewCreateSignature',
    TermOfService = 'TermOfService',
    PrivacyPolicy = 'PrivacyPolicy',
    TermOfServiceWithdrawal = 'TermOfServiceWithdrawal',
    ExplainReferCode = 'ExplainReferCode',
    BusinessRecruiter = 'BusinessRecruiter',
    LoginConflictConfirm = 'LoginConflictConfirm',
    ProviderRegistration = 'ProviderRegistration',
    MailListProvider = 'MailListProvider',
    AddMailProvider = 'AddMailProvider',
    SendBackProvider = 'SendBackProvider',
    ConfirmSubmitApproveProvider = 'ConfirmSubmitApproveProvider',
    ProviderServicesSelectPopup = 'ProviderServicesSelectPopup',
    AddContractProvider = 'AddContractProvider',
    DeleteContractProvider = 'DeleteContractProvider',
    IntroCode = 'IntroCode',
    ConfirmSubmitRejectProvider = 'ConfirmSubmitRejectProvider',
    InvitationPreview = 'InvitationPreview',
    ConfirmDeleteInquiryProvider = 'ConfirmDeleteInquiryProvider',
    ConfirmSubmitSuspendProvider = 'ConfirmSubmitSuspendProvider',
    ExportCSV = 'ExportCSV',
    ConfirmInvitationProvider = 'ConfirmInvitationProvider',
    ConfirmDeleteInvitationProvider = 'ConfirmDeleteInvitationProvider',
    openApproveProviderFree = 'openApproveProviderFree',
    openUnApproveProviderFree = 'openUnApproveProviderFree',
    ConfirmDeleteIncomingProvider = 'ConfirmDeleteIncomingProvider',
    ConfirmSubmitServiceCost = 'ConfirmSubmitServiceCost',
    ProviderFeatureSelectPopup = 'ProviderFeatureSelectPopup',
    ProductProviderPreview = 'ProductProviderPreview',
    ConfirmDeleteProvider = 'ConfirmDeleteProvider',
    ConfirmDeleteProduct = 'ConfirmDeleteProduct',
    PreviewInquiryProvider = 'PreviewInquiryProvider',
    AdvisorInquiryProvider = 'AdvisorInquiryProvider',
    InquiryToProvider = 'InquiryToProvider',
    SearchByGenreFeaturePopup = 'SearchByGenreFeaturePopup',
    SearchByAreaPopup = 'SearchByAreaPopup',
    AdvisorRateProvider = 'AdvisorRateProvider',
    GraphRateProvider = 'GraphRateProvider',
    ErrorRateProvider = 'ErrorRateProvider',
    PopupNotiErrorProduct = 'PopupNotiErrorProduct',
    popUpConfirmCountAccess = 'popUpConfirmCountAccess',
    SearchByGenreFeature = 'SearchByGenreFeature',
    SearchByArea = 'SearchByArea',
    ConfirmDeleteConciergeAdvisor = 'ConfirmDeleteConciergeAdvisor',
    ErrorProductIsDelete = 'ErrorProductIsDelete',
    PopupNotiPublicProduct = 'PopupNotiPublicProduct',
    ConfirmFreeMembership = 'ConfirmFreeMembership',
    confirmFeeManagement = 'ConfirmFeeManagement'
}

enum TypeNotify {
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning',
    warn = 'warn',
    open = 'open'
}

enum UserTypeRole {
    admin = 1,
    provider = 2,
    advisor = 3
}

enum TypeAccount {
    admin = 1,
    adminFQA = 2,
    adminConsultant = 3
}

enum TypeFeeAccount {
    FREE = 1,
    PAID = 2
}

enum TypeRenewalFlg {
    FREE = 0,
    PAID = 1
}

enum DateFormatInput {
    'Input' = 'YYYY年MM月DD日',
    'MonthInput' = 'YYYY年MM月',
    YYYYMM = 'YYYY-MM',
    YYYYMM2 = 'YYYY/MM',
    YYYYMMDD = 'YYYY/MM/DD',
    YYYYMMDDHHMM = 'YYYY/MM/DD HH:mm',
    'search' = 'YYYY-MM-DD',
    'Access' = 'YYYY年MM月DD日 HH:mm',
    'Year' = 'YYYY'
}

enum ImageChoose {
    notUpdate = 'notUpdate'
}

enum ValueStatusAdvisor {
    TEMPORARY_REGISTRATION = 1,
    OFFICIAL_REGISTRATION = 2,
    STOP = 3,
    WITHDRAWN = 4
}
enum ValueStatusProvider {
    TEMPORARY_REGISTRATION = 1,
    OFFICIAL_REGISTRATION = 2,
    IN_USE = 3,
    HAS_FEEDBACK = 4,
    SUSPEND = 5,
    WITHDRAWN = 6,
    DRAFTSENDBACK = 7
}
enum ValueTypeProvider {
    FREE = 1,
    CHARGED = 2
}
enum ValueClassificationProvider {
    TEMPORARY = 1,
    OFFICIAL = 2
}
enum introCodeProvider {
    HAVE = 1,
    NOT_HAVE = 0
}
enum StatusValueUserAdmin {
    INVALID = 0,
    VALID = 1
}

enum StatusSenderType {
    GUEST = 1,
    PROVIDER = 2,
    ADVISOR = 3,
    JOB = 4,
    ADMIN = 5
}
enum StatusProductSenderType {
    PROVIDER = 1,
    ADVISOR = 2
}
enum minutesRange {
    start = 0,
    half = 30,
    end = 60
}
enum StatusReadAnnounce {
    NEW = 1,
    NOT_READ = 2,
    READ = 3
}

enum RecipientTypeAnnouncement {
    ADVISOR = 1,
    PROVIDER = 2,
    GUEST = 3,
    JOB = 4
}

enum RequestType {
    EMAIL = 1,
    INTERVIEW = 2,
    REQUEST = 3
}

enum ViewMode {
    PRIVATE = 0,
    PUBLIC = 1
}

enum DeleteFlg {
    TRUE = 1,
    FALSE = 0
}

enum EditPermission {
    TRUE = 1,
    FALSE = 0
}

enum DirectorFlg {
    TRUE = 1,
    FALSE = 0
}

enum RecieverFlg {
    TRUE = 1,
    FALSE = 0
}

enum StepActive {
    policy = 1,
    customerInfo = 2,
    temporaryRegistration = 3,
    complete = 4
}

enum TypeMail {
    DIRECTOR = 1,
    ACCOUNT = 2,
    WORKER = 3
}

enum StatusAdvisor {
    registration = 1,
    active = 2,
    unactive = 3,
    cancel = 4
}

enum StatusPublicImage {
    PUBLIC = 1,
    PRIVATE = 2
}

enum StyleInput {
    isValue = 'inputProg1'
}

enum StatusPublicSampleFees {
    public = 1,
    private = 0
}

enum GenderVideo {
    office = 'gender_office',
    advisor = 'gender_tax_advisor'
}

enum ContactWayConcierge {
    phone = '1',
    mail = '2',
    both = '1|2'
}
enum ContactedFlg {
    TRUE = 1,
    FALSE = 0
}

enum AreaName {
    Kanto = '関東'
}

enum statusCheckbox {
    CheckAll = 0
}

export {
    DateFormatInput,
    KeyStorage,
    HttpStatusCode,
    TypeInputCustom,
    ListNamePopup,
    TypeNotify,
    UserTypeRole,
    TypeAccount,
    TypeFeeAccount,
    ImageChoose,
    ValueStatusAdvisor,
    StatusSenderType,
    StatusValueUserAdmin,
    minutesRange,
    StatusReadAnnounce,
    RecipientTypeAnnouncement,
    RequestType,
    ViewMode,
    StepActive,
    DeleteFlg,
    EditPermission,
    DirectorFlg,
    RecieverFlg,
    TypeMail,
    StatusAdvisor,
    StatusPublicImage,
    StatusPublicSampleFees,
    GenderVideo,
    StyleInput,
    ContactWayConcierge,
    ContactedFlg,
    ValueStatusProvider,
    ValueTypeProvider,
    ValueClassificationProvider,
    introCodeProvider,
    TypeDatePicker,
    statusCheckbox,
    AreaName,
    TypeRenewalFlg,
    StatusProductSenderType
}
