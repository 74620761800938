import {
    IStateStoreAdminAnnouncement,
    IPayloadCreateAnnounce
} from '@/modules/admin/modules/announcement/shared/@types'
import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import {
    customUrlImage,
    customUrlPdf,
    getRawHTML,
    NotificationCustom,
    objectHasKey
} from '@/shared/function'
import AnnouncementService from '../services'
import { TypeStatusAnnouncementAdmin } from '@/modules/admin/modules/announcement/shared/enums'
import store from '@/store'

export const defaultStateAdminAnnouncement: IStateStoreAdminAnnouncement = {
    data: {}
}

const state = () => copyDeep(defaultStateAdminAnnouncement)

const getters = {
    getDataAnnouncement(state: IStateStoreAdminAnnouncement) {
        return state.data
    }
}

const mutations = {
    setDataAnnouncement(state: IStateStoreAdminAnnouncement, payload: any) {
        state.data = payload
    },
    setClearDataStore(state: IStateStoreAdminAnnouncement) {
        Object.assign(state, copyDeep(defaultStateAdminAnnouncement))
    }
}

const actions = {
    async getListAnnouncement({ commit }: any, params: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AnnouncementService.apiGetListAnnouncement(params)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataAnnouncement', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDetailAnnouncementById({ commit }: any, params: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AnnouncementService.apiGetDetailAnnouncement(
                params
            )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                if (data.data && objectHasKey(data.data, 'file_link')) {
                    data.data.file_link = customUrlPdf(data.data.file_link)
                }
                commit('setDataAnnouncement', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createAnnouncement({ commit }: any, payload: IPayloadCreateAnnounce) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            payload.content = getRawHTML(payload.content)
            const res = await AnnouncementService.apiCreateAnnounce(payload)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                store.dispatch('popup/hidePopup')
                if (payload.status === TypeStatusAnnouncementAdmin.drag) {
                    return data.data
                }
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async editAnnouncement({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await AnnouncementService.apiUpdateAnnouncement(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                await store.dispatch('popup/hidePopup')
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await store.dispatch('popup/hidePopup')
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    changeLoading({ commit }: any, payload: any) {
        commit('setLoading', payload)
    },
    resetParams({ commit }: any) {
        commit('setClearDataStore')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
