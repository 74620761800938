enum RouterAdminUrl {
    signin = '/signin',
    signup = '/signup',
    myPage = '/my-page/:token?',
    passwordReset = '/password-reset',
    resetPassword = '/reset-password/:token',
    advisors = '/advisors',
    basicInformations = '/advisors/basic-info',
    officeInformations = '/advisors/office-info',
    affiliateAdvisor = '/advisors/affiliate-advisor',
    sampleFees = '/advisors/sample-fees',
    promotions = '/advisors/promotions',
    interview = '/advisors/interview',
    adminManagement = '/admin-management',
    registerAdmin = '/admin-management/register',
    updateAdmin = '/admin-management/edit',
    announcement = '/announcement',
    announcementCreate = '/announcement/create',
    announcementEdit = '/announcement/edit',
    announcementDetail = '/announcement/detail',
    announcementPreview = '/announcement/preview',
    myPageDetail = '/my-page/detail',
    myPageProviderDetail = '/my-page/provider/detail',
    feeManagement = '/fee-management',
    providersTemp = '/providers&type=temp',
    providersOfficial = '/providers&type=official',
    providersBasic = '/providers/basic-info',
    providersOffice = '/providers/office-info',
    providersContract = '/providers/contract-info',
    providersVideoPromotion = '/providers/promotions',
    providersProduct = '/providers/products',
    providersProductDetail = '/providers/product/detail',
    invitationProviderAdmin = '/invitation',
    invitationProviderDetailAdmin = '/invitation/detail',
    settings = '/settings',
    countAccess = '/count-access',
    // admin fqa
    notFound = '/:pathMatch(.*)*'
}
export { RouterAdminUrl }
