
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'
import { listSteps } from '@/shared/constants'
import { taoyriLink } from '@/modules/advisor/shared/contants'
import { openNewTab } from '@/shared/function'
import { ITypeStep } from '@/shared/constants/IContants'

export default Vue.extend({
    name: 'Registration',
    components: { TitlePopup, ButtonCustom },
    data() {
        return {
            listSteps,
            checkPage: this.$route.path === RouterAdvisorUrl.signup,
            pageRegisterProvider: window.location.origin.includes('provider'),
            taoyriLink
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        this.listSteps.map((item: ITypeStep, index: number) => {
            if (index <= this.getParamsPopup.itemActive) {
                return (item.status = true)
            }
        })
    },
    methods: {
        closeBtn() {
            this.$store.dispatch('popup/hidePopup')
            window.location.reload()
        },
        openTayoriLink() {
            openNewTab(taoyriLink.questionRegistrationLink)
        }
    }
})
