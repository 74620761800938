
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'
import TitlePopup from '@/components/common_new/popup/title.vue'
export default Vue.extend({
    name: 'ConfirmInvitationProvider',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    components: { TitlePopup, ButtonCustom },
    methods: {
        async submitInvitation() {
            if (this.getParamsPopup) {
                const param = this.getParamsPopup
                await this.$store.dispatch(
                    'invitedProvider/approveInvitedProviderAdmin',
                    param
                )
                await this.$store.dispatch(
                    'invitedProvider/getDetailDataInvitedProviderAdmin',
                    param
                )
                await this.$store.dispatch('signupAuth/countNotifyAdmin')
            }
            return
        },
        hidePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
