
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent } from 'vue'
import {
    DateFormatInput,
    TypeDatePicker,
    TypeInputCustom
} from '@/shared/enums'
import moment from 'moment'

export default defineComponent({
    name: 'DatePickerCustom',
    props: {
        label: {
            type: String,
            required: true
        },
        classLabel: {
            type: String
        },
        classInput: {
            type: String
        },
        funcCustom: {
            type: Function
        },
        nameInput: {
            type: String,
            required: true
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true
        },
        typeInputCustom: {
            type: String,
            default: TypeInputCustom.date
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        format: { type: String, default: DateFormatInput.YYYYMMDD },
        showTime: Boolean,
        getData: Boolean,
        disabledDate: Function,
        disabledTime: Function,
        classFormItem: {
            type: String
        },
        mode: {
            type: String,
            default: TypeDatePicker.date
        },
        requiredText: {
            type: String,
            default(): string {
                return '※必須'
            }
        },
        layoutTable: Boolean
    },
    data() {
        return {
            ListTypeInput: TypeInputCustom,
            DateFormatInput,
            TypeDatePicker
        }
    },
    methods: {
        // disabledDate(current: any) {
        //     if (!this.timeMin) {
        //         return false
        //     }
        //     return current && current < moment(this.timeMin).endOf('day')
        // },
        onChange(value: any) {
            const dateNew = value
                ? moment(value).format(
                      this.format === DateFormatInput.MonthInput
                          ? DateFormatInput.YYYYMM
                          : this.format
                  )
                : ''
            if (this.funcCustom && dateNew) {
                const a = this.funcCustom(dateNew)
                this.$nextTick(() => {
                    this.formInstance.setFieldsValue({
                        [this.nameInput]: a
                    })
                })
                return
            }
            if (!this.getData) {
                return
            }

            this.$emit('getData', dateNew)
        }
    }
})
