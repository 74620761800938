import apiServices from '@/shared/services/api/axios'
type IParamProviderDetail = {
    user_id: string
}
const ProviderAdminService = {
    apiGetProviders(payload: any) {
        let url = '/admin/list-provider'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },
    apiGetBasicInfoProvider(payload: IParamProviderDetail) {
        return apiServices.get(
            `/admin/provider-basic-info?user_id=${payload.user_id}`
        )
    },
    apiPostSendBackProvider(payload: any) {
        return apiServices.post('/admin/provider-sendback', payload)
    },
    apiPostSuspendProvider(payload: any) {
        return apiServices.post('/admin/provider-suspend', payload)
    },
    apiPostUnSuspendProvider(payload: any) {
        return apiServices.post('/admin/provider-unsuspend', payload)
    },
    apiPostApproveProvider(payload: any) {
        return apiServices.post('/admin/provider-approval', payload)
    },
    apiGetDetailDraftSendBackProvider(payload: any) {
        return apiServices.get(
            `/admin/get-provider-sendback?provider_id=${payload.provider_id}`
        )
    },
    apiGetOfficeInfoProvider(payload: any) {
        return apiServices.get(
            `/admin/provider-office?user_id=${payload.user_id}`
        )
    },
    apiGetContractInfoProvider(payload: any) {
        return apiServices.get(
            `/admin/sample-contract?user_id=${payload.user_id}`
        )
    },
    apiGetPromotionInfoProvider(payload: any) {
        return apiServices.get(
            `/admin/promotion-video?user_id=${payload.user_id}`
        )
    },
    apiDeleteProvider(payload: any) {
        return apiServices.post('/admin/provider/delete', payload)
    },
    apiGetProductProvider(payload: any, type: string) {
        let url =
            type === 'full'
                ? '/admin/provider-product-info'
                : '/admin/list-product'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },
    apiGetDetailProduct(payload: any) {
        let url = '/admin/detail-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },
    apiGetPromotionImage(payload: any) {
        return apiServices.get(
            `/admin/promotion-image?user_id=${payload.user_id}`
        )
    }
}
export default ProviderAdminService
