var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"history-product"},[(_vm.showHistory)?_c('div',{staticClass:"inrWrapMax1400"},[_c('div',{staticClass:"mp_ps_browsHx"},[_c('h3',{staticClass:"mp_ps_bHxTtl"},[_vm._v("閲覧した商材・サービス")]),(_vm.isMobile)?_c('div',{staticClass:"swiper-container swiper-container2"},[_c('swiper',{attrs:{"slides-per-view":1,"navigation":"","space-between":50,"breakpoints":{
                        768: { centerInsufficientSlides: true }
                    }},scopedSlots:_vm._u([{key:"wrapper-start",fn:function(){return _vm._l((_vm.getDataProductViewed),function(item,index){return _c('swiper-slide',{key:index,staticClass:"text-center imgWrap",attrs:{"swiper-ref":item}},[_c('div',{on:{"click":function($event){return _vm.redirectDetail(
                                        item.id,
                                        item.user_provider_id
                                    )}}},[_c('img',{attrs:{"src":_vm.renderImage(
                                            item?.image1,
                                            item?.image2,
                                            item?.image3
                                        ),"alt":"","width":"192","height":"221"}}),_c('h5',[_vm._v(_vm._s(item?.product_name))])])])})},proxy:true}],null,false,3560569394)})],1):_c('div',{staticClass:"swiper-container swiper-container2"},[_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper mp_ps_bHxImgWrap"},_vm._l((_vm.getDataProductViewed),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",on:{"click":function($event){return _vm.redirectDetail(
                                    item.id,
                                    item.user_provider_id
                                )}}},[_c('img',{attrs:{"src":_vm.renderImage(
                                        item?.image1,
                                        item?.image2,
                                        item?.image3
                                    ),"alt":"","width":"192","height":"221"}}),_c('h5',[_vm._v(_vm._s(item?.product_name))])])}),0)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }