enum RouterProviderUrl {
    signin = '/signin',
    signup = '/signup',
    passwordReset = '/password-reset',
    resetPassword = '/reset-password/:token',
    basicInformations = '/basic-informations',
    officeInformations = '/office-informations',
    contractInformations = '/contract-informations',
    promotions = '/promotions',
    announcement = '/announcement',
    announcementDetail = '/announcement/detail/',
    myPage = '/my-page/:token?',
    myPageNoToken = '/my-page',
    countAccess = '/count-access-provider',
    incoming = '/incoming',
    incomingDetail = '/incoming/detail/',
    incomingPreview = '/incoming/preview/',
    inquiry = '/inquiry',
    inquiryCreate = '/inquiry/create',
    inquiryDetail = '/inquiry/detail/',
    products = '/products',
    productCreate = '/product/create',
    productsPreview = '/products/preview',
    productManagement = '/products/management/',
    productProgress = '/products/progress/',
    productInquiry = '/products/inquiry/',
    membershipRegist = '/membership-regist',
    membershipRegistFree = '/membership-regist/free',
    productDetail = '/product/detail/',
    providerSearchDetail = '/provider-search/',
    providerFavorite = '/favorite',
    providerSearch = '/provider-search',
    searchResult = '/search-result',
    paymentHistory = '/payment-history',
    notFound = '/:pathMatch(.*)*'
}

enum StatusPublicImage {
    PRIVATE = 0,
    PUBLIC = 1
}

export { RouterProviderUrl, StatusPublicImage }
