
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { getRawHTML, objectHasKey } from '@/shared/function'
import { IPayloadCreateAnnounce } from '@/modules/admin/modules/announcement/shared/@types'
import { copyDeep } from '@/shared'
export default Vue.extend({
    name: 'ConfirmSubmitAnnouncement',
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        ...mapGetters('announcement', ['getDataAnnouncement'])
    },
    data() {
        return {
            date: ''
        }
    },
    components: { Title, ButtonCustom },
    mounted() {
        this.date = this.getParamsPopup
    },
    methods: {
        async submitAnnouncement() {
            const { isPreview, file_link, ...resAnnouncement } =
                this.getDataAnnouncement

            let res
            if (!objectHasKey(this.getDataAnnouncement, 'id')) {
                res = await this.$store.dispatch(
                    'announcement/createAnnouncement',
                    this.getDataAnnouncement
                )
            }
            if (objectHasKey(this.getDataAnnouncement, 'id')) {
                const payload: IPayloadCreateAnnounce =
                    copyDeep(resAnnouncement)

                if (objectHasKey(this.getDataAnnouncement, 'statusImage')) {
                    const { statusImage } = this.getDataAnnouncement
                    payload.image = statusImage
                }
                if (objectHasKey(this.getDataAnnouncement, 'statusFile')) {
                    const { statusFile } = this.getDataAnnouncement
                    payload.file = statusFile
                    payload.filename = ''
                }

                delete payload.statusImage
                delete payload.statusFile
                payload.content = getRawHTML(payload.content)
                res = await this.$store.dispatch(
                    'announcement/editAnnouncement',
                    payload
                )
            }
            if (res) {
                await this.$router.push(RouterAdminUrl.announcement)
            }
        },
        hidePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
