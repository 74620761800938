enum TypeStatusAnnouncementAdmin {
    drag = 0,
    pending = 1,
    sent = 2
}
enum TypeValueRecipient {
    all = 0,
    tax_account = 1,
    corporation = 3
}

export { TypeStatusAnnouncementAdmin, TypeValueRecipient }
