
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TabsCustom from '@/components/common/tabs/index.vue'
import { TabRegisterPreview } from '@/modules/advisor/shared/contants'
import { mapGetters } from 'vuex'
import {
    customUrlProfileImage,
    getDomainMitsukaru,
    getDomainPortal,
    isArray,
    isObject,
    onScrollTopMap
} from '@/shared/function'
import OfficeInformationPreview from '@/modules/advisor/modules/signup/components/office-infomation/OfficeInformationPreview.vue'
import AffiliateAdvisorPreview from '@/modules/advisor/modules/signup/components/affiliate/AffiliateAdvisorPreview.vue'
import ShowVideoPreview from '@/modules/advisor/modules/signup/components/show-video/ShowVideoPreview.vue'
import SampleFeesPreview from '@/modules/advisor/modules/signup/components/sample-fees/SampleFeesPreview.vue'
import InterviewInformationPreview from '@/modules/advisor/modules/signup/components/interview/InterviewInformationPreview.vue'
import MapInformationPreview from '@/modules/advisor/modules/signup/components/map-information/MapInformationPreview.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { logo } from '@/shared/constants'
import { DirectorFlg, StatusPublicSampleFees, ViewMode } from '@/shared/enums'
import { ITypeFormInterviewItem } from '@/modules/advisor/modules/signup/shared/@types'
import { copyDeep } from '@/shared'
import { TitleFormInterviewItem } from '@/modules/advisor/modules/signup/shared/contants'
import MobileLayoutTabContent from '@/modules/advisor/modules/signup/components/mobile-layout/index.vue'
import { filterDomain } from '@/shared/function'
import { UserTypeRole } from '@/shared/enums'

export default Vue.extend({
    name: 'PreviewInfoAdvisorComponent',
    components: {
        MobileLayoutTabContent,
        ButtonCustom,
        TabsCustom,
        ShowVideoPreview,
        OfficeInformationPreview,
        AffiliateAdvisorPreview,
        SampleFeesPreview,
        InterviewInformationPreview,
        MapInformationPreview
    },
    data() {
        return {
            logoHeader: logo.advisor,
            nameTabs: TabRegisterPreview.office as string,
            listDataTab: [
                {
                    title: '事務所情報',
                    name: TabRegisterPreview.office,
                    tab: 1,
                    key: 'tax_advisor_office_info_preview',
                    icon: 'icon-buil',
                    width: '14.6'
                },
                {
                    title: ' 所属税理士情報',
                    name: TabRegisterPreview.affiliate,
                    tab: 2,
                    key: 'tax_advisor_affiliation_advisor_preview',
                    icon: 'icon-man',
                    width: '16.3'
                },
                {
                    title: '料金・事例',
                    name: TabRegisterPreview.sample_fees,
                    tab: 3,
                    key: 'tax_advisor_sample_fees_preview',
                    icon: 'icon-price',
                    width: '14.6'
                },
                {
                    title: 'インタビュー',
                    name: TabRegisterPreview.interviews,
                    tab: 4,
                    key: 'tax_advisor_interviews_preview',
                    icon: 'icon-mic',
                    width: '14.6'
                },
                {
                    title: '地図',
                    name: TabRegisterPreview.map,
                    tab: 5,
                    key: 'tax_advisor_map_info',
                    icon: 'icon-pin',
                    width: '9.4'
                }
            ],
            basicInfo: {} as any,
            officeInfo: {} as any,
            taxAffiliateName: '',
            isRegistration: true,
            isPcLayout: true
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataAdvisor',
            'getBaseOffice',
            'getDataVideo',
            'getDataPreviewAdvisor',
            'getDataAffiliateAdvisor',
            'getDataSampleFeesAdvisor',
            'getDataInterviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', [
            'getDataBasicInfoAdvisorAdmin',
            'getDataOfficeInfoAdvisorAdmin',
            'getDataAffiliateAdvisorAdmin',
            'getDataSamplePeeAdvisorAdmin',
            'getDataInterviewAdvisorAdmin'
        ]),
        fullAddress(): string {
            if (Object.keys(this.basicInfo).length !== 0) {
                return (
                    (this.basicInfo?.address ?? '') +
                    (this.basicInfo?.house_number ?? '') +
                    (this.basicInfo?.building_name ?? '')
                )
            }
            return ''
        },
        formatURLImage(): string {
            if (
                this.getDataPreviewAdvisor.listNameInputImage &&
                this.getDataPreviewAdvisor.listNameInputImage[0].value &&
                this.basicInfo?.image === ''
            ) {
                return this.getDataPreviewAdvisor.listNameInputImage[0].value
            }
            return this.basicInfo?.image ?? ''
        },
        isAdmin() {
            return filterDomain().role === UserTypeRole.admin
        },
        isAdvisor() {
            return filterDomain().role === UserTypeRole.advisor
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        if (window.innerWidth < 768) {
            this.isPcLayout = false
        }
        this.nameTabs = this.getDataPreviewAdvisor?.tabActive
        this.$store.dispatch('popup/setLoading', true, { root: true })
        this.isRegistration =
            this.getDataPreviewAdvisor?.isRegistration ?? false
        this.logoHeader = this.getDataPreviewAdvisor?.isAdmin
            ? logo.portal
            : logo.advisor

        this.getDataPreview()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    watch: {
        getDataBasicInfoAdvisor(newVal) {
            if (isObject(newVal)) {
                this.basicInfo = {
                    ...newVal.tax_advisor_info,
                    tax_advisor_name: newVal.tax_advisor_name
                }
            }
        },
        $route: {
            handler(newVal, oldVal) {
                if (newVal.name !== oldVal.name) {
                    this.$store.commit(
                        'signupAdvisor/setDataPreviewAdvisor',
                        {}
                    )
                }
            }
        },
        getBaseOffice: {
            handler(newVal) {
                this.officeInfo =
                    this.getDataPreviewAdvisor.officeInfo ?? newVal
            },
            immediate: true,
            deep: true
        },
        getDataOfficeInfoAdvisorAdmin: {
            handler(newVal) {
                if (this.getDataPreviewAdvisor.isAdmin) this.officeInfo = newVal
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleResize(event: any) {
            this.isPcLayout = event.target.innerWidth >= 768
        },
        changeTabActive(key: string) {
            this.nameTabs = key ?? 'OfficeInformationForm'
        },
        modifyTab() {
            if (this.getDataPreviewAdvisor.isRegistration) {
                this.listDataTab = this.listDataTab.filter(
                    (itemTab: any) => itemTab.name === TabRegisterPreview.map
                )
                this.nameTabs = TabRegisterPreview.map
                return
            }
            let listSampleFees: any[] = []
            let dataFormInterview: any[] = []

            if (this.getDataPreviewAdvisor.isAdmin) {
                listSampleFees =
                    this.getDataSamplePeeAdvisorAdmin.tax_advisor_sample_fees
                dataFormInterview = this.getDataInterview(
                    this.getDataInterviewAdvisorAdmin
                )
            } else {
                listSampleFees =
                    this.getDataPreviewAdvisor?.listSampleFees ??
                    this.getDataSampleFeesAdvisor.tax_advisor_sample_fees
                dataFormInterview =
                    this.getDataPreviewAdvisor?.listInterview ??
                    this.getDataInterview(this.getDataInterviewAdvisor)
            }
            listSampleFees = listSampleFees?.filter(
                (item: any) => item.public_flg === StatusPublicSampleFees.public
            )

            let filterTab: string[] = []
            filterTab.push(!isArray(listSampleFees) ? 'SampleFeesPreview' : '')
            filterTab.push(
                !isArray(dataFormInterview) ? 'InterviewInformationPreview' : ''
            )

            this.nameTabs = filterTab.includes(
                this.getDataPreviewAdvisor?.tabActive
            )
                ? TabRegisterPreview.office
                : this.getDataPreviewAdvisor?.tabActive

            if (isArray(filterTab)) {
                this.listDataTab = this.listDataTab.filter(
                    (itemTab: any) => !filterTab.includes(itemTab.name)
                )
            }
        },
        getDataInterview(valInterview: any) {
            if (!valInterview) {
                return
            }
            let data = []
            const listInterviewItem = copyDeep(TitleFormInterviewItem).map(
                (item: ITypeFormInterviewItem) => {
                    const { data, value } = item
                    for (const key in data) {
                        data[key] = valInterview[key + `_${value}`]
                    }
                    data.id = valInterview.id
                    return item
                }
            )

            data = listInterviewItem.filter(
                (item: ITypeFormInterviewItem) =>
                    item.data.is_public_flg === ViewMode.PUBLIC
            )
            return data
        },
        scrollToTop() {
            window.scrollTo(0, 0)
        },
        backToInfo() {
            this.scrollToTop()
            this.$store.commit('signupAdvisor/setDataPreviewAdvisor', {})
        },
        handleSubmit() {
            this.$store.commit('signupAdvisor/setDataPreviewAdvisor', {
                ...this.getDataPreviewAdvisor,
                tabSubmit: Object.keys(this.getDataPreviewAdvisor)[0]
            })
        },
        customUrlProfileImage,
        getDataPreview() {
            if (this.getDataPreviewAdvisor.isAdmin) {
                this.officeInfo = this.getDataOfficeInfoAdvisorAdmin
                this.basicInfo = {
                    ...this.getDataBasicInfoAdvisorAdmin.tax_advisor_info,
                    tax_advisor_name:
                        this.getDataBasicInfoAdvisorAdmin.tax_advisor_name
                }
                const listAffiliate = this.getDataAffiliateAdvisorAdmin?.data
                this.taxAffiliateName = listAffiliate.find(
                    (affiliate: any) =>
                        affiliate.director_flg === DirectorFlg.TRUE
                )?.tax_advisor_name
            } else {
                this.officeInfo =
                    this.getDataPreviewAdvisor.officeInfo ?? this.getBaseOffice

                this.basicInfo = this.getDataPreviewAdvisor.basicInfo ?? {
                    ...this.getDataAdvisor.tax_advisor_info,
                    tax_advisor_name: this.getDataAdvisor.tax_advisor_name
                }
                if (this.getDataPreviewAdvisor.listAffiliate) {
                    const listAffiliate =
                        this.getDataPreviewAdvisor.listAffiliate
                    this.taxAffiliateName = listAffiliate.find(
                        (affiliate: any) => affiliate?.form.director_flg
                    )?.form?.tax_advisor_name
                } else {
                    const listAffiliate = this.getDataAffiliateAdvisor
                    this.taxAffiliateName = listAffiliate.find(
                        (affiliate: any) =>
                            affiliate.director_flg === DirectorFlg.TRUE
                    )?.tax_advisor_name
                }
            }
            this.modifyTab()
            this.scrollToTop()
            this.$store.dispatch('popup/setLoading', false, { root: true })
        },
        onChangeTab(item: any) {
            this.nameTabs = item.name
        },
        openTab(val: any) {
            if (!val) {
                return
            }
            window.open(val)
        },
        redirectTop() {
            window
                .open(
                    this.getDataPreviewAdvisor.isAdmin
                        ? getDomainPortal()
                        : getDomainMitsukaru(),
                    '_blank'
                )
                ?.focus()
        },
        openTabMap() {
            this.nameTabs = TabRegisterPreview.map
            setTimeout(() => {
                onScrollTopMap(-60)
            }, 100)
        },
        onScrollTopMap() {
            const top = document?.querySelector(
                '.tcAdmin_tabContents'
            ) as HTMLElement
            window.scrollTo({
                top: top.offsetTop - 60,
                behavior: 'smooth'
            })
        }
    }
})
