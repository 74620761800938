
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { copyDeep } from '@/shared'
import { listIntroductionFee } from '../../shared/constants'
import {
    customUrlImage,
    isArray,
    formatSendDate,
    getRawHTML,
    zoomImage
} from '@/shared/function'
import moment from 'moment'
import { DateFormatInput } from '@/shared/enums'
import { ITypeSelectCustom } from '@/shared/constants/IContants'

type labelType = {
    label: string
    name: string
}
type dataProductPreview = {
    form: any
    dataProduct: any
    labelInfo: labelType[]
    DateFormatInput: any
    listIntroductionFee: ITypeSelectCustom[]
}

export default Vue.extend({
    name: 'ProductProviderPreview',
    components: {
        ButtonCustom,
        TitlePopup
    },
    data(): dataProductPreview {
        return {
            DateFormatInput,
            listIntroductionFee,
            form: this.$form.createForm(this, {
                name: 'product_preview'
            }),
            dataProduct: {},
            labelInfo: [
                {
                    label: '商材・サービスの概要',
                    name: 'bussiness_classifications_id'
                },
                {
                    label: '対応エリア',
                    name: 'area_id'
                },
                {
                    label: 'ジャンル',
                    name: 'genre'
                },
                {
                    label: '特徴',
                    name: 'product_feature'
                },
                {
                    label: '市場性',
                    name: 'marketability'
                },
                {
                    label: '競合',
                    name: 'competition'
                },
                {
                    label: '強み',
                    name: 'strong_point'
                },
                {
                    label: 'サポート体制',
                    name: 'support_org'
                },
                {
                    label: 'その他',
                    name: 'free_mention'
                },
                {
                    label: '関連ページ',
                    name: 'relation_url'
                },
                {
                    label: '資料',
                    name: 'attachment_pdf_name'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    watch: {
        getParamsPopup: {
            handler(newVal) {
                const data = copyDeep(newVal)
                this.dataProduct = data
            },
            immediate: true
        }
    },
    methods: {
        customUrlImage,
        getRawHTML,
        formatSendDate,
        zoomImage,
        isArray,
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        formatDate(date: any, type: string) {
            return date ? moment(date).format(type) : '-'
        },
        renderLabel(array: ITypeSelectCustom[], value: number) {
            return (
                array.find((e: ITypeSelectCustom) => e.value === value)
                    ?.label || '-'
            )
        }
    }
})
