import apiServices from '@/shared/services/api/axios'
import {
    DataFormLogin,
    IDataFormChangePassword,
    ITypeSendEmail,
    ITypeResetPassword,
    ITypeSendEmailForgotPass,
    ITypeChangeEmail,
    ITypeUpdateEmail,
    ITypeDeactive,
    ITypeRefreshToken,
    ITypeProviderDeactive
} from '@/modules/auth/shared/@types'

const AuthService = {
    apiChangePassword(params: IDataFormChangePassword) {
        return apiServices.post('/change-password', params)
    },
    apiLogin(payload: DataFormLogin) {
        return apiServices.post('/login', payload)
    },
    apiResetPassword(params: ITypeResetPassword) {
        return apiServices.post('/reset-password', params)
    },
    apiLogout() {
        return apiServices.post('/logout')
    },
    apiSendEmailForgotPassword(params: ITypeSendEmailForgotPass) {
        return apiServices.post('/forgot-password', params)
    },
    apiCheckExpiryDateToken({ token }: any) {
        const url = '/forgot-password/check-expiry-date-token?token=' + token
        return apiServices.get(url)
    },
    apiRegistration(params: ITypeSendEmail) {
        return apiServices.post('/registration', params)
    },
    apiChangeEmail(params: ITypeChangeEmail) {
        return apiServices.post('/change-email', params)
    },
    apiUpdateEmail(params: ITypeUpdateEmail) {
        return apiServices.post('/update-email', params)
    },
    apiDeactiveAcc() {
        return apiServices.post('/tax-advisor/deactive')
    },
    apiCountAdvisor() {
        return apiServices.get('/tax-advisor/count-notify')
    },
    apiCountAdmin() {
        return apiServices.get('/admin/count-notify')
    },
    apiRefreshToken(payload: ITypeRefreshToken) {
        return apiServices.post('/refresh-token', payload)
    },
    apiProviderDeactiveAcc(payload: ITypeProviderDeactive) {
        return apiServices.post('/provider/delete', payload)
    }
}

export default AuthService
