
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SelectBoxItem from '../components/SelectBoxItem.vue'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ITypeSelectCustom } from '@/shared/constants/IContants'
import {
    generateValueCheckbox,
    getListValueServiceJob
} from '@/modules/advisor/modules/signup/shared/functions'
import { ValueCheckbox } from '@/modules/advisor/modules/signup/shared/enums'
import { copyDeep } from '@/shared'

export default Vue.extend({
    name: 'ServicesSelectPopup',
    components: { SelectBoxItem, Title, ButtonCustom },

    data(): any {
        return {
            isCheckAll: false,
            listCheckBoxSpecialDomain: [],
            listValCheckBoxSpecialDomain: [],

            listCheckBoxCharacteristic: [],
            listValCheckBoxCharacteristic: [],

            listCheckBoxServiceSoftware: [],
            listValCheckBoxServiceSoftware: [],

            listCheckBoxSpecialJob: [],
            listValCheckBoxSpecialJob: [],

            listCheckBoxServiceJob: [],
            listValCheckBoxServiceJob: [],

            listCheckBoxServiceDomain: [],
            listValCheckBoxServiceDomain: [],

            keyNameSpecialJob: 'special_job',
            keyNameSpecialDomain: 'special_domain',
            keyNameCharacteristic: 'characteristic',
            keyNameServiceSoftware: 'service_software',
            keyNameServiceJob: 'service_job',
            keyNameServiceDomain: 'service_domain'
        }
    },

    computed: {
        ...mapGetters('signupAdvisor', [
            'getBaseDataOffice',
            'getDataServicecontent'
        ])
    },

    watch: {
        getBaseDataOffice: {
            handler(valNew) {
                const {
                    special_domain,
                    special_job,
                    characteristic,
                    service_software,
                    service_job,
                    service_domain
                } = valNew
                this.listCheckBoxSpecialDomain =
                    this.customDataCheckbox(special_domain)
                this.listCheckBoxSpecialJob =
                    this.customDataCheckbox(special_job)

                this.listCheckBoxCharacteristic =
                    this.customDataCheckbox(characteristic)
                this.listCheckBoxServiceSoftware =
                    this.customDataCheckbox(service_software)

                this.listCheckBoxServiceJob =
                    this.customDataCheckbox(service_job)
                this.listCheckBoxServiceDomain =
                    this.customDataCheckbox(service_domain)
            },
            immediate: true
        },
        getDataServicecontent: {
            handler(valNew) {
                const {
                    special_domain,
                    special_job,
                    characteristic,
                    service_software,
                    service_job,
                    service_domain
                } = copyDeep(valNew)
                this.listValCheckBoxServiceJob = service_job
                this.listValCheckBoxServiceDomain = service_domain

                const listValueBaseServiceJob = getListValueServiceJob(
                    this.getBaseDataOffice.service_job
                )
                const listValueBaseServiceDomain = getListValueServiceJob(
                    this.getBaseDataOffice.service_domain
                )

                if (
                    service_job &&
                    service_job.length === listValueBaseServiceJob.length
                ) {
                    this.listValCheckBoxServiceJob = [ValueCheckbox.ALL]
                }
                if (
                    service_domain &&
                    service_domain.length === listValueBaseServiceDomain.length
                ) {
                    this.listValCheckBoxServiceDomain = [ValueCheckbox.ALL]
                }

                this.listValCheckBoxSpecialDomain = special_domain
                this.listValCheckBoxSpecialJob = special_job
                this.listValCheckBoxCharacteristic = characteristic
                this.listValCheckBoxServiceSoftware = service_software
            },
            immediate: true
        }
    },

    methods: {
        customDataCheckbox(listCheckboxObject: any) {
            const listNew: ITypeSelectCustom[] = []
            for (const key in listCheckboxObject) {
                const obj: ITypeSelectCustom = {
                    label: listCheckboxObject[key],
                    value: Number(key)
                }
                listNew.push(obj)
            }
            return listNew
        },
        onChangeCheckBoxSpecialDomain(value: any) {
            if (value.length > 3) {
                return
            }
            this.listValCheckBoxSpecialDomain = value
        },
        onChangeCheckBoxServiceDomain(value: any) {
            this.listValCheckBoxServiceDomain = value
        },
        onChangeCheckBoxCharacteristic(value: any) {
            if (value.length > 3) {
                return
            }
            this.listValCheckBoxCharacteristic = value
        },
        onChangeCheckBoxServiceSoftware(value: any) {
            this.listValCheckBoxServiceSoftware = value
        },
        onChangeCheckBoxSpecialJob(value: any) {
            if (value.length > 5) {
                return
            }
            this.listValCheckBoxSpecialJob = value
        },
        onChangeCheckBoxServiceJob(value: any) {
            this.listValCheckBoxServiceJob = value
        },
        submitServiceContent() {
            const { special_domain, special_domain_name } =
                generateValueCheckbox(
                    this.listCheckBoxSpecialDomain,
                    this.listValCheckBoxSpecialDomain,
                    this.keyNameSpecialDomain
                )

            const { special_job, special_job_name } = generateValueCheckbox(
                this.listCheckBoxSpecialJob,
                this.listValCheckBoxSpecialJob,
                this.keyNameSpecialJob
            )

            const { characteristic, characteristic_name } =
                generateValueCheckbox(
                    this.listCheckBoxCharacteristic,
                    this.listValCheckBoxCharacteristic,
                    this.keyNameCharacteristic
                )

            const { service_software_name, service_software } =
                generateValueCheckbox(
                    this.listCheckBoxServiceSoftware,
                    this.listValCheckBoxServiceSoftware,
                    this.keyNameServiceSoftware
                )

            const { service_job_name, service_job } = generateValueCheckbox(
                this.listCheckBoxServiceJob,
                this.listValCheckBoxServiceJob,
                this.keyNameServiceJob,
                true
            )

            const { service_domain_name, service_domain } =
                generateValueCheckbox(
                    this.listCheckBoxServiceDomain,
                    this.listValCheckBoxServiceDomain,
                    this.keyNameServiceDomain,
                    true
                )

            const listDataService = {
                special_domain,
                special_domain_name,
                special_job,
                special_job_name,
                characteristic,
                characteristic_name,
                service_software_name,
                service_software,
                service_job_name,
                service_job,
                service_domain_name,
                service_domain
            }
            this.$store.dispatch(
                'signupAdvisor/changeDataServiceContent',
                listDataService
            )
            this.$store.dispatch('popup/hidePopup')
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
