enum StatusReadConciergeFqa {
    NEW = 1,
    NOT_READ = 2,
    READ = 3,
    KEEP = 4,
    ANSWERED = 5,
    CONTACTED = 6
}

enum StatusReplyConciergeFqa {
    DRAG = 1,
    SAVE
}

enum StatusBusinessSelectConciergeFqa {
    ADMIN = 1,
    ADVISOR = 2,
    OTHER = 3
}

enum StatusConsultSelectConciergeFqa {
    ADVISOR = 1,
    SETTLEMENT = 2,
    TAX = 3,
    INHERITANCE = 4,
    TAX_SAVE = 5,
    COMPANY = 6,
    OTHER = 7
}

enum SenderTypeConcierge {
    GUEST = 1,
    ADVISOR = 2
}

enum SenderTypeConciergeDetail {
    ADVISOR = 1,
    ADMIN = 2
}

enum SenderTypeString {
    GUEST = '法人・個人',
    ADVISOR = '税理士'
}
enum StatusSendBackProviderAdmin {
    DRAG = 1,
    SAVE = 0
}

export {
    StatusReadConciergeFqa,
    StatusReplyConciergeFqa,
    StatusBusinessSelectConciergeFqa,
    StatusConsultSelectConciergeFqa,
    SenderTypeString,
    StatusSendBackProviderAdmin,
    SenderTypeConcierge,
    SenderTypeConciergeDetail
}
