import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreInquiryAdvisorProvider } from '@/modules/advisor/modules/provider/shared/@type'
import AdvisorProviderServices from '@/modules/advisor/modules/provider/service'
import router from '@/router'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

export const defaultStateAdvisorProvider: IStateStoreInquiryAdvisorProvider = {
    data: {},
    dataListProductSelected: []
}

const state = () => copyDeep(defaultStateAdvisorProvider)

const getters = {
    getDataProductSave(state: IStateStoreInquiryAdvisorProvider) {
        return state.data
    },
    getDataListProductSelected(state: IStateStoreInquiryAdvisorProvider) {
        return state.dataListProductSelected
    }
}

const mutations = {
    setDataProductSave(state: IStateStoreInquiryAdvisorProvider, payload: any) {
        state.data = payload
    },
    setDataListProductSelected(
        state: IStateStoreInquiryAdvisorProvider,
        payload: any
    ) {
        state.dataListProductSelected = payload
    }
}

const actions = {
    async getProductSaveProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdvisorProviderServices.apiGetProductSave(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductSave', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async apiCreateMultipleInquiryProvider({ commit }: any, payload: any) {
        try {
            const res =
                await AdvisorProviderServices.apiCreateMultipleInquiryProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })

                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterAdvisorUrl.myPageNoToken)
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteProductSave({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdvisorProviderServices.apiDeleteProductSave(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('popup/hidePopup', null, { root: true })
                // NotificationCustom({
                //     type: TypeNotify.success,
                //     message: data.message
                // })

                dispatch('getProductSaveProvider')
                return true
            }
            // NotificationCustom({
            //     type: TypeNotify.error,
            //     message: data.errors.error_message
            // })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
