
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import { titlePageInquiry } from '../../shared/contants'

export default Vue.extend({
    name: 'PreviewInquiryAdminFqa',
    components: { ButtonCustom },
    data() {
        return {
            dataPreviewInquiry: ''
        }
    },
    computed: {
        ...mapGetters('inquiryAdminFqa', [
            'getPreviewInquiry',
            'getPayloadInquiry',
            'getDataInquiryAdminFqa'
        ])
    },
    mounted() {
        this.$store.commit('pageTitle/setTitleState', titlePageInquiry.preview)

        if (this.getPreviewInquiry) {
            this.dataPreviewInquiry = this.getPreviewInquiry
            return
        }
        this.$router.push({ path: RouterAdminFQA.inquiry })
    },
    methods: {
        backPreview() {
            if (this.$route.name === 'inquiryPreview') {
                this.$nextTick(() => {
                    this.$router.back()
                })
                return
            }
        },
        async submit() {
            await this.$store.dispatch(
                'inquiryAdminFqa/createInquiryAnswerAdminFqa',
                this.getPayloadInquiry
            )

            await this.$router.push({
                path:
                    RouterAdminFQA.inquiryDetail +
                    this.getPayloadInquiry.inquiry_id
            })
        }
    }
})
