// initial state
// shape: [{ id, quantity }]
import { copyDeep } from '@/shared'
import { IStateStoreProvider } from '@/modules/provider/shared/@types'
import SignupProviderService from '@/modules/provider/modules/signup/services'
import {
    KeyStorage,
    HttpStatusCode,
    RecieverFlg,
    TypeNotify
} from '@/shared/enums'
import { isObject, NotificationCustom } from '@/shared/function'
import SignupAdvisorService from '@/modules/advisor/modules/signup/services'
import { IPayloadPopup } from '@/shared/@types/popup-types'
import { IStateStoreAdvisor } from '@/modules/advisor/shared/@types'

export const defaultStateProvider: IStateStoreProvider = {
    data: {},
    roleEditProvider: 0,
    updateBtnLayoutRegister: false,
    dataListMail: {},
    dataListMailReciever: {},
    officeInfo: {},
    dataServiceContent: {
        characteristic: [],
        service_software: [],
        special_domain: [],
        special_job: [],
        characteristic_name: [],
        service_software_name: [],
        special_domain_name: [],
        special_job_name: []
    },
    area: {
        id: [],
        name: []
    },
    baseDataOffice: {},
    dataResultsArea: {
        type1: [],
        type2: [],
        type3: [],
        type4: []
    },
    dataAreaProvince: [],
    promotionVideo: [],
    dataVideo: {},
    dataSampleContract: [],
    dataSampleContractProduct: [],
    dataCountProvider: {},
    dataPreview: {},
    dataFeatureContent: {},
    isOffice: true,
    promotionImage: {},
    productInfo: {}
}

const state = () => copyDeep(defaultStateProvider)

const getters = {
    getDataProvider(state: IStateStoreProvider) {
        return state.data
    },
    getRoleEditProvider(state: IStateStoreProvider) {
        return state.roleEditProvider
    },
    getUpdateBtnLayoutRegister(state: IStateStoreProvider) {
        return state.updateBtnLayoutRegister
    },
    getDataListMail(state: IStateStoreProvider) {
        return state.dataListMail
    },
    getDataListMailReciever(state: IStateStoreProvider) {
        return state.dataListMailReciever
    },
    getBaseOffice(state: IStateStoreProvider) {
        return state.officeInfo
    },
    getDataServiceContent(state: IStateStoreProvider) {
        return state.dataServiceContent
    },
    getArea(state: IStateStoreProvider) {
        return state.area
    },
    getBaseDataOffice(state: IStateStoreProvider) {
        return state.baseDataOffice
    },
    getDataResultsArea(state: IStateStoreProvider) {
        return state.dataResultsArea
    },
    getPromotionVideos(state: IStateStoreAdvisor) {
        return state.promotionVideo
    },
    getDataVideo(state: IStateStoreAdvisor) {
        return state.dataVideo
    },
    getDataSampleContract(state: IStateStoreProvider) {
        return state.dataSampleContract
    },
    getDataSampleContractProduct(state: IStateStoreProvider) {
        return state.dataSampleContractProduct
    },
    getDataCountProvider(state: IStateStoreProvider) {
        return state.dataCountProvider
    },
    getDataPreviewProvider(state: IStateStoreProvider) {
        return state.dataPreview
    },
    getDataFeatureProvider(state: IStateStoreProvider) {
        return state.dataFeatureContent
    },
    getIsProviderOffice(state: IStateStoreProvider) {
        return state.isOffice
    },
    getPromotionImage(state: IStateStoreProvider) {
        return state.promotionImage
    },
    getProductInfo(state: IStateStoreProvider) {
        return state.productInfo
    }
}

const mutations = {
    setDataProvider(state: IStateStoreProvider, payload: any) {
        state.data = payload
    },
    setRoleEditProvider(state: IStateStoreProvider, payload: number) {
        state.roleEditProvider = payload
    },
    setDataListMail(state: IStateStoreProvider, payload: any) {
        state.dataListMail = payload
    },
    setDataListMailReciever(state: IStateStoreProvider, payload: any) {
        state.dataListMailReciever = payload
    },
    setOfficeInfo(state: IStateStoreProvider, payload: any) {
        state.officeInfo = payload
    },
    updateDataServiceContent(state: IStateStoreProvider, payload: any) {
        state.dataServiceContent = payload
    },
    updateArea(state: IStateStoreProvider, payload: any) {
        state.area = payload
    },
    setBaseDataOffice(state: IStateStoreProvider, payload: any) {
        state.baseDataOffice = payload
    },
    setDataResultsArea(state: IStateStoreProvider, payload: any) {
        Object.assign(state.dataResultsArea, payload)
    },
    updateDataAreaProvince(state: IStateStoreProvider, payload: any) {
        state.dataAreaProvince = payload
    },
    setPromotionVideo(state: IStateStoreAdvisor, payload: any) {
        state.promotionVideo = payload
    },
    setDataVideo(state: IStateStoreAdvisor, payload: any) {
        state.dataVideo = { ...state.dataVideo, ...payload }
    },
    setDataSampleContract(state: IStateStoreProvider, payload: any) {
        state.dataSampleContract = payload
    },
    setDataSampleContractProduct(state: IStateStoreProvider, payload: any) {
        state.dataSampleContractProduct = payload
    },
    setDataCountProvider(state: IStateStoreProvider, payload: any) {
        state.dataCountProvider = payload
    },
    setDataPreviewProvider(state: IStateStoreProvider, payload: any) {
        state.dataPreview = payload
    },
    updateDataFeatureContent(state: IStateStoreProvider, payload: any) {
        state.dataFeatureContent = payload
    },
    setIsProviderOffice(state: IStateStoreProvider, payload: any) {
        state.isOffice = payload
    },
    setPromotionImage(state: IStateStoreProvider, payload: any) {
        state.promotionImage = payload
    },
    setProductInfo(state: IStateStoreProvider, payload: any) {
        state.productInfo = payload
    }
}

const actions = {
    async getDataProvider({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupProviderService.apiGetBasicInfo()
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProvider', data.data)
                localStorage.setItem(
                    KeyStorage.profile,
                    JSON.stringify(data.data)
                )
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async registerBasicInfo({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupProviderService.apiCreateBasicInfo(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async updateBasicInfo({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupProviderService.apiUpdateBasicInfo(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataProvider')
                commit('popup/hidePopup', null, { root: true })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    async getListMail({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res: any = await SignupProviderService.apiGetListEmail(
                payload
            )
            const { status, data } = res
            if (status == HttpStatusCode.ok) {
                commit('setDataListMail', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },

    async getMailReceivers({ commit }: any, payload: any) {
        try {
            const res: any = payload?.isProvider
                ? await SignupProviderService.apiGetListMailProvider(
                      payload?.recieverFlg
                  )
                : await SignupProviderService.apiGetListMailAdminProvider(
                      payload?.id
                  )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListMailReciever', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
        }
    },

    async updateListEmail({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res: any = await SignupProviderService.apiUpdateListMail(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                const dataPayload = {
                    recieverFlg: RecieverFlg.TRUE,
                    isProvider: RecieverFlg.TRUE
                }
                dispatch('getMailReceivers', dataPayload)
                dispatch('getListMail')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },

    async editAddMailProvider({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, {
                root: true
            })
            const res: any = await SignupProviderService.apiRegisterEmail(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                const dataPayload = {
                    isProvider: true,
                    recieverFlg: RecieverFlg.TRUE
                }
                dispatch('getMailReceivers', dataPayload)
                return 'success'
            }
            if (status === HttpStatusCode.bad_request) {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message.email[0]
                })
            }
            if (status === HttpStatusCode.error_server) {
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },

    async getOfficeInfo({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiGetOfficeInfo()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                const dataObj = isObject(data.data) ? data.data : {}

                commit('setOfficeInfo', dataObj)

                commit('updateArea', {
                    id: dataObj?.area_id || [],
                    name: dataObj?.area_name || []
                })

                commit('updateDataServiceContent', {
                    content: dataObj?.content || [],
                    content_name: dataObj?.content_name || [],
                    domain: dataObj?.domain || [],
                    domain_name: dataObj?.domain_name || []
                })
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async registerOfficeInfo({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiRegisterOfficeInfo(
                payload
            )

            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getOfficeInfo')

                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    async updateOfficeInfo({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiUpdateOfficeInfo(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getOfficeInfo')
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    updateDataResultsArea({ commit }: any, payload: IPayloadPopup) {
        commit('setDataResultsArea', payload)
    },

    async getDataAreaProvince({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupAdvisorService.apiGetAreaProvince()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('updateDataAreaProvince', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    changeDataServiceContent({ commit }: any, payload: any) {
        commit('updateDataServiceContent', payload)
    },

    changeDataFeatureContent({ commit }: any, payload: any) {
        commit('updateDataFeatureContent', payload)
    },

    changeArea({ commit }: any, payload: any) {
        commit('updateArea', payload)
    },
    async getPromotionVideoInfoProvider({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupProviderService.apiGetPromotionVideo()
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionVideo', data.data)

                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updatePromotionVideoInfoProvider(
        { commit, dispatch }: any,
        payload: any
    ) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any =
                await SignupProviderService.apiUploadPromotionVideo(payload)
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionVideo', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getPromotionVideoInfoProvider')
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },
    changeDataVideo({ commit }: any, payload: any) {
        commit('setDataVideo', payload)
    },

    async getSampleContract({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiGetSampleContract()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataSampleContract', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getSampleContractProduct({ commit }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any =
                await SignupProviderService.apiGetSampleContractProduct()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataSampleContractProduct', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async createContractInformations({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiCreateContract(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                dispatch('getSampleContract')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error: any) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    async updateContractInformations({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiUpdateContract(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getSampleContract')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error: any) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    async deleteContractInformations({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiDeleteContract(
                payload
            )
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('popup/hidePopup', null, { root: true })
                dispatch('getSampleContract')
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error: any) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
            commit('setDataPreviewProvider', {})
        }
    },

    async searchIntroCode({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res: any = await SignupProviderService.apiSearchIntroCode(
                payload
            )
            const { status, data }: any = res

            if (status === HttpStatusCode.ok) {
                return { status: 'success', data: data.data }
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
            return { message: Object.values(data.errors.error_message) }
        } catch (error: any) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },

    async countNotifyProvider({ commit }: any) {
        try {
            const res: any = await SignupProviderService.apiCountProvider()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataCountProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {}
    },
    async getPromotionImageInfoProvider({ commit }: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res: any = await SignupProviderService.apiGetPromotionImage()
            const { status, data }: any = res
            if (status === HttpStatusCode.ok) {
                commit('setPromotionImage', data.data)

                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
