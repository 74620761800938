import { copyDeep } from '@/shared'
import { IStateStoreIncomingProvider } from '@/modules/provider/modules/incoming/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import {
    IPayloadSearchIncoming,
    IPayloadDeleteIncoming,
    ITypeParamsDetailIncoming,
    ITypeParamsUpdateIncoming
} from '@/modules/provider/modules/incoming/shared/@types'
import IncomingProviderService from '@/modules/provider/modules/incoming/services'

export const defaultStateInquiryAdmin: IStateStoreIncomingProvider = {
    data: {},
    dataPreview: {}
}

const state = () => copyDeep(defaultStateInquiryAdmin)

const getters = {
    getDataIncomingProvider(state: IStateStoreIncomingProvider) {
        return state.data
    },
    getDataIncomingPreview(state: IStateStoreIncomingProvider) {
        return state.dataPreview
    }
}

const mutations = {
    setDataIncomingProvider(state: IStateStoreIncomingProvider, payload: any) {
        state.data = payload
    },
    setDataIncomingPreview(state: IStateStoreIncomingProvider, payload: any) {
        state.dataPreview = payload
    }
}

const actions = {
    async getDataIncomingProvider(
        { commit }: any,
        payload: IPayloadSearchIncoming
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await IncomingProviderService.apiGetIncomingProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataIncomingProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailIncoming(
        { commit, dispatch }: any,
        params: ITypeParamsDetailIncoming
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await IncomingProviderService.apiGetDetailIncoming(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataIncomingProvider', data.data)
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusIncomingProvider(
        { commit, dispatch }: any,
        params: ITypeParamsUpdateIncoming
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await IncomingProviderService.apiUpdateIncomingProvider(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataIncomingProvider', data.data)
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async updateStatusReadIncoming({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await IncomingProviderService.updateStatusReadIncoming(
                payload
            )
            if (res.status === HttpStatusCode.ok) {
                dispatch('signupAuth/countNotifyProvider', true, { root: true })
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteIncomingProvider(
        { commit, dispatch }: any,
        payload: IPayloadDeleteIncoming
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await IncomingProviderService.apiDeleteIncoming(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataIncomingProvider', {
                    refreshData: true
                })
                dispatch('signupProvider/countNotifyProvider', null, {
                    root: true
                })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
