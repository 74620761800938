
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { getDataStorage } from '@/shared'
import { KeyStorage } from '@/shared/enums'
import { customUrlImage, customUrlProfileImage } from '@/shared/function'
import { mapGetters } from 'vuex'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import { customDataAdvisorSelectStorage } from '@/shared/function'

export default Vue.extend({
    name: 'RecentlyViewProvider',
    computed: {
        ...mapGetters('productSearch', ['getDataProductViewed']),
        idLocalStorage() {
            return getDataStorage(KeyStorage.productFQARecentlyViewed)
        }
    },
    data() {
        return {
            showHistory: false,
            isMobile: false
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize)
        }
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 769
        },
        getListDataProviderViewed() {
            const listProductSelect = getDataStorage(
                KeyStorage.productFQARecentlyViewed
            )
            const idProductViewCurrent =
                this.$route.query.tab === 'ProductInfo'
                    ? Number(this.$route.params.id)
                    : 0

            if (!listProductSelect) {
                this.showHistory = false
                return
            }
            const listProductStorage = customDataAdvisorSelectStorage(
                listProductSelect
            ).reduce((preVal: any, nextVal: any) => {
                if (preVal.length < 5) {
                    if (
                        (!Number.isNaN(idProductViewCurrent) &&
                            idProductViewCurrent !== nextVal) ||
                        Number.isNaN(idProductViewCurrent)
                    ) {
                        return preVal.concat(nextVal)
                    }
                }
                return preVal
            }, [])

            if (listProductStorage.length === 0) {
                this.showHistory = false
                return
            }
            const payload: any = {
                viewed_products: listProductStorage.toString()
            }

            this.$store.dispatch('productSearch/getListProductByIds', payload)
        },
        renderImage(img1: string, img2: string, img3: string) {
            let image = img1
            if (img1 === '' || img1 === null) {
                image = img2
            }
            if (image === '' || image === null) {
                image = img3
            }

            if (image === null) image = ''
            if (image !== '') {
                return customUrlImage(image)
            } else {
                return customUrlProfileImage('')
            }
        },
        redirectDetail(id: number | string, user_provider_id: number | string) {
            this.$router.push({
                path:
                    RouterAdminFQA.resultSearchProductDetail +
                    id +
                    `?provider_id=${user_provider_id}&tab=ProductInfo`
            })
        }
    },
    watch: {
        getDataProductViewed: {
            handler(newVal) {
                if (newVal && newVal.length) {
                    this.showHistory = true
                }
            },
            deep: true
        },
        $route: {
            handler(value: any) {
                this.onResize()
                this.getListDataProviderViewed()
                window.addEventListener('resize', this.onResize)
            },
            deep: true,
            immediate: true
        }
    }
})
