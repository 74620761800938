
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import PreviewInfoProviderComponent from '@/modules/provider/modules/signup/components/preview/index.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'PreviewInfoProvider',
    components: { PreviewInfoProviderComponent },
    computed: {
        ...mapGetters('signupProvider', ['getDataPreviewProvider'])
    },
    async created() {
        if (this.getDataPreviewProvider.isAdmin) {
            const query = this.getDataPreviewProvider?.providerId
            await Promise.all([
                this.getDataPreviewProvider.basicInfo
                    ? ''
                    : this.$store.dispatch(
                          'providerAdmin/getDataProviderBasicInfo',
                          query
                      ),
                this.getDataPreviewProvider.officeInfo
                    ? ''
                    : this.$store.dispatch(
                          'providerAdmin/getDataOfficeInfoProvider',
                          query
                      ),
                this.getDataPreviewProvider.listContract
                    ? ''
                    : this.$store.dispatch(
                          'providerAdmin/getDataContractInfoProvider',
                          query
                      ),
                this.getDataPreviewProvider.contractInfo
                    ? ''
                    : this.$store.dispatch(
                          'signupProvider/getSampleContractProduct'
                      ),
                this.$store.dispatch(
                    'providerAdmin/getDataPromotionInfoProvider',
                    query
                ),
                this.$store.dispatch(
                    'providerAdmin/getDataProductFullProviderAdmin',
                    { user_provider_id: query.user_id }
                )

                // this.$store.dispatch('popup/setLoading', true, {
                //     root: true
                // })
            ])
        } else if (
            !this.getDataPreviewProvider.isRegistration &&
            !this.getDataPreviewProvider.isAdmin
        ) {
            await Promise.all([
                this.getDataPreviewProvider.basicInfo
                    ? ''
                    : this.$store.dispatch('signupProvider/getDataProvider'),
                this.getDataPreviewProvider.officeInfo
                    ? ''
                    : this.$store.dispatch('signupProvider/getOfficeInfo'),
                this.getDataPreviewProvider.listContract
                    ? ''
                    : [
                          this.$store.dispatch(
                              'signupProvider/getSampleContract'
                          ),
                          this.$store.dispatch(
                              'signupProvider/getSampleContractProduct'
                          )
                      ],
                this.getDataPreviewProvider.promotionVideo
                    ? ''
                    : this.$store.dispatch(
                          'signupProvider/getPromotionVideoInfoProvider'
                      ),
                this.$store.dispatch('productProvider/dataProductFull')
                // this.$store.dispatch('popup/setLoading', true, {
                //     root: true
                // })
            ])
        }
    }
})
