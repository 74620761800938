import apiServices from '@/shared/services/api/axios'
import { ITypeProviderFeeUpdate } from '@/modules/provider/shared/@types'

const MyPageProviderServices = {
    apiGetProviderMyPage() {
        return apiServices.get('/provider/my-page')
    },

    apiGetProviderFee() {
        return apiServices.get('/provider/fee')
    },

    apiApproveProviderFee(payload: ITypeProviderFeeUpdate) {
        return apiServices.post('/provider/fee/approve', payload)
    }
}
export default MyPageProviderServices
