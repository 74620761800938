import apiServices from '@/shared/services/api/axios'
import {
    IPayloadCreateConciergeAdvisor,
    ITypeParamsDetailConciergeAdvisor,
    IPayloadDeleteConciergeAdvisor,
    ITypePayloadAnswerConciergeAdvisor
} from '@/modules/advisor/modules/concierge/shared/@types'

const ConciergeAdvisorService = {
    apiGetConciergeAdvisor(payload: any) {
        let url = '/tax-advisor/list-concierge'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetConciergeDetailAdvisor(payload: any) {
        let url = '/tax-advisor/concierge-detail/' + payload.id
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiCreateConciergeAdvisor(payload: IPayloadCreateConciergeAdvisor) {
        return apiServices.post(
            '/tax-advisor/create-concierge-inquiry',
            payload
        )
    },
    apiDeleteConciergeAdvisor(payload: IPayloadDeleteConciergeAdvisor) {
        return apiServices.post(
            '/tax-advisor/delete-concierge-management',
            payload
        )
    },
    apiCreateConciergeAnswerAdvisor(
        payload: ITypePayloadAnswerConciergeAdvisor
    ) {
        return apiServices.post('/tax-advisor/create-anwser-concierge', payload)
    }
}
export default ConciergeAdvisorService
