import apiServices from '@/shared/services/api/axios'
import {
    ITypePayloadAnswerInquiryAdminFQA,
    IUpdateStatusInquiryAdminFQA
} from '@/modules/adminFQA/modules/inquiry/shared/@types'
type IParamDetailInquiry = {
    id: string
}

const InquiryAdminFQAService = {
    apiGetInquiry(payload: any) {
        let url = '/admin/list-inquiry'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetInquiryDetail(params: IParamDetailInquiry) {
        return apiServices.get(`/admin/inquiry-detail`, { params })
    },
    apiCreateInquiryAnswerAdminFqa(payload: ITypePayloadAnswerInquiryAdminFQA) {
        return apiServices.post('/admin/create-anwser-inquiry', payload)
    },
    apiUpdateStatusInquiryAdminFqa(payload: IUpdateStatusInquiryAdminFQA) {
        return apiServices.post('/admin/update-status-inquiry', payload)
    },
    apiPreviewInquiryAdminFqa(payload: ITypePayloadAnswerInquiryAdminFQA) {
        return apiServices.post('/admin/preview-anwser-inquiry', payload)
    }
}
export default InquiryAdminFQAService
