
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import UserDetails from '@/modules/auth/popup/UserDetails.vue'
import UserForm from '@/modules/auth/popup/UserForm.vue'
import ChangePassword from '@/modules/auth/popup/ChangePassword.vue'
import ChangeEmail from '@/modules/advisor/modules/signup/popup/ChangeEmail.vue'
import Withdrawal from '@/modules/advisor/modules/signup/popup/Withdrawal.vue'
import TaxAccounting from '@/modules/advisor/modules/signup/popup/TaxAccounting.vue'
import ServicesSelectPopup from '@/modules/advisor/modules/signup/popup/ServicesSelectPopup.vue'
import AreaPopup from '@/modules/advisor/modules/signup/popup/AreaPopup.vue'
import AreaCityPopup from '@/modules/advisor/modules/signup/popup/AreaCityPopup.vue'
import StopAdvisorPopup from '@/modules/admin/modules/advisors/popup/StopAdvisorPopup.vue'
import ZoomImage from '@/modules/admin/modules/advisors/popup/ZoomImage.vue'
import DeleteAdmin from '@/modules/admin/modules/admin-management/popup/DeleteAdmin.vue'
import AdminRegistration from '@/modules/admin/modules/admin-management/popup/AdminRegistration.vue'
import AdminUpdate from '@/modules/admin/modules/admin-management/popup/AdminUpdate.vue'
import ConfirmDeactive from '@/modules/advisor/modules/signup/popup/ConfirmDeactive.vue'
import CreateSignature from '@/modules/advisor/modules/signup/popup/CreateSignature.vue'
import ConfirmDeleteInquiryAdvisor from '@/modules/advisor/modules/inquiry/popup/index.vue'
import PopupFormGuest from '@/modules/guest/modules/search-advisor/popup/formGuest.vue'
import ConfirmCreateInquiryGuest from '@/modules/guest/modules/inquiry/popup/index.vue'
import Registration from '@/modules/auth/popup/Registration.vue'
import QualificationSelectPopup from '@/modules/advisor/modules/signup/popup/QualificationSelectPopup.vue'
import MailListAdvisor from '@/modules/advisor/modules/signup/popup/MailListAdvisor.vue'
import AddMailAdvisor from '@/modules/advisor/modules/signup/popup/AddMailAdvisor.vue'
import LoginSupport from '@/components/layouts/login-support/LoginSupport.vue'
import ConfirmUpdateStatusConcierge from '@/modules/adminFQA/modules/popup/index.vue'
import ManagementCompanyPopup from '@/modules/advisor/modules/mypage/popup/ManagementCompanyPopup.vue'
import ConfirmSubmitAnnouncement from '@/modules/admin/modules/announcement/popup/ConfirmSubmitAnnouncement.vue'
import PreviewAnswerConsultAdvisor from '@/modules/advisor/modules/consult/components/preview/index.vue'
import PreviewInquiryAdminFqa from '@/modules/adminFQA/modules/inquiry/components/preview/index.vue'
import PreviewCreateSignature from '@/modules/advisor/modules/signup/popup/PreviewCreateSignature.vue'
import SearchByAreaPopup from '@/modules/advisor/modules/product-search/popup/AreaPopup.vue'
import SearchByGenreFeaturePopup from '@/modules/advisor/modules/product-search/popup/GenreFeaturePopup.vue'
import SearchByArea from '@/modules/provider/modules/search-provider/popup/SearchAreaPopup.vue'
import SearchByGenreFeature from '@/modules/provider/modules/search-provider/popup/GenreFeaturePopup.vue'
export default Vue.extend({
    name: 'PopupCommon',
    components: {
        UserForm,
        UserDetails,
        ChangePassword,
        ChangeEmail,
        Withdrawal,
        TaxAccounting,
        ServicesSelectPopup,
        AreaPopup,
        AreaCityPopup,
        StopAdvisorPopup,
        ZoomImage,
        DeleteAdmin,
        AdminRegistration,
        AdminUpdate,
        ConfirmDeactive,
        CreateSignature,
        ConfirmDeleteInquiryAdvisor,
        PopupFormGuest,
        ConfirmCreateInquiryGuest,
        Registration,
        QualificationSelectPopup,
        MailListAdvisor,
        AddMailAdvisor,
        LoginSupport,
        ConfirmUpdateStatusConcierge,
        ManagementCompanyPopup,
        ConfirmSubmitAnnouncement,
        PreviewAnswerConsultAdvisor,
        PreviewInquiryAdminFqa,
        PreviewCreateSignature,
        SearchByAreaPopup,
        SearchByGenreFeaturePopup,
        SearchByArea,
        SearchByGenreFeature
    },
    computed: {
        ...mapGetters('popup', [
            'getIsOpenPopup',
            'getNamePopup',
            'getWidthPopup'
        ])
    },
    methods: {
        handleOk() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
