import { copyDeep } from '@/shared'
import { HttpStatusCode, AreaName, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreProductAdvisor } from '@/modules/advisor/modules/product-search/shared/@types'
import ProductAdvisorService from '../services'
import { objectHasKey } from '@/shared/function'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import router from '@/router'
import store from '@/store'

export const defaultStateProductAdvisor: IStateStoreProductAdvisor = {
    data: {},
    dataArea: [],
    dataAllArea: [],
    dataProduct: {},
    dataProductViewed: {},
    dataProviderViewed: {},
    dataSavingProduct: {},
    dataInfoProvider: {},
    dataProductsProvider: [],
    filterSearchProduct: {
        areaId: [],
        genre: [],
        product_feature: [],
        introduction_fees: []
    },
    productInfo: {}
}

const state = () => copyDeep(defaultStateProductAdvisor)

const getters = {
    getDataProductAdvisor(state: IStateStoreProductAdvisor) {
        return state.data
    },
    getDataArea(state: IStateStoreProductAdvisor) {
        return state.dataArea
    },
    getDataAllArea(state: IStateStoreProductAdvisor) {
        return state.dataAllArea
    },
    getDataProduct(state: IStateStoreProductAdvisor) {
        return state.dataProduct
    },
    getDataProductViewed(state: IStateStoreProductAdvisor) {
        return state.dataProductViewed
    },
    getDataProviderViewed(state: IStateStoreProductAdvisor) {
        return state.dataProviderViewed
    },
    getDataSavingProduct(state: IStateStoreProductAdvisor) {
        return state.dataSavingProduct
    },
    getDataInfoProvider(state: IStateStoreProductAdvisor) {
        return state.dataInfoProvider
    },
    getDataProductsProvider(state: IStateStoreProductAdvisor) {
        return state.dataProductsProvider
    },
    getFilterSearchProduct(state: IStateStoreProductAdvisor) {
        return state.filterSearchProduct
    },
    getProductInfo(state: IStateStoreProductAdvisor) {
        return state.productInfo
    }
}

const mutations = {
    setDataProductAdvisor(state: IStateStoreProductAdvisor, payload: any) {
        state.data = payload
    },
    setDataArea(state: IStateStoreProductAdvisor, payload: any) {
        state.dataArea = payload
    },
    setDataAllArea(state: IStateStoreProductAdvisor, payload: any) {
        state.dataAllArea = payload
    },
    setDataProduct(state: IStateStoreProductAdvisor, payload: any) {
        state.dataProduct = payload
    },
    setDataProductViewed(state: IStateStoreProductAdvisor, payload: any) {
        state.dataProductViewed = payload
    },
    setDataProviderViewed(state: IStateStoreProductAdvisor, payload: any) {
        state.dataProviderViewed = payload
    },
    setDataSavingProduct(state: IStateStoreProductAdvisor, payload: any) {
        state.dataSavingProduct = payload
    },
    setDataInfoProvider(state: IStateStoreProductAdvisor, payload: any) {
        state.dataInfoProvider = payload
    },
    setDataProductsProvider(state: IStateStoreProductAdvisor, payload: any) {
        state.dataProductsProvider = payload
    },
    setFilterSearchProduct(state: IStateStoreProductAdvisor, payload: any) {
        state.filterSearchProduct = payload
    },
    setProductInfo(state: IStateStoreProductAdvisor, payload: any) {
        state.productInfo = payload
    }
}

const actions = {
    async getListDataProductAdvisor({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductAdvisorService.apiGetListProducts(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductAdvisor', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAllArea({ commit }: any) {
        try {
            const res = await ProductAdvisorService.apiGetAllArea()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataAllArea', data.data)
                return
                // const arrayFlat = flatArray(data.data)
                // commit('setDataAllArea', arrayFlat)
                // const listAreaKanto = data.data.filter(
                //     (area: any) => area?.area_name === AreaName.Kanto
                // )
                // if (listAreaKanto.length) {
                //     commit('setDataAreaKanto', listAreaKanto[0]?.children)
                // }
            }
        } catch (e) {}
    },
    async getSearchProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductAdvisorService.apiSearchProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProduct', data.data)
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataProductViewed({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiGetListProductViewed(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductViewed', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataProviderViewed({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiGetListProviderViewed(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProviderViewed', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataSavingProduct({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiSavingProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getSearchProduct', payload?.search)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async savingProduct({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiSavingProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteSavingProduct({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiDeleteSavingProduct(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getSearchProduct', payload?.search)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataAreaProvince({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductAdvisorService.apiGetAreaProvince(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                const listArea2 = data.data.reduce(
                    (preVal: any[], nextVal: any) => {
                        if (
                            objectHasKey(nextVal, 'area_name') &&
                            nextVal.area_name === AreaName.Kanto
                        ) {
                            return nextVal.childrens.map((item: any) => {
                                item.label = item.area_name
                                item.value = item.id
                                return item
                            })
                        }
                        return preVal
                    },
                    []
                )
                commit('setDataArea', listArea2)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getInfoProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiGetInfoProvider(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInfoProvider', data.data)
                await store.dispatch(
                    'advisorInquiryProvider/DetailRatingProvider',
                    data.data?.provider_basic_infos?.user_provider_id
                )
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            router.push(RouterAdvisorUrl.myPageNoToken)
            return 'back'
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async checkInfoProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiGetInfoProvider({
                product_id: payload.product_id
            })
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                window.open(
                    RouterAdvisorUrl.productSearchDetail +
                        payload.product_id +
                        `?provider_id=${payload.idProvider}&tab=ProductInfo`
                )
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message:
                    '閲覧している商材が無効になりました。再度検索を行なってください。'
            })
            commit('popup/hidePopup', false, { root: true })
            router.push(RouterAdvisorUrl.myPageNoToken)
            return
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getProductsProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProductAdvisorService.apiGetProductsProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductsProvider', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDetailProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const [provider] = await Promise.all([
                ProductAdvisorService.apiGetDetailProvider(payload)
            ])
            const { status: statusProvider, data: dataProvider } = provider
            if (statusProvider === HttpStatusCode.ok) {
                commit('setDataInfoProvider', dataProvider.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: dataProvider.errors.error_message
            })
            return 'back'
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
