import { copyDeep } from '@/shared'
import { HttpStatusCode } from '@/shared/enums'
import { IStateStoreMyPage } from '../shared/contants/IConstants'
import MyPageServices from '../services'

export const defaultStateMyPage: IStateStoreMyPage = {
    data: {},
    dataAccess: {},
    dataAccessProvider: {}
}

const state = () => copyDeep(defaultStateMyPage)

const getters = {
    getDataMyPage(state: IStateStoreMyPage) {
        return state.data
    },
    getAccessInformationDetail(state: IStateStoreMyPage) {
        return state.dataAccess
    },
    getAccessProviderDetail(state: IStateStoreMyPage) {
        return state.dataAccessProvider
    }
}

const mutations = {
    setDataMyPage(state: IStateStoreMyPage, payload: any) {
        state.data = payload
    },
    setDataCountMyPage(state: IStateStoreMyPage, payload: any) {
        state.dataAccess = payload
    },
    setDataCountProviderMyPage(state: IStateStoreMyPage, payload: any) {
        state.dataAccessProvider = payload
    }
}

const actions = {
    async getListDataMyPage({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await MyPageServices.apiGetAdminMyPage(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataMyPage', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getAccessInformationDetail({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await MyPageServices.apiGetAccessInformationDetail(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataCountMyPage', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getAccessProviderDetail({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await MyPageServices.apiGetAccessProviderDetail(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataCountProviderMyPage', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async apiGetAccessInformationCSV({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            let url = process.env.VUE_APP_APP_URL + '/api/access-infomation-csv'
            if (payload) {
                let index = 0
                for (const key in payload) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', 'file.csv')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            return true
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    },
    async apiGetProviderAccessInformationCSV({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, {
            root: true
        })
        try {
            let url =
                process.env.VUE_APP_APP_URL +
                '/api/provider-access-infomation-csv'
            if (payload) {
                let index = 0
                for (const key in payload) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', 'file.csv')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            return true
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, {
                root: true
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
