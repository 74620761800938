import axios, { AxiosRequestConfig } from 'axios'
import { HttpStatusCode, KeyStorage, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import router from '@/router'
import { clearLoginStorage, getDataStorage, setDataStorage } from '@/shared'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import AuthService from '@/modules/auth/services'
import store from '@/store'

let isAlreadyFetchingAccessToken = false
let subscribers = [] as any[]
function onAccessTokenFetched(access_token: string) {
    subscribers = subscribers.filter(callback => callback(access_token))
}
function addSubscriber(callback: any) {
    subscribers.push(callback)
}
const apiConfig = axios.create({
    baseURL: process.env.VUE_APP_APP_URL + '/api',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    timeout: 10000
})

apiConfig.interceptors.request.use(
    (config: any) => {
        const token = getDataStorage(KeyStorage.assetToken)
        if (token) {
            config.headers.Authorization = 'Bearer ' + token
        }
        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)
apiConfig.interceptors.response.use(
    (response: AxiosRequestConfig) => {
        return response
    },
    async (error: any) => {
        const { status, data } = error.response
        const originalConfig = error.config
        if (status === HttpStatusCode.forbidden) {
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return
        }
        if (status === HttpStatusCode.not_accept) {
            clearLoginStorage()
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterAdvisorUrl.signin)
            return
        }
        if (status === HttpStatusCode.expired_token) {
            const retryOrigReq = new Promise(resolve => {
                addSubscriber((access_token: string) => {
                    originalConfig.headers.Authorization =
                        'Bearer ' + access_token
                    resolve(apiConfig(originalConfig))
                })
            })
            if (!isAlreadyFetchingAccessToken) {
                originalConfig._retry = true
                isAlreadyFetchingAccessToken = true
                const payload = {
                    refresh_token: getDataStorage(KeyStorage.refreshToken),
                    user_type: getDataStorage(KeyStorage.profile).user_type
                }
                const res = await store.dispatch(
                    'signupAuth/refreshToken',
                    payload
                )
                if (res.data && res.data.code === HttpStatusCode.error_server) {
                    const a = document.getElementsByClassName(
                        'ant-notification-notice'
                    )
                    if (a.length === 0) {
                        NotificationCustom({
                            type: TypeNotify.error,
                            message: res.data.errors.error_message
                        })
                    }

                    clearLoginStorage()
                    setTimeout(() => {
                        router.push(RouterAdvisorUrl.signin)
                    }, 1000)
                    return
                }
                const { data } = res
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(data.data.access_token)
                setDataStorage(KeyStorage.assetToken, data.data.access_token)
                setDataStorage(KeyStorage.refreshToken, data.data.refresh_token)
            }
            return retryOrigReq
        }
        return error.response
    }
)

const apiServices = {
    post(urlApi: string, params?: any) {
        return apiConfig
            .post(urlApi, params)
            .then((response: any) => response)
            .catch((error: any) => error)
    },
    put(urlApi: string, params?: any) {
        return apiConfig
            .put(urlApi, params)
            .then((response: any) => response)
            .catch((error: any) => error)
    },
    patch(urlApi: string, params?: any) {
        return apiConfig
            .patch(urlApi, params)
            .then((response: any) => response)
            .catch((error: any) => error)
    },
    get(urlApi: string, params?: any) {
        return apiConfig
            .get(urlApi, params)
            .then((response: any) => response)
            .catch((error: any) => error)
    },
    delete(urlApi: string) {
        return apiConfig
            .delete(urlApi)
            .then((response: any) => response)
            .catch((error: any) => error)
    }
}
export default apiServices
