
import Vue from 'vue'
import ContractItem from './ContractItem.vue'
import { mapGetters } from 'vuex'
import { ContractDetailType } from '../../shared/@types'
import { ViewMode } from '@/shared/enums'
import { isArray } from '@/shared/function'

type dataType = {
    dataSuccessContract: ContractDetailType[]
    ViewMode: any
}
export default Vue.extend({
    name: 'ProviderSuccessContract',
    components: { ContractItem },
    data(): dataType {
        return {
            dataSuccessContract: [],
            ViewMode
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getDataSampleContract',
            'getDataPreviewProvider'
        ]),
        ...mapGetters('providerAdmin', ['getDataContractInfoProviderAdmin'])
    },
    watch: {
        getDataPreviewProvider: {
            handler(newVal) {
                if (newVal?.listContract) {
                    this.dataSuccessContract = newVal.listContract
                }
            },
            immediate: true
        },
        getDataSampleContract: {
            handler(newVal, oldVal) {
                if (
                    isArray(newVal) &&
                    newVal !== oldVal &&
                    !isArray(this.getDataPreviewProvider?.listContract)
                ) {
                    this.dataSuccessContract = newVal
                }
            },
            immediate: true
        },
        getDataContractInfoProviderAdmin: {
            handler(newVal, oldVal) {
                if (isArray(newVal) && newVal !== oldVal) {
                    this.dataSuccessContract = newVal
                }
            },
            immediate: true
        }
    }
})
