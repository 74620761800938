import apiServices from '@/shared/services/api/axios'
import {
    IPayloadDeleteIncoming,
    ITypeParamsDetailIncoming,
    ITypeParamsPreviewIncoming,
    ITypeParamsUpdateIncoming
} from '@/modules/provider/modules/incoming/shared/@types'

const IncomingProviderService = {
    apiGetIncomingProvider(payload: any) {
        let url = '/provider/list-incoming'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetDetailIncoming(params: ITypeParamsDetailIncoming) {
        return apiServices.get('/provider/incoming/' + params['id'])
    },
    apiUpdateIncomingProvider(payload: ITypeParamsUpdateIncoming) {
        return apiServices.post('/provider/incoming/update', payload)
    },
    apiPreviewIncomingProvider(payload: ITypeParamsPreviewIncoming) {
        return apiServices.post('/provider/incoming-preview', payload)
    },
    apiDeleteIncoming(payload: IPayloadDeleteIncoming) {
        return apiServices.post('/provider/incoming/delete', payload)
    },
    updateStatusReadIncoming(payload: number) {
        return apiServices.post('/provider/incoming-update-status/', payload)
    }
}

export default IncomingProviderService
