
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent, PropType } from 'vue'
import { StyleInput, TypeInputCustom } from '@/shared/enums'

export default defineComponent({
    name: 'InputCustomNew',
    emits: ['clickOnceLoginEmail'],
    props: {
        label: {
            type: String,
            required: true
        },
        classLabel: {
            type: String
        },
        classInput: {
            type: String
        },
        classFormItem: {
            type: String
        },
        funcCustom: {
            type: Function
        },
        onKeyPressFunc: {
            type: Function
        },
        onKeyChangeFunc: {
            type: Function
        },
        nameInput: {
            type: String,
            required: true,
            default() {
                return ''
            }
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        typeInputCustom: {
            type: String,
            default: TypeInputCustom.text
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        readOnly: Boolean,
        maxCount: Number,
        numberCharactersPr: Number,
        isShowCount: Boolean,
        hideShowCount: Boolean,
        icon: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        layoutTable: Boolean,
        descriptionLabel: {
            type: String
        },
        description: String,
        classDescription: {
            type: String,
            default: 'tbType1_td_note1'
        },
        isShowDescription: {
            type: Boolean,
            default(): boolean {
                return false
            }
        },
        isShowDescriptionRight: {
            type: Boolean,
            default(): boolean {
                return false
            }
        },
        rows: {
            type: [String, Number],
            default: '4'
        },
        requiredText: {
            type: String,
            default(): string {
                return '※必須'
            }
        },
        isConfirm: {
            type: Boolean,
            default: false
        },
        classCount: {
            type: String,
            default: 'tbType4_cnt_check'
        },
        inputProgClass: {
            type: String,
            default: 'inputProg1'
        },
        isShowMax: { type: Boolean, default: false }
    },
    data() {
        return {
            ListTypeInput: TypeInputCustom,
            numberCharacterCount: 0 as number,
            hasValue: false,
            isShowPassword: false,
            isFocus: false
        }
    },
    methods: {
        onStatusPassword() {
            this.isShowPassword = !this.isShowPassword
        },
        onKeyUpDebounce(evt: Event) {
            if (this.funcCustom) {
                const func = this.funcCustom as any
                func(evt, this.formInstance, this.nameInput)
                return
            }
            const eventTarget = evt.target as HTMLInputElement
            this.numberCharacterCount = eventTarget.value.length
            this.isFocus = true
        },
        onKeyPress(event: HTMLElement) {
            if (this.onKeyPressFunc) {
                const func = this.onKeyPressFunc as any
                func(event, this.formInstance, this.nameInput)
            }
        },
        onKeyChange(event: Event) {
            if (this.onKeyChangeFunc) {
                const func = this.onKeyChangeFunc as any
                func(event)
            }
            const eventTarget = event.target as HTMLFormElement
            if (this.typeInputCustom === this.ListTypeInput.count) {
                this.numberCharacterCount = eventTarget.length
                this.isFocus = true
            }
        }
    },
    mounted() {
        if (this.numberCharactersPr) {
            this.numberCharacterCount = this.numberCharactersPr as number
        }
    },
    watch: {
        numberCharactersPr(newVal) {
            this.numberCharacterCount = newVal ? newVal : 0
        }
    }
})
