import apiServices from '@/shared/services/api/axios'

const InvitationAdvisorService = {
    apiGetAnnounce() {
        return apiServices.get('tax-advisor/announcements')
    },
    apiSearchDataInvitation(payload: any) {
        return apiServices.post('tax-advisor/search', payload)
    },
    apiCrateDataInvitation(payload: any) {
        return apiServices.post('tax-advisor/inivited', payload)
    },
    apiGetInvitation(payload: any) {
        let url = '/tax-advisor/inivited'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    }
}
export default InvitationAdvisorService
