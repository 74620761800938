import apiServices from '@/shared/services/api/axios'
import { IParamUpdateStatusAnnouncementById } from '@/modules/adminFQA/modules/announcement/shared/@types'

const AnnounceAdvisorService = {
    apiGetAnnounce(payload: any) {
        let url = 'tax-advisor/announcements'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetAnnounceDetail(id: any) {
        return apiServices.get(`/tax-advisor/announcement/${id}`)
    },
    apiUpdateStatusAnnounce() {
        return apiServices.post(`/tax-advisor/update-status-announcements`)
    },
    apiUpdateStatusAnnounceById(payload: IParamUpdateStatusAnnouncementById) {
        return apiServices.post(
            '/tax-advisor/update-status-read-announcement',
            payload
        )
    }
}
export default AnnounceAdvisorService
