
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { regex } from '@/shared/regex'
import { WrappedFormUtils } from 'ant-design-vue/types/form/form'
import TitlePopup from '@/components/common_new/popup/title.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import { IDataAddMail } from '@/modules/advisor/shared/@types'
import { EditPermission, ListNamePopup } from '@/shared/enums'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { scrollToFirstErrorLineForm } from '@/shared/function'

type IDataEditAddMail = {
    formAddMail: WrappedFormUtils
    titlePopup: string
    btnSubmit: string
    EditPermission: any
}

export default Vue.extend({
    name: 'AddMailAdvisor',
    components: { InputCustom, TitlePopup, ButtonCustom },
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        ...mapGetters('signupAdvisor', ['getRoleEditAdvisor'])
    },
    data(): IDataEditAddMail {
        return {
            formAddMail: this.$form.createForm(this, {
                name: 'form_add_email'
            }),
            titlePopup: '受信用メールアドレスの登録',
            btnSubmit: this.$tc('i18n_add_mail_btn_register'),
            EditPermission
        }
    },
    mounted() {
        if (this.getParamsPopup) {
            const { name, email } = this.getParamsPopup
            this.formAddMail.setFieldsValue({
                name,
                email,
                emailConfirm: email
            })
            this.titlePopup = '登録済みメールアドレスの編集'
            this.btnSubmit = this.$tc('i18n_add_mail_btn_edit')
        }
    },
    methods: {
        onClosePopup() {
            this.backToMailList()
        },
        onSubmitForm() {
            if (this.getRoleEditAdvisor === EditPermission.FALSE) {
                return
            }
            this.formAddMail.validateFields(
                (errors: Error[], values: IDataAddMail) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    this.onPostAddMail(values)
                }
            )
        },
        async onPostAddMail(payload: IDataAddMail) {
            this.getParamsPopup
                ? (payload = Object.assign(payload, {
                      id: this.getParamsPopup.id
                  }))
                : ''
            const res = await this.$store.dispatch(
                'signupAdvisor/editAddMailAdvisor',
                payload
            )
            if (res === 'success') {
                this.formAddMail.resetFields()
                this.backToMailList()
            }
            return
        },
        backToMailList() {
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.MailListAdvisor
            })
        },
        validName() {
            return [
                {
                    required: true,
                    message: this.$tc('i18n_advisor_name_required')
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc('i18n_advisor_name_required')
                                )
                            if (!regex.length20.test(value)) {
                                callback(this.$tc('i18n_advisor_name_lenght20'))
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        ruleValidEmail() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_pop_affiliate_vaidEmail_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length255.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_length255'
                                    )
                                )
                                return
                            }
                            if (!regex.email.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_regexEmail'
                                    )
                                )
                                return
                            }
                            if (!regex.lengthNameEmail.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_lengthNameEmail'
                                    )
                                )
                                return
                            }
                            const emailNewConfirm =
                                this.formAddMail.getFieldValue('emailConfirm')
                            if (emailNewConfirm) {
                                if (emailNewConfirm !== value) {
                                    this.formAddMail.validateFields([
                                        'emailConfirm'
                                    ])
                                    return
                                }
                                if (emailNewConfirm === value) {
                                    this.formAddMail.validateFields([
                                        'emailConfirm'
                                    ])
                                }
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        validPassNewConfirm() {
            return [
                {
                    required: true,
                    message: this.$tc('i18n_advisor_validate_emailNew_required')
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length255.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_validate_emailNew_lenght255'
                                    )
                                )
                                return
                            }
                            if (!regex.email.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_emailNewConfirm_regexEmail'
                                    )
                                )
                                return
                            }

                            if (!regex.lengthNameEmail.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_lengthNameEmail'
                                    )
                                )
                                return
                            }
                            const email =
                                this.formAddMail.getFieldValue('email')
                            if (email && email !== value) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNewConfirm_notMatchEmailNew'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
