
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'

export default Vue.extend({
    name: 'TitlePopup',
    props: {
        title: {
            type: String,
            default: '事業者詳細'
        },
        hideClose: Boolean,
        funCallback: {
            type: Function
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
            if (this.funCallback) {
                this.funCallback()
            }
        }
    }
})
