
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
export default Vue.extend({
    name: 'ConfirmSubmitApproveProvider',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    components: { TitlePopup, ButtonCustom },
    methods: {
        async submitApprove() {
            if (this.getParamsPopup) {
                const res = await this.$store.dispatch(
                    'providerAdmin/approveProvider',
                    this.getParamsPopup
                )
                if (res && res.status) {
                    await this.$router.push(RouterAdminUrl.providersOfficial)
                }
            }
            return
        },
        hidePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
