import { notification } from 'ant-design-vue'
import { INotification } from '@/shared/function/IFuntions'
import {
    DateFormatInput,
    KeyStorage,
    ListNamePopup,
    TypeNotify,
    UserTypeRole,
    ValueStatusAdvisor,
    ValueStatusProvider
} from '@/shared/enums'
import { regex } from '@/shared/regex'
import {
    defaultRememberEmailText,
    listKeyCodeNumber,
    loginPaths,
    jobDefaultCode,
    videoOnline
} from '../constants'
import { i18n } from '@/shared/i18n'
import { getDataStorage, setDataStorage } from '..'
import store from '@/store'
import { ITypeSelectCustom } from '@/shared/constants/IContants'
import moment from 'moment'
import router from '@/router'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'

const clearFormatMoney = (value: string) => {
    if (value) {
        return Number(value.replace(/\D/gi, ''))
    }
    return value
}

const isArray = (value: any): boolean => {
    return value && Array.isArray(value) && value.length > 0
}

const isObject = (value: any): boolean => {
    return value && typeof value === 'object' && Object.keys(value).length > 0
}

const objectHasKey = (objCheck: any, keyCheck: any): boolean => {
    return (
        objCheck &&
        typeof objCheck === 'object' &&
        Object.keys(objCheck).includes(keyCheck)
    )
}

function customDataAdvisorSelectStorage(advisorSelect: string): number[] {
    return advisorSelect.split(',').reduce((preVal: any, nextVal: any) => {
        const idSelect = Number(nextVal)
        if (!Number.isNaN(idSelect)) {
            return preVal.concat(idSelect)
        }
        return preVal
    }, [])
}

function replaceValueArray(value: string, templateData: any) {
    if (!value) return ''
    const listKey = value.split('|')
    // Replace key to value
    templateData.map((e: any) => {
        const indexValue = listKey.indexOf(e?.value.toString())
        if (indexValue !== -1) {
            listKey[indexValue] = e?.label
        }
    })
    return listKey
        .map(e => {
            return `<p>${e}</p>`
        })
        .join('')
}

function NotificationCustom({ type, message, description }: INotification) {
    const a = document.querySelector(
        '.ant-notification-notice:last-child .ant-notification-notice-message'
    ) as HTMLElement
    if (a?.textContent === message.toString()) return
    if (type === TypeNotify.warn) {
        notification.warn({
            message,
            description: description || ''
        })
    }
    if (type === TypeNotify.info) {
        notification.info({
            message,
            description: description || ''
        })
    }
    if (type === TypeNotify.success) {
        notification.success({
            message,
            description: description || ''
        })
    }
    if (type === TypeNotify.error) {
        notification.error({
            message,
            description: description || ''
        })
    }
}

const getValueCookie = (key: string) => {
    const pairs = document.cookie.split(';')
    if (!pairs) {
        return
    }
    const cookies: any = {}
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=')
        cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
    }
    if (!isObject(cookies) || !Object.keys(cookies).includes(key)) {
        return
    }
    if (key === KeyStorage.profile) {
        return JSON.parse(cookies[key])
    }
    return cookies[key]
}

function ruleValidEmail() {
    return [
        {
            required: true,
            message: i18n.t('i18n_advisor_pop_affiliate_vaidEmail_required')
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value) {
                    if (value === defaultRememberEmailText) {
                        callback()
                        return
                    }
                    if (!regex.length255.test(value)) {
                        callback(
                            i18n.t(
                                'i18n_advisor_pop_affiliate_vaidEmail_length255'
                            )
                        )
                        return
                    }
                    if (!regex.email.test(value)) {
                        callback(
                            i18n.t(
                                'i18n_advisor_pop_affiliate_vaidEmail_regexEmail'
                            )
                        )
                        return
                    }
                    if (!regex.lengthNameEmail.test(value)) {
                        callback(
                            i18n.t(
                                'i18n_advisor_pop_affiliate_vaidEmail_lengthNameEmail'
                            )
                        )
                        return
                    }
                }
                callback()
            }
        }
    ]
}

function ruleValidPassword() {
    return [
        {
            required: true,
            message: i18n.t('i18n_advisor_changeEmail_passwordNew_required')
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value) {
                    if (!regex.lengthPassword.test(value)) {
                        callback(i18n.t('i18n_invalid_password'))
                        return
                    }
                    if (!ValidatePasswordRule(value)) {
                        callback(i18n.t('i18n_invalid_password'))
                        return
                    }
                }
                callback()
            }
        }
    ]
}

function ruleValidInviteCode() {
    return [
        {
            required: false,
            message: '紹介コードを入力して下さい。'
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value) {
                    if (regex.space_full.test(value))
                        return callback('紹介コードを入力して下さい。')
                    if (!regex.onlyLength14.test(value)) {
                        return callback(
                            '紹介コードを14文字と大文字と数字で入力してください。'
                        )
                    }
                }
                callback()
            }
        }
    ]
}

function ValidatePasswordRule(value: string) {
    let count = 0
    if (regex.lowerCaseCharacter.test(value)) {
        count++
    }
    if (regex.upperCaseCharacter.test(value)) {
        count++
    }
    if (regex.numberCharacterPassword.test(value)) {
        count++
    }
    if (regex.specialCharacters.test(value)) {
        count++
    }
    return count >= 2
}

function getLoginScreen(path: string) {
    return path.split('/')[1]
}
function formatPostCode(event: any, form: any, nameInput: string) {
    const { target, keyCode, which }: any = event
    if (!listKeyCodeNumber.includes(which)) {
        event.preventDefault()
        return
    }

    const valueTrim = target.value.trim().replace(/[^0-9]/gi, '')
    if (/^[0-9]{7}$/.test(valueTrim)) {
        const match: any = valueTrim.match(/^(\d{3})(\d{4})$/)
        form.setFieldsValue({
            [nameInput]: match[1] + '-' + match[2]
        })
    }
}

const preventKeyMobilePhone = (e: any) => {
    const { target, key } = e

    if (!/\d/.test(key) || target.value.replace(/[^0-9]/gi, '').length > 10) {
        e.preventDefault()

        return
    }
}

const preventKeyPressMobilePhone = (e: any) => {
    const { which } = e
    const charCode: any = which
    if (
        charCode != 46 &&
        charCode != 45 &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
    ) {
        e.preventDefault()
        return false
    }
}
function validPhoneNumber(value: string) {
    const count: any = value.match(/-/g)
    return count?.length === 2
}

const preventKeyNumber = (e: any) => {
    const { target, key } = e

    if (!/^[0-9]+$/.test(key)) {
        e.preventDefault()

        return
    }
}
const preventKeyPostCode = (e: any) => {
    const { target, key } = e

    if (!/\d/.test(key) || target.value.replace(/[^0-9]/gi, '').length > 6) {
        e.preventDefault()

        return
    }
}

function formatNumberPhone(event: any, form: any, nameInput: string) {
    const { target, keyCode, which }: any = event
    if (!listKeyCodeNumber.includes(which)) {
        event.preventDefault()
        return
    }

    const valueTrim = target.value.trim().replace(/[^0-9]/gi, '')

    if (/^[0-9]{11}$/.test(valueTrim)) {
        const match: any = valueTrim.match(/^(\d{3})(\d{4})(\d{4})$/)
        form.setFieldsValue({
            [nameInput]: match[1] + '-' + match[2] + '-' + match[3]
        })
    }
}

function formatInputNumber(event: any, form: any, nameInput: string) {
    const { target }: any = event
    const value = target.value.replace(/,/g, '')

    if (value.length > 20 || !/^[0-9\-)]*$/.test(value)) {
        event.preventDefault()
        return
    }
    const valueSet = value.trim().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    form.setFieldsValue({
        [nameInput]: valueSet
    })
}

function preventInputNumber(e: any) {
    const { target, key } = e
    if (!/\d/.test(key) || target.value.replace(/,/g, '').length > 19) {
        e.preventDefault()
        return
    }
}
function preventInputScale(e: any) {
    const { target, key } = e
    if (!/\d|[/.]/.test(key) || target.value.replace(/,/g, '').length > 19) {
        e.preventDefault()
        return
    }
}

function preventTextMonthSetting(e: any) {
    const { target, key } = e
    if (!/\d/.test(key) || target.value.length > 1) {
        e.preventDefault()
        return
    }
}

function formatNumber(value: any) {
    if (Number(value) === 0) return value
    if (value) return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getRoleTypeDomain() {
    const host = window.location.hostname
    return loginPaths.find(item => host.search(item.type) !== -1)
}

function customUrlImage(valueImage: string): string {
    if (!valueImage) {
        return require('@/assets/image/noImageJP.png')
    }
    if (regex.base64.test(valueImage)) {
        return valueImage
    }
    if (regex.urlHttps.test(valueImage)) {
        return valueImage
    }
    return process.env.VUE_APP_URL_IMAGE + valueImage
}
function customUrlProfileImage(valueImage: string): string {
    if (!valueImage) {
        return require('@/assets/image/noImage.png')
    }
    if (regex.base64.test(valueImage)) {
        return valueImage
    }
    if (regex.urlHttps.test(valueImage)) {
        return valueImage
    }
    return process.env.VUE_APP_URL_IMAGE + valueImage
}
function customURLVideo(valueVideo: string): string {
    if (regex.base64.test(valueVideo)) {
        return valueVideo
    }
    if (!valueVideo) {
        return ''
    }
    return process.env.VUE_APP_URL_IMAGE + valueVideo
}
function customUrlPdf(valuePdf: string): string {
    if (!valuePdf) {
        return ''
    }
    return process.env.VUE_APP_URL_IMAGE + valuePdf
}

function filterDomain(): any {
    const host = window.location.hostname
    return loginPaths.find(item => host.search(item.type) !== -1)
}

function updateDataProfile({ key, value }: any) {
    const profile = getDataStorage(KeyStorage.profile)
    profile[key] = value
    setDataStorage(KeyStorage.profile, JSON.stringify(profile))
}

function rangeTime(start: number, end: number) {
    const result: Array<number> = []
    for (let i = start; i < end; i++) {
        result.push(i)
    }
    return result
}

function zoomImage(val: any) {
    if (!val) {
        return
    }
    store.dispatch('popup/openPopup', {
        isOpen: true,
        namePopup: ListNamePopup.ZoomImage,
        payload: val
    })
}

function filterStatusTable(
    listStatus: ITypeSelectCustom[],
    status: any,
    key = 'label'
) {
    return listStatus.reduce((preVal, nextItem: any) => {
        if (nextItem.value === Number(status)) {
            return nextItem[key]
        }
        return preVal
    }, '')
}

function filterStatusAllTable(
    listStatus: ITypeSelectCustom[],
    status: any,
    key = 'label'
) {
    return listStatus.reduce((preVal, nextItem: any) => {
        if (Number.isInteger(status) && nextItem.value === Number(status)) {
            return nextItem[key]
        }
        return preVal
    }, '')
}

function formatDataSubmit(formInstance: any, dataForm: any) {
    let nameBlank = ''
    for (const key in dataForm) {
        if (typeof dataForm[key] === 'string') {
            const valueNew = dataForm[key].trim()
            if (!valueNew) {
                nameBlank = key
            }
            dataForm[key] = valueNew
        }
    }
    if (nameBlank) {
        formInstance.setFieldsValue({
            [nameBlank]: ''
        })
        setTimeout(() => {
            formInstance.validateFields([nameBlank])
        }, 0)
        return false
    }

    return dataForm
}

function formatSendDate(val?: any, formatTime?: any) {
    if (!val || val === '0000-00-00' || val === '0000-00-00 00:00:00') {
        return null
    }
    return moment(val).format(formatTime || DateFormatInput.YYYYMMDDHHMM)
}

function formatNumberMoney(val?: any, format?: any) {
    if (val === null || val === '') {
        return ' '
    }
    return `${val}`.replace(format || /\B(?=(\d{3})+(?!\d))/g, ',')
}

function removeKeyInObject(obj: any) {
    Object.keys(obj).forEach(key => {
        if (!obj[key]) {
            delete obj[key]
        }
    })
    return obj
}

function findIndexItem(arr: any, step: any) {
    return arr.findIndex((item: any) => item.step === step)
}

function getRawHTML(value: string): any {
    try {
        if (!value) return ''
        return JSON.parse(value)?.rawHTML ?? value
    } catch (e) {
        return value
    }
}

function getTextEditor(value: string): string {
    try {
        if (!value) return ''
        return JSON.parse(value)?.text.replaceAll('\n', '')
    } catch (e) {
        return getInnerTextOfHTML(value).replaceAll('\n', '')
    }
}

function getInnerTextOfHTML(html: string): string {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html.replaceAll('<br>', '\n')
    return tmp.innerText
}

function getDomainMitsukaru() {
    return process.env.VUE_APP_URL_GUEST_MITSUKARU
}

function getDomainPortal() {
    return process.env.VUE_APP_URL_GUEST_PORTAL
}

function getDomainProvider() {
    return process.env.VUE_APP_URL_GUEST_TSUNAGARU
}

function scrollToFirstErrorLineForm(this: any) {
    setTimeout(() => {
        let errorItem: HTMLElement
        if (store.getters['popup/getIsOpenPopup']) {
            errorItem = document.querySelector(
                '.modal .ant-form-item-control.has-error'
            ) as HTMLElement
        } else {
            errorItem = document.querySelector(
                '.ant-form-item-control.has-error'
            ) as HTMLElement
        }
        if (errorItem) {
            errorItem.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
            const e = errorItem.querySelector('input, textarea') as HTMLElement
            e?.focus({ preventScroll: true })
        }
    }, 100)
}

function openNewTab(url: string, newWindow = false) {
    if (!url) return
    window.open(
        url,
        '_blank',
        newWindow
            ? `location=yes,width=${screen.availWidth},height=${screen.availHeight}`
            : ''
    )
}

function formatTextRawHTML(key: number, value: string) {
    let textRawHTML = ''
    if (key === jobDefaultCode) {
        const labelParts = value.split('(')
        const label1 = labelParts[0]
        const label2 = '（' + labelParts[1]
        textRawHTML = `${label1}<br>${label2}`
    } else {
        textRawHTML = value
    }
    return textRawHTML
}
function checkIsTempAdvisor() {
    return (
        getDataStorage(KeyStorage.profile).user_type === UserTypeRole.advisor &&
        Number(getDataStorage(KeyStorage.profile).status) ===
            ValueStatusAdvisor.TEMPORARY_REGISTRATION
    )
}
function checkIsTempProvider() {
    return (
        getDataStorage(KeyStorage.profile).user_type ===
            UserTypeRole.provider &&
        [
            ValueStatusProvider.TEMPORARY_REGISTRATION,
            ValueStatusProvider.OFFICIAL_REGISTRATION,
            ValueStatusProvider.HAS_FEEDBACK
        ].includes(Number(getDataStorage(KeyStorage.profile).status))
    )
}

function routeGoBackToListAdvisor() {
    setTimeout(() => {
        router.push(RouterAdminUrl.advisors)
    }, 1500)
}

function preventText(e: any) {
    const { key } = e
    if (!/\d/.test(key)) {
        e.preventDefault()
        return
    }
}

function checkedBox(listId: string[] | number[], listData: any) {
    const dataResult: any[] = []
    listId?.map((e: any) => {
        const dataCheck = listData.find((e1: any) => e1.id === e)

        if (dataCheck) {
            dataResult.push({
                id: e,
                type: dataCheck.area_type,
                name: dataCheck.area_name,
                parent: dataCheck.parent_id
            })
        } else {
            listData.find((e1: any) =>
                e1.childrens.find((e2: any) => {
                    if (e2.id === e)
                        dataResult.push({
                            id: e,
                            type: e2.area_type,
                            name: e2.area_name,
                            parent: e2.parent_id
                        })
                })
            )
        }
    })

    return dataResult
}

function openNewWindow(url: string, newWindow = true) {
    if (!url) return
    window.open(
        url,
        '_blank',
        newWindow
            ? `location=yes,width=${screen.availWidth},height=${screen.availHeight}`
            : ''
    )
}

function onScrollTopMap(space: number) {
    const top = document?.querySelector('.tcAdmin_tabContents') as HTMLElement
    window.scrollTo({
        top: top.offsetTop + space,
        behavior: 'smooth'
    })
}

function formatTextareaContent(text: string) {
    if (!text || text === '') return
    const formattedContent = text.replace(/\n/g, '<br>')
    return getRawHTML(formattedContent)
}

function formatDateInvalid(date: any) {
    return date != '0000-00-00 00:00:00' &&
        date != '0000-00-00' &&
        date != 'Invalid date'
        ? date
        : null
}

function formatNumberFloat(number: string | number) {
    if (Number.isInteger(Number(number))) {
        return parseInt(number.toString())
    }
    return number
}

function getIdUrl(value: string, regex: any) {
    const match = value.match(regex)
    return match ? match[1] : null
}

function checkTypeUrl(value: string) {
    let typeVideoOnl = ''
    let idUrl: any = ''
    if (value.includes(videoOnline.youtube.domain)) {
        typeVideoOnl = videoOnline.youtube.name
        idUrl = getIdUrl(value, /[?&]v=([^&]+)/)
    } else if (value.includes(videoOnline.facebook.domain)) {
        typeVideoOnl = videoOnline.facebook.name
        idUrl = value
    } else if (value.includes(videoOnline.twitter.domain)) {
        typeVideoOnl = videoOnline.twitter.name
        idUrl = getIdUrl(value, /status\/(.*?)\?s/)
    } else {
        typeVideoOnl = videoOnline.instagram.name
        idUrl = value
    }
    return {
        typeVideoOnl,
        idUrl
    }
}

function renderIconListProduct(index: number) {
    let file = ''
    switch (index) {
        case 1:
            file = 'comment-lbl'
            break
        case 2:
            file = 'man4-lbl'
            break
        case 3:
            file = 'home-lbl'
            break
        case 4:
            file = 'certificate-lbl'
            break
        case 5:
            file = 'hand-shake-lbl'
            break
        default:
            file = 'calculator-lbl'
            break
    }
    return file
}
export {
    clearFormatMoney,
    getValueCookie,
    isObject,
    isArray,
    NotificationCustom,
    ruleValidEmail,
    ruleValidPassword,
    getLoginScreen,
    formatPostCode,
    formatNumberPhone,
    preventKeyMobilePhone,
    preventKeyPostCode,
    getRoleTypeDomain,
    customUrlImage,
    filterDomain,
    updateDataProfile,
    objectHasKey,
    customUrlPdf,
    rangeTime,
    zoomImage,
    filterStatusTable,
    formatDataSubmit,
    formatSendDate,
    removeKeyInObject,
    findIndexItem,
    getRawHTML,
    getTextEditor,
    getInnerTextOfHTML,
    getDomainMitsukaru,
    customURLVideo,
    preventKeyPressMobilePhone,
    validPhoneNumber,
    ValidatePasswordRule,
    customUrlProfileImage,
    scrollToFirstErrorLineForm,
    getDomainPortal,
    openNewTab,
    formatTextRawHTML,
    ruleValidInviteCode,
    checkIsTempAdvisor,
    checkIsTempProvider,
    preventText,
    checkedBox,
    getDomainProvider,
    openNewWindow,
    onScrollTopMap,
    customDataAdvisorSelectStorage,
    replaceValueArray,
    preventKeyNumber,
    formatTextareaContent,
    routeGoBackToListAdvisor,
    formatNumberMoney,
    formatInputNumber,
    preventInputNumber,
    formatNumber,
    preventTextMonthSetting,
    formatDateInvalid,
    formatNumberFloat,
    checkTypeUrl,
    preventInputScale,
    filterStatusAllTable,
    renderIconListProduct
}
