import { copyDeep } from '@/shared'
import { IStateStoreSettingAdmin } from '@/modules/admin/modules/settings/shared/types'
import AdminSettingService from '@/modules/admin/modules/settings/services'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'

export const defaultStateSettingAdmin: IStateStoreSettingAdmin = {
    data: {}
}

const state = () => copyDeep(defaultStateSettingAdmin)

const getters = {
    getDataSetting(state: IStateStoreSettingAdmin) {
        return state.data
    }
}

const mutations = {
    setDataSetting(state: IStateStoreSettingAdmin, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getDataSettingAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await AdminSettingService.apiGetSetting()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataSetting', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async postDataSettingCostAdmin({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await AdminSettingService.apiPostSetting(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataSettingAdmin')
                commit('popup/hidePopup', null, { root: true })
                // commit('setDataSetting', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
