
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common/button/index.vue'

export default Vue.extend({
    name: 'ConfirmCreateInquiryGuest',
    components: { ButtonCustom },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            this.$store.dispatch(
                'inquiryGuest/createInquiry',
                this.getParamsPopup
            )
        }
    }
})
