<template>
    <h3>User form</h3>
</template>

<script>
export default {
    name: 'UserForm'
}
</script>

<style scoped></style>
