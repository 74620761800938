import { copyDeep, getDataStorage, setDataStorage } from '@/shared'
import { HttpStatusCode, KeyStorage, TypeNotify } from '@/shared/enums'
import { NotificationCustom, updateDataProfile } from '@/shared/function'
import { IStateAdminManagement } from '../shared/@types'
import AdminManagementService from '../services'
import store from '@/store'

export const defaultStateAdminManagement: IStateAdminManagement = {
    data: {}
}

const state = () => copyDeep(defaultStateAdminManagement)

const getters = {
    getDataListAdmin(state: IStateAdminManagement) {
        return state.data
    }
}

const mutations = {
    setDataListAdmin(state: IStateAdminManagement, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getDataListAdminManagement({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdminManagementService.apiGetListAdmin(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListAdmin', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message[0]
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async postRegisterAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await AdminManagementService.apiRegisterAdmin(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListAdmin', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data?.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)[0]
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },

    async getAdminUser({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdminManagementService.apiGetAdminUser(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListAdmin', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)[0]
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async postUpdateAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AdminManagementService.apiUpdateAdmin(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataListAdmin', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data?.message
                })
                if (data.data.id === getDataStorage(KeyStorage.profile).id) {
                    updateDataProfile({
                        key: 'email',
                        value: data.data.email
                    })
                    commit('popup/setUpdateEmail', payload, { root: true })
                }
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async handleDeleteAdmin({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res = await AdminManagementService.apiDeleteAdminUser({
                id: payload.id
            })
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data?.message
                })
                await store.dispatch('popup/hidePopup')
                setTimeout(() => {
                    store.dispatch(
                        'managementAdmin/getDataListAdminManagement',
                        {
                            page: payload.page
                        }
                    )
                }, 300)

                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: Object.values(data.errors.error_message)
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
