import apiServices from '@/shared/services/api/axios'
const ProductSearchService = {
    apiGetListProduct(payload: any) {
        const url = 'admin/search-product'
        return apiServices.post(url, payload)
    },
    apiGetListProductSave(payload: any) {
        let url = 'admin/saving-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiSavingProduct(payload: any) {
        return apiServices.post('admin/saving-product/' + payload?.product_id)
    },
    apiRemoveSavingProduct(payload: any) {
        let url = 'admin/delete-saving-product'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.post(url)
    },
    apiRemoveMultiSavingProduct(payload: any) {
        const url = 'admin/delete-saving-products'
        return apiServices.post(url, payload)
    },
    apiGetListProductByIds(payload: any) {
        let url = 'admin/viewed-product'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetListProducts(payload: any) {
        let url = '/admin/top-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    }
}
export default ProductSearchService
