var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"productDetail"},[_c('div',{staticClass:"product_name"},[_vm._v(_vm._s(_vm.dataProduct.product_name))]),_c('div',{staticClass:"imagesArea"},[(_vm.dataProduct.image1)?_c('img',{attrs:{"src":_vm.customUrlImage(_vm.dataProduct.image1),"width":"140","alt":"image-1"}}):_vm._e(),(_vm.dataProduct.image2)?_c('img',{attrs:{"src":_vm.customUrlImage(_vm.dataProduct.image2),"width":"140","alt":"image-2"}}):_vm._e(),_c('p',{staticClass:"providerName"},[_c('span',[_vm._v("事業者名")]),_vm._v(_vm._s(_vm.dataProduct.provider_name)+" ")])]),(
                _vm.item === 1 ? _vm.isShowDetail === false : _vm.isShowDetail === true
            )?_c('table',{staticClass:"tcAdmin_ofc_basicInfo_table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("商材・サービスの概要")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.bussiness_classifications_id
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("対応エリア")]),_c('td',[_vm._v(" "+_vm._s(_vm.dataProduct.area_name ? _vm.dataProduct.area_name?.join('　') : '全国')+" ")])]),_c('tr',[_c('th',[_vm._v("ジャンル")]),_c('td',[_vm._v(" "+_vm._s(_vm.renderDataCheckbox( _vm.CheckboxProductGenre, _vm.dataProduct.genre === 0 ? `${_vm.listGenre}` : _vm.dataProduct.genre, 'genre' ))+" ")])]),_c('tr',[_c('th',[_vm._v("特徴")]),_c('td',[_vm._v(" "+_vm._s(_vm.renderDataCheckbox( _vm.CheckboxProductFeature, _vm.dataProduct.product_feature === '0' ? `${_vm.listFeature}` : _vm.dataProduct.product_feature, 'product_feature' ))+" ")])]),_c('tr',[_c('th',[_vm._v("市場性")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.marketability
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("競合")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.competition
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("強み")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.strong_point
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("サポート体制")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.support_org
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("その他")]),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(
                                _vm.formatTextareaContent(
                                    _vm.dataProduct.free_mention
                                )
                            )}})])]),_c('tr',[_c('th',[_vm._v("関連ページ")]),_c('td',[_c('a',{attrs:{"href":_vm.dataProduct.relation_url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.dataProduct.relation_url)+" ")])])]),_c('tr',[_c('th',[_vm._v("資料")]),_c('td',[_c('a',{staticClass:"noticeMgmt_data-link-cr",on:{"click":function($event){_vm.openNewWindow(
                                    _vm.customUrlPdf(
                                        _vm.dataProduct.attachment_pdf_link
                                    ),
                                    false
                                )}}},[_vm._v(" "+_vm._s(_vm.dataProduct.attachment_pdf_name)+" ")])])])])]):_vm._e(),_c('div',{staticClass:"text-right mt-3"},[_c('a',{on:{"click":function($event){return _vm.toggleAccordion(_vm.isShowDetail)}}},[(_vm.item === 1)?[_vm._v(" "+_vm._s(_vm.isShowDetail ? '全内容表示' : '閉じる')+" ")]:[_vm._v(" "+_vm._s(_vm.isShowDetail ? '閉じる' : '全内容表示')+" ")]],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }