
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import {
    isArray,
    ruleValidEmail,
    scrollToFirstErrorLineForm
} from '@/shared/function'
import { regex } from '@/shared/regex'
import Vue from 'vue'
import InputCustom from '@/components/common_new/input/index.vue'
import SelectCustom from '@/components/common/select/index.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ITypeFormDataTaxAdvisorRegist } from '@/modules/advisor/shared/@types'
import { WrappedFormUtils } from 'ant-design-vue/types/form/form'
import { SampleFeesFormInit } from '@/modules/advisor/shared/contants'
import { copyDeep } from '@/shared'
import TitlePopup from '@/components/common_new/popup/title.vue'

type IDataInit = {
    formTaxAccounting: WrappedFormUtils
    dataFormInit: ITypeFormDataTaxAdvisorRegist
    ruleValidEmail: any
}
export default Vue.extend({
    name: 'TaxAccounting',
    components: { TitlePopup, ButtonCustom, SelectCustom, InputCustom },
    data(): IDataInit {
        return {
            formTaxAccounting: this.$form.createForm(this, {
                name: 'form_tax_accounting'
            }),
            ruleValidEmail: ruleValidEmail(),
            dataFormInit: copyDeep(SampleFeesFormInit)
        }
    },
    computed: {
        dataListTax(): object {
            return isArray(this.$store.state.popup.payload)
                ? this.$store.state.popup.payload
                : []
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            this.formTaxAccounting.validateFields(
                (errors: Error[], values: any) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const { ...payload } = values
                    this.$store.dispatch(
                        'signupAdvisor/createTaxAdvisorRegistration',
                        payload
                    )
                }
            )
        },
        rulesAccName() {
            return [
                {
                    required: true,
                    message: this.$tc('i18n_advisor_basicInfo_taxName_required')
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_taxName_required'
                                    )
                                )
                            if (!regex.length100.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_taxName_length100'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesFurigana() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_basicInfo_furigana_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_furigana_required'
                                    )
                                )
                            if (!regex.length100.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_furigana_length100'
                                    )
                                )
                                return
                            }
                            if (!regex.fullwidthHiragana.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_furigana_fullwidthHiragana'
                                    )
                                )
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesTaxAdvisorNumber() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_basicInfo_certified_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_certified_required'
                                    )
                                )
                            if (!regex.length20.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_certified_length20'
                                    )
                                )
                                return
                            }
                            if (!regex.halfwidthNumber.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_basicInfo_certified_onlyNumber'
                                    )
                                )
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesTaxGroup() {
            return [
                {
                    required: true,
                    message: this.$tc('i18n_advisor_basicInfo_group_required')
                }
            ]
        }
    }
})
