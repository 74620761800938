
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue, { PropType } from 'vue'
import {
    CategoryInterviewSelect,
    TitleFormInterviewItem
} from '@/modules/advisor/modules/signup/shared/contants'
import { ImagePositionInterview } from '@/modules/advisor/modules/signup/shared/enums'
import { ViewMode } from '@/shared/enums'
import {
    customUrlImage,
    filterStatusTable,
    getRawHTML
} from '@/shared/function'
type imageType = {
    name: string
    value: string
}
export default Vue.extend({
    name: 'InterviewInformationPreviewItem',
    props: {
        data: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        listImage: {
            type: Array as PropType<imageType[]>
        }
    },
    data() {
        return {
            TitleFormInterviewItem,
            ImagePositionInterview,
            ViewMode,
            CategoryInterviewSelect
        }
    },
    computed: {
        formatURLImage(): string {
            if (
                this.listImage &&
                this.listImage[0].value &&
                this.data?.image === ''
            ) {
                return this.listImage[0].value
            }
            return this.data?.image ?? ''
        }
    },
    methods: {
        getRawHTML,
        customUrlImage,
        categoryTitle(order: number, status: number) {
            if (!status) {
                return `項目${order}の選択内容を表示`
            }
            const listTitleCategory = CategoryInterviewSelect.filter(
                item => item.order === order
            )
            return filterStatusTable(listTitleCategory, status)
        }
    }
})
