enum ImagePositionInterview {
    LEFT = 1,
    RIGHT = 2
}

enum OrderInterviewItem {
    FIRST = 1,
    SECOND = 2,
    TUESDAY = 3,
    FOURTH = 4
}

enum OrderSampleItem {
    FIRST = 1,
    SECOND = 2,
    TUESDAY = 3
}

enum ValueCheckbox {
    ALL = 0
}

enum IntroductionFlg {
    FALSE = 0,
    TRUE = 1
}

export {
    ImagePositionInterview,
    OrderInterviewItem,
    OrderSampleItem,
    ValueCheckbox,
    IntroductionFlg
}
