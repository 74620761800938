import {
    ITypeListNavigateMenu,
    ITypeListTitleHeader,
    ITypeSelectCustom
} from '@/shared/constants/IContants'
import { ListNamePopup, ValueStatusProvider, ViewMode } from '@/shared/enums'
import { TypeTabRegister } from '@/modules/advisor/shared/contants'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { ITypeColumnsTableCustom } from '@/modules/admin/modules/admin-management/shared/constants/IConstants'
import { nameRouterProvider } from '@/shared/constants'

export const menuProvider: ITypeListNavigateMenu[] = [
    {
        title: '基本情報管理',
        status: ValueStatusProvider.TEMPORARY_REGISTRATION,
        icon: 'icon-user2',
        isOpen: true,
        subMenu: [
            {
                title: '',
                to: '',
                status: ValueStatusProvider.TEMPORARY_REGISTRATION,
                subMenu: [
                    {
                        title: '登録情報',
                        to: RouterProviderUrl.basicInformations,
                        status: ValueStatusProvider.TEMPORARY_REGISTRATION,
                        icon: 'icon-note-ol'
                    }
                ]
            }
        ]
    },
    {
        title: '基本情報管理',
        status: 0,
        icon: 'icon-user2',
        isOpen: false,
        subMenu: [
            {
                title: '・プロフィール登録・編集',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: '基本情報',
                        to: RouterProviderUrl.basicInformations,
                        status: 0,
                        icon: 'icon-note-ol'
                    },
                    {
                        title: '事務所情報',
                        to: RouterProviderUrl.officeInformations,
                        status: 0,
                        icon: 'icon-build-ol'
                    },
                    {
                        title: '成約事例',
                        to: RouterProviderUrl.contractInformations,
                        status: 0,
                        icon: 'icon-flag-ol'
                    },
                    {
                        title: '動画プロモーション',
                        to: RouterProviderUrl.promotions,
                        status: 0,
                        icon: 'movie-bk-ol'
                    }
                ]
            },
            {
                title: '・アカウント登録・編集',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: 'パスワード変更',
                        namePopup: ListNamePopup.ChangePassword,
                        status: 0,
                        icon: 'unlock-bk-ol'
                    },
                    {
                        title: 'メールアドレス変更',
                        namePopup: ListNamePopup.ChangeEmail,
                        status: 0,
                        icon: 'mail3-bk-ol'
                    },
                    {
                        title: '署名設定',
                        namePopup: ListNamePopup.CreateSignature,
                        status: 0,
                        icon: 'note4-bk-ol'
                    },
                    {
                        title: '受信用メールアドレス登録•編集',
                        namePopup: ListNamePopup.MailListProvider,
                        status: 0,
                        icon: 'mail4-bk-ol'
                    }
                ]
            }
        ]
    },
    {
        title: '商材管理',
        status: 0,
        icon: 'icon-book',
        isOpen: false,
        subMenu: [
            {
                title: '',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: '商材登録・編集・進捗',
                        to: RouterProviderUrl.products,
                        name: 'countInquiryProductProvider',
                        status: 0,
                        icon: 'book-bk-ol'
                    }
                ]
            }
        ]
    },
    {
        title: '個人•法人•税理士からの問合せ',
        name: 'countIncomings',
        status: 0,
        icon: 'icon-mail2',
        to: RouterProviderUrl.incoming
    },
    {
        title: '他社商材検索',
        status: 0,
        icon: 'icon-search',
        to: RouterProviderUrl.providerSearch
    },
    {
        title: 'アクセス・レビュー情報',
        status: 0,
        icon: 'icon-chart',
        to: RouterProviderUrl.countAccess
    },
    {
        title: '有料会員費支払い履歴',
        status: 0,
        icon: 'icon-ribbon',
        to: RouterProviderUrl.paymentHistory
    },
    {
        title: '管理者への問合せ',
        status: 0,
        icon: 'icon-marketing2',
        name: 'countInquiryProviders',
        to: RouterProviderUrl.inquiry
    },
    {
        title: '運営からのお知らせ',
        status: 0,
        icon: 'icon-paper-plane2',
        name: 'countAnnouncementProviders',
        to: RouterProviderUrl.announcement
    }
]

export const listDataTabProvider = [
    {
        title: '基本情報',
        name: TypeTabRegister.basicInfo,
        path: RouterProviderUrl.basicInformations,
        icon: 'icon-note',
        width: '25'
    },
    {
        title: '事業所情報',
        name: TypeTabRegister.office,
        path: RouterProviderUrl.officeInformations,
        icon: 'icon-buil',
        width: '25'
    },
    {
        title: '成約事例',
        name: TypeTabRegister.contract,
        path: RouterProviderUrl.contractInformations,
        icon: 'icon-man',
        width: '25'
    },
    {
        title: '動画プロモーション',
        name: TypeTabRegister.videoPromotion,
        path: RouterProviderUrl.promotions,
        icon: 'icon-movie',
        width: '25'
    }
]

export const OPTION_CHOOSE_TYPE = [
    {
        value: 1,
        label: '紹介有り'
    },
    {
        value: 0,
        label: '紹介無し'
    }
]

export const listTitleHeaderProvider: ITypeListTitleHeader[] = [
    {
        title: '基本情報管理',
        name: nameRouterProvider.basicInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterProvider.officeInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterProvider.contractInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterProvider.promotions,
        icon: 'icon-user'
    },
    {
        title: '管理者への問合せ',
        name: nameRouterProvider.inquiry,
        icon: 'icon-marketing'
    },
    {
        title: '問合せ登録',
        name: nameRouterProvider.inquiryCreate,
        icon: 'icon-marketing'
    },
    {
        title: '問合せ詳細',
        name: nameRouterProvider.inquiryDetail,
        icon: 'icon-marketing'
    },
    {
        title: '運営からのお知らせ',
        name: nameRouterProvider.announcement,
        icon: 'icon-paper-plane'
    },
    {
        title: '運営からのお知らせ',
        name: nameRouterProvider.announcementDetail,
        icon: 'icon-paper-plane'
    },
    {
        title: '商材管理',
        name: nameRouterProvider.products,
        icon: 'icon-book'
    },
    {
        title: '商材プレビュー',
        name: nameRouterProvider.productsPreview,
        icon: 'icon-book'
    },
    {
        title: '商材管理',
        name: nameRouterProvider.productManagement,
        icon: 'icon-book'
    },
    {
        title: '商材管理',
        name: nameRouterProvider.productInquiry,
        icon: 'icon-book'
    },
    {
        title: '商材管理',
        name: nameRouterProvider.productProgress,
        icon: 'icon-book'
    },
    {
        title: '商材登録・編集',
        name: nameRouterProvider.productCreate,
        icon: 'icon-marketing'
    },
    {
        title: '個人・法人・税理士からの問合せ',
        name: nameRouterProvider.incoming,
        icon: 'icon-mail'
    },
    {
        title: '個人・法人・税理士からの問合せ',
        name: nameRouterProvider.incomingDetail,
        icon: 'icon-mail'
    },
    {
        title: '個人・法人・税理士からの問合せ',
        name: nameRouterProvider.incomingPreview,
        icon: 'icon-mail'
    },
    {
        title: '有料会員登録',
        name: nameRouterProvider.membershipRegist,
        icon: 'icon-user'
    },
    {
        title: '無料会員変更',
        name: nameRouterProvider.membershipRegistFree,
        icon: 'icon-user'
    },
    {
        title: '商材登録・編集',
        name: nameRouterProvider.productDetail,
        icon: 'icon-marketing'
    },
    {
        title: 'アクセス・レビュー情報',
        name: nameRouterProvider.countAccess,
        icon: 'icon-chart'
    },
    {
        title: '有料会員費支払い履歴',
        name: nameRouterProvider.paymentHistory,
        icon: 'icon-ribbon'
    },
    {
        title: '他社商材検索',
        name: nameRouterProvider.providerSearchDetail,
        icon: 'icon-search'
    },
    {
        title: '他社商材検索',
        name: nameRouterProvider.providerFavorite,
        icon: 'icon-search'
    },
    {
        title: '他社商材検索',
        name: nameRouterProvider.providerSearch,
        icon: 'icon-search'
    },
    {
        title: '他社商材検索',
        name: nameRouterProvider.searchResult,
        icon: 'icon-search'
    }
]

export const TYPE_INTRO_FLAG = {
    show: 1,
    hide: 0
}

const ColumnsListMail: ITypeColumnsTableCustom = [
    {
        title: 'メール種別・名前',
        dataIndex: 'type',
        key: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: '受信可否',
        dataIndex: 'reciever_flg',
        key: 'reciever_flg',
        scopedSlots: { customRender: 'reciever_flg' }
    }
]
const ColumnsListMailAdditional: ITypeColumnsTableCustom = [
    {
        title: '',
        dataIndex: 'type',
        key: 'type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: '',
        dataIndex: 'reciever_flg',
        key: 'reciever_flg',
        scopedSlots: { customRender: 'reciever_flg' }
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        scopedSlots: { customRender: 'delete' }
    }
]

const PublicInfoContractSelect: ITypeSelectCustom[] = [
    { label: '公開', value: ViewMode.PUBLIC },
    { label: '非公開', value: ViewMode.PRIVATE }
]

const TabRegisterPreview = {
    office: 'OfficeInformationPreviewProvider',
    product: 'ProductInfo',
    contract: 'ContractPreview',
    map: 'MapInformationPreview'
}

const TabRegisterPreviewNumber = {
    office: 1,
    product: 2,
    contract: 3,
    map: 4
}

const TabPreviewSubmitProvider = {
    basic: 'basicInfo',
    office: 'officeInfo',
    contract: 'listContract',
    promotion: 'promotionVideo'
}

export {
    ColumnsListMail,
    ColumnsListMailAdditional,
    PublicInfoContractSelect,
    TabRegisterPreview,
    TabPreviewSubmitProvider,
    TabRegisterPreviewNumber
}
