
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import { isObject, customUrlProfileImage } from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ShowImageProviderPreview',
    data() {
        return {
            listNameInputImage: [],
            checkShowImage: true,
            isMobile: false
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize)
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getDataPreviewProvider',
            'getPromotionImage'
        ]),
        ...mapGetters('providerAdmin', ['getPromotionImageAdmin'])
    },
    mounted() {
        if (this.getDataPreviewProvider.isAdmin) {
            const { query } = this.$route
            if (!isObject(query)) {
                return
            }
            this.$store.dispatch(
                'providerAdmin/getPromotionImageInfoProviderAdmin',
                query
            )

            return
        }

        this.$store.dispatch('signupProvider/getPromotionImageInfoProvider')
    },
    methods: {
        customUrlProfileImage,
        renderData(value: any) {
            if (value && isObject(value)) {
                this.listNameInputImage = value.map((obj: any) => {
                    let image = obj?.image1
                    if (obj?.image1 === '' || obj?.image1 === null) {
                        image = obj?.image2
                    }
                    if (image === '' || image === null) {
                        image = obj?.image3
                    }

                    if (image === null) image = ''

                    return image
                })
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 769
        }
    },
    watch: {
        getPromotionImage: {
            handler(valueNew) {
                if (valueNew.length === 0) {
                    this.checkShowImage = false
                    return
                }
                this.renderData(valueNew)
            },
            immediate: true
        },
        getPromotionImageAdmin: {
            handler(valueNew) {
                if (valueNew.length === 0) {
                    this.checkShowImage = false
                    return
                }
                this.checkShowImage = true
                this.renderData(valueNew)
            },
            immediate: true
        },
        $route: {
            handler(value: any) {
                this.onResize()
                window.addEventListener('resize', this.onResize)
            },
            deep: true,
            immediate: true
        }
    }
})
