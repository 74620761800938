
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isArray, isObject } from '@/shared/function'
import ProductDetailComponent from '@/modules/provider/modules/product/components/detail/ProductDetail.vue'

export default Vue.extend({
    name: 'ProductPreview',
    data(): any {
        return {
            listProducts: {},
            keyComponent: 0
        }
    },
    components: { ProductDetailComponent },
    computed: {
        ...mapGetters('productProvider', ['getDataProductFull']),
        ...mapGetters('providerAdmin', ['getDataProductFullProviderAdmin']),
        ...mapGetters('signupProvider', ['getDataProvider'])
    },
    watch: {
        getDataProductFull: {
            handler(newVal, oldVal) {
                if (isObject(newVal) && newVal !== oldVal) {
                    this.listProducts = newVal
                }
            },
            immediate: true
        },
        getDataProductFullProviderAdmin: {
            handler(newVal, oldVal) {
                if (isObject(newVal) && newVal !== oldVal) {
                    this.listProducts = newVal
                }
            },
            immediate: true
        }
    },
    methods: {
        isArray
    }
})
