
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import Title from '@/components/common_new/popup/title.vue'
import { ListNamePopup } from '@/shared/enums'

export default Vue.extend({
    name: 'IntroCode',
    components: { ButtonCustom, Title },
    data() {
        return {
            formDelete: this.$form.createForm(this, {
                name: 'form_stop'
            }),
            officeContractName: '',
            btnSubmit: false
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        this.$set(
            this,
            'officeContractName',
            this.getParamsPopup.intro_office_name
        )
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            this.btnSubmit = true
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.IntroCode,
                payload: {
                    ...this.getParamsPopup,
                    isBtnPopupSubmit: true
                }
            })
        }
    }
})
