
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
    apiEmbedMap,
    defaultAddressMap
} from '@/modules/advisor/modules/signup/shared/contants'
import { regex } from '@/shared/regex'
import ButtonCustom from '@/components/common/button/index.vue'

export default Vue.extend({
    name: 'MapInformationPreview',
    components: { ButtonCustom },
    data() {
        return {
            basicInfo: {} as any,
            officeInfo: {} as any,
            isRegistration: true,
            apiEmbedMap
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getDataProvider',
            'getBaseOffice',
            'getDataPreviewProvider'
        ]),
        ...mapGetters('providerAdmin', ['getDataBasicInfoProviderAdmin']),
        fullAddress(): string {
            if (Object.keys(this.basicInfo).length !== 0) {
                return (
                    (this.basicInfo?.address ?? '') +
                    (this.basicInfo?.house_number ?? '') +
                    (this.basicInfo?.building_name ?? '')
                )
            }
            return ''
        },
        apiAddress(): any {
            return (
                encodeURI(
                    this.fullAddress.toString().replace(regex.andCharacter, '')
                ) || defaultAddressMap
            )
        }
    },
    mounted() {
        this.basicInfo = this.getDataPreviewProvider.basicInfo ?? {
            ...this.getDataProvider.provider_basic_info,
            username: this.getDataProvider.username
        }

        if (this.getDataPreviewProvider.isAdmin) {
            this.officeInfo = this.getDataOfficeInfoProviderAdmin
            this.basicInfo = {
                ...this.getDataBasicInfoProviderAdmin.provider_basic_info,
                username: this.getDataBasicInfoProviderAdmin.username
            }
        }
    },
    methods: {
        async openMap() {
            // TODO: Open map will be implement later
            // const data = {
            //     office_id: this.getDataGuest.tax_advisor_basic_infos?.id
            // }
            // const res = await this.$store.dispatch(
            //     'searchAdvisor/getLatLongOpenMap',
            //     data
            // )
            // if (res) {
            //     window.open(`https://www.google.com/maps/place/${res}`)
            // }
        }
    }
})
