
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { logoutTime } from '@/shared/constants'
import AuthService from '@/modules/auth/services'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { clearLoginStorage } from '@/shared'
import { NotificationCustom } from '@/shared/function'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

export default Vue.extend({
    name: 'AutoLogout',
    data() {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
            userActivityTimeout: 0 as number
        }
    },
    mounted() {
        this.resetUserActivityTimeout()
        this.events.forEach((event: string) => {
            window.addEventListener(event, this.resetUserActivityTimeout)
        }, this)
    },
    beforeDestroy() {
        this.events.forEach((event: string) => {
            window.removeEventListener(event, this.resetUserActivityTimeout)
        }, this)
        clearTimeout(this.userActivityTimeout)
    },
    methods: {
        resetUserActivityTimeout() {
            clearTimeout(this.userActivityTimeout)
            this.userActivityTimeout = setTimeout(() => {
                this.inactiveUserAction()
            }, logoutTime)
        },
        async inactiveUserAction() {
            this.$store.commit('popup/setLoading', true, { root: true })
            try {
                const res: any = await AuthService.apiLogout()
                const { status, data }: any = res
                if (status === HttpStatusCode.ok) {
                    clearLoginStorage()
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: data.message
                    })
                    await this.$router.push(RouterAdvisorUrl.signin)
                    return
                }
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            } catch (e) {
            } finally {
                this.$store.commit('popup/setLoading', false, { root: true })
            }
        }
    }
})
