import pageTitle from '@/store/page-title'
import popup from '@/store/popup'
import signupAdvisor from '@/modules/advisor/modules/signup/store'
import signupProvider from '@/modules/provider/modules/signup/store'
import inquiryAdvisor from '@/modules/advisor/modules/inquiry/store'
import consultAdvisor from '@/modules/advisor/modules/consult/store'
import announcementAdvisor from '@/modules/advisor/modules/announcement/store'
import signupAuth from '@/modules/auth/store'
import myPageAdvisor from '@/modules/advisor/modules/mypage/store'
import loading from '@/store/loading'
import advisorAdmin from '@/modules/admin/modules/advisors/store'
import invitationAdvisor from '@/modules/advisor/modules/invitation/store'
import productSearch from '@/modules/advisor/modules/product-search/store'
import advisorInquiryProvider from '@/modules/advisor/modules/product-inquiry/store'
import advisorDataProvider from '@/modules/advisor/modules/provider/store'
import countAccessAdvisor from '@/modules/advisor/modules/countAccess/store'
import conciergeAdvisor from '@/modules/advisor/modules/concierge/store'

export const storeAdvisor = {
    popup,
    signupAdvisor,
    signupAuth,
    inquiryAdvisor,
    consultAdvisor,
    announcementAdvisor,
    myPageAdvisor,
    loading,
    advisorAdmin,
    signupProvider,
    invitationAdvisor,
    pageTitle,
    productSearch,
    advisorInquiryProvider,
    advisorDataProvider,
    countAccessAdvisor,
    conciergeAdvisor
}
