import apiServices from '@/shared/services/api/axios'
import { IPayloadCreateAnnounce } from '../shared/@types'

const AnnouncementService = {
    apiCreateAnnounce(payload: IPayloadCreateAnnounce) {
        return apiServices.post('/admin/create-announcement', payload)
    },
    apiGetListAnnouncement(params: any) {
        return apiServices.get('/admin/list-announcement', { params })
    },
    apiGetDetailAnnouncement(params: any) {
        return apiServices.get('admin/detail-announcement', { params })
    },
    apiUpdateAnnouncement(payload: IPayloadCreateAnnounce) {
        return apiServices.post('/admin/update-announcement', payload)
    }
}
export default AnnouncementService
