import apiServices from '@/shared/services/api/axios'
import { ITypeStatusAdvisor } from '../shared/@types'

type IParamStopAdvisor = {
    id: string
}

const AdvisorAdminService = {
    apiGetAdvisors(payload: any) {
        let url = '/admin/list-tax-advisor'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },
    apiStopAdvisor(payload: IParamStopAdvisor) {
        return apiServices.post('/admin/stopping-advisor', payload)
    },
    apiUnStopAdvisor(payload: IParamStopAdvisor) {
        return apiServices.post('/admin/un-stopping-advisor', payload)
    },
    apiDeleteAdvisor(payload: IParamStopAdvisor) {
        return apiServices.post('/admin/delete-advisor', payload)
    },
    apiGetBasicInfoAdvisor(payload: IParamStopAdvisor) {
        return apiServices.get(`/admin/basic-info?id=${payload.id}`)
    },
    apiGetSampleFeesAdvisor(payload: IParamStopAdvisor) {
        return apiServices.get(`/admin/fee?id=${payload.id}`)
    },
    apiGetInfoAffiliateAdmin(payload: IParamStopAdvisor) {
        return apiServices.get(`/admin/info?id=${payload.id}`)
    },
    apiListTaxAdvisor() {
        return apiServices.get('/admin/tax_group')
    },
    apiInfoPromotionVideo(payload: IParamStopAdvisor) {
        return apiServices.get(`/admin/tax-promotion-video?id=${payload.id}`)
    },
    apiGetOfficeInfoAdvisor(payload: IParamStopAdvisor) {
        return apiServices.get(`/admin/office-info?id=${payload.id}`)
    },
    apiGetInterviewAdvisor(id: number) {
        return apiServices.get(`/admin/get-interview-tax/${id}`)
    },
    apiUpdateStatusAdvisor(payload: ITypeStatusAdvisor) {
        return apiServices.post(
            `/admin/update-status-advisor/${payload.id}`,
            payload.dataUpdate
        )
    }
}
export default AdvisorAdminService
