import { copyDeep } from '@/shared'
import {
    IPayloadCreateInquiryAdvisor,
    IStateStoreInquiryAdvisor
} from '@/modules/advisor/modules/inquiry/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import paymentProviderService from '@/modules/provider/modules/payment/services'

export const defaultStateInquiryAdmin: IStateStoreInquiryAdvisor = {
    data: {}
}

const state = () => copyDeep(defaultStateInquiryAdmin)

const getters = {
    getDataPaymentHistoryProvider(state: IStateStoreInquiryAdvisor) {
        return state.data
    }
}

const mutations = {
    setDataPaymentHistoryProvider(
        state: IStateStoreInquiryAdvisor,
        payload: any
    ) {
        state.data = payload
    }
}

const actions = {
    async getDataHistoryProvider(
        { commit }: any,
        payload: IPayloadCreateInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await paymentProviderService.apiGetPaymentHistoryProvider(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataPaymentHistoryProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
