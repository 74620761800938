
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
export default Vue.extend({
    name: 'ConfirmSubmitRejectProvider',
    components: { ButtonCustom, TitlePopup },
    data() {
        return {
            titlePopup: '',
            descriptionPopup: '送信しますか？',
            formDelete: this.$form.createForm(this, {
                name: 'form_stop'
            })
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            await this.$store.dispatch(
                'providerAdmin/sendBackProvider',
                this.getParamsPopup
            )
        }
    }
})
