enum RouterAdvisorUrl {
    signin = '/signin',
    signup = '/signup',
    myPage = '/my-page/:token?',
    myPageNoToken = '/my-page',
    passwordReset = '/password-reset',
    resetPassword = '/reset-password/:token',
    basicInformations = '/basic-informations',
    officeInformations = '/office-informations',
    affiliateAdvisor = '/affiliate-advisor',
    sampleFees = '/sample-fees',
    promotions = '/promotions',
    inquiry = '/inquiry',
    inquiryCreate = '/inquiry/create',
    inquiryDetail = '/inquiry/detail/',
    consult = '/consult-request-list',
    consultDetail = '/consult-request-detail/',
    consultResponse = '/consult-response/',
    consultRequestDetail = '/consult-request-detail/',
    consultPreview = '/consult-request/preview',
    announcement = '/announcement',
    announcementDetail = '/announcement/detail/',
    interview = '/interview',
    invitation = '/invitation',
    invitationCreate = '/invitation/create',
    membershipRegist = '/membership-regist',
    productSave = '/product-save',
    productInquiry = '/product-inquiry',
    productSearch = '/product-search',
    providerDetail = '/provider/',
    productSearchDetail = '/product-search/',
    searchResult = '/search-result',
    productProgress = '/product-progress/',
    providerInquiry = '/product-inquiry/detail/',
    countAccessAdvisor = '/count-access-advisor',
    concierge = '/concierge',
    conciergeCreate = '/concierge/create',
    conciergeDetail = '/concierge/detail/',
    officeConciergeAdvisor = '/office-concierge-advisor',
    providerIntroductionService = '/provider-introduction-service'
}

enum StatusPublicImage {
    PUBLIC = 1,
    PRIVATE = 2
}

enum ConcatConditionQuery {
    FIRST = 0,
    SECOND = 1,
    THIRD = 2,
    FOURTH = 3,
    FIFTH = 4,
    SIXTH = 5
}

enum IdAddress {
    Kanto = '1'
}

export { RouterAdvisorUrl, StatusPublicImage, ConcatConditionQuery, IdAddress }
