import { copyDeep } from '@/shared'
import { IStateStoreConciergeAdminFQA } from '@/modules/adminFQA/modules/inquiry/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import router from '@/router'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import ConciergeAdminFQAService from '@/modules/adminFQA/modules/concierge/services'
import {
    IPayloadReplyConciergeFqa,
    IPayloadSearchConciergeFqa,
    IPayloadUpdateStatusConcierge
} from '@/modules/adminFQA/modules/concierge/shared/@types'
import { StatusReadConciergeFqa } from '@/modules/adminFQA/modules/concierge/shared/enums'
import { ITypePayloadAnswerConciergeAdvisor } from '@/modules/advisor/modules/concierge/shared/@types'

export const defaultStateConciergeAdminFqa: IStateStoreConciergeAdminFQA = {
    data: {},
    previewConcierge: '',
    payloadReplyConcierge: {}
}

const state = () => copyDeep(defaultStateConciergeAdminFqa)

const getters = {
    getDataConciergeFqa(state: IStateStoreConciergeAdminFQA) {
        return state.data
    },
    getPreviewConciergeFqa(state: IStateStoreConciergeAdminFQA) {
        return state.previewConcierge
    },
    getPayloadReplyConcierge(state: IStateStoreConciergeAdminFQA) {
        return state.payloadReplyConcierge
    }
}

const mutations = {
    setDataConciergeAdminFqa(
        state: IStateStoreConciergeAdminFQA,
        payload: any
    ) {
        state.data = payload
    },
    setPreviewConciergeAdminFqa(
        state: IStateStoreConciergeAdminFQA,
        payload: any
    ) {
        state.previewConcierge = payload
    },
    setPayloadReplyConcierge(
        state: IStateStoreConciergeAdminFQA,
        payload: any
    ) {
        state.payloadReplyConcierge = payload
    }
}

const actions = {
    async getDataConciergeAdminFqa(
        { commit }: any,
        payload: IPayloadSearchConciergeFqa
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConciergeAdminFQAService.apiGetConcierge(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeAdminFqa', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailConcierge({ commit, dispatch }: any, id: number) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConciergeAdminFQAService.apiGetConciergeDetail(id)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeAdminFqa', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            router.push(RouterAdminFQA.concierge)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailConciergeByAdvisor(
        { commit, dispatch }: any,
        params: any
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, {
            root: true
        })
        try {
            const res =
                await ConciergeAdminFQAService.apiGetConciergeDetailByAdvisor(
                    params
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeAdminFqa', data.data)
                dispatch('signupAuth/countNotifyAdmin', true, { root: true })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return router.push(RouterAdminFQA.concierge)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async createConciergeReplyAdvisor(
        { commit, dispatch }: any,
        payload: ITypePayloadAnswerConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConciergeAdminFQAService.apiCreateConciergeReplyAdvisor(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataDetailConciergeByAdvisor', {
                    id: payload?.concierge_id
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return router.push(RouterAdminFQA.concierge)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async replyDragConcierge(
        { commit }: any,
        payload: IPayloadReplyConciergeFqa
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConciergeAdminFQAService.apiReplyDragConciergeAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async replyConcierge({ commit }: any, payload: IPayloadReplyConciergeFqa) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConciergeAdminFQAService.apiReplyConciergeAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return data
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusConciergeById(
        { commit, dispatch, state }: any,
        payload: IPayloadUpdateStatusConcierge
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConciergeAdminFQAService.apiUpdateStatusConciergeAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                if (
                    state.data.status !== StatusReadConciergeFqa.NOT_READ &&
                    state.data.status !== StatusReadConciergeFqa.NEW
                ) {
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: '状況を更新しました。'
                    })
                }
                if (payload.status === StatusReadConciergeFqa.CONTACTED) {
                    dispatch('getDataDetailConcierge', payload.id)
                }
                dispatch('signupAuth/countNotifyAdmin', true, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async previewConciergeAdminFqa(
        { commit, dispatch, state }: any,
        payload: IPayloadReplyConciergeFqa
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConciergeAdminFQAService.apiPreviewConciergeAdminFqa(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setPreviewConciergeAdminFqa', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return data
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
