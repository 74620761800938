import { RouteConfig } from 'vue-router'
import AboutView from '@/views/AboutView.vue'
import auth from '@/middleware/auth'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import { favicon, nameRouterAdmin, titlePage } from '@/shared/constants'

export const routesAdmin: Array<RouteConfig> = [
    {
        path: '/',
        name: nameRouterAdmin.about,
        redirect: '/my-page',
        component: AboutView
    },
    {
        path: RouterAdminUrl.signin,
        name: nameRouterAdmin.signin,
        component: () => import('../views/admin/signin/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.passwordReset,
        name: nameRouterAdmin.passwordReset,
        component: () => import('../views/advisor/password-reset/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.resetPassword,
        name: nameRouterAdmin.resetPassword,
        component: () => import('../views/reset-password/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.advisors,
        name: nameRouterAdmin.advisors,
        component: () => import('../views/admin/advisors/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.basicInformations,
        name: nameRouterAdmin.basicInformations,
        component: () =>
            import('../views/admin/advisors/signup/basic-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.officeInformations,
        name: nameRouterAdmin.officeInformations,
        component: () =>
            import(
                '../views/admin/advisors/signup/office-information/index.vue'
            ),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.affiliateAdvisor,
        name: nameRouterAdmin.affiliateAdvisor,
        component: () =>
            import(
                '../views/admin/advisors/signup/affiliate-advisor/index.vue'
            ),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.sampleFees,
        name: nameRouterAdmin.sampleFees,
        component: () =>
            import('../views/admin/advisors/signup/sample-fees/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.promotions,
        name: nameRouterAdmin.promotions,
        component: () =>
            import('../views/admin/advisors/signup/promotions-video/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.myPageDetail,
        name: nameRouterAdmin.myPageDetail,
        component: () => import('../views/admin/my-page/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.feeManagement,
        name: nameRouterAdmin.feeManagement,
        component: () => import('../views/admin/fee-management/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.myPageProviderDetail,
        name: nameRouterAdmin.myPageProviderDetail,
        component: () =>
            import('../views/admin/my-page/provider/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.myPage,
        name: nameRouterAdmin.myPage,
        component: () => import('../views/admin/my-page/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.countAccess + '/:id',
        name: nameRouterAdmin.countAccess,
        component: () => import('../views/admin/count-access/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.adminManagement,
        name: nameRouterAdmin.adminManagement,
        component: () => import('../views/admin/admin-management/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.registerAdmin,
        name: nameRouterAdmin.registerAdmin,
        component: () =>
            import('../views/admin/admin-management/CreateAdmin/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.updateAdmin,
        name: nameRouterAdmin.updateAdmin,
        component: () =>
            import('../views/admin/admin-management/updateAdmin/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.announcement,
        name: nameRouterAdmin.announcement,
        component: () => import('../views/admin/announcement/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.announcementCreate,
        name: nameRouterAdmin.announcementCreate,
        component: () => import('../views/admin/announcement/create/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },

    {
        path: RouterAdminUrl.announcementEdit + '/:id',
        name: nameRouterAdmin.announcementEdit,
        component: () => import('../views/admin/announcement/edit/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.announcementDetail + '/:id',
        name: nameRouterAdmin.announcementDetail,
        component: () => import('../views/admin/announcement/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.announcementPreview,
        name: nameRouterAdmin.announcementPreview,
        component: () => import('../views/admin/announcement/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.inquiry,
        name: nameRouterAdmin.inquiry,
        component: () => import('../views/adminFqa/inquiry/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.interview,
        name: nameRouterAdmin.interview,
        component: () =>
            import('../views/admin/advisors/signup/interview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersTemp,
        name: nameRouterAdmin.providersTemp,
        component: () => import('../views/admin/providers/temporary/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal,
            query: {
                type: 'temp'
            }
        }
    },

    {
        path: RouterAdminUrl.providersOfficial,
        name: nameRouterAdmin.providersOfficial,
        component: () => import('../views/admin/providers/official/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal,
            query: {
                type: 'official'
            }
        }
    },
    {
        path: RouterAdminUrl.providersBasic,
        name: nameRouterAdmin.providersBasic,
        component: () =>
            import('../views/admin/providers/signup/basic-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersOffice,
        name: nameRouterAdmin.providersOffice,
        component: () =>
            import('../views/admin/providers/signup/office-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersContract,
        name: nameRouterAdmin.providersContract,
        component: () =>
            import('../views/admin/providers/signup/contract-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersVideoPromotion,
        name: nameRouterAdmin.providersVideoPromotion,
        component: () =>
            import('../views/admin/providers/signup/promotion-video/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersProduct,
        name: nameRouterAdmin.providersProduct,
        component: () =>
            import('../views/admin/providers/signup/product-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.providersProductDetail,
        name: nameRouterAdmin.providersProductDetail,
        component: () =>
            import(
                '../views/admin/providers/signup/product-info/detail/index.vue'
            ),
        meta: {
            middleware: auth,
            layout: 'LayoutProductDetail',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.invitationProviderAdmin,
        name: nameRouterAdmin.invitationProviderAdmin,
        component: () => import('../views/admin/invitation/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.invitationProviderDetailAdmin + '/:id',
        name: nameRouterAdmin.invitationProviderDetailAdmin,
        component: () => import('../views/admin/invitation/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.settings,
        name: nameRouterAdmin.settings,
        component: () => import('../views/admin/settings/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.notFound,
        name: nameRouterAdmin.notFound,
        component: () => import('../views/404.vue')
    }
]
