import {
    ITypeListNavigateMenu,
    ITypeListTitleHeader
} from '@/shared/constants/IContants'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { nameRouterAdmin } from '@/shared/constants'

const menuAdmin: ITypeListNavigateMenu[] = [
    {
        title: '各種設定',
        status: 0,
        icon: 'icon-gear',
        isOpen: false,
        subMenu: [
            {
                title: 'メンバー管理',
                to: RouterAdminUrl.adminManagement,
                status: 0,
                icon: 'icon-user'
            },
            // {
            //     title: '設定条件変更',
            //     status: 0,
            //     namePopup: 'CreateSignature',
            //     icon: 'icon-note3'
            // },
            // {
            //     title: '事業区分管理',
            //     status: 0,
            //     namePopup: 'CreateSignature',
            //     icon: 'icon-buil'
            // },
            {
                title: 'サービス費用管理',
                status: 0,
                to: RouterAdminUrl.settings,
                icon: 'icon-wallet'
            },
            {
                title: '署名設定',
                status: 0,
                namePopup: 'CreateSignature',
                icon: 'icon-note2'
            }
        ]
    },
    {
        title: '税理士管理',
        to: RouterAdminUrl.advisors,
        status: 0,
        icon: 'icon-man'
    },
    {
        title: '事業者管理',
        status: 0,
        name: 'countAdminProviders',
        icon: 'icon-buil',
        isOpen: false,
        subMenu: [
            {
                title: '審査待ち一覧',
                to: RouterAdminUrl.providersTemp,
                status: 0,
                name: 'countAnnouncementAdminProviders',
                icon: 'icon-list'
            },
            {
                title: '事業者一覧',
                to: RouterAdminUrl.providersOfficial,
                status: 0,
                icon: 'icon-buil2'
            },
            {
                title: '紹介事業者',
                to: RouterAdminUrl.invitationProviderAdmin,
                status: 0,
                name: 'countIniviteAdmin',
                icon: 'icon-biz-prof'
            }
        ]
    },
    {
        title: 'お知らせ管理',
        to: RouterAdminUrl.announcement,
        status: 0,
        icon: 'icon-marketing'
    },
    {
        title: 'アクセス管理',
        to: '/my-page',
        status: 0,
        icon: 'icon-diagram'
    },
    {
        title: '会員費用管理',
        to: '/fee-management',
        status: 0,
        icon: 'icon-cost'
    },
    {
        title: 'パスワード変更',
        namePopup: 'ChangePassword',
        status: 0,
        icon: 'icon-lock'
    }
]

const listTitleHeaderAdmin: ITypeListTitleHeader[] = [
    {
        title: '税理士管理',
        name: nameRouterAdmin.advisors,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.basicInformations,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.officeInformations,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.affiliateAdvisor,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.sampleFees,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.promotions,
        icon: 'icon-man'
    },
    {
        title: '税理士管理',
        name: nameRouterAdmin.interview,
        icon: 'icon-man'
    },
    {
        title: '管理者編集',
        name: nameRouterAdmin.updateAdmin,
        icon: 'icon-gear'
    },
    {
        title: '管理者登録',
        name: nameRouterAdmin.registerAdmin,
        icon: 'icon-gear'
    },
    {
        title: '各種設定',
        name: nameRouterAdmin.adminManagement,
        icon: 'icon-gear'
    },
    {
        title: 'お知らせ管理',
        name: nameRouterAdmin.announcement,
        icon: 'icon-marketing'
    },
    {
        title: 'お知らせ管理',
        name: nameRouterAdmin.announcementCreate,
        icon: 'icon-marketing'
    },
    {
        title: 'お知らせ管理',
        name: nameRouterAdmin.announcementEdit,
        icon: 'icon-marketing'
    },
    {
        title: 'お知らせ管理',
        name: nameRouterAdmin.announcementDetail,
        icon: 'icon-marketing'
    },
    {
        title: 'アクセス管理',
        name: nameRouterAdmin.myPage,
        icon: 'icon-diagram'
    },
    {
        title: 'アクセス管理',
        name: nameRouterAdmin.myPageDetail,
        icon: 'icon-diagram'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersTemp,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersOfficial,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersBasic,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersOffice,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersContract,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersVideoPromotion,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.providersProduct,
        icon: 'icon-buil'
    },
    {
        title: '事業者管理',
        name: nameRouterAdmin.invitationProviderAdmin,
        icon: 'icon-buil'
    },
    {
        title: '紹介事業者',
        name: nameRouterAdmin.invitationProviderDetailAdmin,
        icon: 'icon-buil'
    },
    {
        title: '各種設定',
        name: nameRouterAdmin.settings,
        icon: 'icon-gear'
    },
    {
        title: '商材詳細',
        name: nameRouterAdmin.providersProductDetail,
        icon: 'icon-man'
    },
    {
        title: 'アクセス情報',
        name: nameRouterAdmin.countAccess,
        icon: 'icon-diagram'
    },
    {
        title: 'アクセス管理',
        name: nameRouterAdmin.myPageProviderDetail,
        icon: 'icon-diagram'
    },
    {
        title: '会員費用管理',
        name: nameRouterAdmin.feeManagement,
        icon: 'icon-diagram'
    }
]
export { menuAdmin, listTitleHeaderAdmin }
