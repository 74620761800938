import {
    ITypeListNavigateMenu,
    ITypeListTitleHeader
} from '@/shared/constants/IContants'
import { RouterAdminFQA } from '../enums'
import { taoyriLink } from '@/modules/advisor/shared/contants'
import { nameRouterFqa } from '@/shared/constants'

const menuAdminFQA: ITypeListNavigateMenu[] = [
    {
        title: '問合せ管理',
        status: 0,
        name: 'countInquiry',
        icon: 'icon-mail ',
        isOpen: false,
        subMenu: [
            {
                title: '税理士からの問合せ',
                to: RouterAdminFQA.inquiry + '?type=3',
                status: 0,
                name: 'countInquiriesTax',
                icon: 'icon-man'
            },
            {
                title: '個人・法人からの問合せ',
                to: RouterAdminFQA.inquiry + '?type=1',
                status: 0,
                name: 'countInquiriesGuest',
                icon: 'icon-mail'
            },
            {
                title: '事業者からの問合せ',
                to: RouterAdminFQA.inquiry + '?type=2',
                status: 0,
                name: 'countInquiriesProvider',
                icon: 'icon-building'
            }
        ]
    },
    {
        title: 'コンシェルジュ管理',
        to: RouterAdminFQA.concierge,
        status: 0,
        name: 'countConcierges',
        icon: 'icon-bellboy'
    },
    {
        title: 'FAQ管理',
        newWindowLink: taoyriLink.adminSignIn,
        status: 0,
        icon: 'icon-faq'
    },
    {
        title: '署名設定',
        namePopup: 'CreateSignature',
        status: 0,
        icon: 'icon-curvature'
    },
    {
        title: '管理者からのお知らせ',
        to: RouterAdminFQA.announcement,
        status: 0,
        icon: 'icon-megaphone'
    },
    {
        title: 'パスワード変更',
        namePopup: 'ChangePassword',
        status: 0,
        icon: 'icon-lock'
    }
]

const listTitleHeaderAdminFQA: ITypeListTitleHeader[] = [
    {
        title: '問合せ管理',
        name: nameRouterFqa.inquiry,
        icon: 'icon-mail'
    },
    {
        title: '問合せ詳細',
        name: nameRouterFqa.inquiryDetail,
        icon: 'icon-mail'
    },
    {
        title: '問合せ詳細',
        name: nameRouterFqa.inquiryPreview,
        icon: 'icon-mail'
    },
    {
        title: '管理者からのお知らせ',
        name: nameRouterFqa.announcement,
        icon: 'icon-megaphone'
    },
    {
        title: '管理者からのお知らせ',
        name: nameRouterFqa.announcementDetail,
        icon: 'icon-megaphone'
    },
    {
        title: '問合せ参照',
        name: nameRouterFqa.conciergePreview,
        icon: 'icon-bellboy'
    },
    {
        title: 'コンシェルジュ管理',
        name: nameRouterFqa.concierge,
        icon: 'icon-bellboy'
    },
    {
        title: 'コンシェルジュ管理',
        name: nameRouterFqa.conciergeDetailGuest,
        icon: 'icon-bellboy'
    },
    {
        title: '問合せ回答',
        name: nameRouterFqa.conciergeDetailAdvisor,
        icon: 'icon-bellboy'
    },
    {
        title: '商材検索',
        name: nameRouterFqa.productSearchFQA,
        icon: 'icon-bellboy'
    },
    {
        title: '商材検索',
        name: nameRouterFqa.resultSearchProduct,
        icon: 'icon-bellboy'
    },
    {
        title: '商材検索',
        name: nameRouterFqa.productSave,
        icon: 'icon-bellboy'
    },
    {
        title: '商材検索',
        name: nameRouterFqa.resultSearchProductDetail,
        icon: 'icon-bellboy'
    }
]

const specialPages: string[] = [
    '/inquiry/detail',
    '/inquiry/preview',
    '/concierge/preview',
    '/concierge/detail'
]

export { menuAdminFQA, listTitleHeaderAdminFQA, specialPages }
