import { IStateStoreAdminFreeManagement } from '@/modules/admin/modules/fee-management/shared/@types'
import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify, ListNamePopup } from '@/shared/enums'

import { NotificationCustom } from '@/shared/function'
import feeManagementService from '../services'

export const defaultStateAdminFreeManagement: IStateStoreAdminFreeManagement = {
    data: {},
    params: {}
}

const state = () => copyDeep(defaultStateAdminFreeManagement)

const getters = {
    getDataFreeManagement(state: IStateStoreAdminFreeManagement) {
        return state.data
    },
    getParamsFreeManagement(state: IStateStoreAdminFreeManagement) {
        return state.params
    }
}

const mutations = {
    setDataFreeManagement(state: IStateStoreAdminFreeManagement, payload: any) {
        state.data = payload
    },
    setParamsFreeManagement(
        state: IStateStoreAdminFreeManagement,
        payload: any
    ) {
        state.params = payload
    },
    setClearDataStore(state: IStateStoreAdminFreeManagement) {
        Object.assign(state, copyDeep(defaultStateAdminFreeManagement))
    }
}

const actions = {
    async getListFreeManagement({ commit }: any, params: any) {
        commit('popup/setLoading', true, { root: true })
        commit('setParamsFreeManagement', params)

        try {
            const res = await feeManagementService.apiGetListMembershipFee(
                params
            )
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataFreeManagement', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async showPopupConfirmMembershipFee({ commit, dispatch }: any, params: any) {
        try {
            const res = await feeManagementService.apiGetMembershipFeeById(params?.id)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                dispatch(
                    'popup/openPopup',
                    {
                        isOpen: true,
                        namePopup: ListNamePopup.confirmFeeManagement,
                        width: 500,
                        payload: {
                            id: data.data.id,
                            provider_name: data.data.provider_name,
                            payment: data.data.payment,
                            Applicable: data.data.exp_start_date,
                            billStatus: params?.billStatus
                        }
                    },
                    { root: true }
                )
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async confirmMembershipFee({ commit, dispatch }: any, params: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await feeManagementService.apiConfirmMembershipFee(params?.id)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('popup/hidePopup', {}, { root: true })
                dispatch('getListFreeManagement', { page: 1, bill_status: params?.billStatus })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    changeLoading({ commit }: any, payload: any) {
        commit('setLoading', payload)
    },
    resetParams({ commit }: any) {
        commit('setClearDataStore')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
