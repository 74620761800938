
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ITypeSelectCustom } from '@/shared/constants/IContants'
import {
    CheckboxProductGenre,
    CheckboxProductFeature
} from '@/modules/provider/modules/signup/shared/constants'
import { generateValueCheckbox } from '@/modules/provider/modules/signup/shared/functions'
import { isArray } from '@/shared/function'

export default Vue.extend({
    name: 'ProviderFeatureSelectPopup',
    components: { Title, ButtonCustom },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    data(): any {
        return {
            isCheckAll: false,
            indexDisabled: [],
            dataResults: [],
            finalResults: [],
            typeRouter: [],
            CheckboxProductGenre: [] as ITypeSelectCustom[],
            CheckboxProductFeature: [] as ITypeSelectCustom[],
            listGenre: [],
            listFeature: [],
            valueCheckbox: [],
            listCheckedGenre: []
        }
    },
    mounted() {
        for (const key in CheckboxProductGenre) {
            this.listGenre.push(CheckboxProductGenre[key])
        }
        if (this.getParamsPopup) {
            const { genre, product_feature } = this.getParamsPopup
            if (isArray(genre)) {
                genre.map((e: any) => {
                    let data = CheckboxProductGenre.filter(
                        (ev: any) => e === ev.value
                    )
                    if (data) {
                        this.listCheckedGenre.push(data[0])
                        this.indexDisabled.push(data[0].label)
                    }
                })
            }

            if (isArray(product_feature)) {
                product_feature.map((e: any) => {
                    let data = CheckboxProductFeature.filter(
                        (ev: any) => e === ev.value
                    )
                    if (data) {
                        this.dataResults.push(data[0])
                        this.indexDisabled.push(data[0].label)
                    }
                })
            }
        }
    },
    methods: {
        onCheckedProvinceParent(item: any): boolean {
            return this.indexDisabled.includes(item.label)
        },
        onCheckedProvinceChildren(genre: any, feature: any): boolean {
            return this.indexDisabled.includes(feature.label)
        },
        renderData(item: string) {
            const valueArray = item.split('|').map(Number)
            const labels = CheckboxProductFeature.filter(option =>
                valueArray.includes(option.value)
            )
            return labels
        },
        changeProvider(event: any) {
            this.processData(event)
        },

        processData(event: any) {
            // Remove province
            // this.listCheckedGenre = this.listCheckedGenre.filter(
            //     (e: any) => e.value !== event.target.value.value
            // )
            if (!event.target.checked) {
                if (event.target.value.feature) {
                    const feature = this.renderData(event.target.value.feature)
                    for (const key in feature) {
                        this.indexDisabled = this.indexDisabled.filter(
                            (e: any) => e !== feature[key].label
                        )
                        this.dataResults = this.dataResults.filter(
                            (e: any) => e.label !== feature[key].label
                        )
                    }
                }
                this.dataResults = this.dataResults.filter(
                    (e: any) => e.label !== event.target.value.label
                )
                this.indexDisabled = this.indexDisabled.filter(
                    (e: any) => e !== event.target.value.label
                )

                if (this.dataResults.length === 0) {
                    this.indexDisabled = []
                }

                for (const key in this.listGenre) {
                    const feature = this.renderData(this.listGenre[key].feature)
                    const totalChild: any = []
                    feature.map((e: any) => {
                        if (this.indexDisabled.includes(e.label))
                            totalChild.push(e)
                    })

                    if (totalChild.length) {
                        this.indexDisabled.push(this.listGenre[key].label)
                    } else {
                        this.indexDisabled = this.indexDisabled.filter(
                            (v: any) => v !== this.listGenre[key].label
                        )
                        this.listCheckedGenre = this.listCheckedGenre.filter(
                            (k: any) => k.label !== this.listGenre[key].label
                        )
                    }
                }

                return
            }
            // Push province
            if (event.target.value.feature) {
                const feature = this.renderData(event.target.value.feature)
                this.dataResults = this.dataResults.concat(feature)
                let newArr: any = []
                newArr.push(event.target.value)
                newArr = newArr.map((e: any) => e.label)
                this.indexDisabled = this.indexDisabled.concat(newArr)
                const genre = feature.map((e: any) => e.label)
                this.indexDisabled = this.indexDisabled.concat(genre)
            }

            this.dataResults.push(event.target.value)
            this.indexDisabled = this.dataResults.map((e: any) => e.label)

            for (const key in this.listGenre) {
                const feature = this.renderData(this.listGenre[key].feature)
                const totalChild: any = []
                feature.map((e: any) => {
                    if (this.indexDisabled.includes(e.label)) totalChild.push(e)
                })
                if (totalChild.length) {
                    this.indexDisabled.push(this.listGenre[key].label)
                    this.listCheckedGenre.push(this.listGenre[key])
                }
            }

            this.dataResults.map((e: any) => {
                if (e.feature) {
                    this.listCheckedGenre.push(e)
                }
            })
            this.dataResults = this.dataResults.filter((e: any) => !e.feature)
        },
        submitServiceContent() {
            let dataFeature: any = []
            let dataGenre: any = []
            this.dataResults.map((e: any) => {
                dataFeature.push(e.value)
            })
            this.listCheckedGenre.map((e: any) => {
                dataGenre.push(e.value)
            })

            const { product_feature_name, product_feature } =
                generateValueCheckbox(
                    CheckboxProductFeature,
                    dataFeature,
                    'product_feature',
                    false
                )
            const { genre_name, genre } = generateValueCheckbox(
                CheckboxProductGenre,
                dataGenre,
                'genre',
                false
            )

            const listDataService = {
                genre_name,
                genre,
                product_feature_name,
                product_feature
            }

            this.$store.dispatch(
                'signupProvider/changeDataFeatureContent',
                listDataService
            )
            this.$store.dispatch('popup/hidePopup')
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
