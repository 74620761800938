import { ITypeSelectCustom } from '@/shared/constants/IContants'
import { StatusValueUserAdmin, ValueStatusAdvisor } from '@/shared/enums'
import { ITypeColumnsTableCustom } from './IConstants'

const typesAdmin: ITypeSelectCustom[] = [
    {
        value: ValueStatusAdvisor.TEMPORARY_REGISTRATION,
        label: '管理者'
    },
    {
        value: ValueStatusAdvisor.OFFICIAL_REGISTRATION,
        label: '問合せ担当者'
    },
    {
        value: ValueStatusAdvisor.STOP,
        label: 'キャリアコンサルタント'
    }
]

const OPTION_CHOOSE_TYPE = [
    {
        value: 1,
        label: '管理者'
    },
    {
        value: 2,
        label: '問合せ担当者'
    },
    {
        value: 3,
        label: 'キャリアコンサルタント'
    }
]
const OPTION_CHOOSE_STATUS = [
    {
        value: StatusValueUserAdmin.VALID,
        label: '有効'
    },
    {
        value: StatusValueUserAdmin.INVALID,
        label: '無効'
    }
]
const columnsListAdminManagement: ITypeColumnsTableCustom = [
    {
        title: '管理者名',
        dataIndex: 'username',
        key: 'username',
        width: 250
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email',
        width: 300
    },
    {
        title: '権限',
        dataIndex: 'type',
        key: 'type',
        width: 250,
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '状態',
        dataIndex: 'status',
        key: 'status',
        width: 250,
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }
]
export {
    typesAdmin,
    OPTION_CHOOSE_TYPE,
    OPTION_CHOOSE_STATUS,
    columnsListAdminManagement
}
