
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { regex } from '@/shared/regex'
import {
    isArray,
    objectHasKey,
    scrollToFirstErrorLineForm
} from '@/shared/function'
import { mapGetters } from 'vuex'
import { TypeInputCustom, KeyStorage, ListNamePopup } from '@/shared/enums'
import ButtonCustomNew from '@/components/common_new/button-custom/index.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { getDataStorage } from '@/shared'
import SelectCustom from '@/components/common/select/index.vue'
import { OPTION_TITLES } from '@/modules/advisor/modules/provider/shared/constants'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

export default Vue.extend({
    name: 'AdvisorInquiryProvider',
    components: { ButtonCustomNew, InputCustom, TitlePopup, SelectCustom },
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'form'
            }),
            OPTION_TITLES,
            listProductSelect: [],
            TypeInputCustom
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        this.setSignature()
        if (objectHasKey(this.getParamsPopup, 'listProductSelected')) {
            this.listProductSelect = this.getParamsPopup.listProductSelected
        }
        if (objectHasKey(this.getParamsPopup, 'dataPopup')) {
            this.form.setFieldsValue(this.getParamsPopup.dataPopup)
        }
    },
    methods: {
        isArray,
        redirectDetail(id: number, idProvider: number): void {
            this.$store.dispatch('productSearch/checkInfoProvince', {
                product_id: id,
                idProvider
            })
        },
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        setSignature() {
            const signature = getDataStorage(
                KeyStorage.profile
            ).signature_content

            this.form.setFieldsValue({
                signature: signature
            })
        },
        handleClickBtn() {
            this.form.validateFields((errors: Error[], values: any) => {
                if (errors) {
                    scrollToFirstErrorLineForm()
                    return
                }
                this.handleCreateRequest(values)
            })
        },
        async handleCreateRequest(payload: any) {
            const body = {
                ...payload
            }
            if (isArray(this.listProductSelect)) {
                body.product_id = this.listProductSelect.map(
                    (item: any) => item.product_id
                )
            }
            await this.$store.dispatch(
                'advisorDataProvider/apiCreateMultipleInquiryProvider',
                body
            )
            this.$store.dispatch('popup/hidePopup')
        },
        handlePreview() {
            this.form.validateFields((errors: Error[], values: any) => {
                if (errors) {
                    scrollToFirstErrorLineForm()
                    return
                }
                const params = {
                    ...values,
                    listProductSelect: this.listProductSelect
                }
                if (isArray(this.listProductSelect)) {
                    params.product_id = this.listProductSelect.map(
                        (item: any) => item.product_id
                    )
                }

                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.PreviewInquiryProvider,
                    width: 1000,
                    payload: params
                })
            })
        },
        removeAdvisorSelect(advisor: any) {
            this.listProductSelect = this.listProductSelect.filter(
                (item: any) => item.id !== advisor.id
            )
            this.$store.commit(
                'searchProduct/setDataListProductSelected',
                this.listProductSelect
            )
            if (!isArray(this.listProductSelect)) {
                this.$store.dispatch('popup/hidePopup')
            }
        },
        ruleValidTitleMessage() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_inquiry_provider_titleMessage_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_provider_titleMessage_required'
                                    )
                                )
                        }
                        callback()
                    }
                }
            ]
        },
        ruleValidContentMessage() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_inquiry_contentMessage_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_contentMessage_required'
                                    )
                                )
                            if (!regex.length500.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_contentMessage_length500'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesWriteSignature() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length500.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_signature_writesSignature_length500'
                                    )
                                )
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
