
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import InputCustom from '@/components/common_new/input/index.vue'
import { TypeInputCustom } from '@/shared/enums'

type ContractDetailType = {
    id: number
    title: string
    contract_content: string
    product_id: number
    product_name: string
}

export default Vue.extend({
    name: 'ProviderContractItem',
    components: { InputCustom },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'dynamic_rule' }),
            TypeInputCustom
        }
    },
    computed: {
        ...mapGetters('signupProvider', ['getDataSampleContractProduct'])
    },
    props: {
        contract: {
            type: Object as PropType<ContractDetailType>,
            required: true
        }
    },
    mounted() {
        this.form.setFieldsValue({
            title: this.contract?.title,
            contractContent: this.contract?.contract_content
        })
    },
    methods: {
        renderNameProduct(id: number) {
            return this.getDataSampleContractProduct?.find(
                (e: any) => e.value == id
            )?.label
        }
    }
})
