enum ValueCheckbox {
    ALL = 0
}

enum ProductGenre {
    SOFTWARE_SYSTEM = 1,
    CONSULTANT = 2,
    PROFESSIONAL = 3,
    REAL_ESTATE = 4,
    APPROVAL = 5,
    BUSINESS_SUCCESSION = 6
}

export { ValueCheckbox, ProductGenre }
