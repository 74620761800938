
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent } from 'vue'
import { TypeInputCustom } from '@/shared/enums'
import _ from 'lodash'

export default defineComponent({
    name: 'CheckboxCustom',
    props: {
        label: {
            type: String,
            required: true
        },
        classFormItem: {
            type: String
        },
        nameInput: {
            type: String,
            required: true
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        layoutTable: {
            type: Boolean,
            default: false
        },
        classLabelTh: String,
        labelTh: String,
        classTd: String
    },
    data() {
        return {
            TypeInputCustom
        }
    },
    methods: {
        changeChecked(e: any) {
            this.$emit('changeCheckAgreeTerm', e.target.checked)
        }
    }
})
