
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import { copyDeep } from '@/shared'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { filterDomain } from '@/shared/function'
import { keyTypeScreenLogin } from '@/shared/constants'

type IDataInit = {
    isCheckAll: boolean
    listCheckBox: any[]
    indexDisabled: any[]
    dataResults: any[]
    finalResults: any[]
    typeRouter: any
}

export default Vue.extend({
    name: 'AreaPopup',
    data(): IDataInit {
        return {
            isCheckAll: false,
            listCheckBox: [],
            indexDisabled: [],
            dataResults: [],
            finalResults: [],
            typeRouter: []
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataAreaProvince',
            'getIsLoading',
            'getDataResultsArea',
            'getDataResultsAreaChild',
            'getArea'
        ]),
        ...mapGetters({
            resultAreaProvider: 'signupProvider/getDataResultsArea',
            getAreaProvider: 'signupProvider/getArea'
        }),
        ...mapGetters('popup', ['getIsLoading']),
        isProvider(): boolean {
            return (
                this.typeRouter &&
                this.typeRouter.type === keyTypeScreenLogin.provider
            )
        }
    },
    components: { ButtonCustom, Title },
    watch: {
        getDataAreaProvince: {
            handler() {
                this.listCheckBox = this.getDataAreaProvince
            },
            immediate: true
        },
        dataResults: {
            handler(value: any) {
                if (
                    value.filter(
                        (e: any) => !(e.parent ? e.parent : e.parent_id)
                    )?.length === this.getDataAreaProvince.length
                ) {
                    this.isCheckAll = true
                    this.dataResults = []
                    this.indexDisabled = []
                }
            }
        }
    },
    created() {
        this.typeRouter = filterDomain()
        this.dataResults = copyDeep(
            this.typeRouter.type === keyTypeScreenLogin.provider
                ? this.resultAreaProvider.type1
                : this.getDataResultsArea.type1
        )
        this.indexDisabled = this.dataResults.map((e: any) => e.id)
    },
    mounted() {
        this.$watch(
            this.isProvider ? 'getAreaProvider' : 'getArea',
            (newArea: { id: Array<number>; name: Array<string> }) => {
                this.isCheckAll = newArea.id[0] === 0
            },
            { immediate: true }
        )
    },
    methods: {
        changeProvice(event: any) {
            this.processData(event)
        },

        processData(event: any) {
            // Remove province
            if (!event.target.checked) {
                if (this.isCheckAll) {
                    if (event.target.value.childrens) {
                        const dataOtherTypeOne = this.listCheckBox.filter(
                            (e: any) => e.id !== event.target.value.id
                        )
                        this.dataResults = dataOtherTypeOne
                        this.indexDisabled = dataOtherTypeOne.map(
                            (e: any) => e.id
                        )
                    } else {
                        const dataOtherTypeOne = this.listCheckBox.filter(
                            (e: any) => e.id !== event.target.value.parent_id
                        )
                        const dataOtherTypeTwo = this.listCheckBox
                            .find(
                                (e: any) =>
                                    e.id === event.target.value.parent_id
                            )
                            .childrens.filter(
                                (e1: any) => e1.id !== event.target.value.id
                            )
                        this.dataResults =
                            dataOtherTypeOne.concat(dataOtherTypeTwo)
                        this.indexDisabled = dataOtherTypeOne
                            .concat(dataOtherTypeTwo)
                            .map((e: any) => e.id)
                    }
                    this.isCheckAll = false
                    return
                }
                if (
                    this.dataResults.find(
                        (e: any) => e.id === event.target.value.parent_id
                    )
                ) {
                    this.dataResults = this.dataResults.filter(
                        (e: any) => e.id !== event.target.value.parent_id
                    )
                    this.indexDisabled = this.indexDisabled.filter(
                        (e: any) => e !== event.target.value.parent_id
                    )
                    const parent = this.listCheckBox.find(
                        (e: any) => e.id === event.target.value.parent_id
                    )
                    const otherChildren = parent.childrens.filter(
                        (e: any) => e.id !== event.target.value.id
                    )
                    this.dataResults = this.dataResults.concat(otherChildren)
                    this.indexDisabled = this.indexDisabled.concat(
                        otherChildren.map((e: any) => e.id)
                    )
                    return
                }
                this.dataResults = this.dataResults.filter(
                    (e: any) => e.id !== event.target.value.id
                )
                this.indexDisabled = this.indexDisabled.filter(
                    (e: any) => e !== event.target.value.id
                )
                return
            }
            // Push province
            if (event.target.value.childrens) {
                this.dataResults.push(event.target.value)
                this.indexDisabled.push(event.target.value.id)
                this.dataResults = this.dataResults.filter(
                    (e: any) =>
                        (e.parent ? e.parent : e.parent_id) !==
                        event.target.value.id
                )
                this.indexDisabled = this.dataResults.map((e: any) => e.id)
                return
            }
            this.dataResults.push(event.target.value)
            this.indexDisabled.push(event.target.value.id)

            const totalChild = this.dataResults.filter(
                (e: any) =>
                    (e.parent ? e.parent : e.parent_id) ===
                    event.target.value.parent_id
            )

            const totalParent = this.listCheckBox.find(
                (e: any) => e.id === event.target.value.parent_id
            )

            if (totalChild.length === totalParent?.childrens?.length) {
                this.dataResults = [
                    ...this.dataResults.filter(
                        (e: any) =>
                            (e.parent ? e.parent : e.parent_id) !==
                            event.target.value.parent_id
                    ),
                    totalParent
                ]
                this.indexDisabled = this.dataResults.map((e: any) => e.id)
            }
        },
        onCheckedProvinceParent(area: any): boolean {
            return this.indexDisabled.includes(area.id) || this.isCheckAll
        },
        onCheckedProvinceChildren(parentArea: any, area: any): boolean {
            if (this.onCheckedProvinceParent(parentArea)) {
                return true
            }
            return this.indexDisabled.includes(area.id) || this.isCheckAll
        },
        selectAll(event: any) {
            this.isCheckAll = event.target.checked
            this.dataResults = []
            this.indexDisabled = []
        },
        submitData() {
            if (!this.isCheckAll) {
                const tempArr: Array<number> = []
                const tempArrName: Array<string> = []
                this.dataResults.map((e: any) => {
                    if (
                        !e.parent ||
                        !this.dataResults.find(
                            (e1: any) => e1.id === (e.parent ?? e.parent_id)
                        )
                    ) {
                        tempArr.push(e.id)
                        tempArrName.push(e.name ?? e.area_name)
                    }
                })

                this.$store.dispatch(
                    `${
                        this.isProvider ? 'signupProvider' : 'signupAdvisor'
                    }/changeArea`,
                    {
                        id: tempArr,
                        name: tempArrName
                    }
                )
            } else {
                this.$store.dispatch(
                    `${
                        this.isProvider ? 'signupProvider' : 'signupAdvisor'
                    }/changeArea`,
                    {
                        id: [0],
                        name: ['全国']
                    }
                )
            }
            this.$store.dispatch(
                `${
                    this.isProvider ? 'signupProvider' : 'signupAdvisor'
                }/updateDataResultsArea`,
                {
                    type1: this.dataResults
                }
            )
            this.$store.dispatch('popup/hidePopup')
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
