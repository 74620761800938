import { RouterGuest } from '@/modules/guest/shared/enums'
import { RouteConfig } from 'vue-router'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { nameRouterGuest } from '@/shared/constants'
import auth from '@/middleware/auth'

export const routesGuest: Array<RouteConfig> = [
    {
        path: RouterGuest.officeInformations,
        name: nameRouterGuest.searchAdvisor,
        component: () => import('../views/guest/office-infomation/index.vue'),
        meta: {
            layout: 'LayoutGuest'
        }
    },
    {
        path: RouterGuest.affiliateAdvisor,
        name: nameRouterGuest.affiliateAdvisor,
        component: () =>
            import('../views/guest/affiliation-infomation/index.vue'),
        meta: {
            layout: 'LayoutGuest'
        }
    },
    {
        path: RouterGuest.sampleFees,
        name: nameRouterGuest.sampleFees,
        component: () => import('../views/guest/sample-fees/index.vue'),
        meta: {
            layout: 'LayoutGuest'
        }
    },
    {
        path: RouterGuest.interview,
        name: nameRouterGuest.interview,
        component: () => import('../views/guest/interview/index.vue'),
        meta: {
            layout: 'LayoutGuest'
        }
    },
    {
        path: RouterGuest.announcement,
        name: nameRouterGuest.announcement,
        component: () => import('../views/guest/announcement/index.vue'),
        meta: {
            layout: 'LayoutGuestAnnouncement'
        }
    },
    {
        path: RouterGuest.announcementDetail,
        name: nameRouterGuest.announcementDetail,
        component: () => import('../views/guest/announcement/detail/index.vue'),
        meta: {
            layout: 'LayoutGuestAnnouncement'
        }
    },
    {
        path: RouterGuest.concierge,
        name: nameRouterGuest.concierge,
        component: () => import('../views/guest/concierge/index.vue'),
        meta: {
            layout: 'LayoutGuestAnnouncement'
        }
    },
    {
        path: RouterGuest.inquiry,
        name: nameRouterGuest.inquiry,
        component: () => import('../views/guest/inquiry/create/index.vue'),
        meta: {
            layout: 'LayoutGuestAnnouncement'
        }
    },
    {
        path: RouterAdminUrl.notFound,
        name: nameRouterGuest.notFound,
        component: () => import('../views/404.vue')
    }
]
