import apiServices from '@/shared/services/api/axios'
import { IParamUpdateStatusAnnouncementById } from '@/modules/adminFQA/modules/announcement/shared/@types'

type IParamDetailAnnounce = {
    id: string
}

const AnnounceAdminFQAService = {
    apiGetAnnounce(payload: any) {
        let url = '/admin/announcements'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetAnnounceDetail(id: any) {
        return apiServices.get(`/admin/announcement/${id}`)
    },
    apiUpdateStatusAnnounceById(payload: IParamUpdateStatusAnnouncementById) {
        return apiServices.post(
            '/admin/update-status-read-announcement',
            payload
        )
    },
    apiUpdateStatusAnnounce() {
        return apiServices.post('/admin/update-status-announcements')
    }
}
export default AnnounceAdminFQAService
