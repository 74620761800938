import { copyDeep } from '@/shared'
import { IStateStoreAnnouncementAdvisor } from '@/modules/advisor/modules/announcement/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import ConciergeService from '../services'

export const defaultStateConciergeGuest: IStateStoreAnnouncementAdvisor = {
    data: []
}

const state = () => copyDeep(defaultStateConciergeGuest)

const getters = {
    getDataConciergeGuest(state: IStateStoreAnnouncementAdvisor) {
        return state.data
    }
}

const mutations = {
    setDataConciergeGuest(state: IStateStoreAnnouncementAdvisor, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getArea({ commit }: any) {
        try {
            const res = await ConciergeService.apiGetArea()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeGuest', data.data)
                return
            }
        } catch (error) {}
    },
    async createConciergeGuest({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConciergeService.apiCreateConcierge(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeGuest', data.data)
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getArea')
                return res
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
