
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import { getRawHTML, getRoleTypeDomain } from '@/shared/function'
import { ListNamePopup, UserTypeRole } from '@/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'PreviewCreateSignature',
    components: { ButtonCustom, TitlePopup },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    data() {
        return {
            previewSignature: '',
            typeRouter: getRoleTypeDomain(),
            UserTypeRole
        }
    },
    mounted() {
        if (this.getParamsPopup.signature) {
            this.previewSignature = getRawHTML(
                this.getParamsPopup.signature?.signature_content
            )
        }
    },
    methods: {
        backPopup() {
            const params = {
                signature: this.previewSignature,
                dataPreview: true
            }
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.CreateSignature,
                width: 1000,
                payload: params
            })
        }
    }
})
