
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ConfirmDeactive',
    components: { TitlePopup, ButtonCustom },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            if (this.getParamsPopup?.checkRouterAdvisor) {
                this.$store.dispatch('signupAuth/handleDeactive')
            } else {
                this.$store.dispatch('signupAuth/handleProviderDeactive', {
                    id: this.getParamsPopup?.id
                })
            }
        }
    }
})
