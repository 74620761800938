
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
export default Vue.extend({
    name: 'ManagementCompanyPopup',
    components: { Title }
})
