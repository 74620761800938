import { getDataStorage } from '@/shared'
import { KeyStorage, TypeNotify, ValueTypeProvider } from '@/shared/enums'
import { checkIsTempProvider, NotificationCustom } from '@/shared/function'
import {
    CheckboxProductGenre,
    CheckboxServiceContent
} from '../../modules/signup/shared/constants'

function checkPermissionUser() {
    const profile = getDataStorage(KeyStorage.profile)
    if (checkIsTempProvider()) {
        NotificationCustom({
            message: '本登録後にすべて機能がお使いいただけます。',
            type: TypeNotify.error
        })
        return false
    }

    return true
}

function checkPermissionUserVideo() {
    if (
        Number(getDataStorage(KeyStorage.profile).type) ===
        ValueTypeProvider.FREE
    ) {
        NotificationCustom({
            message: '有料会員のみ動画の登録が可能です。',
            type: TypeNotify.error
        })
        return false
    }

    return true
}

function renderGenreAndFeature(genreId: any, featureSelect: any) {
    const parentGenre = CheckboxProductGenre.find(
        (e: any) => e.value == genreId
    )
    const arrChildren = parentGenre?.feature.split('|').map(Number)
    const arrChildrenSelect: number[] = []
    const resultName: any = []

    featureSelect.map((e: any) => {
        if (arrChildren?.includes(e)) {
            arrChildrenSelect.push(e)
        }
    })

    if (arrChildren?.length === arrChildrenSelect.length) {
        resultName.push(parentGenre?.label)
    } else {
        arrChildrenSelect.map((el: number, key: number) => {
            if (key === 0) {
                resultName.push(
                    `${parentGenre?.label} ／ ${CheckboxServiceContent[el]}`
                )
            } else {
                resultName.push(`${CheckboxServiceContent[el]}`)
            }
        })
    }
    return resultName
}

export { checkPermissionUser, checkPermissionUserVideo, renderGenreAndFeature }
