
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { customUrlImage } from '@/shared/function'

export default Vue.extend({
    name: 'AffiliateAdvisorPreviewItem',
    data() {
        return {
            customUrlImage
        }
    },
    props: {
        dataListAff: {
            type: Object
        },
        uploadedImage: {
            type: Object
        }
    },
    computed: {
        formatURLImage(): string {
            if (
                this.uploadedImage &&
                this.uploadedImage.value &&
                this.dataListAff?.image === ''
            ) {
                return this.uploadedImage.value
            }
            return this.dataListAff?.image ?? ''
        }
    }
})
