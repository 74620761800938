
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import {
    filterDomain,
    getDomainMitsukaru,
    isArray,
    openNewTab,
    checkIsTempProvider
} from '@/shared/function'
import { ITypeListTitleHeader } from '@/shared/constants/IContants'
import { KeyStorage, TypeAccount, UserTypeRole } from '@/shared/enums'
import { listTitleHeaderAdvisor } from '@/modules/advisor/shared/contants'
import { listTitleHeaderProvider } from '@/modules/provider/shared/constants'
import { getDataStorage } from '@/shared'
import { listTitleHeaderAdmin } from '@/modules/admin/shared/contants'
import {
    listTitleHeaderAdminFQA,
    specialPages
} from '@/modules/adminFQA/shared/contants'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { mapGetters } from 'vuex'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { pathTopScreen } from '@/shared/constants'

export default Vue.extend({
    name: 'PageTitle',
    computed: {
        ...mapGetters('signupAdvisor', ['getBaseOffice', 'getDataAdvisor']),
        ...mapGetters('pageTitle', ['getTitlePage']),
        ...mapGetters('signupProvider', ['getDataProvider']),
        isMyPageAdvisor(): boolean {
            return (
                this.typeRouter.role === UserTypeRole.advisor &&
                this.$route.path.includes(RouterAdvisorUrl.myPageNoToken)
            )
        },
        isMyPageProvider(): boolean {
            return (
                this.typeRouter.role === UserTypeRole.provider &&
                this.$route.path.includes(RouterProviderUrl.myPageNoToken)
            )
        }
    },
    data(): any {
        return {
            TypeAccount,
            titleHeader: {} as ITypeListTitleHeader,
            listTitleHeader: [],
            typeAccount: getDataStorage(KeyStorage.profile).type,
            taxAdvisorName: '',
            officeName: '',
            providerName: '',
            typeRouter: {},
            isSpecialPath: false
        }
    },
    mounted() {
        this.getListTitleHeader()
    },
    methods: {
        getListTitleHeader() {
            if (!this.typeRouter) {
                return
            }
            let listTitle: ITypeListTitleHeader[] = []

            if (this.typeRouter.role === UserTypeRole.advisor) {
                listTitle = listTitleHeaderAdvisor
            }

            if (this.typeRouter.role === UserTypeRole.provider) {
                listTitle = listTitleHeaderProvider
            }

            if (this.typeRouter.role === UserTypeRole.admin) {
                const profile = getDataStorage(KeyStorage.profile)
                const { type } = profile
                if (type === TypeAccount.admin) {
                    listTitle = listTitleHeaderAdmin
                }
                if (type === TypeAccount.adminFQA) {
                    listTitle = listTitleHeaderAdminFQA
                }
                // config list title header admin, admin fqa
            }

            if (isArray(listTitle)) {
                this.getTitleHeader(listTitle)
            }

            this.listTitleHeader = listTitle
        },
        getTitleHeader(listTitleCustom: ITypeListTitleHeader[]) {
            if (checkIsTempProvider()) {
                this.titleHeader = {
                    title: '会員登録',
                    icon: 'icon-user'
                }
                return
            }

            if (this.isMyPageAdvisor) {
                this.titleHeader = {
                    title: `ようこそ <br class="pcNone"/>${this.officeName} <br class="pcNone"/>${this.taxAdvisorName} 様`,
                    icon: 'icon-user'
                }
                return
            }

            if (this.isMyPageProvider) {
                this.titleHeader = {
                    title: `ようこそ ${this.providerName} 様`,
                    icon: 'icon-user'
                }
                return
            }

            if (listTitleCustom && isArray(listTitleCustom)) {
                return (this.titleHeader = listTitleCustom.find(
                    (item: ITypeListTitleHeader) =>
                        item.name === this.$route.name
                ))
            }

            if (!isArray(this.listTitleHeader)) {
                return
            }
            this.titleHeader = this.listTitleHeader.find(
                (item: ITypeListTitleHeader) => item.name === this.$route.name
            )
        },
        checkSpecialPath() {
            if (
                this.typeRouter.role === UserTypeRole.advisor ||
                this.typeRouter.role === UserTypeRole.provider
            ) {
                this.isSpecialPath = false
                return
            }
            const newArr: any = []

            specialPages.map((e: string) => {
                if (this.$route.path.includes(e)) newArr.push(e)
            })
            this.isSpecialPath = newArr.length !== 0
        },
        openOfficeConcierge(router: string) {
            let routeData = this.$router.resolve({
                path: RouterAdvisorUrl[router]
            })
            openNewTab(routeData.href, false)
        }
    },
    watch: {
        $route: {
            handler() {
                this.typeRouter = filterDomain()
                this.checkSpecialPath()
                this.getTitleHeader([])
            },
            deep: true,
            immediate: true
        },
        getDataAdvisor(newVal) {
            this.taxAdvisorName = newVal.tax_advisor_name
            this.getTitleHeader([])
        },
        getDataProvider(newVal) {
            this.providerName = newVal?.provider_basic_info.provider_name
            this.getTitleHeader([])
        },
        getBaseOffice(newVal) {
            this.officeName = newVal.office_name
            this.getTitleHeader([])
        }
    }
})
