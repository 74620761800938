<template>
    <div class="position-relative">
        <Transition name="fadeIn">
            <div
                class="loading-container center-content-loading"
                v-if="loading"
            >
                <div class="loadingApp"></div>
                <div id="loading-text">loading</div>
            </div>
        </Transition>
        <Transition name="fadeIn">
            <div class="background-loading" v-if="loading"></div>
        </Transition>
        <slot></slot>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'Loading',
    props: {
        loading: {
            default: false
        }
    }
})
</script>

<style scoped>
.fadeIn-leave-active {
    transition: fadeIn 0.3s ease-in;
}

.fadeIn-leave-to {
    transition: fadeOut 0.3s ease-out;
}
.center-content-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
}
.background-loading {
    position: absolute;
    background: #f0f2f5;
    width: 100%;
    height: 100%;
    z-index: 9998;
}
@keyframes fadeIn {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
