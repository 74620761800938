import { copyDeep } from '@/shared'

export const defaultState: object = {
    titleHeader: ''
}

const state = () => copyDeep(defaultState)

const getters: any = {
    getTitlePage(state: any) {
        return state.titleHeader
    }
}

const actions = {
    setTitleHeader({ commit }: any, payload: string) {
        commit('setTitleState', payload)
    }
}

const mutations = {
    setTitleState(state: any, payload: string) {
        state.titleHeader = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
