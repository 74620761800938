import apiServices from '@/shared/services/api/axios'
import {
    IPayloadCreateInquiryAdvisor,
    ITypeParamsDetailInquiryAdvisor,
    IPayloadDeleteInquiryAdvisor,
    ITypePayloadAnswerInquiryAdvisor
} from '@/modules/advisor/modules/inquiry/shared/@types'

const InquiryAdvisorService = {
    apiGetInquiryAdvisor(payload: any) {
        let url = '/tax-advisor/inquiries'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetInquiryDetailAdvisor(params: ITypeParamsDetailInquiryAdvisor) {
        return apiServices.get('/tax-advisor/inquiry-detail', { params })
    },
    apiCreateInquiryAdvisor(payload: IPayloadCreateInquiryAdvisor) {
        return apiServices.post('/tax-advisor/create-inquiry', payload)
    },
    apiDeleteInquiryAdvisor(payload: IPayloadDeleteInquiryAdvisor) {
        return apiServices.post('/tax-advisor/delete-inquiry', payload)
    },
    apiCreateInquiryAnswerAdvisor(payload: ITypePayloadAnswerInquiryAdvisor) {
        return apiServices.post('/tax-advisor/create-anwser-inquiry', payload)
    },
    apiUpdateStatusReadInquiryAdvisor(id: number) {
        return apiServices.post(`/tax-advisor/inquiries/read/${id}`)
    }
}
export default InquiryAdvisorService
