
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { TypeFeeAccount } from '@/shared/enums'

type dataProviderFeePreview = {
    form: any
    dataProviderFee: {
        id: number
        provider_name: string
        email: string
        price: number
    }
}

export default Vue.extend({
    name: 'ApproveProviderFree',
    components: {
        ButtonCustom,
        TitlePopup
    },
    data(): dataProviderFeePreview {
        return {
            form: this.$form.createForm(this, {
                name: 'provider_approve_fee'
            }),
            dataProviderFee: {
                id: 0,
                provider_name: '',
                email: '',
                price: 0
            }
        }
    },
    computed: {
        ...mapGetters('myPageProvider', ['getProviderFee'])
    },
    watch: {
        getProviderFee: {
            handler(newVal) {
                this.dataProviderFee.id = newVal.id
                this.dataProviderFee.provider_name = newVal.username
                this.dataProviderFee.email = newVal.email
                this.dataProviderFee.price = newVal.price
            }
        }
    },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            const data: any = {
                id: this.dataProviderFee.id,
                type: TypeFeeAccount.PAID
            }
            await this.$store.dispatch(
                'myPageProvider/approveProviderFee',
                data
            )

            this.closePopup()
        }
    }
})
