
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TabsCustomNew from '@/components/common_new2/tabs/index.vue'
import {
    TabRegisterPreview,
    TabRegisterPreviewNumber
} from '@/modules/provider/shared/constants'
import { mapGetters } from 'vuex'
import {
    isArray,
    isObject,
    customUrlProfileImage,
    getDomainProvider,
    getDomainPortal,
    onScrollTopMap,
    formatSendDate,
    formatNumberMoney
} from '@/shared/function'
import OfficeInformationPreviewProvider from '@/modules/provider/modules/signup/components/office-info/OfficeInformationPreviewProvider.vue'
import ProductPreview from '@/modules/provider/modules/product/components/ProductPreview.vue'
import ContractPreview from '@/modules/provider/modules/product/components/success-contract/ContractList.vue'
import ShowVideoProviderPreview from '@/modules/provider/modules/signup/components/show-video/ShowVideoProviderPreview.vue'
import ShowImageProviderPreview from '@/modules/provider/modules/signup/components/show-image/ShowImageProviderPreview.vue'
import MapInformationPreview from '@/modules/provider/modules/signup/components/map-information/MapInformationPreview.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import ProductInfo from '@/modules/provider/modules/signup/components/product-info/index.vue'
import { logo } from '@/shared/constants'
import { DateFormatInput } from '@/shared/enums'

export default Vue.extend({
    name: 'PreviewInfoProviderComponent',
    components: {
        ButtonCustom,
        TabsCustomNew,
        ShowVideoProviderPreview,
        OfficeInformationPreviewProvider,
        ProductPreview,
        ContractPreview,
        MapInformationPreview,
        ShowImageProviderPreview,
        ProductInfo
    },
    data() {
        return {
            logo,
            logoHeader: logo.mitsukaru,
            checkProvider: window.location.origin.includes('provider'),
            nameTabs: 'OfficeInformationPreviewProvider' as string,
            keyTabActive: 'provider_office_info_preview',
            listTabProduct: [],
            officeTab: {
                title: '事業所情報',
                name: TabRegisterPreview.office,
                tab: 1,
                key: 'provider_office_info_preview',
                icon: 'icon-buil-provider-admin',
                width: '14.6',
                is_show: false
            },
            contactTab: {
                title: '成約事例',
                name: TabRegisterPreview.contract,
                tab: 3,
                key: 'provider_contract_preview',
                icon: 'icon-flag-provider-admin',
                width: '14.6',
                is_show: false
            },
            mapTab: {
                title: '地図',
                name: TabRegisterPreview.map,
                tab: 4,
                key: 'provider_map_info',
                icon: 'icon-pin-provider-admin',
                width: '14.6',
                is_show: true
            },
            productTab: {
                product_id: Number,
                title: '商材',
                name: TabRegisterPreview.product,
                tab: 2,
                key: 'provider_product_preview',
                icon: 'icon-book-provider-admin',
                width: '14.6',
                is_show: true
            },
            DateFormatInput,
            basicInfo: {} as any,
            officeInfo: {} as any,
            isRegistration: true,
            hasDataContract: false,
            hasDataProduct: false,
            hasDataOffice: false,
            listProduct: []
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getDataProvider',
            'getBaseOffice',
            'getDataVideo',
            'getDataPreviewProvider',
            'getDataSampleContract'
        ]),
        ...mapGetters('productProvider', ['getDataProductFull']),
        ...mapGetters('providerAdmin', [
            'getDataBasicInfoProviderAdmin',
            'getDataOfficeInfoProviderAdmin',
            'getDataProductFullProviderAdmin',
            'getDataContractInfoProviderAdmin'
        ]),
        fullAddress(): string {
            if (Object.keys(this.basicInfo).length !== 0) {
                return (
                    (this.basicInfo?.address ?? '') +
                    (this.basicInfo?.house_number ?? '') +
                    (this.basicInfo?.building_name ?? '')
                )
            }
            return ''
        },
        formatURLImage(): string {
            if (
                this.getDataPreviewProvider.listNameInputImage &&
                this.getDataPreviewProvider.listNameInputImage[0].value &&
                this.basicInfo?.image === ''
            ) {
                return this.getDataPreviewProvider.listNameInputImage[0].value
            }
            return this.basicInfo?.image ?? ''
        },
        listDataTab() {
            return [
                this.officeTab,
                ...this.listTabProduct,
                this.contactTab,
                this.mapTab
            ]
        },
        listDataTabShow(): any {
            return this.listDataTab.filter((item: any) => item.is_show)
        }
    },
    mounted() {
        this.isRegistration =
            this.getDataPreviewProvider?.isRegistration ?? false
        this.logoHeader = this.getDataPreviewProvider?.isAdmin
            ? logo.portal
            : logo.mitsukaru

        this.nameTabs =
            this.getDataPreviewProvider?.tabActive ?? TabRegisterPreview.office

        this.keyTabActive = this.listDataTab.find(
            (item: any) => item.name == this.nameTabs
        ).key
        setTimeout(() => {
            this.setKeyActive(this.listDataTab)
        }, 500)
    },
    watch: {
        getDataPreviewProvider: {
            handler(newVal) {
                // this.nameTabs = newVal?.tabActive ?? TabRegisterPreview.office

                if (!this.getDataPreviewProvider?.isAdmin) {
                    this.officeTab.icon = 'icon-buil-provider'
                    this.contactTab.icon = 'icon-flag-provider'
                    this.mapTab.icon = 'icon-pin-provider'
                    this.productTab.icon = 'icon-book-provider'
                }
            },
            immediate: true
        },
        $route: {
            async handler(newVal, oldVal) {
                if (newVal.path !== oldVal.path) {
                    await this.$store.commit(
                        'signupProvider/setDataPreviewProvider',
                        {}
                    )
                }
            }
        },
        getDataSampleContract: {
            handler(newVal, oldVal) {
                if (this.getDataPreviewProvider?.isAdmin) {
                    return
                }

                let listSampleContract =
                    this.getDataPreviewProvider.listContract ??
                    this.getDataSampleContract

                listSampleContract = listSampleContract.filter(
                    (item: any) => item.public_flg === 1
                )

                if (
                    isObject(listSampleContract) &&
                    Object.keys(listSampleContract).length &&
                    newVal !== oldVal
                ) {
                    this.showTab(TabRegisterPreviewNumber.contract)
                    this.hasDataContract = true
                }
            },
            immediate: true
        },
        getDataContractInfoProviderAdmin: {
            handler(newVal, oldVal) {
                if (isObject(newVal)) {
                    newVal = newVal.filter((item: any) => item.public_flg === 1)
                }

                if (
                    isObject(newVal) &&
                    Object.keys(newVal).length &&
                    newVal !== oldVal &&
                    this.getDataPreviewProvider?.isAdmin
                ) {
                    this.showTab(TabRegisterPreviewNumber.contract)
                    this.hasDataContract = true
                }
            }
        },
        getDataProductFull: {
            async handler(newVal, oldVal) {
                if (this.getDataPreviewProvider?.isAdmin) {
                    return
                }

                if (newVal?.length && newVal !== oldVal) {
                    this.showTab(TabRegisterPreviewNumber.product)
                    this.hasDataProduct = true
                    this.listProduct = newVal
                    await this.$store.commit(
                        'signupProvider/setProductInfo',
                        this.listProduct[0]
                    )
                }
            },
            immediate: true
        },
        getDataProductFullProviderAdmin: {
            async handler(newVal, oldVal) {
                if (
                    newVal?.length &&
                    newVal !== oldVal &&
                    typeof newVal == typeof oldVal &&
                    this.getDataPreviewProvider?.isAdmin
                ) {
                    this.showTab(TabRegisterPreviewNumber.product)
                    this.hasDataProduct = true
                    this.listProduct = newVal
                    await this.$store.commit(
                        'signupProvider/setProductInfo',
                        this.listProduct[0]
                    )
                }
            }
        },

        getBaseOffice: {
            handler(newVal, oldVal) {
                if (this.getDataPreviewProvider?.isAdmin) {
                    return
                }
                let officeInfo: any =
                    this.getDataPreviewProvider.officeInfo ?? this.getBaseOffice
                if (
                    isObject(officeInfo) &&
                    Object.keys(officeInfo).length &&
                    newVal != oldVal
                ) {
                    this.showTab(TabRegisterPreviewNumber.office)
                    this.hasDataOffice = true
                }
            },
            immediate: true
        },
        getDataOfficeInfoProviderAdmin: {
            handler(newVal, oldVal) {
                if (
                    isObject(newVal) &&
                    Object.keys(newVal).length &&
                    newVal !== oldVal &&
                    this.getDataPreviewProvider?.isAdmin
                ) {
                    this.showTab(TabRegisterPreviewNumber.office)
                    this.hasDataOffice = true
                }
            }
        },
        listProduct: {
            handler(newVal, oldVal) {
                this.listTabProduct = []
                for (let i = 0; i < newVal.length; i++) {
                    this.listTabProduct.push({
                        ...this.productTab,
                        key: this.productTab.key + i,
                        product_id: newVal[i].id,
                        title: this.productTab.title + (i + 1)
                    })
                }
                this.setKeyActive()
            }
        }
    },
    methods: {
        formatNumberMoney,
        formatSendDate,
        setKeyActive() {
            this.getDataPreview()

            let emptyTagActive = this.listDataTab.find(
                (item: any) =>
                    item.name == this.getDataPreviewProvider.tabActive &&
                    item.is_show
            )
            if (!emptyTagActive) {
                const tagActive = this.listDataTab.find(
                    (item: any) => item.is_show
                )
                this.nameTabs = tagActive.name
                this.keyTabActive = tagActive.key
                return
            }
            this.nameTabs = emptyTagActive?.name
            this.keyTabActive = emptyTagActive?.key ?? this.keyTabActive
        },
        openTabMap() {
            this.nameTabs = TabRegisterPreview.map
            this.keyTabActive = 'provider_map_info'
            setTimeout(() => {
                onScrollTopMap(-60)
            }, 100)
        },
        backToInfo() {
            this.$store.commit('signupProvider/setDataPreviewProvider', {})
        },
        handleSubmit() {
            this.$store.commit('signupProvider/setDataPreviewProvider', {
                ...this.getDataPreviewProvider,
                tabSubmit: Object.keys(this.getDataPreviewProvider)[0]
            })
        },
        customUrlProfileImage,
        getDataPreview() {
            this.officeInfo =
                this.getDataPreviewProvider.officeInfo ?? this.getBaseOffice
            this.basicInfo = this.getDataPreviewProvider.basicInfo ?? {
                ...this.getDataProvider.provider_basic_info,
                username: this.getDataProvider.username
            }

            if (this.getDataPreviewProvider.isAdmin) {
                this.officeInfo = this.getDataOfficeInfoProviderAdmin
                this.basicInfo = {
                    ...this.getDataBasicInfoProviderAdmin.provider_basic_info,
                    username: this.getDataBasicInfoProviderAdmin.username
                }
            }

            setTimeout(() => {
                this.$store.dispatch('popup/setLoading', false, { root: true })
            }, 1000)
        },
        async onChangeTab(item: any) {
            if (item?.product_id) {
                await this.$store.commit(
                    'signupProvider/setProductInfo',
                    this.listProduct.find(
                        (product: any) => product.id == item?.product_id
                    )
                )
            }
            this.nameTabs = item.name
            this.keyTabActive = item.key
        },
        openTab(val: any) {
            if (!val) {
                return
            }
            window.open(val)
        },
        redirectTop() {
            window
                .open(
                    this.getDataPreviewProvider.isAdmin
                        ? getDomainPortal()
                        : getDomainProvider(),
                    '_blank'
                )
                ?.focus()
        },
        showTab(type: number) {
            for (let object of this.listDataTab) {
                if (object.tab === type) {
                    object.is_show = true
                }
            }
        }
    }
})
