
import Vue from 'vue'

export default Vue.extend({
    name: 'TabsCustomNew2',
    props: {
        dataTab: {
            type: Array,
            required: true
        },
        onActions: {
            type: Function
        },
        tabActive: String
    }
})
