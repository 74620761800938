import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import {
    customUrlPdf,
    NotificationCustom,
    objectHasKey
} from '@/shared/function'
import { IPayloadSearchAnnounce } from '@/shared/@types'
import AnnounceProviderService from '@/modules/provider/modules/announcement/services'
import router from '@/router'
import { IParamUpdateStatusAnnouncementById } from '@/modules/adminFQA/modules/announcement/shared/@types'
import { IStateStoreAnnouncementProvider } from '@/modules/provider/modules/announcement/shared/@types'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'

export const defaultStateAnnouncementProvider: IStateStoreAnnouncementProvider =
    {
        data: {}
    }

const state = () => copyDeep(defaultStateAnnouncementProvider)

const getters = {
    getDataAnnouncementProvider(state: IStateStoreAnnouncementProvider) {
        return state.data
    }
}

const mutations = {
    setDataInquiryAnnouncement(
        state: IStateStoreAnnouncementProvider,
        payload: any
    ) {
        state.data = payload
    }
}

const actions = {
    async getDataAnnouncement(
        { commit }: any,
        payload: IPayloadSearchAnnounce
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AnnounceProviderService.apiGetAnnounce(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryAnnouncement', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailAnnouncement({ commit }: any, id: number) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AnnounceProviderService.apiGetAnnounceDetail(id)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                if (data.data && objectHasKey(data.data, 'file_link')) {
                    data.data.file_link = customUrlPdf(data.data.file_link)
                }

                commit('setDataInquiryAnnouncement', data.data)
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterProviderUrl.announcement)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusAnnouncement({ commit, dispatch }: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await AnnounceProviderService.apiUpdateStatusAnnounce()
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getDataAnnouncement', {
                    page: 1
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusAnnouncementById(
        { commit, dispatch }: any,
        payload: IParamUpdateStatusAnnouncementById
    ) {
        try {
            const res =
                await AnnounceProviderService.apiUpdateStatusAnnounceById(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                // dispatch('signupAuth/countNotifyProvider', true, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
