import apiServices from '@/shared/services/api/axios'
const ProductAdvisorService = {
    apiGetListProducts(payload: any) {
        let url = '/top-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetAllArea() {
        return apiServices.get('/areas')
    },
    apiSearchProduct(payload: any) {
        return apiServices.post(`/tax-advisor/search-product`, payload)
    },
    apiGetListProductViewed(payload: any) {
        let url = '/viewed-product'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetListProviderViewed(payload: any) {
        let url = '/viewed-provider'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiSavingProduct(payload: any) {
        return apiServices.post('/tax-advisor/saving-product', payload)
    },
    apiDeleteSavingProduct(payload: any) {
        return apiServices.post('/tax-advisor/delete-saving-product', payload)
    },
    apiGetAreaProvince(payload: any) {
        let url = '/area-province'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetInfoProvider(payload: any) {
        let url = '/get-provider-info-by-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetProductsProvider(payload: any) {
        let url = '/provider-product-is-after-preview'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetDetailProvider(payload: any) {
        return apiServices.get(`/get-info-provider/${payload.user_provider_id}`)
    }
}
export default ProductAdvisorService
