
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import CheckboxCustom from '@/components/common/checkbox/index.vue'
import { KeyStorage, ListNamePopup } from '@/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { scrollToFirstErrorLineForm } from '@/shared/function'
import { keyTypeScreenLogin } from '@/shared/constants'
import { getDataStorage } from '@/shared'

export default Vue.extend({
    name: 'Withdrawal',
    components: { ButtonCustom, Title, CheckboxCustom },
    data() {
        return {
            formDeactive: this.$form.createForm(this, {
                name: 'Form_Deactive'
            }),
            checkRouterAdvisor: window.location.origin.includes(
                keyTypeScreenLogin.advisor
            )
        }
    },

    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        getIdProvider(): string {
            return getDataStorage(KeyStorage.profile).id
        },
        handleSubmit() {
            this.formDeactive.validateFields((errors: Error[], values: any) => {
                if (errors) {
                    scrollToFirstErrorLineForm()
                    return
                }
                const checkBox = values.director_flg === true

                if (checkBox) {
                    this.$store.dispatch('popup/openPopup', {
                        isOpen: true,
                        namePopup: ListNamePopup.ConfirmDeactive,
                        payload: {
                            id: this.getIdProvider(),
                            checkRouterAdvisor: this.checkRouterAdvisor
                        }
                    })
                }
                if (!checkBox) {
                    alert('利用規約に同意してください。')
                }
            })
        }
    }
})
