enum RouterGuest {
    officeInformations = '/search-advisor/office-info',
    affiliateAdvisor = '/search-advisor/affiliate-advisor',
    sampleFees = '/search-advisor/sample-fees',
    interview = '/search-advisor/interview',
    announcement = '/announcement/list',
    announcementDetail = '/announcement/detail',
    inquiry = '/inquiry',
    concierge = '/concierge'
}

export { RouterGuest }
