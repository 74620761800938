
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import {
    filterDomain,
    getDomainMitsukaru,
    getDomainPortal,
    getDomainProvider,
    openNewTab
} from '@/shared/function'
import { UserTypeRole } from '@/shared/enums'
import { logo, titlePage } from '@/shared/constants'
import { taoyriLink } from '@/modules/advisor/shared/contants'
import { keyTypeScreenLogin } from '@/shared/constants'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

export default {
    name: 'HeaderLogin',
    data() {
        return {
            logoHeader: logo.advisor,
            logo,
            isResetPassword: false,
            typeDomain: {}
        }
    },
    computed: {
        isProvider(): boolean {
            return (
                this.typeRouter &&
                this.typeRouter.type === keyTypeScreenLogin.provider
            )
        },
        isMitsukaru(): boolean {
            return (
                window.location.pathname ===
                RouterAdvisorUrl.providerIntroductionService
            )
        }
    },
    created() {
        this.typeRouter = filterDomain()
    },
    mounted() {
        this.typeDomain = filterDomain()
        if (this.typeDomain.role === UserTypeRole.admin) {
            this.logoHeader = logo.portal
        }
        if (this.isMitsukaru) {
            this.logoHeader = logo.tsunagaru
        }
        if (this.isProvider) {
            document.documentElement.style.setProperty(
                '--main-color',
                '#186BB9'
            )
            document.documentElement.style.setProperty(
                '--color-hover',
                '#044887'
            )
        }
    },
    watch: {
        $route: {
            handler(to) {
                if (to.name === 'resetPassword') {
                    this.isResetPassword = true
                }
                if (to.name === 'officeConciergeAdvisor') {
                    this.logoHeader = logo.mitsukaru
                }
            },
            immediate: true
        }
    },
    methods: {
        openTayoriLink() {
            openNewTab(taoyriLink.fqaLink, false)
        },
        redirectTop() {
            let url = null
            switch (this.typeDomain.role) {
                case UserTypeRole.advisor:
                    url = this.isMitsukaru
                        ? getDomainProvider()
                        : getDomainMitsukaru()
                    break
                case UserTypeRole.provider:
                    url = getDomainProvider()
                    break
                default:
                    url = getDomainPortal()
                    break
            }
            window.open(url, '_blank')?.focus()
        }
    }
}
