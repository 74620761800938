
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import { listSteps } from '@/shared/constants'
import { taoyriLink } from '@/modules/advisor/shared/contants'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { openNewTab } from '@/shared/function'
import { ITypeStep } from '@/shared/constants/IContants'

export default Vue.extend({
    name: 'ProviderRegistration',
    components: { TitlePopup },
    data() {
        return {
            listSteps,
            taoyriLink,
            checkPage: this.$route.path === RouterProviderUrl.signup
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        this.listSteps.map((item: ITypeStep, index: number) => {
            if (index <= this.getParamsPopup.itemActive) {
                return (item.status = true)
            }
        })
    },
    methods: {
        closeBtn() {
            this.$store.dispatch('popup/hidePopup')
            window.location.reload()
        },
        openTayoriLink() {
            openNewTab(taoyriLink.fqaLink, false)
        }
    }
})
