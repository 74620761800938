
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/common/button/index.vue'
import Title from '@/components/common/popup/title.vue'
import { ListNamePopup } from '@/shared/enums'

type IDataInit = {
    isCheckAll: boolean
    listCheckBox: any[]
    dataResults3: any[]
    dataResults4: any[]
    disabledBtn: boolean
    compactData: any[]
}

export default Vue.extend({
    name: 'AreaCityPopup',
    data(): IDataInit {
        return {
            isCheckAll: false,
            listCheckBox: [],
            dataResults3: [],
            dataResults4: [],
            disabledBtn: false,
            compactData: []
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataAreaCity',
            'getDataResultsAreaChild',
            'getDataResultsArea'
        ])
    },
    watch: {
        getDataAreaCity: {
            handler() {
                const newArr: any[] = []
                this.listCheckBox = this.getDataAreaCity
                this.getDataAreaCity.map((e: any) => {
                    newArr.push(e.id)

                    e.childrens &&
                        e.childrens.map((el: any) => newArr.push(el.id))
                })
                this.compactData = newArr
            },
            immediate: true
        },
        getDataResultsArea: {
            handler() {
                this.dataResults3 = this.getDataResultsArea.type3
                this.dataResults4 = this.getDataResultsArea.type4
            },
            immediate: true
        },
        getDataResultsAreaChild: {
            handler() {
                this.isCheckAll = this.getDataResultsArea.type2.find(
                    (e: any) => e.id === this.getDataResultsAreaChild.parent.id
                ).isAll
            },
            immediate: true
        },
        dataResults3: {
            handler() {
                this.checkDisabled()
            },
            immediate: true
        },
        compactData: {
            handler() {
                this.checkDisabled()
            },
            immediate: true
        }
    },
    methods: {
        changeArea(event: any) {
            this.processData(event)
        },
        submitData() {
            if (!this.isCheckAll) {
                this.$store.dispatch('signupAdvisor/updateDataResultsArea', {
                    type3: this.dataResults3,
                    type4: this.dataResults4
                })
                this.$store.dispatch('signupAdvisor/updateType2', {
                    id: this.getDataResultsAreaChild.parent.id,
                    payload: false
                })
            }
            if (this.isCheckAll) {
                this.$store.dispatch('signupAdvisor/updateType2', {
                    id: this.getDataResultsAreaChild.parent.id,
                    payload: true
                })
            }

            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.AreaPopup,
                width: 1000
            })
        },
        processData(event: any) {
            if (event.target.value.area_type === '3') {
                if (event.target.checked) {
                    this.dataResults3 = [
                        ...this.dataResults3,
                        {
                            id: event.target.value.id,
                            type: event.target.value.area_type,
                            name: event.target.value.area_name,
                            parent: event.target.value.parent_id,
                            isChild: false
                        }
                    ]
                } else {
                    this.dataResults3 = this.dataResults3.filter(
                        (e: { id: number; isChild: boolean }) =>
                            e.id !== event.target.value.id
                    )
                }
            } else {
                if (event.target.checked) {
                    this.dataResults4 = [
                        ...this.dataResults4,
                        {
                            id: event.target.value.id,
                            type: event.target.value.area_type,
                            name: event.target.value.area_name,
                            parent: event.target.value.parent_id
                        }
                    ]
                    this.dataResults3 = [
                        ...this.dataResults3,
                        {
                            id: event.target.value.parent_id,
                            type: '3',
                            parent: this.getDataResultsAreaChild.parent.id,
                            name: this.getDataResultsAreaChild.parent.name,
                            isChild: true
                        }
                    ]
                } else {
                    this.dataResults4 = this.dataResults4.filter(
                        (e: { id: number; isChild: boolean }) =>
                            e.id !== event.target.value.id
                    )
                    this.dataResults3 = this.dataResults3.filter(
                        (e: { id: number; isChild: boolean }) =>
                            e.id !== event.target.value.id
                    )
                    this.dataResults3 = this.dataResults3.filter(
                        (e: { id: number; isChild: boolean }) =>
                            e.id !== event.target.value.parent_id && !e.isChild
                    )
                }
            }
        },
        backForm() {
            if (
                (!this.isCheckAll &&
                    !this.getDataResultsArea.type3.find((e: any) =>
                        this.compactData.includes(e.id)
                    )) ||
                (this.isCheckAll &&
                    !this.getDataResultsArea.type2.find(
                        (e: any) =>
                            e.id === this.getDataResultsAreaChild.parent.id
                    ).isAll)
            ) {
                this.$store.dispatch('signupAdvisor/updateDataResultsArea', {
                    type2: this.getDataResultsArea.type2.filter(
                        (e: any) =>
                            e.id !== this.getDataResultsAreaChild.parent.id
                    )
                })
            }
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.AreaPopup,
                width: 1000
            })
        },
        selectAll(event: any) {
            this.isCheckAll = event.target.checked
        },
        checkDisabled() {
            if (
                !this.dataResults3.find((e: any) =>
                    this.compactData.includes(e.id)
                )
            ) {
                this.disabledBtn = true
            } else {
                this.disabledBtn = false
            }
        }
    },
    components: { Button, Title }
})
