import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import ProductSearchService from '../services'
import { NotificationCustom } from '@/shared/function'

export const defaultStateProductSearch: any = {
    dataProduct: [],
    dataProductSave: [],
    dataProductViewed: [],
    dataProductProduct: [],
    dataListProductSelected: []
}

const state = () => copyDeep(defaultStateProductSearch)

const getters = {
    getDataProduct(state: any) {
        return state.dataProduct
    },
    getDataProductSave(state: any) {
        return state.dataProductSave
    },
    getDataProductViewed(state: any) {
        return state.dataProductViewed
    },
    getDataTopProduct(state: any) {
        return state.dataProductProduct
    },
    getDataListProductSelected(state: any) {
        return state.dataListProductSelected
    }
}

const mutations = {
    setDataProduct(state: any, payload: any) {
        state.dataProduct = payload
    },
    setDataProductSave(state: any, payload: any) {
        state.dataProductSave = payload
    },
    setDataProductViewed(state: any, payload: any) {
        state.dataProductViewed = payload
    },
    setDataTopProduct(state: any, payload: any) {
        state.dataProductProduct = payload
    },
    setDataListProductSelected(state: any, payload: any) {
        state.dataListProductSelected = payload
    }
}

const actions = {
    async getDataProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const params: any = {}
            params.product_feature = payload?.product_feature
            params.genre = payload?.genre
            params.areaId = payload?.area_id
            params.perPage = payload?.perPage
            params.page = payload?.page
            const res = await ProductSearchService.apiGetListProduct(params)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProduct', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListProductSave({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiGetListProductSave(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductSave', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async addSaveProduct({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiSavingProduct(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getDataProduct', payload?.search)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async removeSaveProduct({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiRemoveSavingProduct(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                dispatch('getDataProduct', payload?.search)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async removeMultiSaveProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiRemoveMultiSavingProduct(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListProductByIds({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiGetListProductByIds(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductViewed', res.data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getListDataTopProduct({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ProductSearchService.apiGetListProducts(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataTopProduct', data.data)
                return
            }
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
