enum RouterAdminFQA {
    signin = '/signin',
    inquiry = '/inquiry',
    inquiryDetail = '/inquiry/detail/',
    inquiryPreview = '/inquiry/preview',
    announcement = '/announcement',
    announcementDetail = '/announcement/detail/',
    concierge = '/concierge',
    conciergeDetailGuest = '/concierge/detail/',
    conciergeDetailAdvisor = '/concierge-advisor/detail/',
    conciergePreview = '/concierge/preview',
    productSearchFQA = '/search-product',
    resultSearchProductDetail = '/result-product/',
    resultSearchProduct = '/result-product',
    productSave = '/product-save'
}
export { RouterAdminFQA }
