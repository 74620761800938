var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TitlePopup',{attrs:{"title":'パスワード変更'}}),_c('p',{staticClass:"share_modal_note1"},[_vm._v(" 現在のパスワードと新しいパスワードを入力し、「パスワードを変更する」ボタンをクリックしてください。 ")]),_c('div',{staticClass:"change-email_tableWrap"},[_c('a-form',{staticClass:"change-password",attrs:{"form":_vm.formChangePassword,"autocomplete":"off"}},[_c('div',{staticClass:"change-email_tableWrap"},[_c('table',{staticClass:"tableType1 change-email_table"},[_c('tbody',[_c('InputCustom',{attrs:{"class-input":"inputFocus1","typeInputCustom":_vm.isShowPassword
                                    ? _vm.ListTypeInput.text
                                    : _vm.ListTypeInput.password,"form-instance":_vm.formChangePassword,"label":_vm.$tc('i18n_password_current'),"rules":_vm.validPassCurrent(),"name-input":"old_password","class-label":"tbType1_ttlWrap","required":"","layoutTable":""}}),_c('InputCustom',{attrs:{"class-input":"inputFocus1","typeInputCustom":_vm.isShowPassword
                                    ? _vm.ListTypeInput.text
                                    : _vm.ListTypeInput.password,"form-instance":_vm.formChangePassword,"label":_vm.$tc('i18n_password_new'),"rules":_vm.validPassNew(),"name-input":"new_password","class-label":"tbType1_ttlWrap","required":"","layoutTable":""}}),_c('InputCustom',{attrs:{"class-input":"inputFocus1","typeInputCustom":_vm.isShowPassword
                                    ? _vm.ListTypeInput.text
                                    : _vm.ListTypeInput.password,"form-instance":_vm.formChangePassword,"label":_vm.$tc('i18n_password_new_confirm'),"rules":_vm.validPassNewConfirm(),"name-input":"passwordNewConfirm","class-label":"tbType1_ttlWrap","required":"","layoutTable":""}})],1)])]),_c('a-checkbox',{staticClass:"pw_show",on:{"click":_vm.handleShowPassword}},[_vm._v(" パスワードを表示する ")]),_c('div',{staticClass:"change-email_btnWrap flexCols flexJc pc-mt-2-5 sp-mt-2"},[_c('ButtonCustom',{attrs:{"label":_vm.$tc('i18n_back'),"classes":"btnType2c js-modal_close"},on:{"onClick":_vm.onClosePopup}}),_c('ButtonCustom',{attrs:{"label":_vm.$tc('i18n_btn_change_password'),"classes":"btnType2a","btn-call-api":""},on:{"onClick":_vm.onSubmitForm}})],1),_c('div',{staticClass:"note"},[_c('p',[_vm._v(" 【ご注意】"),_c('br'),_vm._v(" 「半角英数字・記号」※8文字〜30文字以内"),_c('br'),_vm._v(" 英文字（大文字・小文字）、数字、記号の内、2種類以上 ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }