import { ITypeSelectCustom } from '@/shared/constants/IContants'
import {
    StatusProductProvider,
    IntroductionFee,
    ProductGenre,
    StatusProductProviderNew
} from '@/modules/provider/modules/product/shared/enum'
import { IObjectValue } from '../@types'

const listStatusReadInquiry: ITypeSelectCustom[] = [
    {
        value: StatusProductProvider.PUBLIC,
        label: '公開'
    },
    {
        value: StatusProductProvider.PRIVATE,
        label: '非公開'
    },
    {
        value: StatusProductProvider.TEMPORARY_STORAGE,
        label: '一時保存'
    }
]

const listIntroductionFee: ITypeSelectCustom[] = [
    {
        value: IntroductionFee.NO,
        label: '有り'
    },
    {
        value: IntroductionFee.YES,
        label: '無し'
    },
    {
        value: IntroductionFee.CONSULTATION,
        label: '相談'
    }
]

const CheckboxProductFeature: ITypeSelectCustom[] = [
    { label: '会計ソフト', value: 1 },
    { label: '経営管理システム', value: 2 },
    { label: '融資・資金繰り', value: 3 },
    { label: '補助金・助成金', value: 4 },
    { label: 'コスト改善', value: 5 },
    { label: '弁護士', value: 6 },
    { label: '司法書士', value: 7 },
    { label: '社会保険労務士', value: 8 },
    { label: '中小企業診断士', value: 9 },
    { label: '行政書士', value: 10 },
    { label: '弁理士', value: 11 },
    { label: '不動産売買', value: 12 },
    { label: '不動産賃貸管理', value: 13 },
    { label: '有効活用', value: 14 },
    { label: '特許・商標登録', value: 15 },
    { label: '職業紹介・労務', value: 16 },
    { label: '建設・不動産', value: 17 },
    { label: '環境許可（産業廃棄物等）', value: 18 },
    { label: 'ビザ申請', value: 19 },
    { label: '営業許可', value: 20 },
    { label: 'M&A', value: 21 },
    { label: '事業承継 ', value: 22 }
]

const ProductGenreStatus: ITypeSelectCustom[] = [
    {
        value: 1,
        label: '会計ソフト・システム'
    },
    {
        value: 2,
        label: 'コンサルタント'
    },
    {
        value: 3,
        label: '士業'
    },
    {
        value: 4,
        label: '不動産'
    },
    {
        value: 5,
        label: '許認可'
    },
    {
        value: 6,
        label: '事業承継'
    }
]

const listPublicFlg: IObjectValue[] = [
    {
        value: 1,
        label: '公開'
    },
    {
        value: 2,
        label: '非公開'
    }
]

const listPublicFlgResponse: IObjectValue[] = [
    {
        value: 1,
        label: '公開'
    },
    {
        value: 0,
        label: '非公開'
    }
]

const maxItemProduct = 5

const listStatusProductProvider: ITypeSelectCustom[] = [
    {
        value: StatusProductProviderNew.PUBLIC,
        label: '公開'
    },
    {
        value: StatusProductProviderNew.PRIVATE,
        label: '非公開'
    },
    {
        value: StatusProductProviderNew.DRAFF,
        label: '一時保存'
    }
]

export {
    listStatusReadInquiry,
    listIntroductionFee,
    ProductGenreStatus,
    listPublicFlg,
    listPublicFlgResponse,
    CheckboxProductFeature,
    maxItemProduct,
    listStatusProductProvider
}
