import { render, staticRenderFns } from "./AddMailProvider.vue?vue&type=template&id=cb50225e&scoped=true&"
import script from "./AddMailProvider.vue?vue&type=script&lang=ts&"
export * from "./AddMailProvider.vue?vue&type=script&lang=ts&"
import style0 from "./AddMailProvider.vue?vue&type=style&index=0&id=cb50225e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb50225e",
  null
  
)

export default component.exports