
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import {
    CheckboxProductGenre,
    CheckboxProductFeature
} from '@/modules/provider/modules/signup/shared/constants'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
export default Vue.extend({
    name: 'SearchByGenreFeaturePopup',
    data(): any {
        return {
            selectGenres: [],
            selectFeatures: []
        }
    },
    computed: {
        ...mapGetters('popup', ['getIsLoading', 'getConditionFilter']),
        isDisableButtonSubmit() {
            return !this.selectGenres.length && !this.selectFeatures.length
        },
        checkboxProductFeature() {
            return CheckboxProductFeature
        },
        checkboxProductGenre() {
            return CheckboxProductGenre
        }
    },
    components: { ButtonCustom, Title },
    mounted() {
        this.selectGenres = this.getConditionFilter.genre ?? []
        this.selectFeatures = this.getConditionFilter.product_feature ?? []
    },
    methods: {
        getListFeature(item: string) {
            const valueArray = item.split('|').map(Number)
            const listFeature = CheckboxProductFeature.filter(option =>
                valueArray.includes(option.value)
            )
            return listFeature
        },
        changeSelectGenre(e: any) {
            let childrenIds = this.getListFeature(e.target.value.feature).map(
                (item: any) => item.value
            )
            if (e.target.checked) {
                this.selectGenres.push(e.target.value.value)
                this.selectFeatures = this.mergeUnique(
                    this.selectFeatures,
                    childrenIds
                )
            } else {
                this.selectGenres = this.selectGenres.filter(
                    (id: number) => id != e.target.value.value
                )
                this.selectFeatures = this.selectFeatures.filter(
                    (id: number) => !childrenIds.includes(id)
                )
            }
        },
        changeSelectFeature(e: any, children: any, parent: any) {
            if (e.target.checked) {
                this.selectFeatures.push(children.value)
            } else {
                this.selectFeatures = this.selectFeatures.filter(
                    (id: number) => id != children.value
                )
            }

            let childrenIds = this.getListFeature(parent.feature).map(
                (item: any) => item.value
            )
            let isCheckedAllChilrent = childrenIds.every(
                (id: number) => !this.selectFeatures.includes(id)
            )
            if (isCheckedAllChilrent) {
                this.selectGenres = this.selectGenres.filter(
                    (id: number) => id !== parent.value
                )
            } else {
                if (!this.selectGenres.includes(parent.value)) {
                    this.selectGenres.push(parent.value)
                }
            }
        },
        isCheckedProvinceParent(id: number): boolean {
            return this.selectGenres.includes(id)
        },
        isCheckedProvinceChildren(id: number): boolean {
            return this.selectFeatures.includes(id)
        },
        submitData() {
            this.setFilterSearch()
            let query: any = {}
            for (const prop in this.getConditionFilter) {
                if (prop == 'perPage') {
                    query[prop] = this.getConditionFilter[prop]
                    continue
                }
                query[prop] = this.getConditionFilter[prop].join(',')
            }
            query.perPage = 10
            query.page = 1
            this.$router
                .replace({
                    path: RouterAdminFQA.resultSearchProduct,
                    query: query
                })
                .catch(() => {
                    //
                })
            this.$store.dispatch('productSearch/getDataProduct', query)
            this.$store.dispatch('popup/hidePopup')
        },
        mergeUnique(array1: Array<number>, array2: Array<number>) {
            let arrayMerge = array1.concat(array2)
            return arrayMerge.filter(
                (item, index) => arrayMerge.indexOf(item) == index
            )
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        },
        setFilterSearch() {
            let getConditionFilter = { ...this.getConditionFilter }
            getConditionFilter.genre = this.selectGenres
            getConditionFilter.product_feature = this.selectFeatures
            this.$store.dispatch('popup/setConditionFilter', {
                conditionFilter: getConditionFilter
            })
        },
        saveFilterSearch() {
            this.setFilterSearch()
            this.cancelSelect()
        }
    }
})
