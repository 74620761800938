
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import SelectCustom from '@/components/common/select/index.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import InputCustomEditor from '@/components/common/input/index.vue'
import { mapGetters } from 'vuex'
import { ListNamePopup, KeyStorage, TypeInputCustom } from '@/shared/enums'
import { regex } from '@/shared/regex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { StatusSendBackProviderAdmin } from '@/modules/adminFQA/modules/concierge/shared/enums'
import { getDataStorage } from '@/shared'
import { configEditorWithSelectFont } from '@/shared/constants'
import { getRawHTML, getTextEditor } from '@/shared/function'

const OPTION_TITLES = [
    { value: 1, label: '修正のお願い' },
    { value: 2, label: '確認のお願い' }
]
export default Vue.extend({
    name: 'SendBackProvider',
    components: {
        ButtonCustom,
        InputCustom,
        SelectCustom,
        TitlePopup,
        InputCustomEditor
    },
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'dynamic_rule'
            }),
            OPTION_TITLES,
            TypeInputCustom,
            StatusSendBackProviderAdmin,
            configEditorWithSelectFont,
            signatureEditor: ''
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        let dataInit: any = {}
        if (getDataStorage(KeyStorage.profile).signature_content) {
            this.$set(
                this,
                'signatureEditor',
                getDataStorage(KeyStorage.profile).signature_content
            )
        }
        if (this.getParamsPopup?.dataSaveDraft) {
            dataInit = {
                title: Number(this.getParamsPopup?.dataSaveDraft?.title),
                content: this.getParamsPopup?.dataSaveDraft?.content
            }
            this.$set(
                this,
                'signatureEditor',
                this.getParamsPopup?.dataSaveDraft?.signature
            )
        }
        dataInit = {
            ...dataInit,
            provider_name:
                this.getParamsPopup?.dataProvider?.provider_basic_info
                    ?.provider_name
        }
        this.form.setFieldsValue(dataInit)
    },
    methods: {
        validContent() {
            return [
                {
                    required: true,
                    message: '通知内容を入力して下さい。'
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length255.test(value)) {
                                callback(
                                    '通知内容は255文字以内で入力してください。'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        validSignature() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length500.test(value)) {
                                callback(
                                    '署名は500文字以内で入力してください。'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSendBack(send_status: number) {
            this.form.validateFields(async (err: Error[], dataForm: any) => {
                if (err) {
                    return
                }
                const { title, content, signature } = dataForm
                let dataSubmit: any = {
                    draft_flg: send_status,
                    title: title,
                    content: content,
                    signature: getRawHTML(signature) || this.signatureEditor,
                    provider_id: this.getParamsPopup?.dataProvider?.id,
                    id: this.getParamsPopup?.dataSaveDraft?.id
                }
                if (send_status) {
                    await this.$store.dispatch(
                        'providerAdmin/sendBackProvider',
                        dataSubmit
                    )
                } else {
                    await this.$store.dispatch('popup/openPopup', {
                        isOpen: true,
                        namePopup: ListNamePopup.ConfirmSubmitRejectProvider,
                        width: 1000,
                        payload: dataSubmit
                    })
                }
            })
        }
    }
})
