import apiServices from '@/shared/services/api/axios'
import {
    IPayloadReplyConciergeFqa,
    IPayloadUpdateStatusConcierge
} from '@/modules/adminFQA/modules/concierge/shared/@types'

const ConciergeAdminFQAService = {
    apiGetConcierge(payload: any) {
        let url = '/admin/list-concierge'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetConciergeDetail(id: any) {
        return apiServices.get(`/admin/get-inquiry-answer-guest/${id}`)
    },
    apiReplyConciergeAdminFqa(payload: IPayloadReplyConciergeFqa) {
        return apiServices.post('/admin/send-inquiry-answer-guest', payload)
    },
    apiReplyDragConciergeAdminFqa(payload: IPayloadReplyConciergeFqa) {
        return apiServices.post(
            '/admin/save-draft-inquiry-answer-guest',
            payload
        )
    },
    apiUpdateStatusConciergeAdminFqa(payload: IPayloadUpdateStatusConcierge) {
        return apiServices.post(
            `/admin/update-status-concicerge-answer`,
            payload
        )
    },
    apiPreviewConciergeAdminFqa(payload: IPayloadReplyConciergeFqa) {
        return apiServices.post('/admin/preview-answer-concierge', payload)
    },
    apiGetConciergeDetailByAdvisor(payload: any) {
        let url = '/admin/concierge-detail/' + payload.id
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiCreateConciergeReplyAdvisor(payload: any) {
        return apiServices.post('admin/create-anwser-concierge', payload)
    }
}
export default ConciergeAdminFQAService
