
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { isArray } from '@/shared/function'
import { copyDeep } from '@/shared'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

type labelType = {
    label: string
    name: string
}
type dataInvitationPreview = {
    form: any
    dataInvitation: {
        provider_name: string
        domain: string
        mobile_phone: number
        email: string
        user_name: string
    }
    labelInfo: labelType[]
}

export default Vue.extend({
    name: 'InvitationPreview',
    components: {
        ButtonCustom,
        TitlePopup
    },
    data(): dataInvitationPreview {
        return {
            form: this.$form.createForm(this, {
                name: 'invitation_preview'
            }),
            dataInvitation: {
                provider_name: '',
                domain: '',
                mobile_phone: 0,
                email: '',
                user_name: ''
            },
            labelInfo: [
                {
                    label: '事業者名',
                    name: 'provider_name'
                },
                {
                    label: '事業分野',
                    name: 'domain'
                },
                {
                    label: '電話番号',
                    name: 'mobile_phone'
                },
                {
                    label: 'メールアドレス',
                    name: 'email'
                },
                {
                    label: '担当者名',
                    name: 'user_name'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    watch: {
        getParamsPopup: {
            handler(newVal) {
                const dataDomain = copyDeep(newVal.data)
                let splitDomainInvitation

                if (newVal.data.domain) {
                    const { label } = newVal.domain_option.find(
                        (option: { value: any }) =>
                            option.value === newVal.data.domain
                    )
                    splitDomainInvitation = label
                }
                this.dataInvitation = dataDomain
                this.dataInvitation.domain = splitDomainInvitation
            },
            immediate: true
        }
    },
    methods: {
        isArray,
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            const { data } = this.getParamsPopup
            const dataSubmit = {
                ...data,
                domain: data.domain ? [data.domain] : []
            }
            const res = await this.$store.dispatch(
                'invitationAdvisor/createDataInvitation',
                dataSubmit
            )
            if (res) {
                await this.$router.push(RouterAdvisorUrl.invitation)
            }
        }
    }
})
