import apiServices from '@/shared/services/api/axios'

const ProductProviderService = {
    apiGetProduct(payload: any, type: string) {
        let url =
            type === 'full'
                ? '/provider/provider-product-info'
                : '/provider/list-products'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }
        return apiServices.get(url)
    },
    apiCreateProduct(payload: any) {
        return apiServices.post('provider/create-product', payload)
    },
    apiUpdateProduct(payload: any) {
        return apiServices.post('provider/update-product', payload)
    },
    apiDetailProduct(payload: any) {
        return apiServices.get(`provider/get-draft-product?id=${payload.id}`)
    },
    apiDeleteProduct(payload: any) {
        return apiServices.post(`provider/delete-products?id=${payload.id}`)
    },
    apiDetailInquiryAdvisor(params: any) {
        return apiServices.get('/provider/product-inquiry-history?id=' + params)
    },
    apiAnswerInquiryAdvisor(params: any) {
        return apiServices.post(
            '/provider/create-anwser-product-inquiry',
            params
        )
    },
    apiProductManagement(payload: any) {
        let url = '/provider/product-detail'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },
    apiProductProgress(params: any) {
        return apiServices.get(
            '/provider/product-progress?inquiry_id=' + params
        )
    },
    apiUpdateProductProgress(params: any) {
        return apiServices.post(
            '/provider/product-progress/create-update',
            params
        )
    }
}
export default ProductProviderService
