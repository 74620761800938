import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import {
    IPayloadReplyConsultAdvisor,
    IPayloadSearchConsultAdvisor,
    IPayloadUpdateStatusConsultAdvisor,
    IStateStoreConsultAdvisor
} from '@/modules/advisor/modules/consult/shared/@types'
import ConsultAdvisorService from '@/modules/advisor/modules/consult/services'
import { AdvisorConsultStatus } from '@/modules/advisor/modules/consult/shared/enums'

export const defaultStateConsultAdvisor: IStateStoreConsultAdvisor = {
    data: {},
    dataPreviewConsult: '',
    payloadConsult: {}
}

const state = () => copyDeep(defaultStateConsultAdvisor)

const getters = {
    getDataConsult(state: IStateStoreConsultAdvisor) {
        return state.data
    },
    getPreviewConsult(state: IStateStoreConsultAdvisor) {
        return state.dataPreviewConsult
    },
    getPayloadConsult(state: IStateStoreConsultAdvisor) {
        return state.payloadConsult
    }
}

const mutations = {
    setDataConsultAdvisor(state: IStateStoreConsultAdvisor, payload: any) {
        state.data = payload
    },
    setPreviewConsultAdvisor(state: IStateStoreConsultAdvisor, payload: any) {
        state.dataPreviewConsult = payload
    },
    setPayloadConsult(state: IStateStoreConsultAdvisor, payload: any) {
        state.payloadConsult = payload
    }
}

const actions = {
    async getDataConsult(
        { commit }: any,
        payload: IPayloadSearchConsultAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsultAdvisorService.apiGetConsultAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConsultAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailConsult({ commit, dispatch }: any, params: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsultAdvisorService.apiGetConsultDetailAdvisor(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConsultAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async updateStatusConsultAdvisor(
        { commit, dispatch }: any,
        params: IPayloadUpdateStatusConsultAdvisor
    ) {
        try {
            const res =
                await ConsultAdvisorService.apiUpdateStatusConsultAdvisor(
                    params
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                if (params.status === AdvisorConsultStatus.CONTACTED) {
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: '状況を更新しました。'
                    })
                    dispatch('getDataDetailConsult', params.id)
                }
                dispatch('signupAuth/countNotifyAdvisor', true, { root: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {}
    },
    async replyConsultAdvisor(
        { commit, dispatch }: any,
        params: IPayloadReplyConsultAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsultAdvisorService.apiReplyConsultAdvisor(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataDetailConsult', params.id)
                return true
            }
            dispatch('getDataDetailConsult', params.id)
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async previewConsultAdvisor(
        { commit, dispatch }: any,
        params: IPayloadReplyConsultAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsultAdvisorService.apiPreviewConsultAdvisor(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setPreviewConsultAdvisor', data.data)
                return true
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
