
import Vue from 'vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'
import TitlePopup from '@/components/common_new/popup/title.vue'
export default Vue.extend({
    name: 'ConfirmDeleteInvitationProvider',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    components: { TitlePopup, ButtonCustom },
    methods: {
        async submitInvitation() {
            if (this.getParamsPopup) {
                await this.$store.dispatch(
                    'invitedProvider/deleteInvitedProviderAdmin',
                    this.getParamsPopup
                )
                await this.$store.dispatch('signupAuth/countNotifyAdmin')
            }
            return
        },
        hidePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
