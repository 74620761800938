
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import { customUrlImage } from '@/shared/function'

export default Vue.extend({
    name: 'ZoomImage',
    data() {
        return {
            imageSrc: ''
        }
    },
    components: { Title },

    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    },
    mounted() {
        this.imageSrc = customUrlImage(this.getParamsPopup)
    }
})
