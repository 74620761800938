
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'

export default Vue.extend({
    name: 'ButtonCustom',
    props: {
        title: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        color: {
            type: String,
            default: '#fff'
        },
        bg: {
            type: String,
            default: '#000000'
        },
        border: {
            type: String,
            default: 'none'
        },
        width: {
            type: String
        },
        type: {
            type: String,
            default: 'button'
        }
    },
    computed: {
        style() {
            return {
                color: this.color,
                background: !this.disabled ? this.bg : '#eeeeee',
                border: this.border,
                width: this.width
            }
        }
    },
    methods: {
        onClick(event: any) {
            this.$emit('click', event)
        }
    }
})
