import apiServices from '@/shared/services/api/axios'
const ProviderSearchService = {
    apiGetListProducts(payload: any) {
        let url = '/provider/top-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetAllArea() {
        return apiServices.get('/areas')
    },
    apiSearchProduct(payload: any) {
        return apiServices.post(`/provider/search-product`, payload)
    },
    apiGetListProductViewed(payload: any) {
        let url = '/viewed-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiSavingProduct(payload: any) {
        return apiServices.post('/provider/saving-product', payload)
    },
    apiDeleteSavingProduct(payload: any) {
        return apiServices.post('/provider/delete-saving-product', payload)
    },
    apiGetAreaProvince(payload: any) {
        let url = '/area-province'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetInfoProvider(payload: any) {
        let url = 'provider/get-provider-info-by-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetProductsProvider(payload: any) {
        let url = '/provider-product-is-after-preview'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetProductSave(payload: any) {
        let url = '/provider/saving-product'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiDeleteProductSave(payload: any) {
        return apiServices.post('provider/delete-saving-products', payload)
    }
}
export default ProviderSearchService
