var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.layoutTable)?_c('tr',{class:_vm.classFormItem},[_c('th',[_c('div',{class:_vm.classLabelTh},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.labelTh)}}),(_vm.required)?_c('span',{staticClass:"req"},[_vm._v("※必須")]):_vm._e()])]),_c('td',{class:_vm.classTd},[_c('a-form-item',{class:_vm.classFormItem},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    _vm.nameInput,
                    {
                        valuePropName: 'checked',
                        initialValue: false
                    }
                ]),expression:"[\n                    nameInput,\n                    {\n                        valuePropName: 'checked',\n                        initialValue: false\n                    }\n                ]"}],attrs:{"disabled":_vm.disabled,"name":_vm.nameInput},on:{"change":_vm.changeChecked}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)],1)]):_c('a-form-item',{class:_vm.classFormItem,attrs:{"labelAlign":"left"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            _vm.nameInput,
            {
                valuePropName: 'checked',
                initialValue: false
            }
        ]),expression:"[\n            nameInput,\n            {\n                valuePropName: 'checked',\n                initialValue: false\n            }\n        ]"}],attrs:{"disabled":_vm.disabled,"name":_vm.nameInput},on:{"change":_vm.changeChecked}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }