
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'UserDetails',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    }
})
