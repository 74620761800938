
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import { isArray } from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustomNew from '@/components/common_new/button-custom/index.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import SelectCustom from '@/components/common/select/index.vue'
import { TypeInputCustom, ListNamePopup } from '@/shared/enums'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { OPTION_TITLES } from '@/modules/advisor/modules/provider/shared/constants'

export default Vue.extend({
    name: 'PreviewAdvisorListProductSave',
    components: {
        ButtonCustomNew,
        SelectCustom,
        InputCustom,
        TitlePopup
    },
    data(): any {
        return {
            listProductSelect: [],
            TypeInputCustom,
            OPTION_TITLES,
            form: this.$form.createForm(this, {
                name: 'form'
            }),
            dataPopup: {}
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        if (this.getParamsPopup) {
            this.listProductSelect = this.getParamsPopup?.listProductSelect
            this.dataPopup = this.getParamsPopup
            this.form.setFieldsValue(this.getParamsPopup)
        }
    },
    methods: {
        isArray,
        closePopup() {
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.AdvisorInquiryProvider,
                payload: {
                    listProductSelected: this.listProductSelect,
                    dataPopup: this.dataPopup
                }
            })
        },
        handleClickBtn() {
            this.form.validateFields((errors: Error[], values: any) => {
                if (errors) {
                    return
                }
                this.handleCreateRequest(values)
                this.$store.dispatch('popup/hidePopup')
            })
        },
        async handleCreateRequest(payload: any) {
            const body = {
                ...payload
            }
            if (isArray(this.listProductSelect)) {
                body.product_id = this.listProductSelect.map(
                    (item: any) => item.product_id
                )
            }
            await this.$store.dispatch(
                'advisorDataProvider/apiCreateMultipleInquiryProvider',
                body
            )
        }
    },
    watch: {}
})
