
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { menuAdmin } from '@/modules/admin/shared/contants'
import { menuAdvisor } from '@/modules/advisor/shared/contants'
import { menuProvider } from '@/modules/provider/shared/constants'
import { getDataStorage } from '@/shared'
import {
    KeyStorage,
    ListNamePopup,
    TypeAccount,
    TypeFeeAccount,
    UserTypeRole,
    ValueStatusAdvisor,
    ValueStatusProvider
} from '@/shared/enums'
import {
    checkIsTempAdvisor,
    checkIsTempProvider,
    filterDomain,
    isObject
} from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { menuAdminFQA } from '@/modules/adminFQA/shared/contants'
import SideMenu from '@/components/layouts/side-menu/index.vue'
import { navBarTitle } from '@/shared/constants'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'

export default Vue.extend({
    name: 'LayoutNavBar',
    components: {
        SideMenu
    },
    props: {
        isChangePositionAtBottomPage: {
            type: Boolean,
            default: false
        }
    },
    data(): any {
        return {
            listMenu: [],
            ValueStatusAdvisor,
            username: '',
            disableMyPage: false,
            profileUser: getDataStorage(KeyStorage.profile),
            TypeAccount,
            UserTypeRole,
            typeRouter: {} as any,
            profile: getDataStorage(KeyStorage.profile),
            navbarTopDistance: 0,
            sidebar: {},
            checkStatus: false,
            pathRouter: {}
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.handleResize)
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getUpdateBtnLayoutRegister',
            'getDataAdvisor'
        ]),
        ...mapGetters('signupAuth', ['getDataCount']),
        ...mapGetters('signupProvider', [
            'getDataCountProvider',
            'getDataProvider'
        ]),
        navBarTitle(): string {
            if (this.profileUser.user_type === UserTypeRole.admin) {
                return this.profileUser.type === TypeAccount.admin
                    ? navBarTitle.adminSys
                    : navBarTitle.adminFqa
            }
            if (this.profileUser.user_type === UserTypeRole.provider) {
                return navBarTitle.provider
            }
            return navBarTitle.advisor
        },
        isLayoutMenuAdvisor(): boolean {
            return (
                this.profileUser.user_type === UserTypeRole.advisor ||
                this.profileUser.user_type === UserTypeRole.provider
            )
        }
    },
    watch: {
        getDataAdvisor(newVal) {
            if (isObject(newVal)) {
                this.username = newVal.tax_advisor_name
                this.loadingContent()
            }
        },
        getDataProvider(newVal) {
            if (isObject(newVal)) {
                this.username = newVal?.provider_basic_info?.provider_name
                this.loadingContent()
                this.getListMenu()
            }
        },
        getUpdateBtnLayoutRegister: {
            handler(value) {
                if (value) {
                    this.getListMenu()
                }
            }
        },
        getDataCount(newdata) {
            if (newdata && isObject(newdata)) {
                this.listMenu = this.listMenu?.map((item: any) => {
                    if (item.name === 'countAnnouncements') {
                        item.title = `運営からのお知らせ${
                            newdata?.announcements === 0
                                ? ''
                                : `(${newdata?.announcements})`
                        }`
                    }
                    if (item.name === 'countInquiries') {
                        item.title = `管理者への問合せ${
                            newdata?.inquiries === 0
                                ? ''
                                : `(${newdata?.inquiries})`
                        }`
                    }
                    if (item.name === 'countIncomings') {
                        item.title = `個人・法人からの問合せ${
                            newdata?.incomings === 0
                                ? ' '
                                : `(${newdata?.incomings})`
                        }`
                    }
                    if (item.name === 'countInquiry') {
                        item.title = `問合せ管理${
                            newdata?.inquiries === 0
                                ? ''
                                : `(${newdata?.inquiries})`
                        }`
                    }
                    if (item.name === 'countConcierges') {
                        item.title = `コンシェルジュ管理${
                            newdata?.concierges === 0
                                ? ''
                                : `(${newdata?.concierges})`
                        }`
                    }
                    if (item.name === 'countAdminProviders') {
                        item.title = `事業者管理${this.totalCount(
                            newdata?.inivite ? newdata?.inivite : 0,
                            newdata?.provider_pending_remand
                                ? newdata?.provider_pending_remand
                                : 0
                        )}`
                    }

                    if (item.name === 'countConciergesAdvisor') {
                        item.title = `コンシェルジュ問合せ${
                            newdata?.concierges === 0
                                ? ''
                                : `(${newdata?.concierges})`
                        }`
                    }

                    if (isObject(item.subMenu)) {
                        item.subMenu?.map((subMenu: any) => {
                            if (subMenu.name === 'countInquiriesTax') {
                                subMenu.title = `税理士からの問合せ${
                                    newdata?.new_inquiries_tax === 0
                                        ? ''
                                        : `(${newdata?.new_inquiries_tax})`
                                }`
                            }
                            if (subMenu.name === 'countInquiriesGuest') {
                                subMenu.title = `個人・法人からの問合せ${
                                    newdata?.new_inquiries_guest === 0
                                        ? ''
                                        : `(${newdata?.new_inquiries_guest})`
                                }`
                            }
                            if (subMenu.name === 'countInquiriesProvider') {
                                subMenu.title = `事業者からの問合せ${
                                    newdata?.new_inquiries_provider === 0
                                        ? ''
                                        : `(${newdata?.new_inquiries_provider})`
                                }`
                            }
                            if (subMenu.name === 'countIniviteAdmin') {
                                subMenu.title = `紹介事業者${
                                    newdata?.inivite === 0
                                        ? ''
                                        : `(${newdata?.inivite})`
                                }`
                            }
                            if (
                                subMenu.name ===
                                'countAnnouncementAdminProviders'
                            ) {
                                subMenu.title = `審査待ち一覧${
                                    newdata?.provider_pending_remand === 0
                                        ? ''
                                        : `(${newdata?.provider_pending_remand})`
                                }`
                            }
                            subMenu.subMenu?.map((subM: any) => {
                                if (
                                    subM.name === 'countInquiryProductAdvisor'
                                ) {
                                    subM.title = `事業者からの回答${
                                        newdata?.productInquiries === 0
                                            ? ''
                                            : `(${newdata?.productInquiries})`
                                    }`
                                }
                            })
                        })
                    }
                    return item
                })
            }
            this.loadingContent()
        },
        getDataCountProvider(newData) {
            if (newData && isObject(newData)) {
                this.listMenu = this.listMenu?.map((item: any) => {
                    if (item.name === 'countAnnouncementProviders') {
                        item.title = `運営からのお知らせ${
                            newData?.announcements === 0
                                ? ''
                                : `(${newData?.announcements})`
                        }`
                    }
                    if (item.name === 'countInquiryProviders') {
                        item.title = `管理者への問合せ${
                            newData?.inquiries === 0
                                ? ''
                                : `(${newData?.inquiries})`
                        }`
                    }

                    if (item.name === 'countIncomings') {
                        item.title = `個人•法人•税理士からの問合せ${
                            newData?.incomings === 0
                                ? ' '
                                : `(${newData?.incomings})`
                        }`
                    }
                    if (isObject(item.subMenu)) {
                        item.subMenu?.map((subMenu: any) => {
                            subMenu.subMenu?.map((subMenu: any) => {
                                if (
                                    subMenu.name ===
                                    'countInquiryProductProvider'
                                ) {
                                    subMenu.title = `商材登録・編集・進捗${
                                        newData?.productInquiries === 0
                                            ? ''
                                            : `(${newData?.productInquiries})`
                                    }`
                                }
                            })
                        })
                    }
                    return item
                })
            }
            this.loadingContent()
        },
        $route: {
            handler() {
                this.getCountNotify(this.typeRouter)
                this.sidebar?.classList.remove('sCntnt_block')
                const bodyClassList = document.body.classList
                bodyClassList.remove('open')
                const spBtnMenu = this.$refs.spBtnMenu as HTMLElement
                spBtnMenu.classList.remove('is-active')
            }
        },
        pathRouter: {
            handler() {
                this.sidebar?.classList.remove('sCntnt_block')
            },
            deep: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.handleResize)
        this.pathRouter = this.$route
        this.getListMenu()
        this.typeRouter = filterDomain()
        this.getCountNotify(this.typeRouter)
        this.sidebar = this.$refs.sidebar as Element
        this.navbarTopDistance = this.sidebar.getBoundingClientRect().top
        if (this.profile) {
            switch (Number(this.profile.status)) {
                case 1:
                    this.username = this.profile.username
                    break
                case 2:
                    this.username = this.profile.tax_advisor_name
                    break
                default:
                    this.username =
                        this.profile.provider_basic_info?.provider_name
                    break
            }
        }
    },
    methods: {
        handleScroll(event: any) {
            const windowSize = window.innerHeight
            const sidebarHeight = this.sidebar.clientHeight
            const bodyScrollHeight = document.body.scrollHeight
            if (
                this.isChangePositionAtBottomPage &&
                window.innerHeight + window.scrollY >= bodyScrollHeight - 200 &&
                window.innerWidth > 768
            ) {
                // this.sidebar.style.position = 'fixed'
                // this.sidebar.style.top = 0
            } else {
                this.sidebar?.classList.remove('sCntnt_block')
                this.sidebar.style.position = null
                this.sidebar.style.top = null
            }
            if (
                windowSize < sidebarHeight + this.navbarTopDistance &&
                sidebarHeight + this.navbarTopDistance + 92 > bodyScrollHeight
            ) {
                this.sidebar?.classList.remove('sCntnt_block')
                return
            }
            if (this.sidebar.getBoundingClientRect().top <= 0) {
                this.sidebar?.classList.add('sCntnt_block')
            }
            if (
                event?.target.documentElement.scrollTop <=
                this.navbarTopDistance
            ) {
                this.sidebar?.classList.remove('sCntnt_block')
            }
        },
        handleResize(event: any) {
            const isCheckResize = event?.target?.document?.documentElement
            if (isCheckResize) {
                this.sidebar?.classList.remove('sCntnt_block')
            }
        },
        getCountNotify(typeRouter: any) {
            if (typeRouter?.role === UserTypeRole.advisor) {
                this.$store.dispatch('signupAuth/countNotifyAdvisor')
            }
            if (typeRouter?.role === UserTypeRole.admin) {
                this.$store.dispatch('signupAuth/countNotifyAdmin')
            }
            if (typeRouter?.role === UserTypeRole.provider) {
                this.$store.dispatch('signupProvider/countNotifyProvider')
            }
        },
        redirectToMyPage() {
            if (this.disableMyPage) return
            window.location.href = '/'
        },
        getListMenu() {
            const typeRouter = filterDomain()
            if (!typeRouter) {
                return
            }
            if (typeRouter.role === UserTypeRole.advisor) {
                this.checkStatus = checkIsTempAdvisor()
                if (
                    this.profile &&
                    Number(this.profile.status) ===
                        ValueStatusAdvisor.TEMPORARY_REGISTRATION
                ) {
                    this.disableMyPage = true
                    this.listMenu = menuAdvisor.filter(
                        item =>
                            item.status ===
                            ValueStatusAdvisor.TEMPORARY_REGISTRATION
                    )
                    return
                }
                this.listMenu = menuAdvisor.filter(
                    item =>
                        item.status !==
                        ValueStatusAdvisor.TEMPORARY_REGISTRATION
                )
            }
            if (typeRouter.role === UserTypeRole.provider) {
                this.disableMyPage = checkIsTempProvider()
                this.checkStatus = checkIsTempProvider()
                this.listMenu = menuProvider.filter(item =>
                    checkIsTempProvider()
                        ? Number(item.status) ===
                          ValueStatusProvider.TEMPORARY_REGISTRATION
                        : Number(item.status) !==
                          ValueStatusProvider.TEMPORARY_REGISTRATION
                )
                return
            }
            if (typeRouter.role === UserTypeRole.admin) {
                if (this.profile && this.profile.type === TypeAccount.admin) {
                    this.listMenu = menuAdmin
                }
                if (
                    this.profile &&
                    this.profile.type === TypeAccount.adminFQA
                ) {
                    this.listMenu = menuAdminFQA
                }
            }
        },
        loadingContent() {
            if (this.getDataCount && this.getDataAdvisor) {
                this.$store.dispatch('loading/setIsLoading', false)
            }
        },
        openNavSP() {
            const bodyClassList = document.body.classList
            const spBtnMenu = this.$refs.spBtnMenu as HTMLElement
            bodyClassList.toggle('open')
            spBtnMenu.classList.toggle('is-active')
        },
        totalCount(invited: number, provider_pending_remand: number) {
            const total = invited + provider_pending_remand
            return total ? `(${total})` : ''
        }
    }
})
