
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import PopupCommon from '@/components/common/popup/index.vue'
import Loading from '@/components/common_new/loading/index.vue'

export default Vue.extend({
    name: 'LayoutGuestAnnouncement',
    components: { Loading, PopupCommon },
    computed: {
        ...mapGetters('popup', ['getIsLoading'])
    },
    data() {
        return {
            title: 'みつかる税理士'
        }
    }
})
