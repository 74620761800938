
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ButtonCustomNew',
    props: {
        label: String,
        type: {
            type: String,
            default: 'button'
        },
        classes: {
            type: [Array, String]
        },
        disabled: {
            type: Boolean
        },
        btnCallApi: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('popup', ['getIsLoading']),
        isDisabled() {
            if (this.disabled) return this.disabled
            return this.getIsLoading
        }
    },
    watch: {
        getIsLoading(newVal) {
            if (!newVal) this.$vnode.elm.classList.remove('btnCallApi')
        }
    },
    methods: {
        onClick(event: Event) {
            this.$emit('onClick', event)
            if (this.btnCallApi) {
                const target = event.target as Element
                target?.classList.add('btnCallApi')
            }
        }
    }
})
