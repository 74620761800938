
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import { regex } from '@/shared/regex'
import { TypeInputCustom, KeyStorage } from '@/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import SelectCustom from '@/components/common/select/index.vue'
import { getDataStorage } from '@/shared'
import { getRawHTML, scrollToFirstErrorLineForm } from '@/shared/function'
import { OPTION_TITLES } from '@/modules/advisor/modules/provider/shared/constants'

export default Vue.extend({
    name: 'InquiryToProvider',
    components: { InputCustom, Title, ButtonCustom, SelectCustom },
    data() {
        return {
            TypeInputCustom,
            OPTION_TITLES,
            dataProduct: [],
            type: '',
            form: this.$form.createForm(this, {
                name: 'form_send_inquiry'
            })
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        if (this.getParamsPopup?.product) {
            this.dataProduct = this.getParamsPopup?.product
            this.form.setFieldsValue({
                product_name: this.dataProduct?.product_name,
                title: this.dataProduct?.title,
                inquiry_content: this.dataProduct?.inquiry_content,
                signature: this.dataProduct?.signature
            })
        }
        if (this.getParamsPopup?.type) {
            this.type = this.getParamsPopup?.type
            if (this.type === 'created') this.setSignature()
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        setSignature() {
            const signature = getDataStorage(
                KeyStorage.profile
            ).signature_content

            this.form.setFieldsValue({
                signature: signature
            })
        },
        handleSubmit(status: number) {
            this.form.validateFields(
                async (
                    err: Error[],
                    values: { inquiry_content: any; title: any; signature: any }
                ) => {
                    if (err) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const { inquiry_content, title, signature } = values
                    const payload: any = {
                        id: this.type !== 'created' ? this.dataProduct?.id : '',
                        product_id:
                            this.type !== 'created'
                                ? this.dataProduct?.product_id
                                : this.dataProduct?.id,
                        title: title,
                        inquiry_content: inquiry_content,
                        draft_flg: status,
                        signature: getRawHTML(signature)
                    }
                    await this.$store.dispatch(
                        'advisorInquiryProvider/CreateInquiryProvider',
                        payload
                    )
                    await this.$store.dispatch(
                        'advisorInquiryProvider/AdvisorInquiryProduct',
                        ''
                    )
                    this.$store.dispatch('popup/hidePopup')
                }
            )
        },
        ruleValidTitleMessage() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_inquiry_provider_titleMessage2_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_provider_titleMessage2_required'
                                    )
                                )
                        }
                        callback()
                    }
                }
            ]
        },
        ruleValidContentMessage() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_inquiry_contentMessage_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (regex.space_full.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_contentMessage_required'
                                    )
                                )
                            if (!regex.length500.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_inquiry_contentMessage_length500'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesWriteSignature() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length500.test(value))
                                return callback(
                                    this.$tc(
                                        'i18n_advisor_signature_writesSignature_length500'
                                    )
                                )
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
