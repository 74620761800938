import { StatusReadInquiryFqa } from '@/modules/adminFQA/modules/inquiry/shared/enums'
import { i18n } from '@/shared/i18n'
import { ITypeSelectCustom } from '@/shared/constants/IContants'
import { ITitlePageInquiry } from '../@types'

const listStatusReadInquiryFQA: ITypeSelectCustom[] = [
    {
        label: i18n.t('i18n_status_inquiry_fqa_new'),
        value: StatusReadInquiryFqa.NEW
    },
    {
        label: i18n.t('i18n_status_inquiry_fqa_not_read'),
        value: StatusReadInquiryFqa.NOT_READ
    },
    {
        label: i18n.t('i18n_status_inquiry_fqa_read'),
        value: StatusReadInquiryFqa.READ
    },
    {
        label: i18n.t('i18n_status_inquiry_fqa_keep'),
        value: StatusReadInquiryFqa.KEEP
    },
    {
        label: i18n.t('i18n_status_inquiry_fqa_answered'),
        value: StatusReadInquiryFqa.ANSWERED
    }
]

const titlePageInquiry: ITitlePageInquiry = {
    default: '問合せ管理',
    forGuest: '問合せ参照',
    forAdvisor: '問合せ回答',
    preview: '問合せ回答'
}

export { listStatusReadInquiryFQA, titlePageInquiry }
