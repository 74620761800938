
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import Title from '@/components/common_new/popup/title.vue'
import { ValueStatusAdvisor } from '@/shared/enums'
export default Vue.extend({
    name: 'StopAdvisorPopup',
    components: { ButtonCustom, Title },
    data() {
        return {
            titlePopup: '税理士停止',
            descriptionPopup: 'この会員のサービスを停止しますか？',
            formDelete: this.$form.createForm(this, {
                name: 'form_stop'
            })
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            let res = false
            const { status, id } = this.getParamsPopup

            const params = {
                id
            }
            if (Number(status) === ValueStatusAdvisor.OFFICIAL_REGISTRATION) {
                res = await this.$store.dispatch(
                    'advisorAdmin/stopAdvisor',
                    params
                )
            }
            if (Number(status) === ValueStatusAdvisor.STOP) {
                res = await this.$store.dispatch(
                    'advisorAdmin/unStopAdvisor',
                    params
                )
            }

            if (res) {
                await this.$store.dispatch('popup/hidePopup')
                this.$store.commit('advisorAdmin/setDataAdvisorAdmin', {
                    refreshData: true
                })
            }
        }
    },
    mounted() {
        if (Number(this.getParamsPopup.status) === ValueStatusAdvisor.STOP) {
            this.titlePopup = '税理士活動'
            this.descriptionPopup = 'この会員のサービス停止を解除しますか？'
        }
    }
})
