import popup from '@/store/popup'
import signupProvider from '@/modules/provider/modules/signup/store'
import signupAdvisor from '@/modules/advisor/modules/signup/store'
import signupAuth from '@/modules/auth/store'
import loading from '@/store/loading'
import announcementProvider from '@/modules/provider/modules/announcement/store'
import myPageProvider from '@/modules/provider/modules/mypage/store'
import inquiryProvider from '@/modules/provider/modules/inquiry/store'
import providerAdmin from '@/modules/admin/modules/providers/store'
import advisorAdmin from '@/modules/admin/modules/advisors/store'
import incomingProvider from '@/modules/provider/modules/incoming/store'
import productProvider from '@/modules/provider/modules/product/store'
import countAccess from '@/modules/provider/modules/countAccess/store'
import providerSearch from '@/modules/provider/modules/search-provider/store'
import providerPayment from '@/modules/provider/modules/payment/store'

export const storeProvider = {
    popup,
    signupProvider,
    signupAdvisor,
    signupAuth,
    loading,
    announcementProvider,
    myPageProvider,
    inquiryProvider,
    providerAdmin,
    advisorAdmin,
    incomingProvider,
    productProvider,
    countAccess,
    providerSearch,
    providerPayment
}
