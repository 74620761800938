
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { getDataStorage } from '@/shared'
import { KeyStorage } from '@/shared/enums'
import { customUrlImage, customUrlProfileImage } from '@/shared/function'
import { mapGetters } from 'vuex'
import { customDataAdvisorSelectStorage } from '@/shared/function'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper/core'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
SwiperCore.use([Navigation])

export default Vue.extend({
    name: 'RecentlyViewProviderProduct',
    computed: {
        ...mapGetters('providerSearch', ['getDataProductViewed'])
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            showHistory: false,
            isMobile: false
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize)
        }
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 769
        },
        getListDataProductViewed() {
            const listProductSelect = getDataStorage(
                KeyStorage.productRecentlyViewed
            )
            const idProductViewCurrent =
                this.$route.query.tab === 'ProductInfo'
                    ? Number(this.$route.params.id)
                    : 0

            if (!listProductSelect) {
                this.showHistory = false
                return
            }
            const listProductStorage = customDataAdvisorSelectStorage(
                listProductSelect
            ).reduce((preVal: any, nextVal: any) => {
                if (preVal.length < 5) {
                    if (
                        (!Number.isNaN(idProductViewCurrent) &&
                            idProductViewCurrent !== nextVal) ||
                        Number.isNaN(idProductViewCurrent)
                    ) {
                        return preVal.concat(nextVal)
                    }
                }
                return preVal
            }, [])

            if (listProductStorage.length === 0) {
                this.showHistory = false
            }
            const payload: any = {
                viewed_products: listProductStorage.toString()
            }

            this.$store.dispatch(
                'providerSearch/getListDataProductViewed',
                payload
            )
        },
        renderImage(img1: string, img2: string, img3: string) {
            let image = img1
            if (img1 === '' || img1 === null) {
                image = img2
            }
            if (image === '' || image === null) {
                image = img3
            }

            if (image === null) image = ''
            if (image !== '') {
                return customUrlImage(image)
            } else {
                return customUrlProfileImage('')
            }
        },
        redirectDetail(id: number | string, user_provider_id: number | string) {
            this.$router.push(
                RouterProviderUrl.providerSearchDetail +
                    id +
                    `?provider_id=${user_provider_id}&tab=ProductInfo`
            )
        }
    },
    watch: {
        getDataProductViewed: {
            handler(newVal) {
                if (newVal && newVal.length) {
                    if (
                        newVal.length === 1 &&
                        this.$route.query.tab === 'ProductInfo' &&
                        newVal[0] === this.$route.params.id
                    ) {
                        this.showHistory = false
                        return
                    }
                    this.showHistory = true
                    return
                }
                this.showHistory = false
            },
            deep: true
        },
        $route: {
            async handler(value: any) {
                await this.getListDataProductViewed()
                this.onResize()
                window.addEventListener('resize', this.onResize)
            },
            deep: true,
            immediate: true
        }
    }
})
