var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"send-back-provider"},[_c('TitlePopup',{attrs:{"title":"差し戻し"}}),_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"change-email_tableWrap mt-2"},[_c('table',{staticClass:"tableType1 change-email_table"},[_c('tbody',[_c('InputCustom',{attrs:{"label":"宛名","name-input":"provider_name","form-instance":_vm.form,"disabled":"","class-input":"inputFocus1","layout-table":"","class-label":"tbType1_ttlWrap"}}),_c('SelectCustom',{attrs:{"label":"タイトル","form-instance":_vm.form,"name-input":"title","rules":[
                            {
                                required: true,
                                message: 'タイトルを選択して下さい。'
                            }
                        ],"option-select":_vm.OPTION_TITLES,"required":"","class-input":"inputFocus1","class-form-item":"formItemSelect","layout-table":"","class-label":"tbType1_ttlWrap"}}),_c('InputCustom',{attrs:{"label":"通知内容","name-input":"content","form-instance":_vm.form,"rules":_vm.validContent(),"required":"","type-input-custom":_vm.TypeInputCustom.textarea,"class-input":"inputFocus1","layout-table":"","class-label":"tbType1_ttlWrap"}},[_c('InputCustomEditor',{attrs:{"class-input":"inputFocus1","class-form-item":"formSignatureFull","label":"","name-input":"signature","form-instance":_vm.form,"rules":_vm.validSignature(),"is-show-rich-text":true,"config-editor":{
                                ..._vm.configEditorWithSelectFont,
                                height: 200
                            },"default-rich-text":_vm.signatureEditor,"type-input-custom":_vm.TypeInputCustom.textarea}})],1)],1)])])]),_c('div',{staticClass:"change-email_btnWrap flexCols flexJc pc-mt-2-5 sp-mt-2"},[_c('ButtonCustom',{attrs:{"label":"戻る","classes":"btnType2c js-modal_close"},on:{"onClick":_vm.closePopup}}),_c('ButtonCustom',{attrs:{"label":"一時保存","classes":"btnConc btnConcType2 withdrawal_type","btnCallApi":""},on:{"onClick":function($event){return _vm.handleSendBack(_vm.StatusSendBackProviderAdmin.DRAG)}}}),_c('ButtonCustom',{attrs:{"label":"送信","classes":"btnType2a withdrawal_type","btnCallApi":""},on:{"onClick":function($event){return _vm.handleSendBack(_vm.StatusSendBackProviderAdmin.SAVE)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }