/* eslint-disable */
//Do Not Use Positive or Negative Lookbehind ( (?<!) or (?<=) ) because low versions of safari won't support
export const regex = {
    full_name_vn:
        /^[A-Za-zảáàạãăắằặẵâấầẫậĐđèéẹẽêếềệễìíịĩóòọõôốồỗộơớờợỡùuúụũưứừựữýỳỵỹẮẰẲẴẶĂẤẦẨẪẬÂÁÀÃẢẠÈÉẸẼÊẾỀỆỄÍÌỊĨÓÒÕỌÔỒỐỖỘƠỚỜỠỢÙÚỤŨƯỪỮỰÝỲỸỴ]+(\s[A-Za-zảáàạãăắằặẵâấầẫậĐđèéẹẽêếềệễìíịĩóòọõôốồỗộơớờợỡùúụũưứừựữýỳỵỹẮẰẲẴẶĂẤẦẨẪẬÂÁÀÃẢẠÈÉẸẼÊẾỀỆỄÍÌỊĨÓÒÕỌÔỒỐỖỘƠỚỜỠỢÙÚỤŨƯỪỮỰÝỲỸỴ]+)*$/,
    // user_name: /^(?=.{3,20}$)(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
    phone_number: /^[0-9]+$/,
    password:
        /^(?!.*[!"#$%&'\\()*+,-.:;\[\]<=>?@/^_`{|}~]{2})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'\\()*+,-.:;\[\]<=>?@/^_`{|}~])([a-zA-Z0-9!"#$%&'\\()*+,-.:;\[\]<=>?@/^_`{|}~]+)$/,
    // email: new RegExp(/^[a-zA-Z0-9_.]{1,200}[@][a-z]{1,20}[.][a-z]{1,25}(.[a-z]+)?$/),
    email: new RegExp(
        /^(?!.*[_.+\/?-]{2})[a-zA-Z0-9]([a-zA-Z0-9_.+\/?-])*[^\s　`!@#$%^&*()=\[\]{};':"\\|,.<>~]@[^\s　`!@#$%^&*()=\[\]{};':"\\|,.<>~]([a-z0-9_.+-]+)[.]([a-z]+)(.[a-z]+([+])?)?$/
    ),
    lengthNameEmail: new RegExp(/^([a-zA-Z0-9_.+\/?-]){1,63}@/),
    urlHttps:
        /^(?=.{1,1023}$)(https:|http:)([a-zA-Z0-9\[\]"{}=!:,%&?@+'_./-]+)([.][a-zA-Z0-9\[\]"{}=!:,%&?@+'_./-]+)$/,
    name: new RegExp(/^([a-zA-Z]+){1,100}$/),
    // landline_phone: new RegExp(/^(?!.*[ ]{2})(?!.*[-]{2})(([(])([+])([0-9]{2})([)])(([0-9]{4}){2})(([-]|[ ])*))$/),
    money: new RegExp(/^(?=.{1,14}$)\d+((,)*(\d+)*([円]*))*$/),
    rate: new RegExp(/\d\.\d{2,}/),
    lengthPassword: new RegExp(/^(.{8,30}$)$/),
    lengthPhone: new RegExp(/^(.{1,15}$)$/),
    lengthOfficeName: new RegExp(/^(.{1,30}$)$/),
    lengthIntroText: new RegExp(/^(.{1,150}$)$/),
    lengthNumberphone: new RegExp(/^(.{1,20}$)$/),
    lengthPostCode: new RegExp(/^(.{1,10}$)$/),
    length100: new RegExp(/^[\s\S]{1,100}$/),
    length2: new RegExp(/^(.{1,2}$)$/),
    length5: new RegExp(/^(.{1,5}$)$/),
    length20: new RegExp(/^(.{1,20}$)$/),
    length30: new RegExp(/^(.{1,30}$)$/),
    length40: new RegExp(/^[\s\S]{1,40}$/),
    length50: new RegExp(/^[\s\S]{1,50}$/),
    length150: new RegExp(/^[\s\S]{1,150}$/),
    length255: new RegExp(/^[\s\S]{1,255}$/),
    length300: new RegExp(/^[\s\S]{1,300}$/),
    length500: new RegExp(/^[\s\S]{1,500}$/),
    length1000: new RegExp(/^[\s\S]{1,1000}$/),
    length1023: new RegExp(/^[\s\S]{1,1023}$/),
    length3000: new RegExp(/^[\s\S]{1,3000}$/),
    space_top_or_end: new RegExp(/^\s+\S+|\S+\s+?$/),
    space_full: new RegExp(/^\s+$/),
    only_space: new RegExp(/^\s+$/i),
    // string_remove_space_of_start: new RegExp(/^\s+|\s+$/g),
    string_remove_space_of_start: new RegExp(/^\s*(\w.*)$/),
    // emoji: new RegExp(
    //     /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/  // regex emoji include special characters
    // )
    emoji: new RegExp(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/ // regex emoji not include special characters
    ),
    newline: new RegExp(/\n|\r\n/),
    fullwidthHiragana: /^[ぁ-ん\s・]+$/,
    onlyNumber: /^[0-9\-]*$/,
    halfwidthAlphabet: /^[a-zA-Z0-9]+$/,
    halfwidth: /^[a-zA-Z]+$/,
    halfwidthNumber: /^[0-9]+$/,
    // regexUrl: new RegExp(
    //     '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
    // ),
    regexUrl: new RegExp(
        '^(https?|ftp):\\/\\/(www\\.)?[\\w.-]+(\\.[a-zA-Z]{2,3})+(\\/[\\w .-]*)*(\\?[^\\s]*)?$'
    ),
    regexUrlCustom: new RegExp(
        '(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})?'
    ),
    halfWidth:
        /^(?!.*[!"#$%&'\\()*+,-.:;\[\]<=>?@/^_`{|}~]{2})[a-zA-Z0-9\s\uff66-\uff9f!"#$%&'\\()*+,-.:;\[\]<=>?@/^_`{|}~]+$/,
    specialCharacters: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    base64: /data:(image|video)\/[^;]+;base64[^"]+/,
    andCharacter: /&/g,
    lowerCaseCharacter: /[a-z]/,
    upperCaseCharacter: /[A-Z]/,
    numberCharacterPassword: /[0-9]/,
    // landline_phone: new RegExp(
    //     /^(?=.{14}$)([(])[0-9]{2-3}([)])(-)[0-9]{4}-[0-9]{4}(?<!-)$/
    // ), // XXX-XXXX-XXXX
    // mobilePhone: new RegExp(
    //     /^(?=.{12,13}$)(?!.*[ ]{2})(?!.*[-]{2})([0-9]{2,3})[-]([0-9]){4}[-]([0-9]){4}(?<![-])$/
    // ), // XXX-XXXX-XXXX
    // mobilePhone: new RegExp(/^\(?([0-9]{2,3})\)?[.]?([0-9]{4})[.]?([0-9]{4})$/) // XX-XXXX-XXXX || XXX-XXXX-XXXX
    zipCode: new RegExp(/^(?=.{8}$)(?!.*-{2})(-?)([0-9]){3}-([0-9]){4}$/),
    validPhoneNumber: new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    ),
    validZipCode: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{4})$/),
    specialCharactersMobilePhone: /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/,
    onlyLength14: /^(?=.*[A-Z]\d)[\dA-Z]{14}$/
}
