import { copyDeep } from '@/shared'
import {
    IPayloadCreateConciergeAdvisor,
    IStateStoreConciergeAdvisor,
    ITypeParamsDetailConciergeAdvisor,
    IPayloadDeleteConciergeAdvisor,
    ITypePayloadAnswerConciergeAdvisor
} from '@/modules/advisor/modules/concierge/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import ConsiergeAdvisorService from '@/modules/advisor/modules/concierge/services'
import router from '@/router'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'

export const defaultStateConciergeAdmin: IStateStoreConciergeAdvisor = {
    data: {}
}

const state = () => copyDeep(defaultStateConciergeAdmin)

const getters = {
    getDataConcierge(state: IStateStoreConciergeAdvisor) {
        return state.data
    }
}

const mutations = {
    setDataConciergeAdvisor(state: IStateStoreConciergeAdvisor, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getDataConcierge(
        { commit }: any,
        payload: IPayloadCreateConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsiergeAdvisorService.apiGetConciergeAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeAdvisor', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailConcierge(
        { commit, dispatch }: any,
        params: ITypeParamsDetailConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, {
            root: true
        })
        try {
            const res =
                await ConsiergeAdvisorService.apiGetConciergeDetailAdvisor(
                    params
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataConciergeAdvisor', data.data)
                dispatch('signupAuth/countNotifyAdvisor', true, { root: true })
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            return router.push(RouterAdvisorUrl.concierge)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async createConcierge(
        { commit, dispatch }: any,
        payload: IPayloadCreateConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await ConsiergeAdvisorService.apiCreateConciergeAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteConciergeAdvisor(
        { commit, dispatch }: any,
        payload: IPayloadDeleteConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await ConsiergeAdvisorService.apiDeleteConciergeAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataConciergeAdvisor', {
                    refreshData: true
                })
                dispatch('signupAuth/countNotifyAdvisor', true, { root: true })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async createConciergeAnswer(
        { commit, dispatch }: any,
        payload: ITypePayloadAnswerConciergeAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await ConsiergeAdvisorService.apiCreateConciergeAnswerAdvisor(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataDetailConcierge', {
                    id: payload?.concierge_id
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            return router.push(RouterAdvisorUrl.concierge)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
