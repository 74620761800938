
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SelectBoxItem from '../components/SelectBoxItem.vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ITypeSelectCustom } from '@/shared/constants/IContants'
import { generateValueCheckbox } from '@/modules/advisor/modules/signup/shared/functions'
import { copyDeep } from '@/shared'

export default Vue.extend({
    name: 'QualificationSelectPopup',
    components: { SelectBoxItem, TitlePopup, ButtonCustom },

    data(): any {
        return {
            listVal: [],
            keyNameQualification: 'edu_qualification'
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getBaseQualification',
            'getDataQualification'
        ])
    },
    mounted() {
        const { edu_qualification } = this.getDataQualification
        this.listVal = copyDeep(edu_qualification)
    },
    methods: {
        customDataCheckbox(listCheckboxObject: any) {
            const listNew: ITypeSelectCustom[] = []
            for (const key in listCheckboxObject) {
                const obj: ITypeSelectCustom = {
                    label: listCheckboxObject[key],
                    value: Number(key)
                }
                listNew.push(obj)
            }
            return listNew
        },
        onChangeCheckBoxSpecialDomain(value: any) {
            if (value.length > 5) {
                return
            }
            this.listVal = value
        },
        submitQualification() {
            const { edu_qualification, edu_qualification_name } =
                generateValueCheckbox(
                    this.getBaseQualification,
                    this.listVal,
                    this.keyNameQualification
                )

            const listDataService = {
                edu_qualification,
                edu_qualification_name
            }
            this.$store.dispatch(
                'signupAdvisor/updateDataQualification',
                listDataService
            )
            this.$store.dispatch('popup/hidePopup')
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
