
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { videoOnline } from '@/shared/constants'

export default Vue.extend({
    name: 'IframeVideoForm',
    props: {
        typeVideoOnl: {
            type: String,
            required: true
        },
        idUrl: {
            type: String,
            required: true
        },
        height: {
            type: String,
            default: '275'
        },
        width: {
            type: String,
            default: '548'
        }
    },
    data() {
        return {
            videoOnline
        }
    }
})
