import { KeyStorage } from '@/shared/enums'

const getRole = () => {
    return localStorage.getItem(KeyStorage.role)
}

const clearStorage = () => {
    localStorage.clear()
}

const clearLoginStorage = () => {
    removeDataStorage(KeyStorage.assetToken)
    removeDataStorage(KeyStorage.profile)
    removeDataStorage(KeyStorage.refreshToken)
}

const copyDeep = (value: any) => {
    return JSON.parse(JSON.stringify(value))
}

const getDataStorage = (key: string) => {
    const data = localStorage.getItem(key)
    if (key === KeyStorage.profile) {
        return data ? JSON.parse(data) : null
    }
    return data
}

const setDataStorage = (key: string, value: string) => {
    localStorage.setItem(key, value)
}

const removeDataStorage = (key: string) => {
    localStorage.removeItem(key)
}

const debounce = (func: any, delay: any) => {
    // let debounceTimer: any
    // return function () {
    //     // @ts-ignore
    //     const context = this
    //     const args = arguments
    //     clearTimeout(debounceTimer)
    //     debounceTimer = setTimeout(() => func.apply(context, args), delay)
    // }
    return {}
}

export {
    copyDeep,
    clearStorage,
    debounce,
    getDataStorage,
    getRole,
    setDataStorage,
    removeDataStorage,
    clearLoginStorage
}
