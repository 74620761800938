import { clearLoginStorage, clearStorage, getDataStorage } from '@/shared'
import {
    KeyStorage,
    TypeAccount,
    UserTypeRole,
    ValueStatusAdvisor
} from '@/shared/enums'
import { objectHasKey } from '@/shared/function'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import { statusAdvisor } from '@/shared/constants'

export default function auth({ next, router, to, ...res }: any) {
    const token = getDataStorage(KeyStorage.assetToken)
    const profile = getDataStorage(KeyStorage.profile)
    if (!token || !profile) {
        clearLoginStorage()
        return router.push(RouterAdvisorUrl.signin)
    }
    const { type, user_type, status } = profile
    // check list router to type account Admin
    if (user_type === UserTypeRole.admin) {
        if (type === TypeAccount.admin) {
            if (!objectHasKey(RouterAdminUrl, to.name)) {
                return router.push(RouterAdminUrl.notFound)
            }
        }
        if (type === TypeAccount.adminFQA) {
            if (!objectHasKey(RouterAdminFQA, to.name)) {
                return router.push(RouterAdminUrl.notFound)
            }
        }
    }
    if (user_type === UserTypeRole.advisor) {
        if (
            Number(status) === ValueStatusAdvisor.TEMPORARY_REGISTRATION &&
            to.path !== RouterAdvisorUrl.basicInformations
        ) {
            return router.push(RouterAdvisorUrl.basicInformations)
        }
    }
    return next()
}
