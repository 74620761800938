
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'popUpConfirmCountAccess',
    components: { ButtonCustom, Title },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
