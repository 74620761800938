import {
    ITypeListNavigateMenu,
    ITypeListTitleHeader
} from '@/shared/constants/IContants'
import {
    DeleteFlg,
    ListNamePopup,
    ValueStatusAdvisor,
    ViewMode
} from '@/shared/enums'
import {
    IFormSampleFees,
    ITypeListTabForm
} from '@/modules/advisor/shared/@types'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { ITypeColumnsTableCustom } from '@/modules/admin/modules/admin-management/shared/constants/IConstants'
import { nameRouterAdvisor } from '@/shared/constants'

const columnsListInquiry: ITypeColumnsTableCustom = [
    {
        title: '問合せ日',
        dataIndex: 'created_at',
        key: 'created_at',
        scopedSlots: { customRender: 'created_at' }
    },
    {
        title: '問合せ者の種別',
        dataIndex: 'sender_type',
        key: 'sender_type',
        scopedSlots: { customRender: 'sender_type' }
    },
    {
        title: '名前',
        dataIndex: 'sender_name',
        key: 'sender_name'
    },
    {
        title: 'タイトル',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: '回答日',
        dataIndex: 'reply_date',
        key: 'reply_date',
        scopedSlots: { customRender: 'reply_date' }
    },
    {
        title: '状況',
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '',
        dataIndex: 'is_contacted_flg',
        key: 'is_contacted_flg',
        scopedSlots: { customRender: 'is_contacted_flg' }
    },
    {
        title: '',
        key: 'action',
        scopedSlots: { customRender: 'action' }
    }
]

const menuAdvisor: ITypeListNavigateMenu[] = [
    {
        title: '基本情報管理',
        status: ValueStatusAdvisor.TEMPORARY_REGISTRATION,
        icon: 'icon-user',
        isOpen: true,
        subMenu: [
            {
                title: '',
                to: '',
                status: ValueStatusAdvisor.TEMPORARY_REGISTRATION,
                subMenu: [
                    {
                        title: '登録情報',
                        to: RouterAdvisorUrl.basicInformations,
                        status: ValueStatusAdvisor.TEMPORARY_REGISTRATION,
                        icon: 'icon-note'
                    }
                ]
            }
        ]
    },
    {
        title: '基本情報管理',
        status: 0,
        icon: 'icon-user',
        isOpen: false,
        subMenu: [
            {
                title: '・プロフィール登録・編集',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: '基本情報',
                        to: RouterAdvisorUrl.basicInformations,
                        status: 0,
                        icon: 'icon-note'
                    },
                    {
                        title: '事務所情報',
                        to: RouterAdvisorUrl.officeInformations,
                        status: 0,
                        icon: 'icon-buil'
                    },
                    {
                        title: '所属税理士情報',
                        to: RouterAdvisorUrl.affiliateAdvisor,
                        status: 0,
                        icon: 'icon-man'
                    },
                    {
                        title: '料金・事例',
                        to: RouterAdvisorUrl.sampleFees,
                        status: 0,
                        icon: 'icon-price'
                    },
                    {
                        title: 'インタビュー',
                        to: RouterAdvisorUrl.interview,
                        status: 0,
                        icon: 'icon-mic'
                    },
                    {
                        title: '動画プロモーション',
                        to: RouterAdvisorUrl.promotions,
                        status: 0,
                        icon: 'icon-movie'
                    }
                ]
            },
            {
                title: '・アカウント登録・編集',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: 'メールアドレス変更',
                        status: 0,
                        namePopup: ListNamePopup.ChangeEmail,
                        icon: 'icon-mail'
                    },
                    {
                        title: 'パスワード変更',
                        status: 0,
                        namePopup: ListNamePopup.ChangePassword,
                        icon: 'icon-lock'
                    },
                    {
                        title: '署名設定',
                        status: 0,
                        namePopup: ListNamePopup.CreateSignature,
                        icon: 'icon-note2'
                    },
                    // {
                    //     title: '退会',
                    //     status: 0,
                    //     namePopup: ListNamePopup.TermOfServiceWithdrawal,
                    //     icon: 'icon-note2'
                    // },
                    {
                        title: '受信用メールアドレス登録•編集',
                        namePopup: ListNamePopup.MailListAdvisor,
                        status: 0,
                        icon: 'icon-mail2'
                    }
                ]
            }
        ]
    },
    {
        title: '商材検索',
        to: '',
        status: 0,
        icon: 'icon-book2',
        isOpen: false,
        subMenu: [
            {
                title: '',
                to: '',
                status: 0,
                subMenu: [
                    {
                        title: '商材検索',
                        to: RouterAdvisorUrl.productSearch,
                        status: 0,
                        icon: 'icon-book-bk'
                    },
                    {
                        title: '事業者からの回答',
                        to: RouterAdvisorUrl.productInquiry,
                        status: 0,
                        name: 'countInquiryProductAdvisor',
                        icon: 'icon-replay'
                    }
                ]
            }
        ]
    },
    {
        title: '事業者を紹介する',
        to: RouterAdvisorUrl.invitation,
        status: 0,
        icon: 'icon-buil2'
    },
    {
        title: '個人・法人からの問合せ',
        to: RouterAdvisorUrl.consult,
        status: 0,
        name: 'countIncomings',
        icon: 'icon-mail'
    },
    {
        title: 'アクセス情報',
        to: RouterAdvisorUrl.countAccessAdvisor,
        status: 0,
        name: 'countAccess',
        icon: 'icon-graph'
    },
    {
        title: '管理者への問合せ',
        to: RouterAdvisorUrl.inquiry,
        status: 0,
        name: 'countInquiries',
        icon: 'icon-marketing '
    },
    {
        title: 'コンシェルジュ問合せ',
        to: RouterAdvisorUrl.concierge,
        status: 0,
        name: 'countConciergesAdvisor',
        icon: 'icon-bellboy2'
    },
    {
        title: '運営からのお知らせ',
        to: RouterAdvisorUrl.announcement,
        status: 0,
        name: 'countAnnouncements',
        icon: 'icon-paper-plane'
    }
]

const listTitleHeaderAdvisor: ITypeListTitleHeader[] = [
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.basicInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.officeInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.affiliateInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.sampleFeesInfo,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.promotions,
        icon: 'icon-user'
    },
    {
        title: '基本情報管理',
        name: nameRouterAdvisor.interview,
        icon: 'icon-user'
    },
    {
        title: '管理者への問合せ',
        name: nameRouterAdvisor.inquiryDetail,
        icon: 'icon-marketing'
    },
    {
        title: '管理者への問合せ',
        name: nameRouterAdvisor.inquiryCreate,
        icon: 'icon-marketing'
    },
    {
        title: '管理者への問合せ',
        name: nameRouterAdvisor.inquiry,
        icon: 'icon-marketing'
    },
    {
        title: '個人・法人からの問合せ',
        name: nameRouterAdvisor.consultDetail,
        icon: 'icon-mail'
    },
    {
        title: '個人・法人からの問合せ',
        name: nameRouterAdvisor.consultRequestDetail,
        icon: 'icon-mail'
    },
    {
        title: '個人・法人からの問合せ',
        name: nameRouterAdvisor.consultResponse,
        icon: 'icon-mail'
    },
    {
        title: '個人・法人からの問合せ',
        name: nameRouterAdvisor.consult,
        icon: 'icon-mail'
    },
    {
        title: 'アクセス情報',
        name: nameRouterAdvisor.countAccessAdvisor,
        icon: 'icon-graph'
    },
    {
        title: '運営からのお知らせ',
        name: nameRouterAdvisor.announcementDetail,
        icon: 'icon-paper-plane'
    },
    {
        title: '運営からのお知らせ',
        name: nameRouterAdvisor.announcement,
        icon: 'icon-paper-plane'
    },
    {
        title: '事業者紹介',
        name: nameRouterAdvisor.invitation,
        icon: 'icon-buil'
    },
    {
        title: '事業者紹介',
        name: nameRouterAdvisor.invitationCreate,
        icon: 'icon-buil'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.productSave,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.productInquiry,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.productSearch,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.searchResult,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.productSearchDetail,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.providerDetail,
        icon: 'icon-marketing'
    },
    {
        title: '商材検索',
        name: nameRouterAdvisor.productProgress,
        icon: 'icon-marketing'
    },
    {
        title: '事業者からの回答',
        name: nameRouterAdvisor.providerInquiry,
        icon: 'icon-marketing'
    },
    {
        title: 'コンシェルジュ問合せ',
        name: nameRouterAdvisor.concierge,
        icon: 'icon-bellboy'
    },
    {
        title: 'コンシェルジュ問合せ登録',
        name: nameRouterAdvisor.conciergeCreate,
        icon: 'icon-marketing'
    },
    {
        title: 'コンシェルジュ問合せ詳細',
        name: nameRouterAdvisor.conciergeDetail,
        icon: 'icon-marketing'
    }
]

const TypeTabRegister: ITypeListTabForm = {
    basic: 'basic-info',
    office: 'office-information',
    taxAccount: 'taxAccount',
    fessExample: 'fees/examples',
    videoPromotion: 'videoPromotion',
    interview: 'interview',
    basicInfo: 'basic-info',
    contract: 'contract',
    product: 'product-info'
}

const SampleFeesFormInit: IFormSampleFees = {
    catch_copy: '',
    fee_explain: '',
    sample_detail: '',
    domain: '',
    delete_flg: DeleteFlg.FALSE,
    public_flg: ViewMode.PRIVATE
}

const TabRegisterPreview = {
    office: 'OfficeInformationPreview',
    affiliate: 'AffiliateAdvisorPreview',
    sample_fees: 'SampleFeesPreview',
    interviews: 'InterviewInformationPreview',
    map: 'MapInformationPreview'
}

const TabPreviewSubmitAdvisor = {
    basic: 'basicInfo',
    office: 'officeInfo',
    affiliate: 'listAffiliate',
    fees: 'listSampleFees',
    interview: 'listInterview',
    promotion: 'promotionVideo'
}
const BodyClassRole = {
    admin: 'my-page-admin clrTypeSub',
    tax_advisor: 'my-page-tax clrTypeMain'
}

const listDataTabAdvisor = [
    {
        title: '基本情報',
        name: TypeTabRegister.basicInfo,
        path: RouterAdvisorUrl.basicInformations,
        icon: 'icon-note',
        width: '14.6'
    },
    {
        title: '事務所情報',
        name: TypeTabRegister.office,
        path: RouterAdvisorUrl.officeInformations,
        icon: 'icon-buil',
        width: '14.6'
    },
    {
        title: '所属税理士情報',
        name: TypeTabRegister.taxAccount,
        path: RouterAdvisorUrl.affiliateAdvisor,
        icon: 'icon-man',
        width: '16.3'
    },
    {
        title: '料金・事例',
        name: TypeTabRegister.fessExample,
        path: RouterAdvisorUrl.sampleFees,
        icon: 'icon-price',
        width: '14.6'
    },
    {
        title: 'インタビュー',
        name: TypeTabRegister.interview,
        path: RouterAdvisorUrl.interview,
        icon: 'icon-mic',
        width: '14.6'
    },
    {
        title: '動画プロモーション',
        name: TypeTabRegister.videoPromotion,
        path: RouterAdvisorUrl.promotions,
        icon: 'icon-movie',
        width: '20.3'
    }
]

const taoyriLink = {
    fqaLink: 'https://tayori.com/faq/e84b70fcafe828e27b9928184df92e60fab8402e/',
    questionInquiryLink:
        'https://tayori.com/faq/e84b70fcafe828e27b9928184df92e60fab8402e/detail/862f55d7566f4576b6af68133937acf94ce7562d/',
    questionRegistrationLink:
        'https://tayori.com/faq/e84b70fcafe828e27b9928184df92e60fab8402e/detail/a1ef4b117b437728331567a76d8598104d45886b/',
    adminSignIn: 'https://tayori.com/admin/signin/'
}
export {
    TypeTabRegister,
    SampleFeesFormInit,
    columnsListInquiry,
    menuAdvisor,
    listTitleHeaderAdvisor,
    TabRegisterPreview,
    BodyClassRole,
    listDataTabAdvisor,
    TabPreviewSubmitAdvisor,
    taoyriLink
}
