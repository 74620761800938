var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"formOfficeInfo"},[_c('div',{staticClass:"ps_basicInfo"},[_c('div',{staticClass:"swiper-container swiper-container1"},[_c('div',{staticClass:"swiper",attrs:{"id":"swiper1"}},[_c('div',{staticClass:"swiper-wrapper ps_biOfcImgWrap"},[(_vm.getDataPreviewProvider?.imageUploaded?.length)?[_vm._l((_vm.getDataPreviewProvider.imageUploaded),function(item,index){return [(
                                    item?.value && _vm.officeInfo[`image${index + 1}`] === '' ||
                                    _vm.officeInfo[`image${index + 1}`]
                                )?_c('div',{key:index,staticClass:"swiper-slide"},[(
                                        item?.value &&
                                        _vm.officeInfo[`image${index + 1}`] === ''
                                    )?_c('img',{attrs:{"src":item?.value,"width":"280","height":"322","alt":"oliver-consulting"}}):_vm._e(),(_vm.officeInfo[`image${index + 1}`])?_c('img',{attrs:{"src":_vm.customUrlImage(
                                            _vm.officeInfo[`image${index + 1}`]
                                        ),"width":"280","height":"322","alt":"oliver-consulting"}}):_vm._e()]):_vm._e()]})]:[(_vm.officeInfo?.image1)?_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":_vm.customUrlImage(_vm.officeInfo?.image1),"width":"280","height":"322","alt":"oliver-consulting"}})]):_vm._e(),(_vm.officeInfo?.image2)?_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":_vm.customUrlImage(_vm.officeInfo?.image2),"width":"280","height":"322","alt":"oliver-consulting"}})]):_vm._e(),(_vm.officeInfo?.image3)?_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":_vm.customUrlImage(_vm.officeInfo?.image3),"width":"280","height":"322","alt":"oliver-consulting"}})]):_vm._e()]],2)])]),_c('div',{staticClass:"ps_infoTableWrap"},[_c('table',{staticClass:"ps_infoTable tableType4"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("キャッチコピー")]),_c('td',[_vm._v(_vm._s(_vm.officeInfo?.catch_copy))])]),_c('tr',[_c('th',[_vm._v("紹介文")]),_c('td',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.officeInfo?.intro_text))])]),_c('tr',[_c('th',[_vm._v("対応エリア")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatArray( _vm.officeInfo?.area_name ?? _vm.officeInfo?.area_id ))+" ")])]),_c('tr',[_c('th',[_vm._v("事業分野・事業内容")]),(_vm.officeInfo?.domainAndContent)?_c('td',[_vm._v(" "+_vm._s(_vm.officeInfo?.domainAndContent)+" ")]):_c('td',[_vm._v(" "+_vm._s(_vm.officeDomainContent( _vm.officeInfo?.domain, _vm.officeInfo?.content ))+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }