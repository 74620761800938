import { copyDeep } from '@/shared'
import { IStateStoreInvitationAdvisor } from '@/modules/advisor/modules/invitation/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import InvitationAdvisorService from '@/modules/advisor/modules/invitation/services'

export const defaultStateAnnouncementAdvisor: IStateStoreInvitationAdvisor = {
    dataSearch: {},
    data: {}
}

const state = () => copyDeep(defaultStateAnnouncementAdvisor)

const getters = {
    getDataSearchInvitation(state: IStateStoreInvitationAdvisor) {
        return state.dataSearch
    },
    getDataInvitation(state: IStateStoreInvitationAdvisor) {
        return state.data
    }
}

const mutations = {
    setDataSearchInvitation(state: IStateStoreInvitationAdvisor, payload: any) {
        state.dataSearch = payload
    },
    setDataInvitation(state: IStateStoreInvitationAdvisor, payload: any) {
        state.data = payload
    }
}

const actions = {
    async dataInvitation({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InvitationAdvisorService.apiGetInvitation(payload)
            const { status, data } = res

            if (status === HttpStatusCode.ok) {
                commit('setDataInvitation', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async searchDataInvitation({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InvitationAdvisorService.apiSearchDataInvitation(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('setDataSearchInvitation', data.data)
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createDataInvitation({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InvitationAdvisorService.apiCrateDataInvitation(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
