import { RouteConfig } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import auth from '@/middleware/auth'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import {
    favicon,
    statusAdvisor,
    titlePage,
    nameRouterAdvisor
} from '@/shared/constants'
import { getDataStorage } from '@/shared'
import { KeyStorage, UserTypeRole, ValueStatusAdvisor } from '@/shared/enums'

export const routesAdvisor: Array<RouteConfig> = [
    {
        path: '/',
        name: nameRouterAdvisor.home,
        redirect: '/my-page',
        component: HomeView
    },
    {
        path: RouterAdvisorUrl.signin,
        name: nameRouterAdvisor.signin,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/advisor/signin/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        },
        beforeEnter: (to, from, next) => {
            const profile = getDataStorage(KeyStorage.profile)
            if (
                profile?.user_type === UserTypeRole.advisor &&
                profile?.status === ValueStatusAdvisor.TEMPORARY_REGISTRATION
            ) {
                return next(RouterAdvisorUrl.basicInformations)
            }
            return next()
        }
    },
    {
        path: RouterAdvisorUrl.passwordReset,
        name: nameRouterAdvisor.passwordReset,
        component: () => import('../views/advisor/password-reset/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.resetPassword,
        name: nameRouterAdvisor.resetPassword,
        component: () => import('../views/reset-password/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.signup,
        name: nameRouterAdvisor.signup,
        component: () => import('../views/advisor/signup/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.basicInformations,
        name: nameRouterAdvisor.basicInfo,
        component: () => import('../views/advisor/signup/basic-info/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.officeInformations,
        name: nameRouterAdvisor.officeInfo,
        component: () =>
            import('../views/advisor/signup/office-information/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.affiliateAdvisor,
        name: nameRouterAdvisor.affiliateInfo,
        component: () =>
            import('../views/advisor/signup/affiliate-advisor/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.sampleFees,
        name: nameRouterAdvisor.sampleFeesInfo,
        component: () =>
            import('../views/advisor/signup/sample-fees/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.promotions,
        name: nameRouterAdvisor.promotions,
        component: () => import('../views/advisor/signup/promotions/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.myPage,
        name: nameRouterAdvisor.myPage,
        component: () => import('../views/advisor/my-page/index.vue'),
        meta: {
            layout: 'LayoutAdmin',
            middleware: auth,
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        },
        beforeEnter: (to, from, next) => {
            const profile = getDataStorage(KeyStorage.profile)
            if (
                profile.user_type === UserTypeRole.advisor &&
                profile.status === ValueStatusAdvisor.TEMPORARY_REGISTRATION
            ) {
                return next(RouterAdvisorUrl.basicInformations)
            }
            return next()
        }
    },
    {
        path: RouterAdvisorUrl.inquiry,
        name: nameRouterAdvisor.inquiry,
        component: () => import('../views/advisor/inquiry/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.inquiryCreate,
        name: nameRouterAdvisor.inquiryCreate,
        component: () => import('../views/advisor/inquiry/create/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.inquiryDetail + ':id',
        name: nameRouterAdvisor.inquiryDetail,
        component: () => import('../views/advisor/inquiry/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.consult,
        name: nameRouterAdvisor.consult,
        component: () => import('../views/advisor/consult/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.announcement,
        name: nameRouterAdvisor.announcement,
        component: () => import('../views/advisor/announcement/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.consultDetail + ':id',
        name: nameRouterAdvisor.consultDetail,
        component: () => import('../views/advisor/consult/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.consultResponse + ':id',
        name: nameRouterAdvisor.consultResponse,
        component: () => import('../views/advisor/consult/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.consultRequestDetail + ':id',
        name: nameRouterAdvisor.consultRequestDetail,
        component: () => import('../views/advisor/consult/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.consultPreview,
        name: nameRouterAdvisor.consultPreview,
        component: () => import('../views/advisor/consult/preview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.announcementDetail + ':id',
        name: nameRouterAdvisor.announcementDetail,
        component: () =>
            import('../views/advisor/announcement/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.interview,
        name: nameRouterAdvisor.interview,
        component: () => import('../views/advisor/signup/interview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.invitation,
        name: nameRouterAdvisor.invitation,
        component: () => import('../views/advisor/invitation/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.invitationCreate,
        name: nameRouterAdvisor.invitationCreate,
        component: () => import('../views/advisor/invitation/create/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.productSave,
        name: nameRouterAdvisor.productSave,
        component: () => import('../views/advisor/provider/product/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.productInquiry,
        name: nameRouterAdvisor.productInquiry,
        component: () => import('../views/advisor/product-inquiry/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.productSearch,
        name: nameRouterAdvisor.productSearch,
        component: () => import('../views/advisor/product-search/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.providerDetail + ':id',
        name: nameRouterAdvisor.providerDetail,
        component: () =>
            import('../views/advisor/product-search/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.productProgress + ':id',
        name: nameRouterAdvisor.productProgress,
        component: () =>
            import('../views/advisor/product-inquiry/product-progress.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.searchResult,
        name: nameRouterAdvisor.searchResult,
        component: () =>
            import('../views/advisor/product-search/result-list/index.vue'),
        meta: {
            middleware: auth,
            layout: 'layoutAdminAdvisor',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.productSearchDetail + ':id',
        name: nameRouterAdvisor.productSearchDetail,
        component: () =>
            import('../views/advisor/product-search/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'layoutAdminAdvisor',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.providerInquiry + ':id',
        name: nameRouterAdvisor.providerInquiry,
        component: () => import('../views/advisor/product-inquiry/inquiry.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.countAccessAdvisor,
        name: nameRouterAdvisor.countAccessAdvisor,
        component: () => import('../views/advisor/count-access/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.concierge,
        name: nameRouterAdvisor.concierge,
        component: () => import('../views/advisor/concierge/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.conciergeCreate,
        name: nameRouterAdvisor.conciergeCreate,
        component: () => import('../views/advisor/concierge/create/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.conciergeDetail + ':id',
        name: nameRouterAdvisor.conciergeDetail,
        component: () => import('../views/advisor/concierge/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.officeConciergeAdvisor,
        name: nameRouterAdvisor.officeConciergeAdvisor,
        component: () =>
            import('../views/advisor/office-concierge-advisor/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.mitsukaru,
            icon: favicon.mitsukaru
        }
    },
    {
        path: RouterAdvisorUrl.providerIntroductionService,
        name: nameRouterAdvisor.providerIntroductionService,
        component: () =>
            import('../views/advisor/provider-introduction-service/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.provider,
            icon: favicon.provider
        }
    },
    {
        path: RouterAdminUrl.notFound,
        name: nameRouterAdvisor.notFound,
        component: () => import('../views/404.vue')
    }
]
