
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import InterviewInformationPreviewItem from '@/modules/advisor/modules/signup/components/interview/InterviewInformationPreviewItem.vue'
import { mapGetters } from 'vuex'
import { ITypeFormInterviewItem } from '@/modules/advisor/modules/signup/shared/@types'
import { ViewMode } from '@/shared/enums'
import { TitleFormInterviewItem } from '@/modules/advisor/modules/signup/shared/contants'
import { copyDeep } from '@/shared'
import { isArray } from '@/shared/function'
export default Vue.extend({
    name: 'InterviewInformationPreview',
    components: {
        InterviewInformationPreviewItem
    },
    data() {
        return {
            dataFormInterview: []
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataInterviewAdvisor',
            'getDataPreviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', ['getDataInterviewAdvisorAdmin'])
    },
    mounted() {
        if (this.getDataPreviewAdvisor.isAdmin) {
            this.dataFormInterview = this.getDataInterview(
                this.getDataInterviewAdvisorAdmin
            )
            return
        }
        this.dataFormInterview =
            this.getDataPreviewAdvisor.listInterview ??
            this.getDataInterview(this.getDataInterviewAdvisor)
    },
    methods: {
        isArray,
        getDataInterview(valInterview: any) {
            if (!valInterview) {
                return
            }
            let data = []
            const listInterviewItem = copyDeep(TitleFormInterviewItem).map(
                (item: ITypeFormInterviewItem) => {
                    const { data, value } = item
                    for (const key in data) {
                        data[key] = valInterview[key + `_${value}`]
                    }
                    data.id = valInterview.id
                    return item
                }
            )

            data = listInterviewItem.filter(
                (item: ITypeFormInterviewItem) =>
                    item.data.is_public_flg === ViewMode.PUBLIC
            )
            return data
        }
    }
})
