import apiServices from '@/shared/services/api/axios'
const MyPageServices = {
    apiGetAdminMyPage(payload: any) {
        let url = '/admin/my-page'
        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }
        return apiServices.get(url)
    },
    apiGetAccessInformationDetail(payload: any) {
        const url = '/admin/count-access-infomation-details'
        return apiServices.post(url, payload)
    },
    apiGetAccessProviderDetail(payload: any) {
        const url = '/admin/list-access-provider'
        return apiServices.post(url, payload)
    }
}
export default MyPageServices
