
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'PopupNotiErrorProduct',
    components: {
        Title
    },
    data(): any {
        return {
            message: '登録可能な商材は５つまでです。'
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        if (this.getParamsPopup?.message)
            this.message = this.getParamsPopup?.message
    },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
