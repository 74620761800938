
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import UserDetails from '@/modules/auth/popup/UserDetails.vue'
import UserForm from '@/modules/auth/popup/UserForm.vue'
import ChangePassword from '@/modules/auth/popup/ChangePassword.vue'
import ChangeEmail from '@/modules/advisor/modules/signup/popup/ChangeEmail.vue'
import Withdrawal from '@/modules/advisor/modules/signup/popup/Withdrawal.vue'
import TaxAccounting from '@/modules/advisor/modules/signup/popup/TaxAccounting.vue'
import ServicesSelectPopup from '@/modules/advisor/modules/signup/popup/ServicesSelectPopup.vue'
import AreaPopup from '@/modules/advisor/modules/signup/popup/AreaPopup.vue'
import AreaCityPopup from '@/modules/advisor/modules/signup/popup/AreaCityPopup.vue'
import StopAdvisorPopup from '@/modules/admin/modules/advisors/popup/StopAdvisorPopup.vue'
import ZoomImage from '@/modules/admin/modules/advisors/popup/ZoomImage.vue'
import DeleteAdmin from '@/modules/admin/modules/admin-management/popup/DeleteAdmin.vue'
import AdminRegistration from '@/modules/admin/modules/admin-management/popup/AdminRegistration.vue'
import AdminUpdate from '@/modules/admin/modules/admin-management/popup/AdminUpdate.vue'
import ConfirmDeactive from '@/modules/advisor/modules/signup/popup/ConfirmDeactive.vue'
import CreateSignature from '@/modules/advisor/modules/signup/popup/CreateSignature.vue'
import ConfirmDeleteInquiryAdvisor from '@/modules/advisor/modules/inquiry/popup/index.vue'
import PopupFormGuest from '@/modules/guest/modules/search-advisor/popup/formGuest.vue'
import ConfirmCreateInquiryGuest from '@/modules/guest/modules/inquiry/popup/index.vue'
import Registration from '@/modules/auth/popup/Registration.vue'
import QualificationSelectPopup from '@/modules/advisor/modules/signup/popup/QualificationSelectPopup.vue'
import MailListAdvisor from '@/modules/advisor/modules/signup/popup/MailListAdvisor.vue'
import AddMailAdvisor from '@/modules/advisor/modules/signup/popup/AddMailAdvisor.vue'
import LoginSupport from '@/components/layouts/login-support/LoginSupport.vue'
import ConfirmUpdateStatusConcierge from '@/modules/adminFQA/modules/popup/index.vue'
import ManagementCompanyPopup from '@/modules/advisor/modules/mypage/popup/ManagementCompanyPopup.vue'
import ConfirmSubmitAnnouncement from '@/modules/admin/modules/announcement/popup/ConfirmSubmitAnnouncement.vue'
import PrivacyPolicy from '@/modules/advisor/modules/signup/popup/PrivacyPolicy.vue'
import TermOfService from '@/modules/auth/popup/TermOfService.vue'
import TermOfServiceWithdrawal from '@/modules/advisor/modules/signup/popup/TermOfServiceWithdrawal.vue'
import ExplainReferCode from '@/modules/auth/popup/ExplainReferCode.vue'
import BusinessRecruiter from '@/modules/auth/popup/BusinessRecruiter.vue'
import PreviewCreateSignature from '@/modules/advisor/modules/signup/popup/PreviewCreateSignature.vue'
import LoginConflictConfirm from '@/modules/auth/popup/LoginConflictConfirm.vue'
import ProviderRegistration from '@/modules/auth/popup/ProviderRegistration.vue'
import MailListProvider from '@/modules/provider/modules/signup/popup/MailListProvider.vue'
import AddMailProvider from '@/modules/provider/modules/signup/popup/AddMailProvider.vue'
import SendBackProvider from '@/modules/admin/modules/providers/components/popup/SendBackProvider.vue'
import ConfirmSubmitApproveProvider from '@/modules/admin/modules/providers/components/popup/ConfirmSubmitApproveProvider.vue'
import ProviderServicesSelectPopup from '@/modules/provider/modules/signup/popup/ProviderServicesSelectPopup.vue'
import AddContractProvider from '@/modules/provider/modules/signup/popup/AddContractProvider.vue'
import DeleteContractProvider from '@/modules/provider/modules/signup/popup/DeleteContractProvider.vue'
import IntroCode from '@/modules/provider/modules/signup/popup/IntroCode.vue'
import ConfirmSubmitRejectProvider from '@/modules/admin/modules/providers/components/popup/ConfirmSubmitRejectProvider.vue'
import InvitationPreview from '@/modules/advisor/modules/invitation/popup/InvitationPreview.vue'
import ConfirmDeleteInquiryProvider from '@/modules/provider/modules/inquiry/popup/index.vue'
import ConfirmSubmitSuspendProvider from '@/modules/admin/modules/providers/components/popup/ConfirmSubmitSuspendProvider.vue'
import ExportCSV from '@/modules/admin/modules/my-page/popup/ExportCSV.vue'
import ConfirmInvitationProvider from '@/modules/admin/modules/invitation/popup/ConfirmInvitationProvider.vue'
import ConfirmDeleteInvitationProvider from '@/modules/admin/modules/invitation/popup/ConfirmDeleteInvitationProvider.vue'
import openApproveProviderFree from '@/modules/provider/modules/mypage/popup/openApproveProviderFree.vue'
import openUnApproveProviderFree from '@/modules/provider/modules/mypage/popup/openUnApproveProviderFree.vue'
import ConfirmDeleteIncomingProvider from '@/modules/provider/modules/incoming/popup/index.vue'
import ConfirmSubmitServiceCost from '@/modules/admin/modules/settings/popup/ConfirmSubmitServiceCost.vue'
import ProviderFeatureSelectPopup from '@/modules/provider/modules/product/components/popup/ProviderFeatureSelectPopup.vue'
import ProductProviderPreview from '@/modules/provider/modules/product/components/popup/ProductPreview.vue'
import ConfirmDeleteProvider from '@/modules/admin/modules/providers/components/popup/ConfirmDeleteProvider.vue'
import ConfirmDeleteProduct from '@/modules/admin/modules/providers/components/popup/ConfirmDeleteProduct.vue'
import AdvisorInquiryProvider from '@/modules/advisor/modules/provider/components/popup/FormSendInquiry.vue'
import PreviewInquiryProvider from '@/modules/advisor/modules/provider/components/popup/PreviewInquiry.vue'
import InquiryToProvider from '@/modules/advisor/modules/product-inquiry/components/popup/InquiryToProvider.vue'
import AdvisorRateProvider from '@/modules/advisor/modules/product-inquiry/components/popup/RateProvider.vue'
import GraphRateProvider from '@/modules/advisor/modules/product-inquiry/components/popup/GraphRateProvider.vue'
import SearchByAreaPopup from '@/modules/advisor/modules/product-search/popup/AreaPopup.vue'
import SearchByGenreFeaturePopup from '@/modules/advisor/modules/product-search/popup/GenreFeaturePopup.vue'
import ErrorRateProvider from '@/modules/advisor/modules/product-inquiry/components/popup/ErrorRateProvider.vue'
import PopupNotiErrorProduct from '@/modules/provider/modules/product/components/popup/PopupNotiError.vue'
import popUpConfirmCountAccess from '@/views/provider/count-access/popUpConfirmCountAccess.vue'
import SearchByArea from '@/modules/provider/modules/search-provider/popup/SearchAreaPopup.vue'
import SearchByGenreFeature from '@/modules/provider/modules/search-provider/popup/GenreFeaturePopup.vue'
import ConfirmDeleteConciergeAdvisor from '@/modules/advisor/modules/concierge/popup/confirmDelete.vue'
import ErrorProductIsDelete from '@/modules/advisor/modules/product-inquiry/popup/ErrorProductIsDelete.vue'
import SearchAreaPopup from '@/modules/adminFQA/modules/popup/AreaPopup.vue'
import SearchGenreFeaturePopup from '@/modules/adminFQA/modules/popup/GenreFeaturePopup.vue'
import PopupNotiPublicProduct from '@/modules/provider/modules/product/components/popup/PopupNotiPublic.vue'
import ConfirmFreeMembership from '@/modules/provider/modules/mypage/popup/confirmApprovePoviderFree.vue'
import ConfirmFeeManagement from '@/modules/admin/modules/fee-management/popup/confirmFeeManagement.vue'

export default Vue.extend({
    name: 'PopupCommon',
    components: {
        UserForm,
        UserDetails,
        ChangePassword,
        ChangeEmail,
        Withdrawal,
        TaxAccounting,
        ServicesSelectPopup,
        AreaPopup,
        AreaCityPopup,
        StopAdvisorPopup,
        ZoomImage,
        DeleteAdmin,
        AdminRegistration,
        AdminUpdate,
        ConfirmDeactive,
        CreateSignature,
        ConfirmDeleteInquiryAdvisor,
        PopupFormGuest,
        ConfirmCreateInquiryGuest,
        Registration,
        QualificationSelectPopup,
        MailListAdvisor,
        AddMailAdvisor,
        LoginSupport,
        ConfirmUpdateStatusConcierge,
        ManagementCompanyPopup,
        ConfirmSubmitAnnouncement,
        TermOfService,
        PrivacyPolicy,
        ExplainReferCode,
        BusinessRecruiter,
        TermOfServiceWithdrawal,
        PreviewCreateSignature,
        LoginConflictConfirm,
        ProviderRegistration,
        MailListProvider,
        AddMailProvider,
        SendBackProvider,
        ConfirmSubmitApproveProvider,
        ProviderServicesSelectPopup,
        AddContractProvider,
        DeleteContractProvider,
        IntroCode,
        ConfirmSubmitRejectProvider,
        InvitationPreview,
        ConfirmDeleteInquiryProvider,
        ConfirmSubmitSuspendProvider,
        ExportCSV,
        ConfirmInvitationProvider,
        ConfirmDeleteInvitationProvider,
        openApproveProviderFree,
        openUnApproveProviderFree,
        ConfirmDeleteIncomingProvider,
        ConfirmSubmitServiceCost,
        ProviderFeatureSelectPopup,
        ProductProviderPreview,
        ConfirmDeleteProvider,
        ConfirmDeleteProduct,
        AdvisorInquiryProvider,
        PreviewInquiryProvider,
        InquiryToProvider,
        AdvisorRateProvider,
        GraphRateProvider,
        SearchByAreaPopup,
        SearchByGenreFeaturePopup,
        ErrorRateProvider,
        PopupNotiErrorProduct,
        popUpConfirmCountAccess,
        SearchByArea,
        SearchByGenreFeature,
        ConfirmDeleteConciergeAdvisor,
        SearchAreaPopup,
        SearchGenreFeaturePopup,
        ErrorProductIsDelete,
        PopupNotiPublicProduct,
        ConfirmFreeMembership,
        ConfirmFeeManagement
    },
    computed: {
        ...mapGetters('popup', [
            'getIsOpenPopup',
            'getNamePopup',
            'getParamsPopup'
        ]),
        style() {
            return this.getIsOpenPopup ? 'display: block' : ''
        }
    },
    watch: {
        getNamePopup() {
            this.$nextTick(() => {
                const modalContent = this.$refs.modalContent as HTMLElement
                modalContent.scrollTop = 0
            })
        },
        getIsOpenPopup(newVal) {
            if (newVal) {
                document.body.classList.add('hide-scrollbar')
            } else {
                document.body.classList.remove('hide-scrollbar')
            }
        }
    },
    methods: {
        handleOk() {
            if (this.getParamsPopup?.reloadPage) {
                window.location.reload()
            }
            this.$store.dispatch('popup/hidePopup')
        }
    }
})
