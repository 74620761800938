
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'

export default Vue.extend({
    name: 'SearchAreaPopup',
    data() {
        return {
            isCheckAll: false,
            listArea: [],
            selectAreaId: []
        }
    },
    computed: {
        ...mapGetters('signupAdvisor', ['getDataAreaProvince']),
        ...mapGetters('popup', ['getIsLoading']),
        ...mapGetters('popup', ['getParamsPopup', 'getConditionFilter']),
        listAreaId(): Array<number> {
            let ids: Array<number> = []

            this.listArea.map((parent: any) => {
                ids.push(parent.id)
                parent.childrens.map((children: any) => {
                    ids.push(children.id)
                })
            })
            return ids
        },
        isDisableButtonSubmit() {
            return !this.selectAreaId.length
        }
    },
    components: { ButtonCustom, Title },
    watch: {
        getDataAreaProvince: {
            handler() {
                this.listArea = this.getDataAreaProvince
            },
            immediate: true
        },
        selectAreaId: {
            handler() {
                this.isCheckAll =
                    this.listAreaId.length == this.selectAreaId.length
            },
            immediate: true
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.selectAreaId = this.getConditionFilter.area_id ?? []

            if (this.selectAreaId.length == 1 && this.selectAreaId[0] === 0) {
                this.isCheckAll = true
                this.selectAreaId = this.listAreaId
            }

            this.listArea.map((item: any) => {
                if (this.selectAreaId.includes(item.id)) {
                    let childrenIds = this.getChildrenIds(item)
                    this.selectAreaId = this.mergeUnique(
                        this.selectAreaId,
                        childrenIds
                    )
                }
            })
        },
        mergeUnique(array1: Array<number>, array2: Array<number>) {
            let arrayMerge = array1.concat(array2)
            return arrayMerge.filter(
                (item, index) => arrayMerge.indexOf(item) == index
            )
        },
        getChildrenIds(parent: any) {
            return parent.childrens.map((item: any) => item.id)
        },
        changeParent(e: any) {
            let value = e.target.value
            let childrenIds = value.childrens.map(
                (children: any) => children.id
            )
            let areaIds = [value.id, ...childrenIds]
            if (e.target.checked) {
                this.selectAreaId = this.mergeUnique(this.selectAreaId, areaIds)
            } else {
                this.selectAreaId = this.selectAreaId.filter(function (
                    item: number
                ) {
                    return !areaIds.includes(item)
                })
            }
        },
        changeChildren(e: any, id: number, parent: any) {
            if (e.target.checked) {
                this.selectAreaId.push(id)
            } else {
                this.selectAreaId = this.selectAreaId.filter(function (
                    item: number
                ) {
                    return item != id
                })
            }

            let childrenIds = parent.childrens.map(
                (children: any) => children.id
            )
            let isCheckedAllChildren = childrenIds.every((item: any) =>
                this.selectAreaId.includes(item)
            )

            if (isCheckedAllChildren) {
                this.selectAreaId.push(parent.id)
            } else {
                this.selectAreaId = this.selectAreaId.filter(function (
                    item: number
                ) {
                    return item != parent.id
                })
            }
        },
        isChecked(val: number) {
            return this.selectAreaId.includes(val)
        },
        selectAll(event: any) {
            this.isCheckAll = event.target.checked
            this.selectAreaId = []
            if (this.isCheckAll) {
                this.selectAreaId = this.listAreaId
            }
        },
        submitData() {
            let getConditionFilter = this.getConditionFilter
            getConditionFilter.area_id = this.resultAreaIds()
            this.$store.dispatch('popup/setConditionFilter', getConditionFilter)

            let query: any = {}
            for (const prop in getConditionFilter) {
                if (prop == 'perPage') {
                    query[prop] = this.getConditionFilter[prop]
                    continue
                }
                query[prop] = getConditionFilter[prop].join(',')
            }
            query.perPage = 10
            query.page = 1
            this.$router
                .replace({
                    path: RouterAdminFQA.resultSearchProduct,
                    query: query
                })
                .catch(() => {
                    //
                })
            this.$store.dispatch('productSearch/getDataProduct', query)
            this.$store.dispatch('popup/hidePopup')
        },
        cancelSelect() {
            this.$store.dispatch('popup/hidePopup')
        },
        saveFilterSearch() {
            let getConditionFilter = { ...this.getConditionFilter }
            getConditionFilter.area_id = this.resultAreaIds()
            this.$store.dispatch('popup/setConditionFilter', {
                conditionFilter: getConditionFilter
            })
            this.cancelSelect()
        },
        resultAreaIds(): Array<number> {
            if (this.isCheckAll) {
                return [0]
            }

            // Remove childrens
            let arr = this.selectAreaId
            this.listArea.map((item: any) => {
                if (arr.includes(item.id)) {
                    let childrenIds = this.getChildrenIds(item)
                    arr = arr.filter((e: number) => !childrenIds.includes(e))
                }
            })
            return arr
        }
    }
})
