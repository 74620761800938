import apiServices from '@/shared/services/api/axios'

const CountAccessService = {
    apiGetListCountAccessProvider(payload: any) {
        let url = 'admin/count-access-provider'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    },
    apiGetListCountAccessAdvisor(payload: any) {
        let url = 'admin/count-access-advisor'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    }
}
export default CountAccessService
