
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ListNamePopup } from '@/shared/enums'
import { isObject } from '@/shared/function'

export default Vue.extend({
    name: 'AdvisorRateProvider',
    components: { ButtonCustom, Title },
    data(): any {
        return {
            isSubmit: false,
            value: 0,
            providerID: 0
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        ...mapGetters('advisorInquiryProvider', ['getDataAdvisorRateProvider'])
    },
    mounted() {
        const { params } = this.$route
        if (!isObject(params)) {
            return
        }

        this.$store.dispatch(
            'advisorInquiryProvider/AdvisorRateProvider',
            params.id
        )
        if (this.getParamsPopup?.rating) {
            this.value = this.getParamsPopup?.rating
        }
    },
    methods: {
        rate(star: number, isSubmit: any) {
            if (!isSubmit) return
            this.value = star
        },
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        openPopup() {
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.GraphRateProvider,
                payload: {
                    provider_id:
                        this.providerID ?? this.getParamsPopup?.provider_id,
                    id: this.getParamsPopup?.id,
                    rating: this.value
                }
            })
        },
        async handleRate() {
            if (!this.value) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.ErrorRateProvider,
                    payload: {
                        idProduct: this.getParamsPopup?.id,
                        idProvider:
                            this.providerID ?? this.getParamsPopup?.provider_id
                    }
                })
            }
            if (this.getParamsPopup?.id) {
                const payload = {
                    rating: this.value,
                    inquiry_id: this.getParamsPopup?.id
                }
                const res = await this.$store.dispatch(
                    'advisorInquiryProvider/RatingProvider',
                    payload
                )
                res ? (this.isSubmit = false) : (this.isSubmit = true)
                const { params } = this.$route
                if (isObject(params)) {
                    this.$store.dispatch(
                        'advisorInquiryProvider/DetailProgressProvider',
                        params.id
                    )
                }

                this.$store.dispatch('popup/hidePopup')
            }
        }
    },
    watch: {
        getDataAdvisorRateProvider: {
            handler(newData) {
                this.value = newData?.rating
                this.providerID = newData?.provider_id

                if (!newData || newData?.rating === 0 || newData?.rating === '')
                    this.isSubmit = true
                else this.isSubmit = false
            },
            immediate: true
        }
    }
})
