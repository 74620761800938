var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"formInquiry"},[_c('div',{staticClass:"inquiryDetailsArea mp_basicInfo_tableWrap"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"formHeadInfo flexCols"},[_c('div',{staticClass:"formImage"},[_c('div',{staticClass:"imgMain",on:{"click":function($event){return _vm.zoomImage(_vm.imageSelected)}}},[_c('img',{attrs:{"src":_vm.customUrlImage(_vm.imageSelected),"alt":"oliver-consulting"}})]),_c('div',{staticClass:"imgChild mt-1",class:_vm.getClassListImg()},[(_vm.countImg === 0)?_c('div',{staticClass:"childItem noImg"},[_c('img',{attrs:{"src":_vm.customUrlImage(''),"alt":"oliver-consulting"}})]):_vm._l((_vm.listImage),function(item,index){return [(item.url)?_c('div',{key:index,staticClass:"childItem",class:_vm.imageActive === item.value
                                        ? 'childItem-act'
                                        : ''},[_c('img',{attrs:{"src":_vm.customUrlImage(item.url),"alt":"oliver-consulting"},on:{"click":function($event){return _vm.handleSelectImg(item.value)}}})]):_vm._e()]})],2)]),_c('div',{staticClass:"formInfo"},[_c('span',[_vm._v(_vm._s(this.productInfo.product_name))]),_c('div',{staticClass:"mt-2"}),_c('pre',[_vm._v(_vm._s(this.productInfo.bussiness_classifications_id))])])]),_c('h2',{staticClass:"form-title pc-mt-3"},[_vm._v("商材・サービスの概要")]),_c('table',{staticClass:"tcAdmin_ofc_basicInfo_table tableType4"},[_c('tbody',[(this.productInfo.areaName)?_c('tr',[_c('th',[_vm._v("対応エリア")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo.areaName)+" ")])]):_vm._e(),(_vm.nameGenreAndFeature())?_c('tr',[_c('th',[_vm._v("ジャンル・特徴")]),_c('td',[_vm._v(" "+_vm._s(_vm.nameGenreAndFeature())+" ")])]):_vm._e(),(this.productInfo?.marketability)?_c('tr',[_c('th',[_vm._v("商品・サービス")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo?.marketability)+" ")])]):_vm._e()])]),_c('h2',{staticClass:"form-title pc-mt-5"},[_vm._v("商材・サービスの特徴")]),_c('table',{staticClass:"tcAdmin_ofc_basicInfo_table tableType4"},[_c('tbody',[(this.productInfo?.competition)?_c('tr',[_c('th',[_vm._v("税理士事務所の活用方法")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo?.competition)+" ")])]):_vm._e(),(this.productInfo?.strong_point)?_c('tr',[_c('th',[_vm._v("顧問先への付加価値")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo?.strong_point)+" ")])]):_vm._e(),(this.productInfo?.support_org)?_c('tr',[_c('th',[_vm._v("サポート体制")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo?.support_org)+" ")])]):_vm._e(),(this.productInfo?.attachment_pdf_name)?_c('tr',[_c('th',[_vm._v("資料")]),_c('td',[_c('a',{attrs:{"href":_vm.customUrlPdf(
                                        this.productInfo
                                            ?.attachment_pdf_link
                                    ),"target":"_blank"}},[_vm._v(" "+_vm._s(this.productInfo?.attachment_pdf_name)+" ")])])]):_vm._e(),(this.productInfo?.relation_url)?_c('tr',[_c('th',[_vm._v("関連ページ")]),_c('td',[_c('a',{attrs:{"href":this.productInfo?.relation_url,"target":"_blank"}},[_vm._v(" "+_vm._s(this.productInfo?.relation_url)+" ")])])]):_vm._e(),(this.productInfo?.start_date)?_c('tr',[_c('th',[_vm._v("公開日")]),_c('td',[_vm._v(" "+_vm._s(this.productInfo?.start_date)+" ")])]):_vm._e(),(
                            (this.productInfo?.introduction_fee ||
                                this.productInfo?.introduction_fee === 0) &&
                            !_vm.isSearchProvider
                        )?_c('tr',[_c('th',[_vm._v("紹介料")]),_c('td',[_vm._v(" "+_vm._s(_vm.renderLabel( _vm.listIntroductionFee, this.productInfo?.introduction_fee ))+" ")])]):_vm._e()])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }