import { ITypeSelectCustom } from '@/shared/constants/IContants'
import { isArray, isObject } from '@/shared/function'
import { i18n } from '@/shared/i18n'
import { ValueCheckbox } from '@/modules/advisor/modules/signup/shared/enums'

function generateValueCheckbox(
    listCheckbox: ITypeSelectCustom[],
    listValue: number[],
    keyValue: string,
    isCheckAll = false
) {
    const keyName = keyValue + '_name'
    if (
        isCheckAll &&
        (listValue.length === listCheckbox.length || listValue[0] === 0)
    ) {
        return {
            [keyName]: [
                i18n.t('i18n_select_all_checkbox_service_office_advisor')
            ],
            [keyValue]: [ValueCheckbox.ALL]
        }
    }

    return listCheckbox.reduce(
        (preVal: any, nextVal: ITypeSelectCustom) => {
            const { label, value } = nextVal

            if (isArray(listValue) && listValue.includes(value)) {
                preVal[keyValue] = preVal[keyValue].concat(value)
                preVal[keyName] = preVal[keyName].concat(label)
            }

            return preVal
        },
        {
            [keyName]: [],
            [keyValue]: []
        }
    )
}
function getListValueServiceJob(valueCustom: any) {
    return isObject(valueCustom)
        ? Object.keys(valueCustom).map(item => Number(item))
        : []
}

export { generateValueCheckbox, getListValueServiceJob }
