
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import AffiliateAdvisorPreviewItem from '@/modules/advisor/modules/signup/components/affiliate/AffiliateAdvisorPreviewItem.vue'
import { mapGetters } from 'vuex'
import { isArray } from '@/shared/function'
import { StatusAdvisor } from '@/shared/enums'

export default Vue.extend({
    name: 'AffiliateAdvisorPreview',
    methods: { isArray },
    data() {
        return {
            listAffiliate: [] as any
        }
    },
    components: { AffiliateAdvisorPreviewItem },
    computed: {
        ...mapGetters('signupAdvisor', [
            'getDataAffiliateAdvisor',
            'getDataListTaxGroup',
            'getDataPreviewAdvisor'
        ]),
        ...mapGetters('advisorAdmin', [
            'getDataAffiliateAdvisorAdmin',
            'getDataListTaxGroupAdvisorAdmin'
        ])
    },
    mounted() {
        if (this.getDataPreviewAdvisor.listAffiliate) {
            this.listAffiliate = this.getDataPreviewAdvisor.listAffiliate
            return
        }
        let dataAffiliate = this.getDataAffiliateAdvisor
        let listTaxGroup = this.getDataListTaxGroup
        if (this.getDataPreviewAdvisor.isAdmin) {
            dataAffiliate = this.getDataAffiliateAdvisorAdmin.data
            listTaxGroup = this.getDataListTaxGroupAdvisorAdmin
        }

        dataAffiliate
            .filter((item: any) => Number(item.status) === StatusAdvisor.active)
            .forEach((item: any) => {
                const tax_group = listTaxGroup.find((tax: any) => {
                    return tax.value === item.tax_group
                })
                this.listAffiliate.push({
                    form: {
                        ...item,
                        tax_group_name: tax_group?.label
                    },
                    uploadedImage: { ...item?.uploadedImage }
                })
            })
    }
})
