
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import { checkTypeUrl, customUrlImage } from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import IframeVideoForm from '@/components/common/videoIframe/IframeVideoForm.vue'
import { StatusPublicImage } from '@/modules/provider/shared/enums'
import { typeVideo } from '@/shared/constants'
export default Vue.extend({
    name: 'ShowVideoProviderPreview',
    components: {
        IframeVideoForm
    },
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'form_video'
            }),
            dataVideo: {} as any,
            typeVideoOnl: '',
            idUrl: '',
            typeVideo
        }
    },
    computed: {
        ...mapGetters('signupProvider', [
            'getPromotionVideos',
            'getDataVideo',
            'getDataPreviewProvider'
        ]),
        ...mapGetters('providerAdmin', ['getDataPromotionInfoProviderAdmin']),
        formatURLOfficeVideo(): string {
            if (
                this.getDataPreviewProvider.officeVideo &&
                this.getDataPreviewProvider.officeVideo[0].value &&
                this.dataVideo?.video_link === '' &&
                this.dataVideo?.video_link_online === ''
            ) {
                return this.getDataPreviewProvider.officeVideo[0].value
            }

            if (
                this.getDataPreviewProvider.officeVideo &&
                this.getDataPreviewProvider.officeVideo[0].value
            ) {
                return this.getDataPreviewProvider.officeVideo[0].value
            }
            return (
                this.dataVideo?.video_link_online || this.dataVideo?.video_link
            )
        }
    },
    methods: {
        customUrlImage,
        isPublicVideo(dataVideo: any, status = StatusPublicImage.PRIVATE) {
            return dataVideo && status === StatusPublicImage.PUBLIC
        },
        getUrlAndTypeVideo() {
            let { video_link_online } = this.dataVideo
            if (video_link_online) {
                let { typeVideoOnl, idUrl } = checkTypeUrl(video_link_online)
                this.typeVideoOnl = typeVideoOnl
                this.idUrl = idUrl
            }
        }
    },
    mounted() {
        if (this.getDataPreviewProvider.isAdmin) {
            this.dataVideo = this.getDataPromotionInfoProviderAdmin
            this.getUrlAndTypeVideo()
            return
        }
        this.dataVideo =
            this.getDataPreviewProvider.promotionVideo ??
            this.getPromotionVideos
        this.getUrlAndTypeVideo()
    },
    watch: {
        getDataPromotionInfoProviderAdmin(newVal) {
            if (this.getDataPreviewProvider.isAdmin) {
                this.dataVideo = newVal
                this.getUrlAndTypeVideo()
            }
        },
        getPromotionVideos(newVal) {
            if (this.getDataPreviewProvider.isAdmin) {
                return
            }
            this.dataVideo = newVal
            this.getUrlAndTypeVideo()
        }
    }
})
