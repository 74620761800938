import apiServices from '@/shared/services/api/axios'

const MembershipFeeService = {
    apiGetListMembershipFee(params: any) {
        return apiServices.get('/admin/membership-fee-history', { params })
    },
    apiGetMembershipFeeById(id: number) {
        return apiServices.get(`/admin/membership-fee/${id}`)
    },
    apiConfirmMembershipFee(id: number) {
        return apiServices.post(`/admin/confirm-membership-fee/${id}`)
    }
}
export default MembershipFeeService
