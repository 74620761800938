import { RouteConfig } from 'vue-router'
import auth from '@/middleware/auth'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import { favicon, nameRouterFqa, titlePage } from '@/shared/constants'

export const routesAdminFqa: Array<RouteConfig> = [
    {
        path: '/',
        name: nameRouterFqa.about,
        redirect: '/inquiry',
        component: () => import('../views/adminFqa/inquiry/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.signin,
        name: nameRouterFqa.signin,
        component: () => import('../views/admin/signin/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.passwordReset,
        name: nameRouterFqa.passwordReset,
        component: () => import('../views/advisor/password-reset/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.resetPassword,
        name: nameRouterFqa.resetPassword,
        component: () => import('../views/reset-password/index.vue'),
        meta: {
            layout: 'LayoutDefault',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.inquiry,
        name: nameRouterFqa.inquiry,
        component: () => import('../views/adminFqa/inquiry/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.inquiryDetail + ':id',
        name: nameRouterFqa.inquiryDetail,
        component: () => import('../views/adminFqa/inquiry/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.inquiryPreview,
        name: nameRouterFqa.inquiryPreview,
        component: () => import('../views/adminFqa/inquiry/preview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.announcement,
        name: nameRouterFqa.announcement,
        component: () => import('../views/adminFqa/announcement/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.announcementDetail + ':id',
        name: nameRouterFqa.announcementDetail,
        component: () =>
            import('../views/adminFqa/announcement/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.concierge,
        name: nameRouterFqa.concierge,
        component: () => import('../views/adminFqa/concierge/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.conciergeDetailGuest + ':id',
        name: nameRouterFqa.conciergeDetailGuest,
        component: () => import('../views/adminFqa/concierge/detail/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.conciergeDetailAdvisor + ':id',
        name: nameRouterFqa.conciergeDetailAdvisor,
        component: () =>
            import(
                '../views/adminFqa/concierge/detail/detailConciergeAdvisor.vue'
            ),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.conciergePreview,
        name: nameRouterFqa.conciergePreview,
        component: () =>
            import('../views/adminFqa/concierge/preview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.productSearchFQA,
        name: nameRouterFqa.productSearchFQA,
        component: () => import('../views/adminFqa/product-search/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.resultSearchProduct,
        name: nameRouterFqa.resultSearchProduct,
        component: () =>
            import('../views/adminFqa/product-search/resultSearchProduct.vue'),
        meta: {
            middleware: auth,
            layout: 'layoutAdminFQAProvider',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.productSave,
        name: nameRouterFqa.productSave,
        component: () => import('../views/adminFqa/product-save/index.vue'),
        meta: {
            middleware: auth,
            layout: 'LayoutAdmin',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminFQA.resultSearchProductDetail + ':id',
        name: nameRouterFqa.resultSearchProductDetail,
        component: () => import('../views/adminFqa/product-preview/index.vue'),
        meta: {
            middleware: auth,
            layout: 'layoutAdminFQAProvider',
            title: titlePage.portal,
            icon: favicon.portal
        }
    },
    {
        path: RouterAdminUrl.notFound,
        name: nameRouterFqa.notFound,
        component: () => import('../views/404.vue')
    }
]
