
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import AuthService from '@/modules/auth/services'
import { clearLoginStorage, getDataStorage } from '@/shared'
import {
    HttpStatusCode,
    KeyStorage,
    TypeAccount,
    TypeNotify,
    UserTypeRole,
    ValueStatusAdvisor,
    TypeRenewalFlg,
    ValueStatusProvider
} from '@/shared/enums'
import {
    getDomainMitsukaru,
    NotificationCustom,
    getDomainPortal,
    openNewTab,
    filterDomain,
    checkIsTempProvider,
    getDomainProvider
} from '@/shared/function'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import { taoyriLink } from '@/modules/advisor/shared/contants'
import { logo, pathTopScreen, keyTypeScreenLogin } from '@/shared/constants'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { ListNamePopup } from '@/shared/enums'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'

export default Vue.extend({
    name: 'LayoutHeader',
    components: { ButtonCustom },
    data(): any {
        return {
            logo,
            TypeAccount,
            UserTypeRole,
            typeAccount: getDataStorage(KeyStorage.profile).renewal_flg,
            userTypeAccount: getDataStorage(KeyStorage.profile).user_type,
            RouterAdvisorUrl,
            logoHeader: logo.advisor,
            ListNamePopup,
            TypeRenewalFlg,
            checkProvider: false,
            statusAccount: getDataStorage(KeyStorage.profile).status
        }
    },
    computed: {
        ...mapGetters('signupAuth', ['getChangeEmailNew']),
        ...mapGetters('signupProvider', ['getDataProvider']),
        ...mapGetters('popup', ['getUpdateEmail']),
        isOfficialAdvisor(): boolean {
            return (
                Number(getDataStorage(KeyStorage.profile).status) ===
                    ValueStatusAdvisor.OFFICIAL_REGISTRATION &&
                getDataStorage(KeyStorage.profile).user_type ===
                    UserTypeRole.advisor
            )
        },
        isProviderNew(): boolean {
            const isRegister = [
                Number(ValueStatusProvider.TEMPORARY_REGISTRATION),
                Number(ValueStatusProvider.OFFICIAL_REGISTRATION),
                Number(ValueStatusProvider.HAS_FEEDBACK),
                Number(ValueStatusProvider.DRAFTSENDBACK)
            ].includes(Number(getDataStorage(KeyStorage.profile).status))

            return (
                getDataStorage(KeyStorage.profile).user_type ===
                    UserTypeRole.provider && isRegister
            )
        },
        isProvider(): boolean {
            return (
                this.typeRouter &&
                this.typeRouter.type === keyTypeScreenLogin.provider
            )
        },
        getAssetToken(): string {
            return getDataStorage(KeyStorage.assetToken)
        },
        redirectTop(): string {
            switch (this.userTypeAccount) {
                case UserTypeRole.advisor:
                    return getDomainMitsukaru()
                case UserTypeRole.provider:
                    return getDomainProvider()
                default:
                    return getDomainPortal()
            }
        }
    },
    created() {
        this.typeRouter = filterDomain()
    },
    mounted() {
        const typeDomain = filterDomain()
        if (typeDomain.role === UserTypeRole.admin) {
            this.logoHeader = logo.portal
        }
        this.checkProvider = checkIsTempProvider()
    },
    methods: {
        openMembershipRegist(type: any) {
            if (type === TypeRenewalFlg.PAID)
                this.$router.push({
                    path: RouterProviderUrl.membershipRegistFree
                })
            else this.$router.push({ path: RouterProviderUrl.membershipRegist })
        },
        checkIsTempProvider,
        openPortalInquiry() {
            if (this.isProvider && this.isProviderNew) {
                openNewTab(getDomainProvider() + pathTopScreen.inquiry)
                return
            }

            if (this.isProvider && !this.isProviderNew) {
                this.$router.push({ path: RouterProviderUrl.inquiryCreate })
                return
            }

            if (!this.isOfficialAdvisor) {
                openNewTab(getDomainMitsukaru() + pathTopScreen.inquiry)
                return
            }

            if (this.isOfficialAdvisor || !this.checkIsTempProvider()) {
                this.$router.push({ path: RouterAdvisorUrl.inquiryCreate })
                return
            }
            openNewTab(getDomainPortal() + pathTopScreen.inquiry)
        },
        redirectGuest() {
            openNewTab(getDomainProvider())
        },
        openPopup(namePopup: string) {
            this.$store.dispatch('myPageProvider/getDataProviderFee')

            if (!namePopup) {
                return
            }
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: namePopup,
                payload: {
                    openFromLoginSupport: true
                }
            })
        },
        openTayoriLink() {
            openNewTab(taoyriLink.fqaLink, false)
        },
        async handleLogout() {
            this.$store.commit('popup/setLoading', true, { root: true })
            try {
                const res: any = await AuthService.apiLogout()
                const { status, data }: any = res
                if (status === HttpStatusCode.ok) {
                    clearLoginStorage()
                    NotificationCustom({
                        type: TypeNotify.success,
                        message: data.message
                    })
                    await this.$router.push(RouterAdvisorUrl.signin)
                    return
                }
                NotificationCustom({
                    type: TypeNotify.error,
                    message: data.errors.error_message
                })
            } catch (e) {
            } finally {
                this.$store.commit('popup/setLoading', false, { root: true })
            }
        }
    },
    watch: {
        getUpdateEmail() {
            this.getProfile()
        },

        getDataProvider: {
            handler(newVal) {
                this.typeAccount = newVal.renewal_flg
                this.checkProvider = checkIsTempProvider()
            },
            deep: true
        }
    }
})
