
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ConfirmSubmitServiceCost',
    components: { ButtonCustom, TitlePopup },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            this.$store.dispatch(
                'settingAdmin/postDataSettingCostAdmin',
                this.getParamsPopup
            )
        }
    }
})
