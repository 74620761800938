
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent } from 'vue'
import { TypeInputCustom } from '@/shared/enums'
import { regex } from '@/shared/regex'
import _ from 'lodash'

export default defineComponent({
    name: 'TextAreaCustom',
    props: {
        label: {
            type: String,
            required: true
        },
        classLabel: {
            type: String
        },
        classInput: {
            type: String
        },
        funcCustom: {
            type: Function
        },
        onKeyPressFunc: {
            type: Function
        },
        nameInput: {
            type: String,
            required: true
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true
        },
        typeInputCustom: {
            type: String,
            default: TypeInputCustom.text
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        autoSize: Boolean
    },
    data() {
        return {
            ListTypeInput: TypeInputCustom
        }
    },
    methods: {
        onKeyUpDebounce(evt: any) {
            if (this.funcCustom) {
                this.funcCustom(evt)
                return
            }

            this.autoTrimValue(evt, this.formInstance)
        },
        onKeyPress(event: any) {
            if (this.onKeyPressFunc) {
                this.onKeyPressFunc(event)
            }
        },
        autoTrimValue: _.debounce(function (evt: any, formInstance: any) {
            const { name, value } = evt.target
            if (value && regex.space_full.test(value) && formInstance) {
                formInstance.setFieldsValue({ [name]: value.trim() })
                return
            }

            if (value && regex.space_top_or_end.test(value) && formInstance) {
                formInstance.setFieldsValue({
                    [name]: value.trim()
                })
                formInstance
                    .validateFields([name])
                    .catch((e: any) => e.outOfDate)
            }
        }, 1000)
    }
})
