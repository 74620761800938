import apiServices from '@/shared/services/api/axios'
import { IPayloadInvitedProvider } from '../shared/@type'

const InvitedProviderAdminService = {
    apiGetInvitedProvider(payload: any) {
        let url = '/admin/list-inivited-provider'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (!index) {
                    url += `?${key}=${payload[key]}`
                }
                if (index) {
                    url += `&${key}=${payload[key]}`
                }
                index++
            }
        }

        return apiServices.get(url)
    },

    apiGetDetailInvitedProvider(payload: IPayloadInvitedProvider) {
        return apiServices.get(`admin/inivited-provider/${payload.id}`)
    },

    apiApproveInvitedProvider(payload: IPayloadInvitedProvider) {
        return apiServices.get(`admin/inivited-provider/approve/${payload.id}`)
    },

    apiDeleteInvitedProvider(payload: IPayloadInvitedProvider) {
        return apiServices.post(`admin/inivited-provider/delete`, payload)
    }
}
export default InvitedProviderAdminService
