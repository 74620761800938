
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import { regex } from '@/shared/regex'
import { clearLoginStorage, getDataStorage } from '@/shared'
import { HttpStatusCode, KeyStorage, TypeInputCustom } from '@/shared/enums'
import {
    ruleValidPassword,
    scrollToFirstErrorLineForm
} from '@/shared/function'
import { RouterAdvisorUrl } from '@/modules/advisor/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import AuthService from '@/modules/auth/services'

export default Vue.extend({
    name: 'ChangeEmail',
    components: { InputCustom, Title, ButtonCustom },
    data() {
        return {
            TypeInputCustom,
            formChangeEmail: this.$form.createForm(this, {
                name: 'form_change_email'
            }),
            ruleValidPass: ruleValidPassword()
        }
    },
    mounted() {
        const getEmail = getDataStorage(KeyStorage.profile).email
        if (getEmail) {
            this.formChangeEmail.setFieldsValue({
                emailCurrent: getEmail
            })
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        rulesEmailNew() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_changeEmail_emailNew_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length255.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNew_lenght255'
                                    )
                                )
                                return
                            }
                            if (!regex.email.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNew_regexEmail'
                                    )
                                )
                                return
                            }
                            if (!regex.lengthNameEmail.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_lengthNameEmail'
                                    )
                                )
                                return
                            }
                            const emailNewConfirm =
                                this.formChangeEmail.getFieldValue(
                                    'emailNewConfirm'
                                )

                            if (emailNewConfirm) {
                                if (emailNewConfirm !== value) {
                                    this.formChangeEmail.validateFields([
                                        'emailNewConfirm'
                                    ])
                                    return
                                }
                                if (emailNewConfirm === value) {
                                    this.formChangeEmail.validateFields([
                                        'emailNewConfirm'
                                    ])
                                }
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesEmailNewConfirm() {
            return [
                {
                    required: true,
                    message: this.$tc(
                        'i18n_advisor_changeEmail_emailNewConfirm_required'
                    )
                },
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length255.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNewConfirm_lenght255'
                                    )
                                )
                                return
                            }
                            if (!regex.email.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNewConfirm_regexEmail'
                                    )
                                )
                                return
                            }
                            if (!regex.lengthNameEmail.test(value)) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_pop_affiliate_vaidEmail_lengthNameEmail'
                                    )
                                )
                                return
                            }
                            const email =
                                this.formChangeEmail.getFieldValue('email')
                            if (email && email !== value) {
                                callback(
                                    this.$tc(
                                        'i18n_advisor_changeEmail_emailNewConfirm_notMatchEmailNew'
                                    )
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        redirectRouter() {
            this.onClosePopup()
            this.$router.push(RouterAdvisorUrl.passwordReset)
        },
        handleSubmit() {
            this.formChangeEmail.validateFields(
                (errors: Error[], values: any) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const body = {
                        user_type: getDataStorage(KeyStorage.profile).user_type,
                        ...values
                    }
                    this.$store.dispatch('signupAuth/updateChangeEmail', body)
                }
            )
        }
    }
})
