import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import GuestService from '../services'
import { IStateStoreGuest } from '../../../shared/@types'
import { NotificationCustom } from '@/shared/function'
import AdvisorAdminService from '@/modules/admin/modules/advisors/services'

export const defaultStateGuest: IStateStoreGuest = {
    data: {},
    dataInterview: {}
}

const state = () => copyDeep(defaultStateGuest)

const getters = {
    getDataGuest(state: IStateStoreGuest) {
        return state.data
    },
    getDataInterview(state: IStateStoreGuest) {
        return state.dataInterview
    }
}

const mutations = {
    setDataGuest(state: IStateStoreGuest, payload: any) {
        state.data = payload
    },
    setDataInterviewGuest(state: IStateStoreGuest, payload: any) {
        state.dataInterview = payload
    }
}
const actions = {
    async getDataListGuest({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })

        try {
            const res = await GuestService.apiGetInfoTax(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataGuest', data.data)
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async postCreateIncomingRequest({ commit, dispatch }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await GuestService.apiCreateIncomingRequest(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('setDataGuest', data.data)
                commit('popup/hidePopup', null, { root: true })
                dispatch('getDataListGuest', payload.supervisor_id)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.error.error_message
            })
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async getDataInterviewAdvisor({ commit }: any, id: number) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await GuestService.apiSearchInterviewAdvisor(id)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInterviewGuest', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getLoadViewOffice({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await GuestService.apiLoadViewOffice(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInterviewGuest', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
