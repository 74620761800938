
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import FormConfirm from '../components/form-confirm/index.vue'
export default Vue.extend({
    name: 'AdminUpdate',
    components: { FormConfirm }
})
