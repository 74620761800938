import {
    IKeyTypeScreen,
    ITypeLoginPaths,
    ITypeSelectCustom,
    ITypeStep
} from './IContants'
import {
    introCodeProvider,
    StatusReadAnnounce,
    StatusSenderType,
    StepActive,
    TypeMail,
    UserTypeRole,
    ValueClassificationProvider,
    ValueStatusAdvisor,
    ValueStatusProvider,
    ValueTypeProvider
} from '@/shared/enums'
import { ITypeColumnsTableCustom } from '@/modules/admin/modules/admin-management/shared/constants/IConstants'

const keyTypeScreenLogin: IKeyTypeScreen = {
    admin: 'admin',
    provider: 'provider',
    advisor: 'advisor'
}

const loginPaths: Array<ITypeLoginPaths> = [
    {
        type: keyTypeScreenLogin.admin,
        path: `/${keyTypeScreenLogin.admin}/signin`,
        role: UserTypeRole.admin
    },
    {
        type: keyTypeScreenLogin.provider,
        path: `/${keyTypeScreenLogin.provider}/signin`,
        role: UserTypeRole.provider
    },
    {
        type: keyTypeScreenLogin.advisor,
        path: `/${keyTypeScreenLogin.advisor}/signin`,
        role: UserTypeRole.advisor
    }
]

const listKeyCodeNumber: (undefined | number)[] = [
    undefined,
    8,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105
]

const statusAdvisor: ITypeSelectCustom[] = [
    {
        value: ValueStatusAdvisor.TEMPORARY_REGISTRATION,
        label: '仮登録'
    },
    {
        value: ValueStatusAdvisor.OFFICIAL_REGISTRATION,
        label: '利用中'
    },
    {
        value: ValueStatusAdvisor.STOP,
        label: '停止'
    }
]
const YYYYMMDD = 'YYYY-MM-DD HH:mm:ss'

const TypeSender: ITypeSelectCustom[] = [
    {
        label: '法人・個人',
        value: StatusSenderType.GUEST
    },
    {
        label: '税理士',
        value: StatusSenderType.ADVISOR
    },
    {
        label: '事業者',
        value: StatusSenderType.PROVIDER
    }
]

const columnsListAnnounce: ITypeColumnsTableCustom = [
    {
        title: 'お知らせ日',
        dataIndex: 'send_date',
        key: 'send_date',
        scopedSlots: { customRender: 'send_date' }
    },
    {
        title: 'タイトル',
        dataIndex: 'title',
        key: 'title',
        scopedSlots: { customRender: 'title_announcement' }
    },
    {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        scopedSlots: { customRender: 'content' }
    },
    {
        title: '状況',
        dataIndex: 'read_status',
        key: 'read_status',
        scopedSlots: { customRender: 'read_status' }
    }
]

const listStatusReadAnnounce: ITypeSelectCustom[] = [
    {
        label: '新規',
        value: StatusReadAnnounce.NEW
    },
    {
        label: '未読',
        value: StatusReadAnnounce.NOT_READ
    },
    {
        label: '既読',
        value: StatusReadAnnounce.READ
    }
]

const listSteps: ITypeStep[] = [
    {
        id: 1,
        title: '個人情報保護方針のご確認',
        step: StepActive.policy,
        status: false
    },
    {
        id: 2,
        title: 'お客様情報の入力',
        step: StepActive.customerInfo,
        status: false
    },
    {
        id: 3,
        title: '仮登録受付完了',
        step: StepActive.temporaryRegistration,
        status: false
    },
    {
        id: 4,
        title: '完了',
        step: StepActive.complete,
        status: false
    }
]

const listTypeMail: ITypeSelectCustom[] = [
    {
        value: TypeMail.DIRECTOR,
        label: '代表者アドレス'
    },
    {
        value: TypeMail.ACCOUNT,
        label: '所属税理士アドレス'
    },
    {
        value: TypeMail.WORKER,
        label: '事務所員アドレス'
    }
]

const configEditor = {
    elementpath: false,
    menubar: false,
    toolbar: 'bold italic underline forecolor',
    language: 'ja'
}

const configEditorWithSelectFont = {
    menubar: false,
    toolbar: 'bold italic underline forecolor | fontfamily | link',
    plugins: 'link',
    language: 'ja',
    elementpath: false
}

const defaultRememberEmailText = 'IDはすでに入力されています'

const navBarTitle = {
    advisor: '税理士マイページ',
    adminFqa: '問合せ担当者マイページ',
    adminSys: '管理者マイページ',
    provider: '事業者マイページ'
}

const logoutTime = 10800000

const titlePage = {
    portal: '税理士ポータル',
    mitsukaru: 'みつかる税理士',
    provider: 'つながる税理士'
}
const favicon = {
    portal: '/favicon_1-1.ico',
    mitsukaru: '/favicon_1-2.ico',
    provider: '/favicon_1-3.ico'
}
const logo = {
    portal: 'logo-1-1.png',
    advisor: 'logo-1-2.png',
    provider: 'logo-2-3.png',
    mitsukaru: 'logo-1-1-2.png',
    tsunagaru: 'logo-3-1-1.png'
}

const perPage = {
    five: 5,
    ten: 10,
    fifteen: 15,
    twenty: 20,
    twentyFive: 25,
    thirty: 30,
    fifty: 50,
    hundred: 100
}

const statusProvider: ITypeSelectCustom[] = [
    {
        value: ValueStatusProvider.TEMPORARY_REGISTRATION,
        label: '仮登録'
    },
    {
        value: ValueStatusProvider.OFFICIAL_REGISTRATION,
        label: '承認待ち'
    },
    {
        value: ValueStatusProvider.IN_USE,
        label: '利用中'
    },
    {
        value: ValueStatusProvider.HAS_FEEDBACK,
        label: '差し戻し'
    },
    {
        value: ValueStatusProvider.SUSPEND,
        label: '停止中'
    },
    {
        value: ValueStatusProvider.WITHDRAWN,
        label: '退会済み'
    },
    {
        value: ValueStatusProvider.DRAFTSENDBACK,
        label: '一時保存'
    }
]

const statusTempProvider: ITypeSelectCustom[] = [
    {
        value: ValueStatusProvider.TEMPORARY_REGISTRATION,
        label: '仮登録'
    },
    {
        value: ValueStatusProvider.OFFICIAL_REGISTRATION,
        label: '承認待ち'
    },
    {
        value: ValueStatusProvider.HAS_FEEDBACK,
        label: '差し戻し'
    },
    {
        value: ValueStatusProvider.DRAFTSENDBACK,
        label: '一時保存'
    }
]

const statusOfficialProvider: ITypeSelectCustom[] = [
    {
        value: ValueStatusProvider.IN_USE,
        label: '利用中'
    },
    {
        value: ValueStatusProvider.SUSPEND,
        label: '停止中'
    }
]

const typeProvider: ITypeSelectCustom[] = [
    {
        value: ValueTypeProvider.FREE,
        label: '無料会員'
    },
    {
        value: ValueTypeProvider.CHARGED,
        label: '有料会員'
    }
]
const classificationProvider: ITypeSelectCustom[] = [
    {
        value: ValueClassificationProvider.TEMPORARY,
        label: '仮登録'
    },
    {
        value: ValueClassificationProvider.OFFICIAL,
        label: '本登録'
    }
]

const introductionCodeProvider: ITypeSelectCustom[] = [
    {
        value: introCodeProvider.HAVE,
        label: '有り'
    },
    {
        value: introCodeProvider.NOT_HAVE,
        label: '無し'
    }
]

const jobDefaultCode = 15

const pathTopScreen = {
    inquiry: 'inquiry',
    officeConciergeAdvisor: 'office-concierge-advisor'
}

const nameRouterGuest = {
    searchAdvisor: 'searchAdvisor',
    affiliateAdvisor: 'affiliateAdvisor',
    sampleFees: 'sampleFees',
    interview: 'interview',
    announcement: 'announcement',
    announcementDetail: 'announcementDetail',
    concierge: 'concierge',
    inquiry: 'inquiry',
    notFound: 'NotFound'
}

const nameRouterProvider = {
    home: 'home',
    signin: 'signin',
    signup: 'signup',
    passwordReset: 'passwordReset',
    resetPassword: 'resetPassword',
    myPage: 'myPage',
    countAccess: 'countAccess',
    basicInfo: 'basicInformations',
    officeInfo: 'basicInformations',
    contractInfo: 'basicInformations',
    promotions: 'promotions',
    inquiry: 'inquiry',
    inquiryCreate: 'inquiryCreate',
    inquiryDetail: 'inquiryDetail',
    incoming: 'incoming',
    incomingDetail: 'incomingDetail',
    incomingPreview: 'incomingPreview',
    announcement: 'announcement',
    announcementDetail: 'announcementDetail',
    products: 'products',
    productsPreview: 'productsPreview',
    productCreate: 'productCreate',
    productManagement: 'productManagement',
    productInquiry: 'productInquiry',
    membershipRegist: 'membershipRegist',
    membershipRegistFree: 'membershipRegistFree',
    productProgress: 'productProgress',
    productDetail: 'productDetail',
    providerSearchDetail: 'providerSearchDetail',
    providerFavorite: 'providerFavorite',
    providerSearch: 'providerSearch',
    searchResult: 'searchResult',
    paymentHistory: 'paymentHistory',
    notFound: 'NotFound'
}

const nameRouterAdvisor = {
    home: 'home',
    signin: 'signin',
    signup: 'signup',
    passwordReset: 'passwordReset',
    resetPassword: 'resetPassword',
    myPage: 'myPage',
    basicInfo: 'basicInformations',
    officeInfo: 'basicInformations',
    affiliateInfo: 'affiliateAdvisor',
    sampleFeesInfo: 'sampleFees',
    promotions: 'promotions',
    inquiry: 'inquiry',
    inquiryCreate: 'inquiryCreate',
    inquiryDetail: 'inquiryDetail',
    consult: 'consult',
    consultDetail: 'consultDetail',
    consultResponse: 'consultResponse',
    consultRequestDetail: 'consultRequestDetail',
    consultPreview: 'consultPreview',
    announcement: 'announcement',
    announcementDetail: 'announcementDetail',
    interview: 'interview',
    invitation: 'invitation',
    invitationCreate: 'invitationCreate',
    productSave: 'productSave',
    inquiryProvider: 'inquiryProvider',
    previewInquiryProvider: 'previewInquiryProvider',
    productInquiry: 'productInquiry',
    productSearch: 'productSearch',
    providerDetail: 'providerDetail',
    searchResult: 'searchResult',
    productSearchDetail: 'productSearchDetail',
    productProgress: 'productProgress',
    providerInquiry: 'providerInquiry',
    countAccessAdvisor: 'countAccessAdvisor',
    concierge: 'concierge',
    conciergeCreate: 'conciergeCreate',
    conciergeDetail: 'conciergeDetail',
    officeConciergeAdvisor: 'officeConciergeAdvisor',
    providerIntroductionService: 'providerIntroductionService',
    notFound: 'NotFound'
}

const nameRouterFqa = {
    about: 'about',
    signin: 'signin',
    passwordReset: 'passwordReset',
    resetPassword: 'resetPassword',
    inquiry: 'inquiry',
    inquiryDetail: 'inquiryDetail',
    inquiryPreview: 'inquiryPreview',
    announcement: 'announcement',
    announcementDetail: 'announcementDetail',
    concierge: 'concierge',
    conciergeDetailGuest: 'conciergeDetailGuest',
    conciergeDetailAdvisor: 'conciergeDetailAdvisor',
    conciergePreview: 'conciergePreview',
    productSearchFQA: 'productSearchFQA',
    resultSearchProduct: 'resultSearchProduct',
    resultSearchProductDetail: 'resultSearchProductDetail',
    productSave: 'productSave',
    productPreview: 'productPreview',
    notFound: 'NotFound'
}

const nameRouterAdmin = {
    about: 'about',
    signin: 'signin',
    signup: 'signup',
    passwordReset: 'passwordReset',
    resetPassword: 'resetPassword',
    advisors: 'advisors',
    basicInformations: 'basicInformations',
    officeInformations: 'officeInformations',
    affiliateAdvisor: 'affiliateAdvisor',
    sampleFees: 'sampleFees',
    promotions: 'promotions',
    myPageDetail: 'myPageDetail',
    myPage: 'myPage',
    feeManagement: 'feeManagement',
    membershipFeeManagement: 'membershipFeeManagement',
    adminManagement: 'adminManagement',
    registerAdmin: 'registerAdmin',
    updateAdmin: 'updateAdmin',
    announcement: 'announcement',
    announcementCreate: 'announcementCreate',
    announcementEdit: 'announcementEdit',
    announcementDetail: 'announcementDetail',
    announcementPreview: 'announcementPreview',
    inquiry: 'inquiry',
    interview: 'interview',
    providersTemp: 'providersTemp',
    providersOfficial: 'providersOfficial',
    providersBasic: 'providersBasic',
    providersOffice: 'providersOffice',
    providersContract: 'providersContract',
    providersVideoPromotion: 'providersVideoPromotion',
    providersProduct: 'providersProduct',
    providersProductDetail: 'providersProductDetail',
    notFound: 'NotFound',
    invitationProviderAdmin: 'invitationProviderAdmin',
    invitationProviderDetailAdmin: 'invitationProviderDetailAdmin',
    countAccess: 'countAccess',
    myPageProviderDetail: 'myPageProviderDetail',
    settings: 'settings'
}

const typeProviderCustomer: ITypeSelectCustom[] = [
    {
        value: ValueTypeProvider.FREE,
        label: '無料'
    },
    {
        value: ValueTypeProvider.CHARGED,
        label: '有料'
    }
]

const titleTemplateMail = {
    answer_concierge:
        '【みつかる税理士】税理士紹介サービスへお問合せの件について'
}

const urlOliveConsulting = 'https://olive-consulting.co.jp/'

const listAreaSearch = [
    {
        id: 1,
        area_name: '北海道・東北',
        childrens: [
            {
                id: 2,
                area_name: '北海道'
            },
            {
                id: 3,
                area_name: '青森'
            },
            {
                id: 4,
                area_name: '秋田'
            },
            {
                id: 5,
                area_name: '岩手'
            },
            {
                id: 6,
                area_name: '山形'
            },
            {
                id: 7,
                area_name: '宮城'
            },
            {
                id: 8,
                area_name: '福島'
            }
        ]
    },
    {
        id: 9,
        area_name: '関東',
        childrens: [
            {
                id: 10,
                area_name: '東京'
            },
            {
                id: 73,
                area_name: '千葉'
            },
            {
                id: 133,
                area_name: '埼玉'
            },
            {
                id: 207,
                area_name: '神奈川'
            },
            {
                id: 269,
                area_name: '茨城'
            },
            {
                id: 314,
                area_name: '栃木'
            },
            {
                id: 340,
                area_name: '群馬'
            }
        ]
    },
    {
        id: 376,
        area_name: '北陸・甲信越',
        childrens: [
            {
                id: 377,
                area_name: '石川'
            },
            {
                id: 378,
                area_name: '富山'
            },
            {
                id: 379,
                area_name: '新潟'
            },
            {
                id: 380,
                area_name: '福井'
            },
            {
                id: 381,
                area_name: '長野'
            },
            {
                id: 382,
                area_name: '山梨'
            }
        ]
    },
    {
        id: 383,
        area_name: '東海',
        childrens: [
            {
                id: 384,
                area_name: '愛知'
            },
            {
                id: 439,
                area_name: '岐阜'
            },
            {
                id: 461,
                area_name: '三重'
            },
            {
                id: 476,
                area_name: '静岡'
            }
        ]
    },
    {
        id: 510,
        area_name: '関西',
        childrens: [
            {
                id: 511,
                area_name: '兵庫'
            },
            {
                id: 550,
                area_name: '京都'
            },
            {
                id: 577,
                area_name: '滋賀'
            },
            {
                id: 591,
                area_name: '大阪'
            },
            {
                id: 656,
                area_name: '奈良'
            },
            {
                id: 669,
                area_name: '和歌山'
            }
        ]
    },
    {
        id: 679,
        area_name: '四国・中国',
        childrens: [
            {
                id: 680,
                area_name: '山口'
            },
            {
                id: 681,
                area_name: '島根'
            },
            {
                id: 682,
                area_name: '鳥取'
            },
            {
                id: 683,
                area_name: '広島'
            },
            {
                id: 684,
                area_name: '岡山'
            },
            {
                id: 685,
                area_name: '愛媛'
            },
            {
                id: 686,
                area_name: '香川'
            },
            {
                id: 687,
                area_name: '高知'
            },
            {
                id: 688,
                area_name: '徳島'
            }
        ]
    },
    {
        id: 689,
        area_name: '九州・沖縄',
        childrens: [
            {
                id: 690,
                area_name: '福岡'
            },
            {
                id: 691,
                area_name: '大分'
            },
            {
                id: 692,
                area_name: '長崎'
            },
            {
                id: 693,
                area_name: '佐賀'
            },
            {
                id: 694,
                area_name: '熊本'
            },
            {
                id: 695,
                area_name: '宮崎'
            },
            {
                id: 696,
                area_name: '鹿児島'
            },
            {
                id: 697,
                area_name: '沖縄'
            }
        ]
    }
]

const videoOnline = {
    youtube: {
        name: 'youtube',
        url: 'https://www.youtube.com/embed/',
        domain: 'youtube.com'
    },
    facebook: {
        name: 'facebook',
        url: 'https://www.facebook.com/plugins/video.php?',
        domain: 'facebook.com'
    },
    twitter: {
        name: 'x.com',
        url: 'https://twitframe.com/show?url=https://twitter.com/nytimes/status/',
        domain: 'x.com'
    },
    instagram: {
        name: 'instagram',
        url: 'https://www.instagram.com/p/',
        domain: 'instagram.com'
    }
}

const domainUrlVideo = ['youtube.com', 'facebook.com', 'x.com', 'instagram.com']

const typeVideo = {
    offline: 1,
    online: 2
}

export {
    keyTypeScreenLogin,
    listKeyCodeNumber,
    loginPaths,
    statusAdvisor,
    YYYYMMDD,
    TypeSender,
    columnsListAnnounce,
    listStatusReadAnnounce,
    listSteps,
    listTypeMail,
    configEditor,
    configEditorWithSelectFont,
    defaultRememberEmailText,
    navBarTitle,
    logoutTime,
    titlePage,
    favicon,
    logo,
    perPage,
    jobDefaultCode,
    statusProvider,
    typeProvider,
    classificationProvider,
    statusTempProvider,
    statusOfficialProvider,
    introductionCodeProvider,
    pathTopScreen,
    nameRouterGuest,
    nameRouterProvider,
    nameRouterAdvisor,
    nameRouterFqa,
    nameRouterAdmin,
    typeProviderCustomer,
    titleTemplateMail,
    urlOliveConsulting,
    listAreaSearch,
    videoOnline,
    domainUrlVideo,
    typeVideo
}
