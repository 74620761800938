import { copyDeep } from '@/shared'
import { IPayloadCreateInquiryGuest } from '../../../shared/@types'
import InquiryGuestService from '@/modules/guest/modules/inquiry/services'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import { IStateStoreInquiryGuest } from '@/modules/guest/modules/inquiry/shared/@types'

export const defaultInquiryStateGuest: IStateStoreInquiryGuest = {
    data: {}
}

const state = () => copyDeep(defaultInquiryStateGuest)

const getters = {
    getDataInquiryGuest(state: IStateStoreInquiryGuest) {
        return state.data
    }
}

const mutations = {
    setDataInquiryGuest(state: IStateStoreInquiryGuest, payload: any) {
        state.data = payload
    },
    clearDataInquiryGuest(state: IStateStoreInquiryGuest) {
        Object.assign(state, copyDeep(defaultInquiryStateGuest))
    }
}
const actions = {
    async createInquiry({ commit }: any, payload: IPayloadCreateInquiryGuest) {
        try {
            commit('popup/setLoading', true, { root: true })
            commit('popup/setIsNotPageLoading', true, { root: true })
            const res = await InquiryGuestService.apiCreateInquiry(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataInquiryGuest', { refreshData: true })
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
