
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { isArray, formatTextRawHTML } from '@/shared/function'

export default Vue.extend({
    name: 'SelectBoxItem',
    props: {
        listCheckbox: {
            type: Array,
            required: true,
            default(): any[] {
                return []
            }
        },
        description: {
            type: String
        },
        classCustom: {
            type: String
        },
        classDescription: String,
        valueCheckbox: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        hasCheckAll: Boolean,
        label: String,
        maxChoose: {
            type: Number
        },
        isQualification: Boolean,
        checkTextHTML: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isCheckAll: false,
            isDisabledCheckboxItem: false,
            listValue: [] as any
        }
    },
    mounted() {
        this.checkDisabledCheckbox(this.valueCheckbox)
        if (
            isArray(this.valueCheckbox) &&
            this.valueCheckbox.includes(0) &&
            this.hasCheckAll &&
            !this.isQualification
        ) {
            this.isCheckAll = true
            this.listValue = this.listCheckbox.map((item: any) => item.value)
            this.updateVal(this.listValue)
        }
    },
    methods: {
        formatTextRawHTML,
        isCheckedCheckbox(value: number) {
            return this.valueCheckbox.find((e: any) => e === value)
        },
        disabledCheckbox(value: number) {
            return (
                this.isDisabledCheckboxItem &&
                !this.valueCheckbox.includes(value)
            )
        },
        onChangeAll(event: any) {
            const { checked } = event.target
            this.isCheckAll = checked
            this.listValue = checked
                ? this.listCheckbox.map((item: any) => item.value)
                : []
            this.updateVal(this.listValue)
        },
        onChangeCheckBox(event: any) {
            const { checked, _value } = event.target
            if (checked) {
                this.listValue.push(_value)
            } else {
                this.listValue = this.listValue.filter(
                    (e: number) => e !== _value
                )
            }
            this.isCheckAll = this.listValue.length === this.listCheckbox.length
            this.updateVal(this.listValue)
        },
        updateVal(value: any[]) {
            this.$emit('changeVal', value)
        },
        checkDisabledCheckbox(listValue: any[]) {
            if (this.maxChoose) {
                this.isDisabledCheckboxItem =
                    this.maxChoose === listValue.length
            }
        }
    },
    watch: {
        valueCheckbox: {
            handler(newVal) {
                this.listValue = newVal
                this.checkDisabledCheckbox(newVal)
            },
            deep: true,
            immediate: true
        }
    }
})
