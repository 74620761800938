import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { i18n } from '@/shared/i18n'
import '@/assets/index.css'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import '@/assets/lib/css/common.css'
import '@/assets/lib/css/page.css'
import '@/assets/lib/css/custom.css'
import '@/assets/lib/css/my-page.css'
import '@/assets/lib/css/mbr-sign.css'
import '@/assets/lib/css/tax-cons-admin.css'

Vue.config.productionTip = false

Vue.use(Antd)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
