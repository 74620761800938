
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import Title from '@/components/common_new/popup/title.vue'
import { mapGetters } from 'vuex'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'PopupNotiPublicProduct',
    components: {
        Title,
        ButtonCustom
    },
    data(): any {
        return {
            message: '非公開が設定されていますが良いですか？',
            disabled: false
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    mounted() {
        if (this.getParamsPopup?.message)
            this.message = this.getParamsPopup?.message
    },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        async handleSubmit() {
            this.disabled = true
            let url = this.getParamsPopup?.id
                ? 'productProvider/updateDataProduct'
                : 'productProvider/createDataProduct'

            const res: any = await this.$store.dispatch(
                url,
                this.getParamsPopup
            )

            if (res) {
                this.$router.push(RouterProviderUrl.products)
                this.$store.dispatch('popup/hidePopup')
            }
        }
    }
})
