import { copyDeep } from '@/shared'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import ProviderService from '../services'
import { NotificationCustom } from '@/shared/function'
import router from '@/router'
import { RouterAdminFQA } from '@/modules/adminFQA/shared/enums'
import store from '@/store'

export const defaultStateProvider: any = {
    dataInfoProvider: {},
    dataProductsProvider: [],
    productInfo: {},
    providerRate: []
}
const state = () => copyDeep(defaultStateProvider)

const getters = {
    getDataInfoProvider(state: any) {
        return state.dataInfoProvider
    },
    getDataProductsProvider(state: any) {
        return state.dataProductsProvider
    },
    getProductInfo(state: any) {
        return state.productInfo
    },
    getProviderRate(state: any) {
        return state.providerRate
    }
}

const mutations = {
    setDataInfoProvider(state: any, payload: any) {
        state.dataInfoProvider = payload
    },
    setDataProductsProvider(state: any, payload: any) {
        state.dataProductsProvider = payload
    },
    setProductInfo(state: any, payload: any) {
        state.productInfo = payload
    },
    setProviderRate(state: any, payload: any) {
        state.providerRate = payload
    }
}

const actions = {
    async getInfoProvince({ commit, dispatch }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderService.apiGetInfoProviderByProductId(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInfoProvider', data.data)
                await store.dispatch(
                    'adminFQAProduct/getRateProvider',
                    data.data?.provider_basic_infos?.user_provider_id
                )
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })

            router.push(RouterAdminFQA.inquiry)
            return 'back'
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },

    async getProductsProvince({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderService.apiGetProductsProvider(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataProductsProvider', data.data)
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            router.push(RouterAdminFQA.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getRateProvider({ commit }: any, payload: any) {
        try {
            commit('popup/setLoading', true, { root: true })
            const res = await ProviderService.apiGetProviderRate(payload)
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setProviderRate', data.data)
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDetailProvider({ commit }: any, payload: any) {
        commit('popup/setLoading', true, { root: true })
        try {
            const [provider] = await Promise.all([
                ProviderService.apiGetDetailProvider(payload)
            ])
            const { status: statusProvider, data: dataProvider } = provider
            if (statusProvider === HttpStatusCode.ok) {
                commit('setDataInfoProvider', dataProvider.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: dataProvider.errors.error_message
            })
            return 'back'
        } catch (error) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
