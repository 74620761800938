
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import { defineComponent } from 'vue'
import { TypeInputCustom } from '@/shared/enums'
import _ from 'lodash'

export default defineComponent({
    name: 'SelectCustom',
    props: {
        label: {
            type: String
        },
        classLabel: {
            type: String
        },
        classSelect: {
            type: String
        },
        classFormItem: {
            type: String
        },
        funcCustom: {
            type: Function
        },

        nameInput: {
            type: String,
            required: true
        },
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        formInstance: {
            type: Object,
            required: true
        },
        typeInputCustom: {
            type: String,
            default: TypeInputCustom.text
        },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        optionSelect: {
            type: Array,
            default() {
                return []
            }
        },
        afterText: String,
        layoutTable: {
            type: Boolean,
            default: false
        },
        modeSelect: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            TypeInputCustom
        }
    },
    methods: {
        handleChange(e: any) {
            this.$emit('changeSelect', e)
        },
        triggerSelectInPopup(trigger: HTMLElement) {
            return trigger.parentNode
        }
    }
})
