
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { ListNamePopup } from '@/shared/enums'
import { mapGetters } from 'vuex'
import {
    CheckboxProductGenre,
    CheckboxProductFeature
} from '@/modules/provider/modules/signup/shared/constants'
import { ITypeSelectCustom } from '@/shared/@types'

export default Vue.extend({
    name: 'BusinessRecruiter',
    components: { TitlePopup },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    data(): any {
        return {
            CheckboxProductGenre: [] as ITypeSelectCustom[],
            CheckboxProductFeature: [] as ITypeSelectCustom[],
            listGenre: []
        }
    },
    mounted() {
        for (const key in CheckboxProductGenre) {
            this.listGenre.push(CheckboxProductGenre[key])
        }
    },
    methods: {
        handleClose() {
            if (this.getParamsPopup.openFromLoginSupport) {
                this.$store.dispatch('popup/openPopup', {
                    isOpen: true,
                    namePopup: ListNamePopup.LoginSupport
                })
                return
            }
            this.$store.dispatch('popup/hidePopup')
        },
        renderData(item: string) {
            const valueArray = item.split('|').map(Number)
            const labels = CheckboxProductFeature.filter(option =>
                valueArray.includes(option.value)
            )
            return labels
        }
    }
})
