import apiServices from '@/shared/services/api/axios'

const paymentProviderService = {
    apiGetPaymentHistoryProvider(payload: any) {
        let url = '/provider/payment-history'

        if (payload) {
            let index = 0
            for (const key in payload) {
                if (payload[key]) {
                    if (!index) {
                        url += `?${key}=${payload[key]}`
                    }
                    if (index) {
                        url += `&${key}=${payload[key]}`
                    }
                    index++
                }
            }
        }
        return apiServices.get(url)
    }
}
export default paymentProviderService
