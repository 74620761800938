
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import InputCustom from '@/components/common_new/input/index.vue'
import { RouterAdminUrl } from '@/modules/admin/shared/enums'
import {
    OPTION_CHOOSE_STATUS,
    OPTION_CHOOSE_TYPE
} from '../../shared/constants'
import { scrollToFirstErrorLineForm } from '@/shared/function'
export default Vue.extend({
    name: 'FormConfirm',
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        checkEditAdmin(): boolean {
            return this.$route.path === RouterAdminUrl.updateAdmin
        }
    },
    data(): any {
        return {
            formConfirmAdmin: this.$form.createForm(this, {
                name: 'Admin_Confirm'
            }),
            titlePopup: '管理者登録',
            role: '',
            status: '',
            getInfoCreate: {} as any
        }
    },
    components: { Title, ButtonCustom, InputCustom },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleConfirm() {
            if (this.checkEditAdmin) {
                this.handleUpdate()
                return
            }
            this.handleRegister()
        },
        handleRegister() {
            this.formConfirmAdmin.validateFields(
                async (errors: Error[], values: any) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const body = {
                        ...values,
                        type: Number(this.getParamsPopup.type)
                    }
                    const payload = {
                        user_admins: {
                            ...body
                        }
                    }
                    const res = await this.$store.dispatch(
                        'managementAdmin/postRegisterAdmin',
                        payload
                    )
                    if (res) {
                        await this.$store.dispatch('popup/hidePopup')
                        await this.$router.push(RouterAdminUrl.adminManagement)
                    }
                }
            )
        },
        handleUpdate() {
            this.formConfirmAdmin.validateFields(
                async (errors: Error[], values: any) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const body = {
                        ...values,
                        type: Number(this.getParamsPopup.type),
                        status: Number(this.getParamsPopup.status),
                        id: Number(Object.values(this.$route.query)[0])
                    }
                    const payload = {
                        user_admins: {
                            ...body
                        }
                    }
                    const res = await this.$store.dispatch(
                        'managementAdmin/postUpdateAdmin',
                        payload
                    )
                    if (res) {
                        await this.$store.dispatch('popup/hidePopup')
                        await this.$router.push(RouterAdminUrl.adminManagement)
                    }
                }
            )
        }
    },
    mounted() {
        this.getInfoCreate = this.getParamsPopup
        if (this.getInfoCreate) {
            this.formConfirmAdmin.setFieldsValue({
                user_name: this.getInfoCreate.user_name,
                email: this.getInfoCreate.email
            })
            this.role = OPTION_CHOOSE_TYPE.find(
                e => e.value === Number(this.getInfoCreate.type)
            )?.label
            this.status = OPTION_CHOOSE_STATUS.find(
                e => e.value === Number(this.getInfoCreate.status)
            )?.label
        }

        if (this.checkEditAdmin) {
            this.titlePopup = '管理者更新'
        }
    }
})
