var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form',{staticClass:"formGuest",attrs:{"form":_vm.form}},[_c('h1',[_vm._v("税理士事務所詳細")]),_c('div',{staticClass:"imgTabTitle"},[_c('div',{staticClass:"image",on:{"click":function($event){_vm.zoomImage(
                    _vm.customUrlImage(
                        _vm.getDataGuest?.tax_advisor_basic_infos?.image
                    )
                )}}},[(_vm.getDataGuest?.tax_advisor_basic_infos?.image)?_c('img',{attrs:{"src":_vm.customUrlImage(
                        _vm.getDataGuest?.tax_advisor_basic_infos?.image
                    ),"alt":"oliver-consulting"}}):_c('span',[_vm._v("画像")])]),_c('div',{staticClass:"inforTab"},[_c('h2',[_vm._v(" "+_vm._s(_vm.getDataGuest?.tax_advisor_office_infos?.office_name)+" ")]),_c('div',{staticClass:"inforPrivate"},[_c('span',[(
                            _vm.getDataGuest?.tax_advisor_basic_infos?.zip_code
                        )?_c('a-icon',{attrs:{"type":"environment"}}):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.getDataGuest?.tax_advisor_basic_infos?.zip_code))])],1),_c('span',[_vm._v(_vm._s(_vm.getDataGuest?.tax_advisor_basic_infos?.address))])]),_c('div',{staticClass:"inforPrivate"},[_c('span',[(_vm.getDataGuest?.tax_advisor_basic_infos?.phone)?_c('a-icon',{attrs:{"type":"phone"}}):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.getDataGuest?.tax_advisor_basic_infos?.phone))])],1)]),_c('div',{staticClass:"inforPrivate"},[_c('span',[_c('label',[_vm._v("代表税理士")])]),_c('span',[_vm._v(_vm._s(_vm.getDataGuest?.user?.tax_advisor_name))])]),_c('div',{staticClass:"inforPrivate"},[_c('span',[_c('label',[_vm._v("税理士登録番号")])]),_c('span',[_vm._v(_vm._s(_vm.getDataGuest?.user?.tax_advisor_number))])]),_c('div',{staticClass:"inforPrivate"},[_c('span',[_c('label',[_vm._v("ホームページ")])]),_c('a',{on:{"click":function($event){return _vm.openTab(
                            _vm.getDataGuest?.tax_advisor_basic_infos?.home_page
                        )}}},[_vm._v(_vm._s(_vm.getDataGuest?.tax_advisor_basic_infos?.home_page)+" ")])]),_c('div',{staticClass:"inforPrivate"},[_c('span',[_c('label',[_vm._v("アクセス")])]),_c('span',[_vm._v(_vm._s(_vm.getDataGuest?.tax_advisor_basic_infos?.transportation))])])])]),_c('TabsCustom',{attrs:{"dataTab":_vm.listDataTab,"onActions":_vm.onChangeTab}}),_c('router-view'),_c('PopupCommon')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }