
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Title from '@/components/common_new/popup/title.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'

export default Vue.extend({
    name: 'ConfirmDeleteProduct',
    components: { ButtonCustom, Title },
    data() {
        return {
            formDelete: this.$form.createForm(this, {
                name: 'form_delete'
            }),
            isProductContract: false
        }
    },
    mounted() {
        this.isProductContract = this.getParamsPopup?.contract_product_flag
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        handleSubmit() {
            this.$store.dispatch('productProvider/deleteProduct', {
                id: this.getParamsPopup?.id
            })
        }
    }
})
