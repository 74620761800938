import apiServices from '@/shared/services/api/axios'

const AdminSettingService = {
    apiGetSetting() {
        return apiServices.get('/admin/setting_fees')
    },

    apiPostSetting(payload: any) {
        return apiServices.post('/admin/setting_fees', payload)
    }
}

export default AdminSettingService
