
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import LayoutAdmin from '@/components/layouts/admin.vue'
import LayoutAdminNew from '@/components/layouts/adminNew.vue'
import LayoutProductDetail from '@/components/layouts/product-detail.vue'
import layoutAdminAdvisor from '@/components/layouts/adminAdvisor.vue'
import layoutAdminFQAProvider from '@/components/layouts/adminFQAProvider.vue'
import LayoutDefault from '@/components/layouts/default.vue'
import LayoutGuest from '@/modules/guest/components/LayoutGuest.vue'
import LayoutGuestAnnouncement from '@/modules/guest/components/layout-guest-announcement/index.vue'
import { titlePage } from '@/shared/constants'

export default Vue.extend({
    components: {
        LayoutAdmin,
        LayoutAdminNew,
        LayoutProductDetail,
        LayoutDefault,
        LayoutGuest,
        LayoutGuestAnnouncement,
        layoutAdminAdvisor,
        layoutAdminFQAProvider
    },
    data() {
        return {
            layout: 'div'
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || titlePage.portal
                const link = document.querySelector("[rel='icon']")
                link?.setAttribute('href', to.meta.icon)
            }
        }
    }
})
