import { copyDeep } from '@/shared'
import {
    IPayloadCreateInquiryAdvisor,
    IStateStoreInquiryAdvisor,
    ITypeParamsDetailInquiryAdvisor,
    IPayloadDeleteInquiryAdvisor,
    ITypePayloadAnswerInquiryAdvisor
} from '@/modules/advisor/modules/inquiry/shared/@types'
import { HttpStatusCode, TypeNotify } from '@/shared/enums'
import { NotificationCustom } from '@/shared/function'
import InquiryProviderService from '@/modules/provider/modules/inquiry/services'
import { RouterProviderUrl } from '@/modules/provider/shared/enums'
import router from '@/router'

export const defaultStateInquiryAdmin: IStateStoreInquiryAdvisor = {
    data: {}
}

const state = () => copyDeep(defaultStateInquiryAdmin)

const getters = {
    getDataInquiryProvider(state: IStateStoreInquiryAdvisor) {
        return state.data
    }
}

const mutations = {
    setDataInquiryProvider(state: IStateStoreInquiryAdvisor, payload: any) {
        state.data = payload
    }
}

const actions = {
    async getDataInquiryProvider(
        { commit }: any,
        payload: IPayloadCreateInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InquiryProviderService.apiGetInquiryProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryProvider', data.data)
                return
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async getDataDetailInquiry(
        { commit, dispatch }: any,
        params: ITypeParamsDetailInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InquiryProviderService.apiGetInquiryDetailAdvisor(
                params
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                commit('setDataInquiryProvider', data.data)
                dispatch('signupProvider/countNotifyProvider', true, {
                    root: true
                })
                return data.data
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterProviderUrl.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createInquiryProvider(
        { commit, dispatch }: any,
        payload: IPayloadCreateInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res = await InquiryProviderService.apiCreateInquiryProvider(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async createInquiryAnswer(
        { commit, dispatch }: any,
        payload: ITypePayloadAnswerInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InquiryProviderService.apiCreateInquiryAnswerAdvisor(
                    payload
                )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                dispatch('getDataDetailInquiry', { id: payload?.inquiry_id })
                return true
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            await router.push(RouterProviderUrl.inquiry)
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    },
    async deleteInquiryProvider(
        { commit, dispatch }: any,
        payload: IPayloadDeleteInquiryAdvisor
    ) {
        commit('popup/setLoading', true, { root: true })
        commit('popup/setIsNotPageLoading', true, { root: true })
        try {
            const res = await InquiryProviderService.apiDeleteInquiryAdvisor(
                payload
            )
            const { status, data } = res
            if (status === HttpStatusCode.ok) {
                NotificationCustom({
                    type: TypeNotify.success,
                    message: data.message
                })
                commit('popup/hidePopup', null, { root: true })
                commit('setDataInquiryProvider', {
                    refreshData: true
                })
                dispatch('signupProvider/countNotifyProvider', true, {
                    root: true
                })
            }
            NotificationCustom({
                type: TypeNotify.error,
                message: data.errors.error_message
            })
            commit('popup/hidePopup', null, { root: true })
            commit('setDataInquiryProvider', {
                refreshData: true
            })
            dispatch('signupProvider/countNotifyProvider', true, {
                root: true
            })
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
            commit('popup/setIsNotPageLoading', false, { root: true })
        }
    },
    async updateStatusReadInquiryProvider(
        { commit, dispatch }: any,
        id: number
    ) {
        commit('popup/setLoading', true, { root: true })
        try {
            const res =
                await InquiryProviderService.apiUpdateStatusReadInquiryProvider(
                    id
                )
            if (res.status === HttpStatusCode.ok) {
                dispatch('signupProvider/countNotifyProvider', true, {
                    root: true
                })
                return
            }
        } catch (e) {
        } finally {
            commit('popup/setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
