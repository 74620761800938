import advisorAdmin from '@/modules/admin/modules/advisors/store'
import popup from '@/store/popup'
import signupAdvisor from '@/modules/advisor/modules/signup/store'
import managementAdmin from '@/modules/admin/modules/admin-management/store'
import inquiryAdminFqa from '@/modules/adminFQA/modules/inquiry/store'
import announcementAdminFqa from '@/modules/adminFQA/modules/announcement/store'
import conciergeAdminFqa from '@/modules/adminFQA/modules/concierge/store'
import announcement from '@/modules/admin/modules/announcement/store'
import myPageAdmin from '@/modules/admin/modules/my-page/store'
import signupAuth from '@/modules/auth/store'
import pageTitle from '@/store/page-title'
import providerAdmin from '@/modules/admin/modules/providers/store'
import signupProvider from '@/modules/provider/modules/signup/store'
import invitedProvider from '@/modules/admin/modules/invitation/store'
import settingAdmin from '@/modules/admin/modules/settings/store'
import countAccess from '@/modules/admin/modules/countAccess/store'
import productProvider from '@/modules/provider/modules/product/store'
import productSearch from '@/modules/adminFQA/modules/product-search/store'
import adminFQAProduct from '@/modules/adminFQA/modules/product-preview/store'
import advisorInquiryProvider from '@/modules/advisor/modules/product-inquiry/store'
import feeManagement from '@/modules/admin/modules/fee-management/store'

export const storeAdmin = {
    popup,
    advisorAdmin,
    signupAdvisor,
    managementAdmin,
    inquiryAdminFqa,
    announcement,
    announcementAdminFqa,
    conciergeAdminFqa,
    myPageAdmin,
    signupAuth,
    pageTitle,
    providerAdmin,
    signupProvider,
    invitedProvider,
    settingAdmin,
    countAccess,
    productProvider,
    productSearch,
    adminFQAProduct,
    advisorInquiryProvider,
    feeManagement
}
