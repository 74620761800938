
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TabsCustom from '@/components/common/tabs/index.vue'
import { mapGetters } from 'vuex'
import { customUrlImage, zoomImage } from '@/shared/function'
import { RouterGuest } from '../shared/enums'
import PopupCommon from '@/components/common/popup/index.vue'
import { ListNamePopup } from '@/shared/enums'

export default Vue.extend({
    name: 'LayoutGuest',
    data() {
        return {
            form: this.$form.createForm(this, {
                name: 'form'
            }),
            customUrlImage,
            listDataTab: [
                {
                    title: '事務所情報',
                    name: 'OfficeInfo',
                    path: RouterGuest.officeInformations
                },
                {
                    title: ' 所属税理士',
                    name: 'affiliateAdvisor',
                    path: RouterGuest.affiliateAdvisor
                },
                {
                    title: '料金・事例',
                    name: 'sampleFees',
                    path: RouterGuest.sampleFees
                },
                {
                    title: 'インタビュー',
                    name: 'interview',
                    path: RouterGuest.interview
                }
            ]
        }
    },
    components: { TabsCustom, PopupCommon },
    props: {
        hideBtnImage: {
            type: Boolean
        },
        readOnly: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        }
    },
    mounted() {
        this.getDataGuestOfficeInfoById()
        this.getLoadData()
    },
    computed: {
        ...mapGetters('Guest', ['getDataGuest'])
    },
    methods: {
        zoomImage,
        onChangeTab(item: any) {
            const { path, query } = this.$route
            if (path === item.path) {
                return
            }
            this.$router.push(
                item.path + '?advisor-office-id=' + query['advisor-office-id']
            )
        },
        getDataGuestOfficeInfoById() {
            const id = Number(Object.values(this.$route.query)[0])
            this.$store.dispatch('Guest/getDataListGuest', id)
        },
        openTab(val: any) {
            if (!val) {
                return
            }
            window.open(val)
        },
        getLoadData() {
            const id = this.$route.query['advisor-office-id']
            this.$store.dispatch('Guest/getLoadViewOffice', {
                office_id: id
            })
        }
    }
})
