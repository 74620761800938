
// ******************
//
// Ver.1.0
// Released on: July 27, 2023
// Phase 1.0
//
// ******************
import Vue from 'vue'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { ListNamePopup } from '@/shared/enums'

export default Vue.extend({
    name: 'TermOfServiceWithdrawal',
    components: { TitlePopup },
    methods: {
        closePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        openWithdrawalModal() {
            this.$store.dispatch('popup/openPopup', {
                isOpen: true,
                namePopup: ListNamePopup.Withdrawal,
                width: 1000
            })
        }
    }
})
