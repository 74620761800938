
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'
import {
    customUrlImage,
    customUrlPdf,
    isObject,
    openNewWindow,
    formatTextareaContent
} from '@/shared/function'
import {
    CheckboxProductGenre,
    CheckboxProductFeature
} from '@/modules/provider/modules/signup/shared/constants'
import { ITypeSelectCustom } from '@/shared/@types'
import { ProductDetail } from '../../shared/@types'
import { generateValueCheckbox } from '@/modules/advisor/modules/signup/shared/functions'

type IData = {
    dataProduct: object
    CheckboxProductGenre: ITypeSelectCustom[]
    CheckboxProductFeature: ITypeSelectCustom[]
    isShowDetail: boolean
    listFeature: object
    listGenre: object
}
export default Vue.extend({
    name: 'ProductDetail',
    computed: {
        ...mapGetters('popup', ['getParamsPopup'])
    },
    props: {
        product: Object as PropType<ProductDetail>,
        item: Number
    },
    data(): IData {
        return {
            isShowDetail: false,
            CheckboxProductGenre,
            CheckboxProductFeature,
            dataProduct: {} as ProductDetail,
            listFeature: [],
            listGenre: []
        }
    },
    mounted() {
        this.dataProduct = isObject(this.getParamsPopup)
            ? this.getParamsPopup
            : this.product
        if (this.dataProduct.genre === '0') {
            for (const key in CheckboxProductGenre) {
                this.listGenre.push(key)
            }
            this.listGenre = this.listGenre.join('|')
        }
        if (this.dataProduct.product_feature === '0') {
            for (const key in CheckboxProductFeature) {
                this.listFeature.push(key)
            }
            this.listFeature = this.listFeature.join('|')
        }
    },
    methods: {
        openNewWindow,
        customUrlPdf,
        customUrlImage,
        formatTextareaContent,
        renderDataCheckbox(
            listCheck: any,
            dataName: string,
            name: string,
            isAll?: boolean
        ) {
            const result = generateValueCheckbox(
                listCheck,
                dataName?.split('|').map(Number),
                name,
                isAll
            )
            return result[`${name}_name`].join('　')
        },
        toggleAccordion(type: boolean) {
            return (this.isShowDetail = !type)
        }
    }
})
