import popup from '@/store/popup'
import Guest from '@/modules/guest/modules/search-advisor/store'
import announcementGuest from '@/modules/guest/modules/announcement/store'
import inquiryGuest from '@/modules/guest/modules/inquiry/store'
import concierge from '@/modules/guest/modules/concierge/store'
import loading from '@/store/loading'
import pageTitle from '@/store/loading'

export const storeGuest = {
    popup,
    Guest,
    announcementGuest,
    inquiryGuest,
    concierge,
    loading,
    pageTitle
}
