
// ******************
//
// Ver.1.0
// Released on: Nov 15, 2023
// Phase 1.5
//
// ******************
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
    isArray,
    customUrlImage,
    customUrlPdf,
    zoomImage,
    openNewWindow
} from '@/shared/function'
import { TypeInputCustom, ImageChoose } from '@/shared/enums'
import {
    listPublicFlg,
    listPublicFlgResponse,
    listIntroductionFee
} from '@/modules/provider/modules/product/shared/constants/index'
import {
    CheckboxServiceDomain,
    CheckboxServiceContent,
    CheckboxProductGenre
} from '@/modules/provider/modules/signup/shared/constants'
import { regex } from '@/shared/regex'
import moment from 'moment'
import { renderGenreAndFeature } from '@/modules/provider/shared/function'
import { getRawHTML } from '@/shared/function'
import { ITypeSelectCustom } from '@/shared/constants/IContants'

export default Vue.extend({
    name: 'ProviderProductPreview',
    computed: {
        ...mapGetters('signupProvider', ['getProductInfo'])
    },
    data(): any {
        return {
            TypeInputCustom,
            ImageChoose,
            listPublicFlg,
            listPublicFlgResponse,
            listIntroductionFee,
            productImage1: '',
            productImage2: '',
            productImage3: '',
            notUpdate: 'notUpdate',
            valueAll: 0,
            isSubmit: false,
            form: this.$form.createForm(this, {
                name: 'form'
            }),
            productInfo: {},
            CheckboxServiceDomain,
            CheckboxServiceContent,
            CheckboxProductGenre,
            listImage: [],
            imageSelected: '',
            countImg: 0,
            isSearchProvider:
                window.location.pathname.includes('provider-search'),
            imageActive: 0
        }
    },
    methods: {
        openNewWindow,
        customUrlImage,
        customUrlPdf,
        renderGenreAndFeature,
        zoomImage,
        getRawHTML,
        renderLabel(array: any[], status: number) {
            return array.reduce((preVal: any, nextItem: ITypeSelectCustom) => {
                if (status !== null && nextItem.value === status) {
                    return nextItem.label
                }
                return preVal
            }, '')
        },
        customNameDisplay(listName: string[]) {
            return isArray(listName)
                ? listName.toString().replaceAll(',', '　')
                : ''
        },
        customUrlOfficeImage(valueImage: string): string {
            if (!valueImage) {
                return ''
            }
            if (regex.base64.test(valueImage)) {
                return valueImage
            }
            if (regex.urlHttps.test(valueImage)) {
                return valueImage
            }
            return process.env.VUE_APP_URL_IMAGE + valueImage
        },
        setImageProduct() {
            this.productImage1 =
                this.productInfo?.imageName1 ??
                this.customUrlOfficeImage(this.productInfo?.image1)
            this.productImage2 =
                this.productInfo.imageName2 ??
                this.customUrlOfficeImage(this.productInfo?.image2)
            this.productImage3 =
                this.productInfo?.imageName3 ??
                this.customUrlOfficeImage(this.productInfo?.image3)

            if (this.productImage1) {
                this.imageActive = 1
                this.imageSelected = this.productImage1
            } else if (this.productImage2) {
                this.imageActive = 2
                this.imageSelected = this.productImage2
            } else {
                this.imageActive = 3
                this.imageSelected = this.productImage3
            }

            this.listImage = [
                {
                    value: 1,
                    url: this.productImage1
                },
                {
                    value: 2,
                    url: this.productImage2
                },
                {
                    value: 3,
                    url: this.productImage3
                }
            ]

            this.listImage.forEach((img: any) => {
                if (img.url) {
                    this.countImg++
                }
            })
        },
        nameGenreAndFeature() {
            let nameGenreAndFeature: string[] = []
            this.productInfo?.genre?.forEach((genre: number) => {
                nameGenreAndFeature = [
                    ...nameGenreAndFeature,
                    ...this.renderGenreAndFeature(
                        genre,
                        this.productInfo.product_feature
                    )
                ]
            })

            return nameGenreAndFeature.join('　')
        },
        formatDate(date: string) {
            const dateFormat = moment(date).format('YYYY/MM/DD')
            return dateFormat != 'Invalid date' ? dateFormat : ''
        },

        handleSelectImg(index: number) {
            this.imageActive = index
            this.imageSelected = this.listImage.find(
                (img: any) => img.value === index
            ).url
        },
        checkLinkPdf(link: string) {
            const condition = link.includes('blob:')
            return condition ? link : this.customUrlPdf(link)
        },
        getClassListImg() {
            if (this.countImg === 0 || this.countImg === 1) {
                return 'justify-content-center'
            } else if (this.countImg === 3) {
                return 'justify-content-between'
            } else {
                return 'gap-2'
            }
        }
    },
    watch: {
        getProductInfo: {
            handler(newValue) {
                this.countImg = 0
                this.productInfo = { ...newValue }
                this.productInfo.areaName =
                    this.productInfo?.area_name?.join('　')
                this.productInfo.businessName =
                    newValue?.bussiness_classifications_id
                let genre = newValue?.genre?.split('|').map(Number)
                let genre_name = genre
                    ?.map((value: any) => {
                        return this.CheckboxServiceDomain[value]
                    })
                    .join('　')

                let product_feature = newValue?.product_feature
                    ?.split('|')
                    .map(Number)
                let product_feature_name = product_feature
                    ?.map((item: any) => this.CheckboxServiceContent[item])
                    .join('　')

                this.productInfo.genre = genre
                this.productInfo.genreName = genre_name
                this.productInfo.product_feature = product_feature
                this.productInfo.productFeatureName = product_feature_name

                this.setImageProduct()
            },
            immediate: true
        }
    }
})
