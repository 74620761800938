
import {
    isArray,
    scrollToFirstErrorLineForm,
    NotificationCustom
} from '@/shared/function'
import { regex } from '@/shared/regex'
import Vue from 'vue'
import InputCustom from '@/components/common_new/input/index.vue'
import SelectCustom from '@/components/common/select/index.vue'
import ButtonCustom from '@/components/common_new/button-custom/index.vue'
import { WrappedFormUtils } from 'ant-design-vue/types/form/form'
import TitlePopup from '@/components/common_new/popup/title.vue'
import { TypeInputCustom, TypeNotify } from '@/shared/enums'
import { mapGetters } from 'vuex'

type IDataInit = {
    formTaxAccounting: WrappedFormUtils
    TypeInputCustom: object
    contentLength: number
}
export default Vue.extend({
    name: 'AddContractProvider',
    components: { TitlePopup, ButtonCustom, SelectCustom, InputCustom },
    data(): IDataInit {
        return {
            formTaxAccounting: this.$form.createForm(this, {
                name: 'form_add_contract_provider'
            }),
            TypeInputCustom,
            contentLength: 0
        }
    },
    computed: {
        ...mapGetters('popup', ['getParamsPopup']),
        dataListTax(): object {
            return isArray(this.$store.state.popup.payload)
                ? this.$store.state.popup.payload
                : []
        }
    },
    methods: {
        onClosePopup() {
            this.$store.dispatch('popup/hidePopup')
        },
        listDataProduct() {
            return this.getParamsPopup.list_product || []
        },
        handleSubmit() {
            this.formTaxAccounting.validateFields(
                (errors: Error[], values: any) => {
                    if (errors) {
                        scrollToFirstErrorLineForm()
                        return
                    }
                    const { ...payload } = values
                    payload.product_id = [
                        values.product_id_one ?? '',
                        values.product_id_two ?? '',
                        values.product_id_three ?? ''
                    ].filter(item => item !== '')

                    const set = new Set(payload.product_id)

                    if (payload.product_id.length !== set.size) {
                        NotificationCustom({
                            type: TypeNotify.error,
                            message: this.$tc('i18n_provider_public_flg')
                        })
                        return
                    }
                    this.$store.dispatch(
                        'signupProvider/createContractInformations',
                        payload
                    )
                }
            )
        },
        rulesContractTitle() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length50.test(value)) {
                                callback(
                                    'タイトルは50文字以内で入力してください。'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesContractContent() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length500.test(value)) {
                                callback(
                                    '成約事例は500文字以内で入力してください。'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        },
        rulesContractName() {
            return [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value) {
                            if (!regex.length50.test(value)) {
                                callback(
                                    '商材名は50文字以内で入力してください。'
                                )
                                return
                            }
                        }
                        callback()
                    }
                }
            ]
        }
    }
})
